import React from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '~/lib/utils'

export const typographyVariants = cva('yns-typography m-0', {
  variants: {
    variant: {
      title1: 'font-roboto-slab text-[2rem] font-bold tracking-wide',
      title2: 'font-roboto-slab text-2xl font-normal',
      title3: 'font-roboto-slab text-xl font-normal',
      title4: 'font-roboto text-xl font-medium',
      subtitle: 'font-roboto text-lg font-medium',
      body: 'font-roboto text-base font-normal leading-6',
      small: 'font-roboto text-sm font-normal',
    },
    color: {
      default: 'text-cod-gray-800',
      primary: 'text-primary',
      secondary: 'text-intoo-green-medium',
    },
  },
  defaultVariants: { variant: 'body', color: 'default' },
})

const typographyElementMap = {
  title1: 'h1',
  title2: 'h2',
  title3: 'h3',
  title4: 'h4',
}

const Typography = React.forwardRef(
  ({ children, className, variant, color, component, ...props }, ref) => {
    const Comp = component || typographyElementMap[variant] || 'div'
    return (
      <Comp
        className={cn(typographyVariants({ variant, color, className }))}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    )
  },
)
Typography.displayName = 'Typography'

export default Typography
