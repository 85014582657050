import React from 'react'
import { DocumentPage, Text, View, StyleSheet } from '../../lib/pdf'

export const defaultStyle = {
  xMargin: 0.4,
  yMargin: 0.4,
  primaryFontFamily: 'Inter',
  orientation: 'landscape',
  accentColor: '#000000',
}

const isNotBlank = (item) => ![null, undefined, ''].includes(item)

export const Template = ({ jsonDocument, templateStyle }) => {
  const { accentColor } = templateStyle

  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      width: '100%',
    },
    heading: {
      fontSize: '16px',
      fontWeight: 'bold',
      color: accentColor,
    },
    sectionTitle: {
      fontSize: '12px',
      fontWeight: 'bold',
      marginTop: '10px',
      marginBottom: '5px',
    },
    borderBottom: {
      borderBottomWidth: '2px',
      borderBottomColor: accentColor,
    },
    flexRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    mb10FlexRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '10px',
    },
    my10: {
      marginTop: '10px',
      marginBottom: '10px',
    },
    flexStart: {
      justifyContent: 'flex-start',
    },
    strong: {
      fontSize: '12px',
      fontWeight: 'bold',
    },
    width50: {
      width: '50%',
    },
  })

  const {
    basics,
    professionalIdentity,
    skills,
    callToAction,
    careerHighlights,
    targetCompanies,
    targetRoles,
    goals,
  } = jsonDocument
  const { name, position, companyCityState, phone, email, linkedin, company } =
    basics || {}
  const { personalBrandStatement } = professionalIdentity || {}
  const { professionalAchievements } = careerHighlights || {}
  const { callToActionStatement } = callToAction || {}
  const { networkingGoals } = goals || {}

  return (
    <DocumentPage templateStyle={templateStyle}>
      <HeaderSection
        name={name}
        email={email}
        phone={phone}
        linkedinProfile={linkedin}
        styles={styles}
      />
      <PersonalInfoSection
        position={position}
        company={company}
        companyCityState={companyCityState}
        personalBrandStatement={personalBrandStatement}
        styles={styles}
      />
      <View style={styles.flexRow}>
        <View style={styles.width50}>
          {skills && skills.length > 0 && (
            <SkillSection skills={skills} styles={styles} />
          )}
        </View>
        <View style={styles.width50}>
          {isNotBlank(careerHighlights) && (
            <CareerHighlightsSection
              professionalAchievements={professionalAchievements}
              styles={styles}
            />
          )}
        </View>
      </View>
      {isNotBlank(callToAction) && (
        <CallToActionSection
          callToActionStatement={callToActionStatement}
          styles={styles}
        />
      )}
      {isNotBlank(goals) && (
        <NetworkingGoalsSection
          networkingGoals={networkingGoals}
          styles={styles}
        />
      )}
      {targetRoles && targetRoles.length > 0 && (
        <TargetRolesSection targetRoles={targetRoles} styles={styles} />
      )}
      {targetCompanies && targetCompanies.length > 0 && (
        <TargetCompaniesSection
          targetCompanies={targetCompanies}
          styles={styles}
        />
      )}
    </DocumentPage>
  )
}

const HeaderSection = ({ name, email, phone, linkedinProfile, styles }) => (
  <View style={styles.borderBottom}>
    <View style={styles.flexRow}>
      <Text style={styles.heading}>{name}</Text>
      {linkedinProfile && (
        <Text style={{ fontSize: '12px', justifyEnd: 'flex-end' }}>
          {linkedinProfile}
        </Text>
      )}
    </View>
    <View style={styles.mb10FlexRow}>
      {isNotBlank(email) && (
        <Text style={{ fontSize: '12px', flexStart: 'flex-start' }}>
          {email}
        </Text>
      )}
      {isNotBlank(phone) && (
        <Text style={{ fontSize: '12px', justifyEnd: 'flex-end' }}>
          {phone}
        </Text>
      )}
    </View>
  </View>
)

const PersonalInfoSection = ({
  position,
  company,
  companyCityState,
  personalBrandStatement,
  styles,
}) => (
  <View style={styles.my10}>
    <Text style={styles.strong}>
      {position} {isNotBlank(company) ? `- ${company}` : ''}{' '}
      {isNotBlank(companyCityState) ? `- ${companyCityState}` : ''}
    </Text>
    <Text style={{ fontSize: '12px' }}>{personalBrandStatement}</Text>
  </View>
)

const CallToActionSection = ({ callToActionStatement, styles }) => (
  <View>
    <View style={styles.flexStart}>
      <Text style={styles.sectionTitle}>Call to Action</Text>
      <Text style={{ fontSize: '12px' }}>{callToActionStatement}</Text>
    </View>
  </View>
)

const NetworkingGoalsSection = ({ networkingGoals, styles }) => (
  <View>
    <View>
      <Text style={styles.sectionTitle}>Networking Goals</Text>
      <Text style={{ fontSize: '12px' }}>{networkingGoals}</Text>
    </View>
  </View>
)

const CareerHighlightsSection = ({ professionalAchievements, styles }) => (
  <View>
    <Text style={styles.sectionTitle}>Career Highlights</Text>
    <Text style={{ fontSize: '12px' }}>{professionalAchievements}</Text>
  </View>
)

const TargetRolesSection = ({ targetRoles, styles }) => (
  <View style={styles.flexStart} wrap={false}>
    <Text style={styles.sectionTitle}>Target Roles</Text>
    {targetRoles?.map((role, index) => (
      <RoleView role={role} key={`role-${index}`} />
    ))}
  </View>
)

const RoleView = ({ role }) => {
  if (!role.role) {
    return null
  }
  return (
    <View wrap={false}>
      <Text style={{ fontSize: '12px' }}>{role.role}</Text>
    </View>
  )
}

const TargetCompaniesSection = ({ targetCompanies, styles }) => (
  <View style={styles.flexStart} wrap={false}>
    <Text style={styles.sectionTitle}>Target Companies</Text>
    {targetCompanies?.map((company, index) => (
      <CompanyView company={company} key={`company-${index}`} />
    ))}
  </View>
)

const CompanyView = ({ company }) => {
  if (!company.companyOrIndustry) {
    return null
  }
  return (
    <View wrap={false}>
      <Text style={{ fontSize: '12px' }}>{company.companyOrIndustry}</Text>
    </View>
  )
}

const SkillSection = ({ skills, styles }) => (
  <View style={styles.flexStart} wrap={false}>
    <Text style={styles.sectionTitle}>Key Competencies</Text>
    {skills?.map((skill, index) => (
      <SkillView skill={skill} key={`skill-${index}`} />
    ))}
  </View>
)

const SkillView = ({ skill }) => {
  if (!skill.skill) {
    return null
  }
  return (
    <View style={{ fontSize: '12px', paddingBottom: '3px' }} wrap={false}>
      <Text style={{ fontSize: '12px' }}>{skill.skill}</Text>
    </View>
  )
}
