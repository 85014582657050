import React, { useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '../../../lib/ui/form'
import useSavedRecruitersForm from './useSavedRecruiterForm'
import { Input } from '../../../lib/ui/input'
import { Textarea } from '../../../lib/ui/textarea'
import { Button } from '../../../lib/ui/button'
import { DatePickerField } from '../../shared/DatePickerField'
import { cn } from '../../../lib/utils'
import { PaperclipIcon } from 'lucide-react'
import { DownloadIcon } from './Icons'
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogClose,
  DialogHeader,
  DialogTitle,
} from '../../../lib/ui/dialog'

const ExpandedCell = ({ row }) => {
  const [buttonText, setButtonText] = useState('save')
  const { form, onSubmit, submitted } = useSavedRecruitersForm(
    setButtonText,
    row.original,
    false,
  )

  const acceptList = ['.pdf', '.rtf', '.doc', '.docx', '.txt']
  const rowClasses = cn('flex flex-col gap-4 md:grid md:grid-cols-8')
  const inputClasses = cn('md:flex-grow')
  const colSpan = 'col-span-2'

  const { t } = useTranslation()

  const recruiterId = row.original.id

  const handleButtonClick = () => {
    document.querySelector(`.resume_upload_${recruiterId}`).click()
  }

  const { watch, handleSubmit, control } = form

  const uploadedResume = watch('resume')

  const resumeUrl = row.original.resume_url
  const resumeName = row.original.resume

  const [otherSavedRecruiters, setOtherSavedRecruiters] = useState(
    row.original.other_saved_recruiters,
  )

  return (
    <div className="p-2">
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-4">
            <div className="flex flex-col gap-4 ">
              <div className={rowClasses}>
                <InputField
                  control={control}
                  label={t('TableHeader.firm_name')}
                  name="firm_name"
                  className={colSpan}
                />
                <InputField
                  control={control}
                  label={t('TableHeader.email')}
                  name="email"
                  className={cn(inputClasses, colSpan)}
                />
                <InputField
                  control={control}
                  label={t('TableHeader.phone')}
                  name="phone"
                  className={colSpan}
                />
                <InputField
                  control={control}
                  label={t('TableHeader.website')}
                  name="website"
                  className={cn(inputClasses, colSpan)}
                />
              </div>
              <div className={rowClasses}>
                <InputField
                  control={control}
                  label={t('TableHeader.name')}
                  name="recruiter_name"
                  className={cn(inputClasses, colSpan)}
                />
                <DatePickerField
                  control={control}
                  label={t('TableHeader.followup_on')}
                  name="follow_up"
                  notRounded={true}
                  innerClassName={'bg-input-background'}
                  className={colSpan}
                />
                <div className="col-span-4 flex flex-col">
                  <FileField
                    control={control}
                    label={t('TableHeader.resume')}
                    name="resume"
                    accept={acceptList.join(',')}
                    className={`resume_upload_${recruiterId} hidden`}
                  />
                  <div className="flex items-center gap-2">
                    {resumeUrl ? (
                      <DownloadResumeButton
                        resumeName={resumeName || 'Download Resume'}
                        resumeUrl={resumeUrl}
                        onClick={handleButtonClick}
                        t={t}
                      />
                    ) : (
                      <UploadResumeButton onClick={handleButtonClick} t={t} />
                    )}

                    <div className="w-[14rem]">
                      {uploadedResume?.name || ''}
                    </div>
                  </div>
                  <div className="hint">
                    {t('FormActions.upload_resume_hint')}
                  </div>
                </div>
              </div>

              {otherSavedRecruiters &&
                otherSavedRecruiters.map((recruiter, idx) => (
                  <div className={rowClasses} key={`other-recruiter-${idx}`}>
                    <FormLabel>Contact {idx + 2}</FormLabel>
                    <ContactDetails className="col-span-2">
                      <FormLabel>{t('TableHeader.name')}</FormLabel>
                      <div>{recruiter.recruiter_name}</div>
                    </ContactDetails>
                    <ContactDetails className="col-span-3">
                      <FormLabel>{t('TableHeader.email')}</FormLabel>
                      <div>{recruiter.email}</div>
                    </ContactDetails>
                    <ContactDetails className="col-span-2">
                      <FormLabel>{t('TableHeader.phone')}</FormLabel>
                      <div>{recruiter.phone}</div>
                    </ContactDetails>
                  </div>
                ))}
              <TextField control={form.control} label="Notes" name={'notes'} />
              <div className="flex gap-4 self-end">
                <Button disabled={submitted} type="submit" className="w-64">
                  {t(`FormActions.${buttonText}`)}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Form>
      <AddContactDialog
        row={row}
        t={t}
        rowClasses={rowClasses}
        inputClasses={inputClasses}
        setOtherSavedRecruiters={setOtherSavedRecruiters}
      >
        <Button variant="link" className="pl-4">
          + {t('FormActions.add_another_from_firm')}
        </Button>
      </AddContactDialog>
    </div>
  )
}

export default ExpandedCell

const ContactDetails = ({ className, children }) => {
  return <div className={cn('flex flex-col gap-2', className)}>{children}</div>
}

const AddContactDialog = ({
  row,
  t,
  inputClasses,
  setOtherSavedRecruiters,
  children,
}) => {
  const [buttonText, setButtonText] = useState('save')
  const [showAddDialog, setShowAddDialog] = useState(false)
  const toggleShowAddReminderDialog = () => setShowAddDialog(!showAddDialog)
  const { form, onSubmit, submitted } = useSavedRecruitersForm(
    setButtonText,
    row.original,
    true,
    toggleShowAddReminderDialog,
    setOtherSavedRecruiters,
  )

  const firmName = row.original.firm_name

  return (
    <Dialog open={showAddDialog} onOpenChange={toggleShowAddReminderDialog}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="site-info-dialog max-w-xl font-roboto">
        <DialogHeader>
          <DialogTitle className="font-roboto-slab text-xl tracking-[0.12px]">
            Add Another Recruiter to {firmName}
          </DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col items-start gap-8 p-4">
              <div className={'flex flex-col gap-4 md:flex-row md:gap-8'}>
                <InputField
                  control={form.control}
                  label={t('TableHeader.name')}
                  name="recruiter_name"
                  className={inputClasses}
                />
                <InputField
                  control={form.control}
                  label={t('TableHeader.email')}
                  name="email"
                  className={inputClasses}
                />
                <InputField
                  control={form.control}
                  label={t('TableHeader.phone')}
                  name="phone"
                />
              </div>
              <Button disabled={submitted} type="submit">
                {t(`FormActions.${buttonText}`)}
              </Button>
            </div>
          </form>
        </Form>
      </DialogContent>
      <DialogClose />
    </Dialog>
  )
}

const UploadResumeButton = ({ t, ...props }) => {
  return (
    <Button className="w-80" {...props} type="button">
      <div className="flex items-center gap-2">
        {t('FormActions.choose_file')}
        <PaperclipIcon />
      </div>
    </Button>
  )
}

const DownloadResumeButton = ({ resumeName, resumeUrl, t, ...props }) => {
  return (
    <div className="flex flex-col items-start">
      <div className="flex items-center gap-4">
        <Button type="button">
          <a
            className="flex items-center gap-2 text-white hover:text-white focus:text-white"
            href={resumeUrl}
            target="_blank"
            rel="noreferrer"
          >
            {resumeName}
            <DownloadIcon />
          </a>
        </Button>
      </div>
      <Button className="pl-0" type="button" {...props} variant="link">
        {t('FormActions.upload_different_resume')}
      </Button>
    </div>
  )
}

export const InputField = ({ control, label, name, className, ...props }) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input {...field} {...props} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
const TextField = ({ control, label, name }) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea className={'resize-none'} {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export const FileField = ({ control, label, name, accept, className }) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { value, onChange, ...fieldProps } }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              {...fieldProps}
              type="file"
              accept={accept}
              name={name}
              onChange={(e) => onChange(e.target.files && e.target.files[0])}
              className={cn(
                'mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-base shadow-sm focus:border-accent focus:ring-accent sm:text-sm',
                className,
              )}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
