import React from 'react'
import BasicsEditor from './BasicsEditor'
import EmploymentEditor from './EmploymentEditor'
import EducationEditor from './EducationEditor'
import SkillsEditor from './SkillsEditor'
import VolunteerEditor from './VolunteerEditor'
import { Switch } from '~/lib/ui/switch'
import { useTemplate } from '~/lib/pdf/TemplateProvider'

const Editor = () => {
  const { showAllEditorFields, setShowAllEditorFields } = useTemplate()

  return (
    <div className="relative grid grid-cols-1 gap-14">
      <div className="absolute -top-5 right-0 inline-flex items-center">
        <span className="mr-2">Show all fields</span>
        <Switch
          checked={showAllEditorFields}
          onCheckedChange={setShowAllEditorFields}
        />
      </div>
      <BasicsEditor />
      <EmploymentEditor />
      <EducationEditor />
      <VolunteerEditor />
      <SkillsEditor />
      <div className="md:h-80"></div>
    </div>
  )
}
export default Editor
