import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '.././ExerciseProvider'
import { RadioGroup, RadioGroupItem } from '../../../lib/ui/radio-group'

const NetworkingPreferencesSectionTwo = ({ data = {} }) => {
  const {
    updateRadioResponse,
    getResponse,
    exerciseIndex,
    formattedExerciseName,
    setContinueButtonDisabled,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const sectionTwoTranslations = t('pages.1')
  const introvertingPreference = sectionTwoTranslations.introverting_preference
  const extrovertingPreference = sectionTwoTranslations.extroverting_preference
  const noPreference = sectionTwoTranslations.no_preference

  const { speed_networking, networking_event } = data[0] || {}

  let preferenceMessage = noPreference
  if (speed_networking === 'a' && networking_event === 'b') {
    preferenceMessage = introvertingPreference
  } else if (speed_networking === 'b' && networking_event === 'a') {
    preferenceMessage = extrovertingPreference
  }

  const [response, setResponse] = useState(
    getResponse(exerciseIndex, 'preferences') || '',
  )

  useEffect(() => {
    setContinueButtonDisabled(response === '')
  }, [response, setContinueButtonDisabled])

  const handleValueChange = (value) => {
    updateRadioResponse(exerciseIndex, 'preferences', value)
    setResponse(value)
  }

  return (
    <div className="flex flex-col">
      <p>{preferenceMessage}</p>
      <p>{sectionTwoTranslations.p1}</p>
      <p>{sectionTwoTranslations.p2}</p>
      <p>{sectionTwoTranslations.select_your_preference}</p>
      <RadioGroup
        className="mt-2"
        value={response}
        onValueChange={handleValueChange}
      >
        <label className="mb-2 !flex items-center">
          <RadioGroupItem value="introverting" className="mr-2" />
          {sectionTwoTranslations.preferences.options.a.option}
        </label>
        <label className="mb-2 !flex items-center">
          <RadioGroupItem value="extroverting" className="mr-2" />
          {sectionTwoTranslations.preferences.options.b.option}
        </label>
      </RadioGroup>
    </div>
  )
}

export default NetworkingPreferencesSectionTwo
