import React, { Fragment } from 'react'
import _ from 'lodash'
import { Button, Card, Typography } from '~/components/_atoms'
import { Page, Stack } from '~/components/_layouts'
import {
  ChevronLeftIcon,
  ChevronRightIcon,
  PencilIcon,
} from '@heroicons/react/24/outline'

const Prototype = () => {
  const variants = 'solid outline ghost link'.split(' ')
  const colors = 'primary secondary neutral destructive'.split(' ')
  const sizes = 'sm md lg xl icon'.split(' ')
  const typographies = 'title1 title2 title3 title4 subtitle body small'.split(
    ' ',
  )

  return (
    <Page
      title={'Prototype Page'}
      header={
        <>
          <p>Some header content</p>
          <p>Some header content</p>
        </>
      }
      footer={
        <>
          <p>Some footer content</p>
          <p>Some footer content</p>
        </>
      }
      rightDrawer={{
        title: 'Right Drawer',
        viewActionText: 'View Right Drawer',
        children: (
          <div>
            <p>Right drawer content</p>
            <p>Right drawer content</p>
            <p>Right drawer content</p>
            <p>Right drawer content</p>
          </div>
        ),
      }}
    >
      <Card>
        <h3>Buttons</h3>
        <Stack spacing={10}>
          {variants.map((v) => (
            <Fragment key={v}>
              <h4>{_.capitalize(v)}</h4>
              <Stack spacing={3}>
                {colors.map((c) => (
                  <Fragment key={c}>
                    <h5>{_.capitalize(c)}</h5>
                    <Stack direction="row" spacing={3}>
                      {sizes.map((s) => {
                        const btnProps = {}
                        if (s === 'icon') {
                          btnProps.onClick = () => alert('test on click')
                          btnProps.component = 'button'
                        } else {
                          btnProps.startIcon = <ChevronLeftIcon />
                          btnProps.endIcon = <ChevronRightIcon />
                          btnProps.href = '#test'
                        }

                        return (
                          <Button
                            key={`${v}_${s}_button`}
                            variant={v}
                            color={c}
                            size={s}
                            {...btnProps}
                          >
                            {s === 'icon' ? (
                              <PencilIcon className="h-5 w-5" />
                            ) : (
                              `${_.capitalize(v)} ${_.capitalize(s)} Button`
                            )}
                          </Button>
                        )
                      })}
                    </Stack>
                  </Fragment>
                ))}
              </Stack>
            </Fragment>
          ))}
        </Stack>
      </Card>
      <Card>
        <h3>Typography</h3>
        <br />
        <Stack spacing={3}>
          {typographies.map((t) => (
            <Typography key={t} variant={t}>
              {_.capitalize(t)}
            </Typography>
          ))}
        </Stack>
      </Card>
    </Page>
  )
}

export default Prototype
