import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react'
import { usePDF } from '@react-pdf/renderer'
import { TemplateSwitcher, useTemplate } from '../../lib/pdf'
import { apiPost, apiPut } from '../../util/api'
import { useToast } from '../../lib/ui/toast'
import { sha256 } from 'js-sha256'

const ThankYouNoteContext = createContext()

export const useThankYouNote = () => {
  return useContext(ThankYouNoteContext)
}

const hashObject = (obj) => {
  const json = JSON.stringify(obj)
  return sha256(json)
}

const ThankYouNoteProvider = ({
  thank_you_note_json,
  document_id,
  thank_you_note_name,
  dev_mode,
  candidate_name,
  ayla_thank_you_note,
  children,
}) => {
  const [thankYouNoteDocument, setThankYouNoteDocument] =
    useState(thank_you_note_json)
  const [thankYouNoteName, setThankYouNoteName] = useState(thank_you_note_name)
  const [lastPersistedHash, setLastPersistedHash] = useState(
    hashObject(thank_you_note_json),
  )
  const { toast } = useToast()
  const documentId = document_id
  const candidateName = candidate_name
  const aylaThankYouNote = ayla_thank_you_note

  const { templateIndex, templateStyle, templates } = useTemplate()

  useEffect(() => {
    // If the thank you note has unsaved changes, warn the user before leaving the page
    if (hashObject(thankYouNoteDocument) !== lastPersistedHash) {
      window.onbeforeunload = (event) => {
        event.preventDefault()
        event.returnValue = true // For legacy browser compatibility
      }
    } else {
      window.onbeforeunload = undefined
    }
  }, [thankYouNoteDocument, lastPersistedHash])

  // use hook for fine-grained control over PDF rendering
  const [pdf, renderPdf] = usePDF({
    document: (
      <TemplateSwitcher
        jsonDocument={thankYouNoteDocument}
        templateIndex={templateIndex}
        templateStyle={templateStyle}
        Template={templates[templateIndex]}
        candidateName={candidateName}
      />
    ),
  })

  // Re-render the PDF
  const updateThankYouNote = useCallback(() => {
    renderPdf(
      <TemplateSwitcher
        jsonDocument={thankYouNoteDocument}
        templateIndex={templateIndex}
        templateStyle={templateStyle}
        Template={templates[templateIndex]}
        candidateName={candidateName}
      />,
    )
  }, [
    thankYouNoteDocument,
    renderPdf,
    templateIndex,
    templateStyle,
    templates,
    candidateName,
  ])

  // Automatically re-render pdf every time thankYouNoteDocument changes
  useEffect(() => {
    updateThankYouNote()
  }, [thankYouNoteDocument, updateThankYouNote])

  // Persist thank you note data to the server
  const persistThankYouNote = async () => {
    setLastPersistedHash(hashObject(thankYouNoteDocument))
    if (documentId) {
      await saveExistingThankYouNote()
    } else {
      await saveNewThankYouNote()
    }
  }

  const saveNewThankYouNote = async () => {
    let response
    try {
      response = await apiPost('/interview/thank_you_note_builder/', {
        thank_you_note: thankYouNoteDocument,
        template_index: templateIndex,
        template_settings: templateStyle,
        name: thankYouNoteName,
      })
    } catch (e) {
      console.error(
        'Error saving new thank you note. Expected status 200. Got:',
        e.response.status,
        e.response,
      )
      toast({ description: 'Error! Thank you note failed to save' })
      return
    }
    if (response.status === 200) {
      window.location.href =
        '/interview/thank_you_note_builder/' + response.data.document_id
    } else {
      console.error(
        'Error saving new thank you note. Expected status 200. Got:',
        response.status,
        response,
      )
      toast({ description: 'Error: Failed to create thank you note' })
    }
  }

  const saveExistingThankYouNote = async () => {
    let response
    toast({ description: 'Saving thank you note...' })
    try {
      response = await apiPut(
        `/interview/thank_you_note_builder/${documentId}`,
        {
          thank_you_note: thankYouNoteDocument,
          template_index: templateIndex,
          template_settings: templateStyle,
          name: thankYouNoteName,
        },
      )
    } catch (e) {
      console.error(
        'Error saving existing thank you note. Expected status 200. Got:',
        e.response.status,
        e.response,
      )
      toast({ description: 'Error: Thank you note failed to save' })
      return
    }
    if (response.status === 200) {
      toast({
        description: 'Thank you note saved successfully',
        duration: 1500,
      })
    } else {
      console.error(
        'Error saving existing thank you note. Expected status 200. Got:',
        response.status,
        response,
      )
      toast({ description: 'Error! Thank you note failed to save' })
    }
  }

  const value = {
    pdf,
    documentId,
    persistThankYouNote,
    thankYouNoteDocument,
    setThankYouNoteDocument,
    thankYouNoteName,
    setThankYouNoteName,
    aylaThankYouNote,
    devMode: dev_mode,
  }

  return (
    <ThankYouNoteContext.Provider value={value}>
      {children}
    </ThankYouNoteContext.Provider>
  )
}
export default ThankYouNoteProvider
