import React, { useEffect, useState } from 'react'
import useTranslation from '../../shared/TranslationProvider'
import Walkthrough from '../../shared/Walkthrough'
import { useJobSearch } from './JobSearchProvider'
import { ACTIONS, EVENTS } from 'react-joyride'
import { useWalkthrough } from './WalkthroughProvider'
import { useWalkthroughFeedbackForm } from '../../Walkthrough/useWalkthroughFeedbackForm'
import { Button } from '../../../lib/ui/button'
import { ThumbsUpIcon, ThumbsDownIcon } from 'lucide-react'
import {
  Dialog,
  DialogHeader,
  DialogTitle,
  DialogContent,
} from '../../../lib/ui/dialog'
export const WalkthroughStep = ({ step }) => {
  const { t } = useTranslation('Walkthrough')
  const screen = step + 1
  const text = t(`step${screen}.text`)
  return (
    <div>
      {text.map((t, i) => (
        <p key={`walkthrough-step-${step}-${i}`}>{t}</p>
      ))}
    </div>
  )
}

const FeedbackStep = () => {
  const { showFeedbackDialog, toggleShowFeedbackDialog } = useJobSearch()
  const { t } = useTranslation('Walkthrough')
  const yes = t('feedback.yes')
  const no = t('feedback.no')
  const submit = t('feedback.submit')

  const { form, onSubmit, submitted } = useWalkthroughFeedbackForm({
    url: '/job_search_walkthrough_feedbacks',
    toggleShowFeedbackDialog,
  })
  const [selectedFeedback, setSelectedFeedback] = useState(null)

  const submitButtonDisabled = !selectedFeedback || submitted

  const handleYesClick = () => {
    setSelectedFeedback('yes')
    form.setValue('feedback', 'yes')
  }

  const handleNoClick = () => {
    setSelectedFeedback('no')
    form.setValue('feedback', 'no')
  }

  const handleSubmit = (e) => {
    e.preventDefault()
    form.handleSubmit(onSubmit)()
  }

  return (
    <Dialog open={showFeedbackDialog} onOpenChange={toggleShowFeedbackDialog}>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('feedback.title')}</DialogTitle>
        </DialogHeader>
        <div className="flex flex-col items-center gap-8 p-2">
          <div>
            <div>
              {t('feedback.text').map((t, i) => (
                <p key={`walkthrough-feedback-${i}`}>{t}</p>
              ))}
            </div>
            <div className="flex justify-center gap-2">
              <Button
                type="button"
                variant={selectedFeedback === 'yes' ? 'default' : 'outline'}
                onClick={handleYesClick}
              >
                <ThumbsUpIcon title={yes} />
              </Button>
              <Button
                type="button"
                variant={selectedFeedback === 'no' ? 'default' : 'outline'}
                onClick={handleNoClick}
              >
                <ThumbsDownIcon title={no} />
              </Button>
            </div>
          </div>
          <Button
            disabled={submitButtonDisabled}
            onClick={handleSubmit}
            type="submit"
          >
            {submit}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  )
}

const JobSearchWalkthrough = () => {
  const { testing, runWalkthrough } = useJobSearch()
  const { t } = useTranslation('Walkthrough')
  const walkthroughSteps = [
    {
      content: <WalkthroughStep step={0} />,
      target: '.step1',
      spotlightPadding: 15,
      placement: 'right',
      disableBeacon: !testing,
      title: t('step1.title'),
      spotlightClicks: true,
    },
    {
      content: <WalkthroughStep step={1} />,
      target: '.step2',
      spotlightPadding: 15,
      placement: 'top',
      title: t('step2.title'),
      spotlightClicks: true,
    },
    {
      content: <WalkthroughStep step={2} />,
      target: '.step3',
      spotlightPadding: 20,
      placement: 'left',
      title: t('step3.title'),
      spotlightClicks: true,
    },
    {
      content: <WalkthroughStep step={3} />,
      target: '.step4',
      spotlightPadding: 15,
      placement: 'right',
      title: t('step4.title'),
      spotlightClicks: true,
    },
    {
      content: <WalkthroughStep step={4} />,
      target: '.step5',
      spotlightPadding: 15,
      placement: 'auto',
      title: t('step5.title'),
      spotlightClicks: true,
      isFixed: true,
    },
    {
      content: <WalkthroughStep step={5} />,
      target: '.step6',
      spotlightPadding: 30,
      placement: 'auto',
      title: t('step6.title'),
      spotlightClicks: true,
    },
    {
      content: <WalkthroughStep step={5} />,
      target: '.step6a',
      spotlightPadding: 15,
      placement: 'auto',
      title: t('step6.title'),
      spotlightClicks: true,
    },
    {
      content: <WalkthroughStep step={5} />,
      target: '.step6b',
      spotlightPadding: 15,
      placement: 'auto',
      title: t('step6.title'),
      spotlightClicks: true,
    },
    {
      content: <WalkthroughStep step={5} />,
      target: '.step6c',
      spotlightPadding: 15,
      placement: 'auto',
      title: t('step6.title'),
      spotlightClicks: true,
    },
    {
      content: <WalkthroughStep step={7} />,
      target: '#help-center',
      spotlightPadding: 15,
      placement: 'auto',
      title: t('step7.title'),
      spotlightClicks: true,
    },
  ]

  const submitForm = () => {
    const form = document.querySelector('form')
    setTimeout(() => {
      form.requestSubmit()
    }, 500)
  }

  const scrollToElement = (selector) => {
    const ele = document.querySelector(selector)
    setTimeout(() => {
      ele.scrollIntoView({ behavior: 'smooth' })
    }, 1000)
  }

  const { toggleShowFeedbackDialog } = useJobSearch()

  const {
    setState,
    state: { run, stepIndex, steps },
  } = useWalkthrough()

  useEffect(() => {
    setState((prev) => ({
      ...prev,
      run: runWalkthrough || testing,
      tourActive: true,
      stepIndex: 0,
      steps: walkthroughSteps,
    }))
  }, [])
  const handleJoyrideCallback = (data) => {
    const { action, index, type } = data

    if (type === EVENTS.TOUR_END) {
      toggleShowFeedbackDialog()
    }

    if (type === EVENTS.TARGET_NOT_FOUND) {
      if (action == ACTIONS.NEXT) {
        setState((prev) => ({ ...prev, stepIndex: index + 1 }))
      } else if (action == ACTIONS.PREV) {
        setState((prev) => ({ ...prev, stepIndex: index - 1 }))
      }
      if (stepIndex === 2) {
        setState((prev) => ({ ...prev, openAllFilters: true }))
      }
    }

    if (type === EVENTS.STEP_AFTER) {
      if (action === ACTIONS.NEXT) {
        if (stepIndex === 1) {
          setState((prev) => ({ ...prev, openAllFilters: true, stepIndex: 2 }))
        } else
          setState((prev) => ({
            ...prev,
            stepIndex: index + 1,
            openAllFilters: false,
          }))
      } else if (action == ACTIONS.PREV) {
        if (stepIndex === 3) {
          setState((prev) => ({ ...prev, openAllFilters: true, stepIndex: 2 }))
        } else
          setState((prev) => ({
            ...prev,
            stepIndex: index - 1,
            openAllFilters: false,
          }))
      }
    } else if (type === EVENTS.TOOLTIP) {
      if (index === 0) setState((prev) => ({ ...prev, started: true }))
      if (index === 3) {
        submitForm()
      }
      if (index === 4) {
        scrollToElement('.step-5-scroll')
      }
    }
  }

  return (
    <>
      <Walkthrough
        run={run}
        steps={steps}
        callback={handleJoyrideCallback}
        stepIndex={stepIndex}
      />
      <FeedbackStep />
    </>
  )
}

export default JobSearchWalkthrough
