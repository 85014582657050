import React from 'react'
import { useReferenceSheet } from '../ReferenceSheetProvider'
import {
  JsonItemProvider,
  JsonItemField,
  useJsonItem,
} from '../../../lib/json-editor'

export const EditorItemField = ({ ...props }) => {
  return <JsonItemField {...props} />
}

export const useEditorItem = useJsonItem

export const EditorItemProvider = ({ ...props }) => {
  const { referenceSheetDocument, setReferenceSheetDocument } =
    useReferenceSheet()

  return (
    <JsonItemProvider
      jsonDocument={referenceSheetDocument}
      setJsonDocument={setReferenceSheetDocument}
      {...props}
    />
  )
}
