import React from 'react'
import { VideoIcon, FavoriteIcon } from './icons'
import { useResources } from './ResourcesProvider'
import { useTranslation } from '../../hooks/useTranslation'
import ResourceCardList from './ResourceCardList'

export const VideoCards = () => {
  return <ResourceCardList Comp={VideoCard} contextPropertyName="videos" />
}

const VideoCard = ({ resource }) => {
  const { headerImageUrl, headerImageAlt, path, title, description } = resource
  const { checkResourceFavorite, toggleResourceFavorite } = useResources()
  const isResourceFavorite = checkResourceFavorite(resource)
  const { t } = useTranslation('index')

  const favoriteIconClass = isResourceFavorite
    ? 'fill-primary stroke-primary'
    : 'stroke-primary'
  const favoriteTitle = isResourceFavorite
    ? t('bookmarked')
    : t('bookmark_this_resource')

  return (
    <div className="relative mb-8 overflow-hidden rounded-3xl border border-solid border-[#B9B9B9]">
      <div className="absolute right-4 top-4">
        <button
          className="rounded-lg bg-white p-2 hover:bg-gray-100"
          title={favoriteTitle}
          onClick={() => toggleResourceFavorite(resource)}
        >
          <FavoriteIcon
            className={favoriteIconClass}
            title={favoriteTitle}
            height={24}
          />
        </button>
      </div>
      <img
        src={headerImageUrl}
        alt={headerImageAlt}
        className="aspect-[22/11] w-full object-cover object-center"
      />
      <div className="flex flex-col p-4 md:justify-between">
        <div className="h-28">
          <p className="!my-0 line-clamp-4 flex-grow md:leading-relaxed">
            <span
              role="heading"
              tabIndex="0"
              aria-level="2"
              className="font-robot !mb-2 !mt-0 break-normal text-lg focus:overflow-visible"
              title={title}
            >
              {title}
            </span>
            <br />
            {description}
          </p>
        </div>
        <div className="mt-4">
          <a
            href={path}
            className="flex w-full items-center justify-center rounded-3xl bg-primary px-4 py-3 text-base font-medium text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white sm:py-2"
          >
            Watch Video
            <VideoIcon className="ml-2 stroke-white" />
          </a>
        </div>
      </div>
    </div>
  )
}

export default VideoCard
