import React, { createContext, useContext } from 'react'
import { useIsMobile, useIsDesktop } from '../../../hooks/useMediaQuery'

const LandingPageContext = createContext()

export const useLandingPage = () => {
  return useContext(LandingPageContext)
}
const LandingPageProvider = ({
  serialized_item_list,
  pitchDocument,
  pitchComplete,
  children,
}) => {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()
  const exercises = serialized_item_list
  const pitch = pitchDocument
  const pitch_complete = pitchComplete

  const value = {
    exercises,
    pitch,
    pitch_complete,
    isMobile,
    isDesktop,
  }

  return (
    <LandingPageContext.Provider value={value}>
      {children}
    </LandingPageContext.Provider>
  )
}

export default LandingPageProvider
