import React from 'react'
import {
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
  FormDescription,
} from '../../lib/ui/form'
import { Input } from '../../lib/ui/input'
import { cn } from '../../lib/utils'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../lib/ui/select'
import { Checkbox } from '../../lib/ui/checkbox'
import { Textarea } from '../../lib/ui/textarea'

export const InputField = ({
  control,
  label,
  description,
  name,
  className,
  inputClassName,
  ...props
}) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          {label && <FormLabel>{label}</FormLabel>}
          <FormDescription>{description}</FormDescription>
          <FormControl>
            <Input {...field} {...props} className={inputClassName} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function SelectField({
  name,
  label,
  control,
  className,
  choices,
  description,
  descriptionClassName,
  placeholder,
  onChange,
}) {
  if (!choices) return null
  if (choices.length === 0) return null
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn(className)}>
          {label && <FormLabel>{label}</FormLabel>}
          {description && (
            <FormDescription className={cn('text-xs', descriptionClassName)}>
              {description}
            </FormDescription>
          )}
          <Select onValueChange={onChange} defaultValue={field.value}>
            <FormControl>
              <SelectTrigger>
                <SelectValue placeholder={placeholder || 'Select one'} />
              </SelectTrigger>
            </FormControl>
            <SelectContent>
              {choices.map((choice, idx) => (
                <SelectItem
                  key={`${name}-${choice.value}-${idx}`}
                  value={choice.value}
                >
                  {choice.label}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function TextField({
  description,
  placeholder,
  name,
  label,
  control,
  className,
  innerClassName,
  ...props
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={className}>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea
              placeholder={placeholder}
              className={cn('resize-none', innerClassName)}
              {...field}
              {...props}
            />
          </FormControl>
          <FormDescription>{description}</FormDescription>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

export function CheckboxField({ name, label, control, className, onChange }) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn(className)}>
          <FormControl>
            <Checkbox
              checked={field.value}
              onCheckedChange={onChange}
              className="data-[state=checked]:bg-intoo-blue-medium"
            />
          </FormControl>
          <div className="space-y-1 leading-none">
            <FormLabel>{label}</FormLabel>
          </div>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
