import React from 'react'
import { useReferenceSheet } from './ReferenceSheetProvider'
import { Button } from '../../lib/ui/button'
import { HeartIcon } from '@heroicons/react/24/outline'

const PdfViewerPanel = () => {
  const {
    pdf: { url, loading },
    persistReferenceSheet,
  } = useReferenceSheet()

  const saveAndExit = async () => {
    await persistReferenceSheet()
    window.location.href = '/personal_brand/references'
  }

  if (loading) {
    return null
  }
  return (
    <>
      <div className="absolute flex h-16 w-full flex-row items-center justify-evenly bg-[#525659]">
        <Button variant="outline" asChild>
          <a href={url} download>
            Download PDF
          </a>
        </Button>
        <Button onClick={saveAndExit}>
          Save &amp; Exit <HeartIcon className="ml-2 w-6" />
        </Button>
      </div>
      <iframe
        src={`${url}#toolbar=0&navpanes=0`}
        className="h-full w-full pt-16"
        title="Reference Sheet Preview"
        frameBorder="0"
        loading="lazy"
      />
    </>
  )
}
export default PdfViewerPanel
