import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { apiPatch } from '../../../util/api'

const useExtendAlertExpirationForm = (alert, toggleShowDialog) => {
  const [submitted, setSubmitted] = useState(false)
  const today = new Date()
  const schema = z.object({
    expires_at: z.union([z.string(), z.date()]),
  })

  const defaultValues = {
    expires_at: new Date(today.setMonth(today.getMonth() + 1)),
  }

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const onSubmit = async (values) => {
    setSubmitted(true)
    const url = `/job-search/alerts/${alert.id}`
    const data = {
      alert: {
        expires_at: values.expires_at,
        format: 'json',
      },
    }

    let response
    try {
      response = await apiPatch(url, data)
    } catch (error) {
      console.log(error)
    }
    if (response.status === 200) {
      toggleShowDialog()
      return response
    } else throw new Error('Error extending expiration date')
  }

  return { form, onSubmit, submitted }
}

export default useExtendAlertExpirationForm
