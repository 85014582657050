import React from 'react'
import BasicsEditor from './BasicsEditor'
import ContentEditor from './ContentEditor'

const Editor = ({ templateIndex }) => {
  return (
    <div className="grid grid-cols-1 gap-14">
      <BasicsEditor templateIndex={templateIndex} />
      <ContentEditor templateIndex={templateIndex} />
      <div className="h-80"></div>
    </div>
  )
}
export default Editor
