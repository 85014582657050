import React from 'react'
import Results from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { useSpotSoftSkillQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'

const SpotSoftSkillResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)
  const score = useSpotSoftSkillQuizScore(trackContent, t('questions'))

  return (
    quizName === 'Spot Soft Skill' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 flex flex-col gap-4 sm:w-9/12">
            <h2>{t(`results.score`, { score })}</h2>
            <div className="mt-4">
              <p>{t(`results.great_job`)}</p>
              <div className="mt-4 grid grid-cols-3 gap-4">
                {t('results.skills')
                  .split(',')
                  .map((skill, index) => (
                    <p key={index}>{skill}</p>
                  ))}
              </div>
              <p className="mt-4 font-bold">{t(`results.time_to_reflect`)}</p>
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
        <SpotSoftSkillCoachTips />
      </Results>
    )
  )
}

const SpotSoftSkillCoachTips = () => {
  const { formattedQuizName, quizName } = useQuiz()
  const { t } = useTranslation(formattedQuizName)

  return (
    quizName === 'Spot Soft Skill' && (
      <div className="flex pb-6 sm:justify-start">
        <div className="ml-5 flex flex-col sm:w-9/12">
          <h2>{t('coach_tips.header')}</h2>

          <div className="mt-8 grid">
            <p>{t('coach_tips.intro')}</p>

            <ul className="mt-8">
              {t('coach_tips.essential_skills').map((skills_list, index) => (
                <li key={index} className="ml-4 list-disc">
                  <strong>{skills_list.title}</strong>: {skills_list.desc}
                </li>
              ))}
            </ul>
          </div>

          <div className="mt-8 grid">
            {t('coach_tips.paragraphs').map((paragraph, index) => (
              <div key={index} className="mt-8 grid">
                <p>
                  <strong>{paragraph.title}</strong>
                </p>
                <p>{paragraph.p} </p>
              </div>
            ))}
          </div>

          <div className="mt-8 grid">
            <p>{t('coach_tips.star_method.p1')}</p>
            <ul className="mt-8">
              {t('coach_tips.star_method.starlist').map((starlist, index) => (
                <li key={index} className="ml-4 list-disc">
                  <strong>{starlist.title}</strong>: {starlist.desc}
                </li>
              ))}
            </ul>
            <p className="mt-2">{t('coach_tips.star_method.p2')}</p>
            <p className="mt-2">{t('coach_tips.star_method.p3')}</p>
            <p className="mt-2">{t('coach_tips.star_method.p4')}</p>
          </div>

          <div className="mt-8 grid">
            <p>{t('coach_tips.continuous_improvement.p1')}</p>
            <ul className="mt-8">
              {t('coach_tips.continuous_improvement.list').map(
                (list, index) => (
                  <li key={index} className="ml-4 list-disc">
                    <strong>{list.title}</strong>: {list.desc}
                  </li>
                ),
              )}
            </ul>
            <p className="mt-2">{t('coach_tips.continuous_improvement.p2')}</p>
            <p className="mt-2">{t('coach_tips.continuous_improvement.p3')}</p>
            <p className="mt-2">{t('coach_tips.continuous_improvement.p4')}</p>
          </div>
        </div>
      </div>
    )
  )
}

export default SpotSoftSkillResults
