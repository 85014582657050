import React from 'react'
import { FavoriteIcon, ShortCourseIcon } from './icons'
import { useResources } from './ResourcesProvider'
import { useTranslation } from '../../hooks/useTranslation'
import { Button } from '../../lib/ui/button'
import ResourceCardList from './ResourceCardList'

export const WorkshopCards = () => {
  return (
    <ResourceCardList Comp={WorkshopCard} contextPropertyName="workshops" />
  )
}

const WorkshopCard = ({ resource }) => {
  const {
    headerImageUrl,
    headerImageAlt,
    path,
    text,
    title,
    description,
    completed,
  } = resource
  const { checkResourceFavorite, toggleResourceFavorite } = useResources()
  const isResourceFavorite = checkResourceFavorite(resource)
  const { t } = useTranslation('index')

  const favoriteIconClass = isResourceFavorite
    ? 'fill-primary stroke-primary'
    : 'stroke-primary'
  const favoriteTitle = isResourceFavorite
    ? t('bookmarked')
    : t('bookmark_this_resource')

  return (
    <div className="relative mb-8 overflow-hidden rounded-3xl border border-solid border-[#B9B9B9]">
      <div className="absolute right-4 top-4">
        <button
          className="rounded-lg bg-white p-2 hover:bg-gray-100"
          title={favoriteTitle}
          onClick={() => toggleResourceFavorite(resource)}
        >
          <FavoriteIcon
            className={favoriteIconClass}
            title={favoriteTitle}
            height={24}
          />
        </button>
      </div>
      <img
        src={headerImageUrl}
        alt={headerImageAlt}
        className="aspect-[22/11] w-full object-cover object-center"
      />
      <div className="flex flex-col p-4 md:justify-between">
        <div className="h-28">
          <p className="!my-0 mb-8 line-clamp-4 flex-grow overflow-hidden md:leading-relaxed">
            <span
              role="heading"
              tabIndex="0"
              aria-level="2"
              className="!mb-2 !mt-0 break-normal font-roboto text-lg focus:overflow-visible"
              title={title}
            >
              {title}
            </span>
            <br />
            {description}
          </p>
        </div>
        <div className="mt-4">
          {completed ? (
            <a href={path}>
              <Button className="w-full" variant="completed">
                View Results
                <ShortCourseIcon className="ml-2 stroke-primary" />
              </Button>
            </a>
          ) : (
            <a href={path}>
              <Button className="w-full">
                {text || 'Start Short Course'}
                <ShortCourseIcon className="ml-2 stroke-white" />
              </Button>
            </a>
          )}
        </div>
      </div>
    </div>
  )
}

export default WorkshopCard
