import React from 'react'
import { useResources } from './ResourcesProvider'
import { useTabNav } from '~/components/shared/TabNav'

export const useFavoritesActive = () => {
  const { activeTab } = useTabNav()
  return activeTab === 'favorites'
}

const ResourceCardList = ({ Comp, contextPropertyName }) => {
  const context = useResources()
  const resourceCollection = context[contextPropertyName]
  const displayResources = useFavoritesActive()
    ? resourceCollection.filter(context.checkResourceFavorite)
    : resourceCollection
  return (
    <>
      {displayResources.map((resource) => (
        <Comp key={resource.title} resource={resource} />
      ))}
    </>
  )
}
export default ResourceCardList
