import * as React from 'react'
import {
  SearchResultsCard,
  SaveResult,
  ItemDetails,
} from '~/components/shared/SearchResultCard'
import { useCompanySearch } from './CompanySearchProvider'
import { HeartIconFull, HeartIconOutline } from './HeartIcon'
import { Button } from '~/lib/ui/button'

export default function CompanyCard({ company }) {
  const { selectedCompany, handleSelectCompany } = useCompanySearch()
  const { id, name, addressLine1, addressLine2, city, state, postalCode } =
    company

  const selected = id === selectedCompany?.id

  return (
    <SearchResultsCard
      className="relative flex cursor-pointer flex-row gap-4 rounded-2xl border-2 p-4 transition-colors hover:border-gray-400 hover:shadow-lg data-[selected=true]:border-intoo-blue-bright"
      data-selected={selected}
      onClick={handleSelectCompany(company)}
    >
      <SaveResult>
        <SavedStatusIcon company={company} />
      </SaveResult>

      <ItemDetails title={name}>
        <div className="flex flex-col">
          <div>{addressLine1}</div>
          {addressLine2 && <div>{addressLine2}</div>}
          <div>
            {city}, {state}
          </div>
          <div>{postalCode}</div>
        </div>
      </ItemDetails>
    </SearchResultsCard>
  )
}

const SavedStatusIcon = ({ company }) => {
  const { addCompany, removeCompany, isSavedCompany } = useCompanySearch()

  return isSavedCompany(company) ? (
    <Button variant="ghost" onClick={() => removeCompany(company)}>
      <HeartIconFull />
    </Button>
  ) : (
    <Button variant="ghost" onClick={() => addCompany(company)}>
      <HeartIconOutline />
    </Button>
  )
}
