import React from 'react'
import Box from '@mui/material/Box'
import { Input } from '../../../../lib/ui/input'
import { Textarea } from '../../../../lib/ui/textarea'
import { Button } from '../../../../lib/ui/button'
import { cn } from '../../../../lib/utils'

export default function NewCoverLetterForm({
  targetJobTitle,
  setTargetJobTitle,
  yearsExperience,
  setYearsExperience,
  exampleDescription,
  setExampleDescription,
  exampleLink,
  setExampleLink,
  skillsExperiences,
  setSkillsExperiences,
  handleSubmitButtonClick,
  submitButtonDisabled,
  tonePresent,
  coverLetterTone,
  setCoverLetterTone,
  t,
  useSkillsMatch,
}) {
  return (
    <>
      {!tonePresent && <p className="mt-4 md:w-3/4">{t.subtitle}</p>}
      {tonePresent && (
        <p className="mt-4 px-8 md:w-3/4">{t.regenerate_subtitle}</p>
      )}

      <Box
        marginY={tonePresent && 2}
        className={cn('!my-0 flex flex-col gap-4', tonePresent && 'px-8')}
      >
        <div>
          <label className="required-text !mb-1" htmlFor="target_job_title">
            {t.target_job_title_label}
          </label>
          <Input
            placeholder={t.target_job_title_placeholder}
            name="target_job_title"
            defaultValue={targetJobTitle}
            onChange={(event) => setTargetJobTitle(event.target.value)}
          />
        </div>
        <div>
          <label className="required-text !mb-1" htmlFor="years_experience">
            {t.years_experience_label}
          </label>
          <Input
            label={t.years_experience_label}
            placeholder={t.years_experience_placeholder}
            name="years_experience"
            defaultValue={yearsExperience}
            onChange={(event) => setYearsExperience(event.target.value)}
          />
        </div>
        <div>
          <label className="!mb-1" htmlFor="example_description">
            {t.example_description_label}
          </label>
          <Textarea
            label={t.example_description_label}
            name="example_description"
            defaultValue={exampleDescription}
            onChange={(event) => setExampleDescription(event.target.value)}
          />
        </div>
        {!useSkillsMatch && (
          <>
            <div>
              <label className="!mb-1" htmlFor="example_link">
                {t.example_link_label}
              </label>
              <Textarea
                label={t.example_link_label}
                name="example_link"
                defaultValue={exampleLink}
                onChange={(event) => setExampleLink(event.target.value)}
              />
            </div>
            <div>
              <label className="!mb-1" htmlFor="skills_experiences">
                {t.skills_experiences_label}
              </label>
              <Textarea
                label={t.skills_experiences_label}
                name="skills_experiences"
                defaultValue={skillsExperiences}
                onChange={(event) => setSkillsExperiences(event.target.value)}
              />
            </div>
          </>
        )}
        {tonePresent && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.5rem',
                paddingTop: '2rem',
                gap: '1rem',
                fontFamily: 'roboto',
              }}
            >
              <label
                htmlFor="cover-letter-tone-slider"
                style={{ marginBottom: '2rem' }}
              >
                {t.select_tone}
              </label>
              <Input
                type="range"
                min="0"
                max="99"
                value={coverLetterTone}
                onChange={(event) => setCoverLetterTone(event.target.value)}
                step={33}
                list="tickmarks"
                id="cover-letter-tone-slider"
                name="cover-letter-tone"
                className="w-full md:w-1/2"
              />
              <datalist id="tickmarks" className="relative !font-roboto">
                <option
                  className="relative block"
                  value="0"
                  label={t.tone_informal}
                />
                <option
                  value="33"
                  className="relative hidden pl-[40px] md:!visible md:!block"
                  label={t.tone_neutral}
                />
                <option
                  value="66"
                  className="relative hidden pl-[40px] md:!visible md:!block"
                  label={t.tone_business_formal}
                />
                <option
                  className="relative block pl-[40px]"
                  value="99"
                  label={t.tone_very_formal}
                />
              </datalist>

              <div>
                {coverLetterTone < 33 && t.tone_informal}
                {coverLetterTone >= 33 &&
                  coverLetterTone < 66 &&
                  t.tone_neutral}
                {coverLetterTone >= 66 &&
                  coverLetterTone < 99 &&
                  t.tone_business_formal}
                {coverLetterTone >= 99 && t.tone_very_formal}
              </div>
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: tonePresent ? 'center' : 'flex-start',
          padding: '0.5rem',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <Button
          onClick={handleSubmitButtonClick}
          disabled={submitButtonDisabled}
        >
          {tonePresent ? t.create_new_button_text : t.submit_button_text}
        </Button>
      </Box>
    </>
  )
}
