import React, { useState, useEffect } from 'react'
import NetworkingBriefProvider, {
  useNetworkingBrief,
} from './NetworkingBriefProvider'
import { TemplateProvider, initializeTemplates } from '../../lib/pdf'
import { Textarea } from '../../lib/ui/textarea'
import { Button } from '../../lib/ui/button'
import { Toaster } from '../../lib/ui/toast'
import {
  TabNav,
  TabPanel,
  PanelContainer,
  ButtonContainer,
  TabButton,
} from '~/components/shared/TabNav'
import {
  IdentificationIcon,
  ClipboardDocumentCheckIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline'
import Editor from './Editor/index'
import PdfViewerPanel from './PdfViewerPanel'
import StyleEditor from './StyleEditor'
import * as T0 from './Template0'

// These need to be declared outside of a component so they can't affect rendering
const [templates, templateStyle, templateSupportedStyles] = initializeTemplates(
  [T0],
)

const NetworkingBriefBuilder = ({
  template_settings,
  template_index,
  ...props
}) => {
  const defaultStyle = template_settings
    ? {
        ...templateStyle,
        [`template-${template_index}`]: template_settings,
      }
    : templateStyle
  const templateProps = {
    templates,
    defaultStyle,
    templateSupportedStyles,
    template_index,
    dev_mode: props.dev_mode,
  }
  return (
    <TemplateProvider {...templateProps}>
      <NetworkingBriefProvider {...props}>
        <NetworkingBriefBuilderInner />
      </NetworkingBriefProvider>
    </TemplateProvider>
  )
}
export default NetworkingBriefBuilder

const NetworkingBriefBuilderInner = () => {
  const { devMode } = useNetworkingBrief()

  const defaultActiveTab = 'details'

  return (
    <>
      <div className="p-4 md:p-12">
        <h1>Networking Brief Builder</h1>
        <p className="w-4/5">
          A networking brief is like your personal marketing one-pager for job
          searching. It acts as a concise and compelling summary of your skills,
          experience, and career goals. This handy tool helps you quickly and
          effectively communicate your value proposition to potential employers
          or contacts in your network. With a well-crafted networking brief, you
          can make a memorable first impression, spark meaningful conversations,
          and open doors to hidden job opportunities. It keeps you focused and
          prepared to articulate your career aspirations and qualifications,
          ultimately increasing your chances of landing that dream job.
        </p>
        <ul className="list-inside list-disc">
          <li>Be concise and engaging to support quick introductions.</li>
          <li>Focus on your professional identity and networking goals.</li>
          <li>Use action-oriented language.</li>
          <li>
            Use simple, jargon-free language suitable for a diverse user base.
          </li>
          <li>
            Be professional and use consistent headings, bullet points, and
            spacing throughout.
          </li>
        </ul>
      </div>
      <div className="flex w-full flex-col md:flex-row">
        <div className="md:basis-3/5">
          <TabNav
            className="px-4 pt-4 lg:px-8 xl:px-12"
            defaultActiveTab={defaultActiveTab}
            mobileMode="swipe"
          >
            <ButtonContainer>
              <TabButton
                name="details"
                text="Details"
                Icon={IdentificationIcon}
              />
              <TabButton
                name="formattingAndDesign"
                text="Formatting & Design"
                Icon={ClipboardDocumentCheckIcon}
              />
              {devMode && (
                <TabButton
                  name="text"
                  text="Raw JSON"
                  Icon={DocumentTextIcon}
                />
              )}
            </ButtonContainer>
            <PanelContainer className="md:min-h-[130vh]">
              <TabPanel name="details" className="pt-8">
                <Editor />
              </TabPanel>
              <TabPanel name="formattingAndDesign" className="pt-8">
                <StyleEditor />
              </TabPanel>
              <TabPanel name="text" className="pt-8">
                <TextEditor />
              </TabPanel>
            </PanelContainer>
          </TabNav>
        </div>
        <div id="resume-pdf-preview" className="relative md:flex-grow">
          <div className="h-screen w-full md:sticky md:left-0 md:top-0">
            <PdfViewerPanel />
          </div>
        </div>
        <Toaster />
      </div>
    </>
  )
}

const TextEditor = () => {
  const { networkingBriefDocument, setNetworkingBriefDocument } =
    useNetworkingBrief()
  const [editorNetworkingBrief, setEditorNetworkingBrief] = useState(
    JSON.stringify(networkingBriefDocument, null, 2),
  )

  const sync = () => {
    const updatedNetworkingBrief = JSON.parse(editorNetworkingBrief)
    setNetworkingBriefDocument(updatedNetworkingBrief)
  }

  useEffect(() => {
    setEditorNetworkingBrief(JSON.stringify(networkingBriefDocument, null, 2))
  }, [networkingBriefDocument])

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-row justify-evenly">
        <Button onClick={sync}>Sync</Button>
      </div>
      <Textarea
        className="h-full w-full"
        value={editorNetworkingBrief}
        onChange={(e) => setEditorNetworkingBrief(e.target.value)}
      ></Textarea>
    </div>
  )
}
