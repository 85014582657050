import React, { createContext, useContext, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { apiPost, apiPatch } from '../../util/api'

const QuizContext = createContext()

export const useQuiz = () => {
  return useContext(QuizContext)
}

export const QuizProvider = ({
  startPageAvatars,
  resultsPageAvatars,
  quizId,
  quizPartId,
  quizName,
  quizTime,
  content,
  bucketPath,
  children,
  numChoices,
  hasResumeReview,
  flexOrAbove,
}) => {
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const retake = queryParams.get('retake') === 'true'

  const [started, setStarted] = useState(
    (quizPartId && localStorage.getItem(`${quizPartId}:started`) === 'true') ||
      false,
  )
  const [showResults, setShowResults] = useState(content?.showResults || false)

  const getSlugs = () => {
    if (content) return content.slugs
  }

  const getResultsTasks = () => {
    if (showResults) {
      return content?.tasks
    }
  }
  const tempSlugs = getSlugs()
  const tempResults = getResultsTasks()

  const [tasks, setTasks] = useState(content?.tasks || {})
  const [slugs, setSlugs] = useState(tempSlugs ? new Set(tempSlugs) : new Set())
  const [documentId, setDocumentId] = useState(quizId)
  const [documentPartId, setDocumentPartId] = useState(quizPartId)
  const [pageRenderedOnce, setPageRenderedOnce] = useState(false)
  const [trackContent, setTrackContent] = useState(content?.content || {})
  const [quizIndex, setQuizIndex] = useState(0)
  const formattedQuizName = quizName.toLowerCase().split(' ').join('_')
  const [resultsTasks, setResultsTasks] = useState(tempResults || [])
  const module = window.location.pathname.split('/')[1]
  const randomStartAvatar =
    startPageAvatars[Math.floor(Math.random() * startPageAvatars.length)]

  const randomResultsAvatar =
    resultsPageAvatars[Math.floor(Math.random() * resultsPageAvatars.length)]

  const avatarBucketPath =
    'https://www-yns-com.s3.us-east-2.amazonaws.com/images/platform/job_search_strategy_knowledge_quiz/'

  const startPageAvatar = avatarBucketPath + randomStartAvatar

  const resultsPageAvatar = avatarBucketPath + randomResultsAvatar

  const actions = (questionNumber, answerType) => {
    const prevState = slugs
    switch (parseInt(questionNumber)) {
      case 1:
        if (answerType === 0) {
          prevState.delete('resume-builder')
          // setSlugs(new Set([...prevState, 'resume-review']));
          setSlugs(new Set([...prevState]))
        } else setSlugs((prev) => new Set([...prev, 'resume-webinar']))
        break
      case 2:
        prevState.add('linked-in-guide')
        setSlugs(new Set([...prevState]))
        break
      // setSlugs(prev => new Set([...prev, 'linked-in-guide']))
      case 3:
        prevState.add('cover-letter-builder')
        setSlugs(new Set([...prevState]))
        break
      case 4:
        break
    }
  }

  useEffect(() => {
    if (quizPartId) {
      localStorage.setItem(`${quizPartId}:started`, started)
      localStorage.setItem(`${quizPartId}:results`, showResults)
    }
  }, [started, showResults, quizIndex, quizPartId])

  useEffect(() => {
    if (!pageRenderedOnce && Object.keys(trackContent).length > 0) {
      setQuizIndex(Object.keys(trackContent).length - 1)
    }
    return () => {
      setPageRenderedOnce(true)
    }
  }, [trackContent, pageRenderedOnce])

  useEffect(() => {
    if (retake) {
      retakeQuiz()
    }
  }, [retake])

  const retakeQuiz = async () => {
    setStarted(true)
    setShowResults(false)
    setTasks({})
    setSlugs(new Set())
    setTrackContent({})
    setQuizIndex(0)

    try {
      await apiPost(
        `/${module}/resources/quizzes/${formattedQuizName.replace(
          'job_search',
          'job_search_preparedness',
        )}/delete_achievement`,
        { quiz_id: documentId },
      )
    } catch (error) {
      console.error('Error retaking quiz:', error)
    }
  }

  const saveQuiz = async (results = false, refresh = true) => {
    const url = documentId
      ? `/documents/${documentId}/parts/${documentPartId}`
      : `/quiz/document/quizzes?quiz_type=${formattedQuizName}`

    try {
      if (!documentId) {
        const res = await apiPost(url, {})
        if (res.status === 200) {
          setStarted(true)
          const data = res.data
          setDocumentId(data.document_id)
          setDocumentPartId(data.document_part_id)
          return res
        }
      } else {
        const documentPartsInfo = {
          content: { content: trackContent },
        }
        documentPartsInfo.content['tasks'] = tasks
        documentPartsInfo.content['slugs'] = Array.from(slugs)
        documentPartsInfo.content['showResults'] = results
        if (results) {
          setResultsTasks(tasks)
          setTasks([])
        }
        const res = await apiPatch(url, documentPartsInfo)
        if (res.status === 200) {
          if (results) {
            setShowResults(true)
            document
              .getElementsByTagName('body')[0]
              .classList.add('quiz-results')

            const module = window.location.pathname.split('/')[1]
            await apiPost(
              `/${module}/resources/quizzes/${formattedQuizName.replace(
                /^job_search$/,
                'job_search_preparedness',
              )}/send_achievement`,
              { quiz_id: documentId },
            )
          } else if (refresh) window.location.pathname = `/${module}/resources`
          return res
        }
      }
    } catch (error) {
      console.error('Error saving quiz:', error)
    }
    throw new Error('res not okay')
  }

  const value = {
    quizPartId,
    documentId,
    setDocumentId,
    documentPartId,
    setDocumentPartId,
    quizName,
    tasks,
    setTasks,
    actions,
    slugs,
    setSlugs,
    startPageAvatar,
    resultsPageAvatar,
    quizTime,
    content,
    bucketPath,
    pageRenderedOnce,
    setPageRenderedOnce,
    started,
    setStarted,
    showResults,
    setShowResults,
    quizIndex,
    setQuizIndex,
    saveQuiz,
    resultsTasks,
    trackContent,
    setTrackContent,
    formattedQuizName,
    numChoices,
    hasResumeReview,
    retakeQuiz,
    flexOrAbove,
  }

  return <QuizContext.Provider value={value}>{children}</QuizContext.Provider>
}
