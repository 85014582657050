import * as React from 'react'

import {
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from '../../lib/ui/form'

import { Input } from '../../lib/ui/input'

import { cn } from '../../lib/utils'

export default function SearchInput({
  name,
  control,
  label,
  placeholder,
  description,
  className,
  tempVal,
  icon: Icon,
  inputClassName,
  ...props
}) {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem className={cn('w-full', className)}>
          {label && <FormLabel>{label}</FormLabel>}
          {description && <FormDescription>{description}</FormDescription>}
          <FormControl>
            <div className="relative">
              <div className="absolute left-2 top-2 z-10 text-muted-foreground md:left-4 md:top-3">
                <Icon />
              </div>
              <Input
                placeholder={placeholder}
                className={cn(
                  'autocomplete w-full rounded-xl border-alto-300 bg-intoo-haze pl-10 focus-visible:ring-intoo-blue-medium md:h-12 md:pl-14 md:text-lg',
                  inputClassName,
                )}
                {...field}
                {...props}
              />
            </div>
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
