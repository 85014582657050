import React from 'react'
import Template0 from './Template0'
import Template1 from './Template1'

const TemplateSwitcher = ({ referenceSheetDocument, templateIndex }) => {
  switch (templateIndex) {
    case 0:
      return <Template0 referenceSheetDocument={referenceSheetDocument} />
    case 1:
      return <Template1 referenceSheetDocument={referenceSheetDocument} />
    default:
      console.error('Invalid template index', templateIndex)
      return null
  }
}
export default TemplateSwitcher
