import React from 'react'

const ProductIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M11.3636 36.6666L11.3637 31.9049C10.8651 26.9426 5 24.2861 5 16.6672C4.99998 9.04848 9.54562 3.23948 18.6365 3.3344C25.1823 3.40273 31.3637 7.14378 31.3637 14.7626L35 20.4766C35 24.2861 31.3637 24.2861 31.3637 24.2861C31.3637 24.2861 32.2727 33.8096 24.0908 33.8096V36.6666"
        stroke="#08467C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3333 19.9999C20.1743 19.9999 21.6667 18.5076 21.6667 16.6666C21.6667 14.8256 20.1743 13.3333 18.3333 13.3333C17.7262 13.3333 17.157 13.4956 16.6667 13.7792C15.6703 14.3555 15 15.4328 15 16.6666C15 17.9004 15.6703 18.9776 16.6667 19.5539C17.157 19.8376 17.7262 19.9999 18.3333 19.9999Z"
        stroke="#08467C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.3334 21.6667C21.0949 21.6667 23.3334 19.4282 23.3334 16.6667C23.3334 13.9053 21.0949 11.6667 18.3334 11.6667C15.572 11.6667 13.3334 13.9053 13.3334 16.6667C13.3334 19.4282 15.572 21.6667 18.3334 21.6667Z"
        stroke="#08467C"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeDasharray="0.3 2"
      />
    </svg>
  )
}

const EarCatchingPhraseIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M20.8333 21.6667L16.6666 28.3334H23.3333L19.1666 35.0001"
        stroke="#A98300"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.66671 22.4997L5.85826 22.7019C4.37436 23.0729 3.33337 24.4062 3.33337 25.9357V29.0639C3.33337 30.5934 4.37436 31.9267 5.85826 32.2977L8.75751 33.0226C9.38866 33.1802 10 32.7029 10 32.0524V22.9472C10 22.2967 9.38866 21.8194 8.75751 21.9771L6.66671 22.4997ZM6.66671 22.4997V21.6667C6.66671 13.3825 12.6362 6.66675 20 6.66675C27.3639 6.66675 33.3334 13.3825 33.3334 21.6667V22.5001M33.3334 22.5001L34.1419 22.7019C35.6257 23.0729 36.6667 24.4062 36.6667 25.9357V29.0639C36.6667 30.5934 35.6257 31.9267 34.1419 32.2977L31.2425 33.0226C30.6114 33.1802 30 32.7029 30 32.0524V22.9472C30 22.2967 30.6114 21.8194 31.2425 21.9771L33.3334 22.5001Z"
        stroke="#A98300"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const OfferIcon = () => {
  return (
    <svg
      width="40"
      height="40"
      viewBox="0 0 40 40"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.7249 24.0252L9.45658 20.3535C9.67888 19.8822 10.3211 19.8822 10.5434 20.3535L12.2751 24.0252L16.1476 24.6177C16.6445 24.6937 16.8425 25.3328 16.4828 25.6997L13.6811 28.5557L14.3423 32.5905C14.4273 33.1087 13.9076 33.5037 13.463 33.259L9.99998 31.353L6.53696 33.259C6.09238 33.5037 5.57269 33.1087 5.65761 32.5905L6.31881 28.5557L3.51715 25.6997C3.15743 25.3328 3.35546 24.6937 3.85233 24.6177L7.7249 24.0252Z"
        stroke="#1C6F29"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M27.7248 24.0252L29.4567 20.3535C29.6788 19.8822 30.3212 19.8822 30.5433 20.3535L32.2752 24.0252L36.1477 24.6177C36.6445 24.6937 36.8425 25.3328 36.4828 25.6997L33.6812 28.5557L34.3423 32.5905C34.4273 33.1087 33.9077 33.5037 33.463 33.259L30 31.353L26.537 33.259C26.0923 33.5037 25.5727 33.1087 25.6577 32.5905L26.3188 28.5557L23.5172 25.6997C23.1575 25.3328 23.3555 24.6937 23.8523 24.6177L27.7248 24.0252Z"
        stroke="#1C6F29"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M17.7248 9.02525L19.4566 5.35351C19.6788 4.88216 20.3211 4.88216 20.5433 5.35351L22.2751 9.02525L26.1476 9.61766C26.6445 9.69366 26.8425 10.3329 26.4828 10.6996L23.6811 13.5556L24.3423 17.5905C24.4273 18.1087 23.9076 18.5037 23.463 18.259L20 16.353L16.537 18.259C16.0924 18.5037 15.5727 18.1087 15.6576 17.5905L16.3188 13.5556L13.5171 10.6996C13.1574 10.3329 13.3555 9.69366 13.8523 9.61766L17.7248 9.02525Z"
        stroke="#1C6F29"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

const PlusIcon = ({ className }) => {
  return (
    <svg
      width="24"
      height="24"
      className={className}
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12 4.5V19.5M19.5 12H4.5"
        stroke="white"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export { ProductIcon, EarCatchingPhraseIcon, OfferIcon, PlusIcon }
