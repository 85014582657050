import React from 'react'
import { useTemplate } from './TemplateProvider'

const SelectControl = ({ title, settingName, children }) => {
  const { useTemplateSetting, isSettingSupported } = useTemplate()
  const [settingValue, setSettingValue] = useTemplateSetting(settingName)

  if (!isSettingSupported(settingName)) {
    return null
  }

  const selectClasses =
    'border-input bg-input-background rounded-md inline mb-4 w-full'

  return (
    <>
      <div id={`select-control-${settingName}`} className="font-bold">
        {title}
      </div>
      <select
        className={selectClasses}
        aria-labelledby={`select-control-${settingName}`}
        name={title}
        value={settingValue}
        onChange={(e) => setSettingValue(e.target.value)}
      >
        {children}
      </select>
    </>
  )
}

export default SelectControl
