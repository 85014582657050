import React from 'react'
import { useNetworkingBrief } from '../NetworkingBriefProvider'
import { EditorHeading } from '../../ResumeBuilder/Editor/common'
import { EditorItemTextField, EditorItemProvider } from './EditorItemProvider'

const CareerHighlightsEditor = () => {
  const { networkingBriefDocument: { careerHighlights } = {} } =
    useNetworkingBrief()

  return (
    <EditorItemProvider sectionName="careerHighlights" item={careerHighlights}>
      <div>
        <EditorHeading>Career Highlights</EditorHeading>
        <p> Top 2-3 professional achievements in a brief statement.</p>
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          <EditorItemTextField
            containerClassName="md:col-span-2"
            fieldName="professionalAchievements"
            label="Professional Achievements Statement"
            areaClassName="h-[150px]"
          />
        </div>
      </div>
    </EditorItemProvider>
  )
}
export default CareerHighlightsEditor
