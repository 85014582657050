import React from 'react'
import { ArticleCards } from './ArticleCard'
import { AudioCards } from './AudioCard'
import { VideoCards } from './VideoCard'
import { ExerciseCards } from './ExerciseCard'
import { QuizCards } from './QuizCard'
import { WorkshopCards } from './WorkshopCard'
import { ScriptCards } from './ScriptCard'
import { ChecklistCards } from './ChecklistCard'
import { useResources } from './ResourcesProvider'

const NoFavoritesMessage = () => {
  return (
    <div className="col-span-1 mt-5 text-center md:col-span-2">
      <img
        src="https://www-yns-com.s3.us-east-2.amazonaws.com/images/no_favorites.png"
        alt="No Favorites"
        className="mx-auto mb-6"
      />
      <h2 className="mb-4">No Favorites</h2>
      <p>
        Explore other sections and mark your preferred resources as favorites to
        see them displayed here.
      </p>
    </div>
  )
}

const FavoritesCards = () => {
  const { hasFavorites } = useResources()
  return (
    <>
      {hasFavorites() ? (
        <>
          <ArticleCards />
          <AudioCards />
          <VideoCards />
          <ExerciseCards />
          <QuizCards />
          <WorkshopCards />
          <ChecklistCards />
          <ScriptCards />
        </>
      ) : (
        <NoFavoritesMessage />
      )}
    </>
  )
}
export default FavoritesCards
