import React from 'react'
import BasicsEditor from './BasicsEditor'
import ProfessionalIdentityEditor from './ProfessionalIdentityEditor'
import KeySkills from './KeySkills'
import CareerHighlightsEditor from './CareerHighlightsEditor'
import GoalsEditor from './GoalsEditor'
import CallToActionEditor from './CallToActionEditor'
import TargetRolesEditor from './TargetRolesEditor'
import TargetCompaniesEditor from './TargetCompaniesEditor'

const Editor = () => {
  return (
    <div className="grid grid-cols-1 gap-14">
      <BasicsEditor />
      <ProfessionalIdentityEditor />
      <KeySkills />
      <CareerHighlightsEditor />
      <GoalsEditor />
      <CallToActionEditor />
      <TargetRolesEditor />
      <TargetCompaniesEditor />
      <div className="h-80"></div>
    </div>
  )
}
export default Editor
