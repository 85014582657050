import React from 'react'
import {
  SliderControl,
  useTemplate,
  fonts,
  SelectControl,
  ColorPickerControl,
} from '../../../lib/pdf'

const formatDecimal = (value) => {
  if (value >= 1.0) {
    return value.toFixed(2)
  } else {
    return value.toFixed(2).slice(1)
  }
}

const formatInches = (value) => {
  return `${formatDecimal(value)} in`
}

const StyleEditor = () => {
  const { templateSettings, devMode } = useTemplate()

  return (
    <>
      <div className="mb-6 text-lg font-medium text-primary">Styling</div>
      <SelectControl title="Primary Font" settingName="primaryFontFamily">
        {fonts.map((font) => (
          <option key={font.family} value={font.family}>
            {font.family}
          </option>
        ))}
      </SelectControl>
      <ColorPickerControl settingName="accentColor" title="Accent Color" />
      <div className="mb-6 text-lg font-medium text-primary">Page Setup</div>
      <SliderControl
        title="Top and Bottom Margin"
        min={0.4}
        max={1.5}
        step={0.01}
        settingName="yMargin"
        formattingFunction={formatInches}
      />
      <SliderControl
        title="Left and Right Margin"
        min={0.4}
        max={1.5}
        step={0.01}
        settingName="xMargin"
        formattingFunction={formatInches}
      />
      {devMode && <pre>{JSON.stringify(templateSettings, null, 2)}</pre>}
    </>
  )
}

export default StyleEditor
