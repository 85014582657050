import React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '.././ExerciseProvider'
import { Textarea } from '../../../lib/ui/textarea'

// Helper function to ensure the URL has a protocol
const ensureProtocol = (url) => {
  if (!url) return ''
  return url.startsWith('http://') || url.startsWith('https://')
    ? url
    : `https://${url}`
}

const SocialMediaSection = ({
  translations,
  profileLink,
  onProfileLinkChange,
  site,
}) => {
  return (
    <div className="mb-6">
      <h2 className="mb-4">{translations.header}</h2>
      <p className="mb-4">{translations.description}</p>
      <ol className="mb-4 ml-4 list-inside !list-decimal">
        {translations.steps.map((step, index) => (
          <li key={index} className="mb-1">
            {step}
          </li>
        ))}
      </ol>
      <p className="mb-4">{translations.p1}</p>
      <div className="mb-4 flex flex-row items-center">
        <p className="!mb-0 mr-3 !font-bold">{translations.profile_link}</p>
        <Textarea
          placeholder={translations.placeholder}
          value={profileLink}
          onChange={(event) => {
            const url = event.target.value
            const formattedUrl =
              site === 'facebook' || site === 'linkedin'
                ? ensureProtocol(url)
                : url
            onProfileLinkChange(event, formattedUrl)
          }}
          className={'min-w-[400px]'}
        />
      </div>
      <p>{translations.great_job}</p>
    </div>
  )
}

const SocialMediaReviewSectionTwo = () => {
  const { trackContent, formattedExerciseName, handleChange, exerciseIndex } =
    useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const sectionTwoTranslations = t('pages.1')
  const selectedSocialMediaSites = trackContent['0']
  const profileLinks = trackContent['1'] || {}

  const socialMediaTranslations = {
    linkedin: sectionTwoTranslations.linkedin,
    facebook: sectionTwoTranslations.facebook,
    x: sectionTwoTranslations.x,
    instagram: sectionTwoTranslations.instagram,
    tiktok: sectionTwoTranslations.tiktok,
  }

  const getProfileLink = (site) => {
    switch (site) {
      case 'linkedin':
        return profileLinks.linkedin_link
      case 'facebook':
        return profileLinks.facebook_link
      case 'x':
        return profileLinks.x_link
      case 'instagram':
        return profileLinks.instagram_link
      case 'tiktok':
        return profileLinks.tiktok_link
      default:
        return ''
    }
  }

  return (
    <div className="flex flex-col">
      {Object.keys(selectedSocialMediaSites).map(
        (site) =>
          selectedSocialMediaSites[site] && (
            <SocialMediaSection
              key={site}
              translations={socialMediaTranslations[site]}
              profileLink={getProfileLink(site)}
              site={site}
              onProfileLinkChange={(event, url) => {
                handleChange(exerciseIndex, site + '_link', event, url)
              }}
            />
          ),
      )}
    </div>
  )
}

export default SocialMediaReviewSectionTwo
