import React, { useRef, useEffect } from 'react'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { cn } from '../utils'

const ExpandyPanel = ({
  headingText,
  onClick,
  className,
  index,
  expanded,
  minHeight = 'min-h-[24rem]',
  children,
}) => {
  const defaultClasses = cn(
    'w-full rounded-lg border border-solid border-[#B9B9B9] transition-all duration-500 overflow-hidden',
    className,
  )
  const expandedClasses = cn(defaultClasses, minHeight)
  const contractedClasses = cn(defaultClasses, 'h-12')
  const chevronExpandedClasses = 'transition-all rotate-180 duration-500'
  const chevronContractedClasses = 'transition-all rotate-0 duration-500'
  const expandyPanelRef = useRef()

  useEffect(() => {
    const firstInput = expandyPanelRef.current?.querySelector('input')
    if (expanded) {
      firstInput?.focus()
    }
  }, [expanded, expandyPanelRef])

  return (
    <div
      ref={expandyPanelRef}
      className={expanded ? expandedClasses : contractedClasses}
    >
      <button
        onClick={onClick}
        key={`expandy-panel-${index}-button`}
        className="flex h-12 w-full cursor-pointer flex-row items-center justify-between px-4"
      >
        <div tabIndex="-1" className="font-bold">
          {headingText}
        </div>
        <div
          className={
            expanded ? chevronExpandedClasses : chevronContractedClasses
          }
        >
          <ChevronDownIcon className="w-6" />
        </div>
      </button>
      {expanded && <div className="p-4">{children}</div>}
    </div>
  )
}

export default ExpandyPanel
