import React from 'react'
import BasicsEditor from './BasicsEditor'
import ReferenceEditor from './ReferenceEditor'

const Editor = () => {
  return (
    <div className="grid grid-cols-1 gap-14">
      <BasicsEditor />
      <ReferenceEditor />
      <div className="h-80"></div>
    </div>
  )
}
export default Editor
