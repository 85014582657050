import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { apiPatch } from '../../../util/api'

const useSavedJobsForm = (savedJob) => {
  const id = savedJob.id
  const [submitted, setSubmitted] = useState(false)
  const schema = z.object({
    title: z.string().optional(),
    city: z.string().optional(),
    state: z.string().optional(),
    country: z.string().optional(),
    postal_code: z.union([z.string(), z.null()]).optional(),
    url: z.string().optional(),
    description: z.string().optional(),
    company_name: z.string().optional(),
    location: z.string().optional(),
    status: z.string().optional(),
    followup_on: z.union([z.date(), z.null(), z.string()]).optional(),
    notes: z.string().optional(),
    resume: z
      .union([z.instanceof(File), z.null(), z.string()])
      .optional()
      .refine((file) => !file || file.size < 5000000, {
        message: 'Resume file must be less than 5MB',
      }),
    cover_letter: z
      .union([z.instanceof(File), z.null(), z.string()])
      .optional()
      .refine((file) => !file || file.size < 5000000, {
        message: 'Cover letter file must be less than 5MB',
      }),
  })

  const defaultValues = {
    title: savedJob.title,
    city: savedJob.city,
    state: savedJob.state,
    country: savedJob.country,
    postal_code: savedJob.postal_code,
    url: savedJob.url,
    description: savedJob.description,
    company_name: savedJob.company_name,
    location: savedJob.location,
    status: savedJob.status,
    followup_on: savedJob.followup_on,
    notes: savedJob.notes || '',
    resume: savedJob.resume,
    cover_letter: savedJob.cover_letter,
  }

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const onSubmit = async (values) => {
    setSubmitted(true)

    const url = `/job-search/saved_jobs/${id}`

    const data = {
      saved_job: {
        title: values.title,
        city: values.city,
        state: values.state,
        country: values.country,
        postal_code: values.postal_code,
        url: values.url,
        description: values.description,
        company_name: values.company_name,
        location: values.location,
        status: values.status,
        followup_on: values.followup_on,
        notes: values.notes,
        resume: values.resume,
        cover_letter: values.cover_letter,
      },
    }

    const response = await apiPatch(url, data, true)

    if (response.status === 200 || response.status === 201) {
      console.log('Success')
      window.location.reload()
    } else {
      console.log(response)
      throw new Error('Failed to save job')
    }
  }

  return { form, onSubmit, submitted }
}

export default useSavedJobsForm
