import React from 'react'
import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
} from '@react-pdf/renderer'
import { timesNewRoman } from '../../lib/pdf/fonts'

Font.register(timesNewRoman)

const isNotBlank = (item) => ![null, undefined, ''].includes(item)

const Template0 = ({ referenceSheetDocument }) => {
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      width: '100%',
      padding: '20px',
    },
    heading: {
      width: '100%',
      textAlign: 'center',
      fontSize: 24,
    },
    mainContainer: {
      color: '#000000',
      fontFamily: 'Times New Roman',
      flexDirection: 'column',
      width: '100%',
    },
    contactSection: {
      flexDirection: 'row',
      fontSize: 12,
      justifyContent: 'center',
      paddingBottom: 4,
    },
    contactItem: {},
    contactSpacer: {
      padding: '0 8px',
    },
    sectionTitle: {
      fontSize: '12px',
      fontWeight: 'bold',
      marginTop: '15px',
      borderBottom: '1px solid #000000',
      marginBottom: '3px',
    },
  })

  const { basics, references } = referenceSheetDocument
  const { name, email, phone, location } = basics || {}
  const { address, city, region, postalCode } = location || {}

  const regionPostalCode = [region, postalCode].filter(isNotBlank).join(' ')
  const cityStateZip = city ? `${city}, ${regionPostalCode}` : regionPostalCode
  const contactItems = [address, cityStateZip, email, phone].filter(isNotBlank)

  return (
    <Document pageMode="useNone" pageLayout="OneColumn">
      <Page size="Letter" style={styles.page}>
        <View style={styles.mainContainer}>
          <Text style={styles.heading}>{name}</Text>
          <View style={styles.contactSection}>
            {contactItems.map((item, index) => {
              return (
                <View key={index} style={{ flexDirection: 'row' }}>
                  <Text style={styles.contactItem}>{item}</Text>
                  {index < contactItems.length - 1 && (
                    <Text style={styles.contactSpacer}>|</Text>
                  )}
                </View>
              )
            })}
          </View>
          <View>
            {references && references.length > 0 && (
              <ReferencesSection references={references} styles={styles} />
            )}
          </View>
        </View>
      </Page>
    </Document>
  )
}
export default Template0

const ReferencesSection = ({ references, styles }) => {
  return (
    <>
      <Text style={styles.sectionTitle}>References</Text>
      {references.map((refItem, index) => (
        <ReferenceView reference={refItem} key={`reference-${index}`} />
      ))}
    </>
  )
}

const ReferenceView = ({ reference }) => {
  const styles = StyleSheet.create({
    container: {
      paddingTop: '15px',
      fontSize: '12px',
    },
    title: {
      fontWeight: 'bold',
      paddingRight: '8px',
    },
    referenceRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '3px',
    },
    connectionRow: {
      marginTop: '6px',
    },
  })

  if (!reference.name) {
    return null
  }

  const {
    name,
    positionTitle,
    companyName,
    streetAddress,
    cityStateZip,
    phoneNumber,
    email,
    connection,
  } = reference

  return (
    <View style={styles.container} wrap={false}>
      <View style={styles.referenceRow}>
        <Text style={styles.title}>{name}</Text>
      </View>
      {positionTitle && positionTitle.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{positionTitle}</Text>
        </View>
      )}
      {companyName && companyName.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{companyName}</Text>
        </View>
      )}
      {streetAddress && streetAddress.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{streetAddress}</Text>
        </View>
      )}
      {cityStateZip && cityStateZip.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{cityStateZip}</Text>
        </View>
      )}
      {phoneNumber && phoneNumber.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{phoneNumber}</Text>
        </View>
      )}
      {email && email.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{email}</Text>
        </View>
      )}
      {connection && connection.length > 0 && (
        <View style={styles.connectionRow}>
          <Text>{connection}</Text>
        </View>
      )}
    </View>
  )
}
