import React, { useState, useEffect } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '.././ExerciseProvider'

const SocialMediaReviewSectionOne = () => {
  const {
    updateResponse,
    getResponse,
    exerciseIndex,
    formattedExerciseName,
    setContinueButtonDisabled,
    handleChange,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const sectionOneTranslations = t('pages.0')
  const socialMediaSites = sectionOneTranslations.social_media_sites

  const order = ['LinkedIn', 'Facebook', 'X', 'Instagram', 'TikTok']
  const orderedSocialMediaSites = order.reduce((acc, site) => {
    const slug = Object.keys(socialMediaSites).find(
      (key) => socialMediaSites[key] === site,
    )
    if (slug) {
      acc[slug] = site
    }
    return acc
  }, {})

  const initialCheckboxesState = Object.keys(orderedSocialMediaSites).reduce(
    (acc, slug) => {
      acc[slug] = getResponse(exerciseIndex, slug) || false
      return acc
    },
    {},
  )

  const [selectedCheckboxes, setSelectedCheckboxes] = useState(
    initialCheckboxesState,
  )

  useEffect(() => {
    const isAnyCheckboxSelected = Object.values(selectedCheckboxes).some(
      (value) => value,
    )
    setContinueButtonDisabled(!isAnyCheckboxSelected)
  }, [selectedCheckboxes, setContinueButtonDisabled])

  const handleCheckboxChange = (slug, checked) => {
    setSelectedCheckboxes((prevState) => ({
      ...prevState,
      [slug]: checked,
    }))
  }

  const handleDivClick = (section, slug) => {
    const currentValue = getResponse(section, slug) || false
    const newValue = !currentValue
    updateResponse(section, slug, newValue)
    handleCheckboxChange(slug, newValue)
  }

  const handleInputChange = (exerciseIndex, slug, event) => {
    handleChange(exerciseIndex, slug, event)
    handleCheckboxChange(slug, event.target.checked)
  }

  return (
    <div className="flex flex-col">
      <p className="-mt-4">{sectionOneTranslations.first_step}</p>
      <p>{sectionOneTranslations.social_media_account_question}</p>
      <div className="mt-4">
        {Object.entries(orderedSocialMediaSites).map(([slug, site], index) => (
          <div key={index} className="mb-6 font-roboto text-base font-medium">
            <div
              className="flex items-center rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3"
              onClick={() => handleDivClick(exerciseIndex, slug)}
            >
              <input
                type="checkbox"
                className="!mb-1 !mr-2 !mt-0 h-4 w-4 rounded border-gray-300 text-blue-600"
                onChange={(e) => handleInputChange(exerciseIndex, slug, e)}
                checked={selectedCheckboxes[slug] || false}
                onClick={(e) => e.stopPropagation()}
              />
              <label className="font-roboto-light !mb-1 text-sm font-medium">
                {site}
              </label>
            </div>
          </div>
        ))}
      </div>
      <p>{sectionOneTranslations.great_first_step}</p>
    </div>
  )
}

export default SocialMediaReviewSectionOne
