import React from 'react'
import { useResume } from '../ResumeProvider'
import { useTemplate } from '~/lib/pdf'
import { EditorHeading } from './common'
import {
  EditorItemField,
  EditorTextArea,
  EditorItemProvider,
} from './EditorItemProvider'
import AiSummary from './AiSummary'

const BasicsEditor = () => {
  const { resumeDocument: { basics } = {} } = useResume()
  const { editorFieldEnabled } = useTemplate()

  const handleLinkedInProfileUpdate = (item, value) => {
    const updatedProfiles = item.profiles?.map((profile) =>
      profile.network?.toLowerCase() === 'linkedin'
        ? { ...profile, url: value }
        : profile,
    ) || [{ network: 'LinkedIn', url: value }]
    return {
      ...item,
      profiles: updatedProfiles,
    }
  }

  const getLinkedInUrl = (item) =>
    item?.profiles?.find(
      (profile) => profile.network?.toLowerCase() === 'linkedin',
    )?.url || ''

  return (
    <EditorItemProvider sectionName="basics" item={basics}>
      <div>
        <EditorHeading>Personal Details</EditorHeading>
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          {editorFieldEnabled('basicsJobTitle') && (
            <EditorItemField
              containerClassName="md:col-span-2"
              label="Job Title"
              fieldName="label"
            />
          )}
          <EditorItemField
            containerClassName="md:col-span-2"
            label="Full Name"
            fieldName="name"
          />
          <EditorItemField label="Phone" fieldName="phone" />
          <EditorItemField label="Email" fieldName="email" />
          {editorFieldEnabled('basicsLocation') && (
            <>
              <EditorItemField
                label="Address"
                fieldName="location"
                childField="address"
              />
              <EditorItemField
                label="City"
                fieldName="location"
                childField="city"
              />
              <EditorItemField
                label="Region (State, Province, etc...)"
                fieldName="location"
                childField="region"
              />
              <EditorItemField
                label="Postal Code"
                fieldName="location"
                childField="postalCode"
              />
            </>
          )}
          {editorFieldEnabled('basicsWebsite') && (
            <EditorItemField label="Website" fieldName="url" />
          )}
          {editorFieldEnabled('basicsLinkedin') && (
            <EditorItemField
              label="LinkedIn"
              fieldName="profiles"
              getItemValue={getLinkedInUrl}
              handleItemUpdate={handleLinkedInProfileUpdate}
            />
          )}
          {editorFieldEnabled('basicsSummary') && (
            <>
              <EditorTextArea
                label="Summary"
                autoExpand={true}
                fieldName="summary"
                containerClassName="md:col-span-2"
                areaClassName="h-40"
              />
              <AiSummary />
            </>
          )}
        </div>
      </div>
    </EditorItemProvider>
  )
}
export default BasicsEditor
