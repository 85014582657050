import React, { useState } from 'react'
import { Button } from '../../lib/ui/button'
import { Toaster } from '../../lib/ui/toast'
import {
  ArrowUpTrayIcon,
  DocumentTextIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../lib/ui/dialog'
import DropzoneResumeUpload from './DropzoneResumeUpload'

const NewResumeOptions = ({ uploadResumeClick }) => {
  return (
    <div className="px-4 pb-4">
      <div className="mb-4 text-lg font-medium">Choose your approach</div>
      <div className="pb-4">
        You can create a resume using any of the options below.
      </div>
      <div className="pb-4">
        <a
          href="/resume/builder/new"
          className="flex h-28 w-full flex-row rounded-2.5xl bg-lily-blue text-primary"
        >
          <div className="flex items-center justify-center p-6">
            <PlusIcon className="w-10" />
          </div>
          <div className="flex flex-col items-start justify-evenly">
            <div className="text-roboto-slab text-xl font-medium">
              Create New Resume
            </div>
            <div>Choose a blank template and fill in the fields yourself.</div>
          </div>
        </a>
      </div>
      <div className="pb-4">
        <button
          onClick={uploadResumeClick}
          className="flex h-28 w-full flex-row rounded-2.5xl bg-lily-blue text-primary"
        >
          <div className="flex items-center justify-center p-6">
            <ArrowUpTrayIcon className="w-10" />
          </div>
          <div className="flex flex-col items-start justify-evenly">
            <div className="text-roboto-slab text-xl font-medium">
              Upload an Existing Resume
            </div>
            <div>Upload your existing resume in DOC, DOCX, or PDF formats.</div>
          </div>
        </button>
      </div>
    </div>
  )
}

const NewResumeModal = () => {
  const [open, setOpen] = useState(false)
  const [uploadResumeMode, setUploadResumeMode] = useState(false)

  const uploadResumeClick = () => {
    setUploadResumeMode(true)
  }

  const toggleOpen = () => {
    setOpen(!open)
    setUploadResumeMode(false)
  }

  return (
    <Dialog
      open={open}
      onOpenChange={toggleOpen}
      className="!w-auto rounded-2xl lg:max-w-[37rem] xl:max-w-[37rem]"
    >
      <DialogTrigger asChild className="cursor-pointer">
        <Button>Create a new Resume</Button>
      </DialogTrigger>
      <DialogContent
        className="font-roboto lg:max-w-3xl xl:max-w-5xl"
        variant="default"
        dialogCloseClass="text-black"
      >
        <DialogHeader>
          <DialogTitle className="text-left" asChild>
            <h2 className="text-2xl">
              Create Your Resume
              <DocumentTextIcon className="ml-2 inline w-6" />
            </h2>
          </DialogTitle>
        </DialogHeader>
        {!uploadResumeMode && (
          <NewResumeOptions uploadResumeClick={uploadResumeClick} />
        )}
        {uploadResumeMode && <DropzoneResumeUpload />}
      </DialogContent>
      <Toaster />
    </Dialog>
  )
}
export default NewResumeModal
