import React, { useEffect } from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import { useExercise } from './ExerciseProvider'
import { ReturnToResourceCenter, DownloadExerciseButton } from './Buttons'
import {
  CalendarIcon,
  ConclusionPageImage,
  ExerciseIcon,
  PersonIcon,
} from './icons'
import { useIsMobile } from '../../hooks/useMediaQuery'
import SummaryPage from '../Exercises/ChartYourCourse/SummaryPage'
import { cn } from '../../lib/utils'

const Conclusion = () => {
  const {
    formattedExerciseName,
    documentId,
    dateAndTimeUpdated,
    candidateFirstName,
    ssoName,
    trackContent,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const conclusionGreatJob = t('conclusion_great_job')
  const conclusionP1 = t('conclusion_p1')
  const conclusionP2 = t('conclusion_p2')

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const link = {
    linkPath: `/documents/${documentId}.pdf`,
    linkText: t('conclusion_link'),
  }

  const actionPlanLink = {
    linkPath: `/orientation/action_plan`,
    linkText: t('action_plan_link'),
  }

  return (
    <div className="exercise mb-0">
      <Header
        t={t}
        ssoName={ssoName}
        candidateFirstName={candidateFirstName}
        dateAndTimeUpdated={dateAndTimeUpdated}
      />
      <SummaryContent
        conclusionGreatJob={conclusionGreatJob}
        conclusionP1={conclusionP1}
        conclusionP2={conclusionP2}
        link={link}
        actionPlanLink={actionPlanLink}
        trackContent={trackContent}
        t={t}
      />
    </div>
  )
}

const Header = ({ t, ssoName, candidateFirstName, dateAndTimeUpdated }) => {
  const isMobile = useIsMobile()
  const { t: tExercise } = useTranslation('exercise')
  return (
    <div className="bg-intoo-blue-dark text-white">
      <div
        className={cn(
          'flex items-center justify-between',
          isMobile ? 'mx-4 pb-10' : 'mx-8 pb-14',
        )}
      >
        <div>
          <h1 className="mb-0 mt-10 !font-roboto-slab text-2xl text-[32px] font-medium leading-normal !text-white">
            {t(`start_exercise.title`)}
          </h1>
          <hr className="!b-0 my-4 h-[1px] w-32 bg-white text-white" />
          <div className="flex flex-col !font-roboto-slab text-[20px] sm:flex-row">
            {!ssoName && (
              <div
                className={cn(
                  'mr-0 flex items-center sm:mr-12',
                  isMobile ? 'mb-4' : '',
                )}
              >
                <div className="mr-4">
                  <PersonIcon />
                </div>
                {`${candidateFirstName}'s`} {tExercise('results')}
              </div>
            )}
            <div className="flex items-center">
              <div className="mr-4">
                <CalendarIcon />
              </div>
              {dateAndTimeUpdated}
            </div>
          </div>
        </div>
        <div className="mt-10 flex">
          <ExerciseIcon />
        </div>
      </div>
    </div>
  )
}

const SummaryContent = ({
  conclusionGreatJob,
  conclusionP1,
  conclusionP2,
  link,
  actionPlanLink,
  trackContent,
  t,
}) => {
  const isMobile = useIsMobile()
  const { formattedExerciseName, retakeExercise } = useExercise()
  const { t: tExercise } = useTranslation('exercise')

  const showDownloadButton =
    formattedExerciseName === 'motivation_reflection' ||
    formattedExerciseName === 'career_brainstorm' ||
    formattedExerciseName === 'chart_your_course'

  return (
    <div
      className={`summary-content min-h-[1200px] ${
        isMobile ? 'p-6' : 'p-10'
      } flex flex-col bg-[#E0F2F9]`}
    >
      <div
        className={`rounded-[20px] bg-white ${
          isMobile ? 'px-4 py-12' : 'px-12 py-12'
        }`}
      >
        <div className="text-center">
          <h2 className="mb-4 text-2xl font-semibold">{conclusionGreatJob}</h2>
          {formattedExerciseName === 'design_thinking' && (
            <LinkifiedCopy copy={conclusionP1} link={link} />
          )}
          {formattedExerciseName === 'career_brainstorm' && (
            <LinkifiedCopy copy={conclusionP1} link={actionPlanLink} />
          )}
          <div className="mx-auto my-6 flex justify-center">
            <ConclusionPageImage />
          </div>
          {formattedExerciseName === 'personal_brand_statement' &&
            trackContent[2]?.personal_brand_statement && (
              <div>
                <p className="subtitle !mb-6 font-roboto text-base font-normal">
                  {conclusionP1}
                </p>
                <p className="!mb-6 font-roboto text-base italic">
                  {trackContent[2]?.personal_brand_statement}
                </p>
              </div>
            )}
          {formattedExerciseName === 'reason_for_leaving_statement' &&
            trackContent[1]?.user_crafted_statement && (
              <div>
                <p className="subtitle !mb-6 font-roboto text-base font-normal">
                  {conclusionP1}
                </p>
                <p className="!mb-6 font-roboto text-base italic">
                  {trackContent[1]?.user_crafted_statement}
                </p>
              </div>
            )}
        </div>
        {formattedExerciseName === 'design_thinking' && (
          <p className="!mb-6 font-roboto text-base font-normal">
            {conclusionP2}
          </p>
        )}
        {formattedExerciseName === 'chart_your_course' && (
          <SummaryPage
            conclusionP1={conclusionP1}
            conclusionP2={conclusionP2}
          />
        )}
        {showDownloadButton && (
          <div className="flex justify-center">
            <DownloadExerciseButton
              href={link.linkPath}
              text={t('conclusion_link')}
            />
          </div>
        )}
        <div className="flex justify-center">
          <a
            onClick={retakeExercise}
            className="mt-4 cursor-pointer !underline"
          >
            {tExercise('retake_exercise')}
          </a>
        </div>
      </div>
      <ReturnToResourceCenter />
    </div>
  )
}

const LinkifiedCopy = ({ copy, link }) => {
  const [copy1, copy2] = copy.split('[link]')
  const { linkPath, linkText } = link
  return (
    <p className="font-roboto text-lg font-medium">
      {copy1}
      {copy2 && (
        <a
          href={linkPath}
          target="_blank"
          rel="noopener noreferrer"
          className="!underline"
        >
          {linkText}
        </a>
      )}
      {copy2}
    </p>
  )
}

export default Conclusion
