import React, { useState } from 'react'
import { DndContext } from '@dnd-kit/core'
import { Draggable, Droppable } from '../../../shared/DragAndDrop'
import { cn } from '../../../../lib/utils'
import { apiPatch } from '../../../../util/api'

const IndustryPreferences = ({ selectedIndustries, formOptions }) => {
  const { url } = formOptions
  const [industryPrefs, setIndustryPrefs] = useState(selectedIndustries)
  const droppables = [1, 2, 3, 4, 5]

  const datifyIndustries = (vals) => {
    const orderdVals = vals.sort((a, b) => a.rank - b.rank)
    const data = {}
    orderdVals.forEach((val, index) => {
      data[index] = { rank: val.rank, id: val.id }
    })
    return data
  }

  const updateDocument = async (industries) => {
    const industryData = datifyIndustries(industries)
    const data = { document_part: { industries_attributes: industryData } }
    const res = await apiPatch(url, data)
    if (!res.status === 200) {
      throw new Error('Failed to save response')
    }
  }

  const unrankedIndustries = industryPrefs.filter((i) => !i.rank)

  const [dragging, setDragging] = useState(false)

  const updateRanking = (id, rank) => {
    let rankToCompare = rank
    const newIndustryPrefs = industryPrefs.map((i) => {
      if (i.id === id) {
        return { ...i, rank }
      }
      if (i.rank === rankToCompare) {
        if (i.rank >= 5) {
          return { ...i, rank: null }
        }
        rankToCompare += 1
        return { ...i, rank: i.rank + 1 }
      }
      return i
    })
    setIndustryPrefs(newIndustryPrefs)
    updateDocument(newIndustryPrefs)
  }

  const removeRanking = (id) => {
    const newIndustryPrefs = industryPrefs.map((i) => {
      if (i.id === id) {
        return { ...i, rank: null }
      }
      return i
    })
    setIndustryPrefs(newIndustryPrefs)
    updateDocument(newIndustryPrefs)
  }

  const handleDragEnd = (e) => {
    setDragging(false)
    const { active, over } = e
    if (active?.id && over?.id) {
      const draggedIndustryId = active.id
      const rank = over.id
      updateRanking(draggedIndustryId, rank)
    } else if (!over) {
      const draggedIndustryId = active.id
      removeRanking(draggedIndustryId, null)
    }
  }

  const getRankedIndustry = (rank) => {
    const rankedIndustry = industryPrefs.find((i) => parseInt(i.rank) === rank)
    return rankedIndustry ? rankedIndustry : null
  }

  const getRank = (industryId) => {
    const rankedIndustry = industryPrefs.find((i) => i.id === industryId)
    return rankedIndustry && rankedIndustry.rank
  }

  const handleDragStart = (e) => {
    const { active } = e
    const draggedIndustryId = active.id
    const rank = getRank(draggedIndustryId)
    if (rank) {
      setDragging(true)
    }
  }

  const droppableClasses = cn(
    'flex h-10 justify-center rounded-[15px] border border-dashed border-intoo-blue-medium text-intoo-blue-medium',
  )

  return (
    <div>
      <DndContext onDragStart={handleDragStart} onDragEnd={handleDragEnd}>
        <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
          <div className="order-last flex flex-col gap-4 md:order-first">
            {unrankedIndustries.map((industry) => (
              <DraggableItem key={`draggable-${industry.id}`} id={industry.id}>
                {industry.name}
              </DraggableItem>
            ))}
          </div>
          <div className="order-first flex flex-col gap-4 md:order-last">
            {droppables.map((rankNumber) => {
              const rankedIndustry = getRankedIndustry(rankNumber)
              if (rankedIndustry) {
                return (
                  <Droppable
                    key={`ranked-draggable-${rankNumber}`}
                    id={rankNumber}
                    className={cn(dragging ? droppableClasses : 'w-full')}
                  >
                    <DraggableItem
                      id={rankedIndustry.id}
                      className={'w-full bg-intoo-blue-medium text-white'}
                    >
                      {rankedIndustry.name}
                    </DraggableItem>
                  </Droppable>
                )
              } else {
                return (
                  <Droppable
                    className={droppableClasses}
                    key={`droppable-container-${rankNumber}`}
                    id={rankNumber}
                  >
                    <div className="flex items-center justify-center font-roboto">
                      {rankNumber === 1 && 'Most Important '}
                      {rankNumber === 5 && 'Least Important '}
                    </div>
                  </Droppable>
                )
              }
            })}
          </div>
        </div>
      </DndContext>
    </div>
  )
}

export default IndustryPreferences

const DraggableItem = ({ className, id, children }) => {
  return (
    <Draggable
      className={cn(
        'rounded-[15px] border-2 border-intoo-blue-medium bg-white font-roboto capitalize text-intoo-blue-medium hover:bg-intoo-blue-medium hover:text-white',
        className,
      )}
      id={id}
    >
      {children}
    </Draggable>
  )
}
