import React, { useEffect } from 'react'
import { CarouselContent, useCarousel } from '~/lib/ui/carousel'
import { ChevronLeftIcon, ChevronRightIcon } from '@heroicons/react/20/solid'
import { useTabNav } from './TabNav'
import { cn } from '~/lib/utils'

const CarouselWrapper = ({ className, children }) => {
  const { activeTabIndex, setActiveTabIndex } = useTabNav()

  const { api } = useCarousel()
  useEffect(() => {
    if (!api) {
      return
    }

    api.on('select', (e) => {
      setActiveTabIndex(e.selectedScrollSnap())
      return true
    })
  }, [api, setActiveTabIndex])

  useEffect(() => {
    if (!api) {
      return
    }

    const tabIndex = activeTabIndex()
    api.scrollTo(tabIndex, true)
  }, [api, activeTabIndex])

  return (
    <div
      className={cn(
        'md:min-h-auto flex min-h-[3.5rem] items-end [&>_div]:w-full',
        className,
      )}
    >
      <NavButtonPrev />
      <CarouselContent>{children}</CarouselContent>
      <NavButtonNext />
    </div>
  )
}
export default CarouselWrapper

const NavButtonPrev = () => {
  const { scrollPrev, canScrollPrev } = useCarousel()

  return (
    <button
      className="absolute left-4 top-0 z-10 block h-24 w-8 disabled:opacity-50"
      onClick={scrollPrev}
      disabled={!canScrollPrev}
      aria-label="Previous menu item"
    >
      <ChevronLeftIcon />
    </button>
  )
}

const NavButtonNext = () => {
  const { scrollNext, canScrollNext } = useCarousel()

  return (
    <button
      className="absolute right-4 top-0 z-10 block h-24 w-8 disabled:opacity-50"
      onClick={scrollNext}
      disabled={!canScrollNext}
      aria-label="Next menu item"
    >
      <ChevronRightIcon />
    </button>
  )
}
