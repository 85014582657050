import React from 'react'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import { CompanySearchProvider } from './CompanySearchProvider'
import translations from '../locales.json'
import { TranslationProvider } from '../../shared/TranslationProvider'
import { BrowserRouter } from 'react-router-dom'
import Page from './Page'

const queryClient = new QueryClient()

export default function CompanySearchPage({ locale, ...props }) {
  return (
    <QueryClientProvider client={queryClient}>
      <TranslationProvider
        locale={locale}
        translations={translations}
        scope="javascript.components.ResearchLearning.CompanySearchPage"
      >
        <BrowserRouter>
          <CompanySearchProvider {...props}>
            <Page />
          </CompanySearchProvider>
        </BrowserRouter>
      </TranslationProvider>
    </QueryClientProvider>
  )
}
