import React from 'react'
import Page from './Page'
import { useTranslation } from '../../hooks/useTranslation'
import { useExercise } from './ExerciseProvider'
import PieOfLifeExercise from './PieOfLife/PieOfLifeExercise'

const Exercise = () => {
  const { formattedExerciseName, exerciseName } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const pagesInfo = t('pages')
  const pagesArr = Object.values(pagesInfo)
  const numPages = pagesArr.length

  if (exerciseName === 'Pie Of Life') {
    return <PieOfLifeExercise />
  }

  return (
    <div>
      {pagesArr.map((page, idx) => (
        <Page
          key={`page-${idx}`}
          idx={idx}
          pageInfo={page}
          numPages={numPages}
        />
      ))}
    </div>
  )
}

export default Exercise
