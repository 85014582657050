import { zodResolver } from '@hookform/resolvers/zod'
import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { z } from 'zod'
import { apiPost } from '../../util/api'

export const useWalkthroughFeedbackForm = ({
  url,
  toggleShowFeedbackDialog,
}) => {
  const [submitted, setSubmitted] = useState(false)
  const FormSchema = z.object({
    feedback: z.string().optional(),
  })

  const form = useForm({
    resolver: zodResolver(FormSchema),
    defaultValues: {
      feedback: '',
    },
  })

  const onSubmit = async (values) => {
    const data = {
      feedback_job_search_walkthrough: { feedback: values.feedback },
    }
    setSubmitted(true)

    let response
    try {
      response = await apiPost(url, data)
    } catch (err) {
      console.error(err)
    }
    if (response?.status === 200) {
      toggleShowFeedbackDialog()
      return response
    }
  }

  return {
    form,
    onSubmit,
    submitted,
  }
}
