import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import axios from 'axios'

const SCHEMA = z.object({
  documentId: z.string().optional(),
  uploadedResume: z
    .instanceof(File)
    .optional()
    .nullable()
    .refine((file) => !file || file.size < 5000000, {
      message: 'File must be less than 5MB',
    }),
})

const DEFAULT_VALUES = {
  documentId: '',
  uploadedResume: null,
}

const URL = '/job-search/job_application'
const ERROR_MESSAGES = {
  multipleResumes:
    'Please select only one resume to submit, either from the dropdown or by uploading a new one',
  noResume: 'No resume selected or uploaded',
  responseNotOk: 'Response not OK',
}

const getToken = () => document.querySelector('meta[name="csrf-token"]').content

const logError = (error) => {
  if (error.response) {
    console.error('Server error response:', error.response.data)
  } else if (error.request) {
    console.error('No response received:', error.request)
  } else {
    console.error('Error setting up request:', error.message)
  }
}

export const useUploadJobApplicationForm = ({ onClose, jobPostingId }) => {
  const form = useForm({
    resolver: zodResolver(SCHEMA),
    defaultValues: { ...DEFAULT_VALUES, jobPostingId },
  })

  const onSubmit = async (values) => {
    try {
      const token = getToken()
      const formData = new FormData()
      formData.append('request_format', 'json')
      formData.append('job_application[job_posting_id]', jobPostingId)

      if (values.documentId && values.uploadedResume) {
        throw new Error(ERROR_MESSAGES.multipleResumes)
      }

      if (values.documentId) {
        formData.append('job_application[document_id]', values.documentId)
      } else if (values.uploadedResume) {
        formData.append(
          'job_application[uploaded_resume]',
          values.uploadedResume,
        )
      } else {
        throw new Error(ERROR_MESSAGES.noResume)
      }

      const config = {
        headers: {
          'content-type': 'multipart/form-data',
          'X-CSRF-TOKEN': token,
        },
      }

      const response = await axios.post(URL, formData, config)
      if (response.status === 200) {
        onClose()
        window.location.reload()
      } else {
        throw new Error(ERROR_MESSAGES.responseNotOk)
      }
    } catch (error) {
      logError(error)
    }
  }

  return { form, onSubmit }
}
