import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import { Stack } from '~/components/_layouts'

export function DashboardHeader({ current_user }) {
  const WelcomeMessage = ({ current_user }) => {
    const { t } = useTranslation()
    const { first_name, sign_in_count } = current_user
    const [firstRowMessage, setFirstRowMessage] = useState('')
    const [secondRowMessage, setSecondRowMessage] = useState('')

    useEffect(() => {
      const isMorning = new Date().getHours() < 12

      const welcome_message_first_login = t('welcome_message_first_login', {
        first_name: first_name,
      })

      const randomize = (array) => {
        return array[Math.floor(Math.random() * array.length)]
      }

      const welcome_message_afternoon = randomize(
        t('welcome_message_afternoon', { first_name: first_name }),
      )
      const welcome_message_morning = t('welcome_message_morning', {
        first_name: first_name,
      })
      const welcome_message_returning_user = isMorning
        ? welcome_message_morning
        : welcome_message_afternoon

      const first_row_message =
        sign_in_count == 1
          ? welcome_message_first_login
          : welcome_message_returning_user

      const second_row_message = randomize(t('welcome_message_second_row'))
      setFirstRowMessage(first_row_message)
      setSecondRowMessage(second_row_message)
    }, [current_user, first_name, sign_in_count, t])

    return (
      <>
        <div className="font-roboto-flex text-lg leading-7 text-[#4a5463]">
          {firstRowMessage}
        </div>
        <div className="font-roboto-flex text-lg leading-7 text-[#4a5463]">
          {secondRowMessage}
        </div>
      </>
    )
  }

  return (
    <Stack spacing={2}>
      <WelcomeMessage current_user={current_user} />
    </Stack>
  )
}
