import React from 'react'
import { EditorHeading } from '../../ResumeBuilder/Editor/common'
import { EditorItemProvider, EditorItemTextField } from './EditorItemProvider'
import { useThankYouNote } from '../ThankYouNoteProvider'
import thankYouNoteMap from './index'

const ContentEditor = ({ templateIndex }) => {
  const { aylaThankYouNote } = useThankYouNote()
  const { thankYouNoteDocument: { thankYouNote } = {} } = useThankYouNote()

  return (
    <div className="flex flex-col">
      <EditorHeading>
        {thankYouNoteMap[templateIndex]} Thank You Note Content
      </EditorHeading>
      <p className="pb-4">
        The purpose of a thank you note is to express gratitude to the recipient
        while reinforcing your interest, qualifications, and professionalism,
        leaving a positive and lasting impression after an interview or
        professional interaction.
      </p>
      {!aylaThankYouNote && templateIndex == 0 && (
        <>
          <p className="pb-4">
            Paragraph One: Communicate your appreciation for the time and energy
            spent with you in the interview. Also, state that you are confident
            that you possess the necessary qualifications and are the best
            candidate for the position. Finally, express your increased interest
            in the position now that you have had the opportunity to learn more
            about the organization.
          </p>
          <p className="pb-4">
            Paragraph Two: In your first sentence, note some important issue
            that the interviewer brought up that impressed you or stands out
            about the position or organization. Your second sentence describes
            how you can add value or are in alignment with this point. The third
            sentence should offer an example illustrating this point.
          </p>
          <p className="pb-4">
            Paragraph Three (Optional): Address any skill or quality you possess
            that you feel was not presented effectively in the interview. For
            example, if the interviewer asked about a certain technology that
            you did not know, take time here to address what you will do to
            become knowledgeable about this technology. Also, explain how you
            demonstrated an ability to learn new applications quickly in past
            positions. If possible, give an example of your ability to overcome
            this obstacle.
          </p>
          <p className="pb-4">
            Paragraph Four: Express your interest in receiving an offer and
            willingness to furnish references who can speak to the very
            qualifications the hiring manager is seeking. Indicate that you will
            follow-up within the week (or the time-frame they gave you) to learn
            about the next steps in the selection process.
          </p>
        </>
      )}
      <EditorItemProvider sectionName="thankYouNote" item={thankYouNote}>
        <EditorItemTextField
          label="Write your thank you note here:"
          containerClassName="md:col-span-2"
          fieldName="content"
          areaClassName="h-[650px]"
        />
      </EditorItemProvider>
    </div>
  )
}

export default ContentEditor
