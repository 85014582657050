import React from 'react'
import { useNetworkingBrief } from './NetworkingBriefProvider'
import { Button } from '../../lib/ui/button'
import SaveModal from './SaveModal'

const PdfViewerPanel = () => {
  const {
    networkingBriefName,
    persistNetworkingBrief,
    pdf: { url, loading },
  } = useNetworkingBrief()

  if (loading) {
    return null
  }

  return (
    <>
      <div className="absolute flex h-16 w-full flex-row items-center justify-evenly bg-[#525659]">
        <Button variant="outline" asChild>
          <a
            href={url}
            onClick={persistNetworkingBrief}
            download={`${networkingBriefName}.pdf`}
            className="text-cod-gray-700"
          >
            Download PDF
          </a>
        </Button>
        <SaveModal />
      </div>
      <iframe
        src={`${url}#toolbar=0&navpanes=0`}
        className="tw-hidden h-full w-full pt-16 md:block"
        title="Networking Brief Preview"
        frameBorder="0"
        loading="lazy"
      />
    </>
  )
}
export default PdfViewerPanel
