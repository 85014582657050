import * as React from 'react'

import { useRecruiterSearch } from './RecruiterSearchProvider'
import Sanitize from '../Sanitize'
import { useTranslation } from '../../../hooks/useTranslation'
import { useIsMobile } from '../../../hooks/useMediaQuery'
import { Button } from '../../../lib/ui/button'
import { cn } from '../../../lib/utils'
import SearchResultsSavedStatus from '~/components/shared/SearchResultsSavedStatus'

export default function RecruiterDetails() {
  return (
    <div className="flex flex-col gap-4 p-4">
      <RecruiterDetailsTop />
      <RecruiterDetailsBottom />
    </div>
  )
}

export function RecruiterDetailsTop() {
  const {
    selectedRecruiter: recruiter,
    debug,
    queryStatusMessage,
    isSavedRecruiter,
    isSavedRecruiterCompany,
  } = useRecruiterSearch()
  const isMobile = useIsMobile()
  if (!recruiter) return null

  return (
    <>
      <div className={`bg-primary text-white ${isMobile ? 'p-4' : 'p-0'}`}>
        {!isMobile && (
          <div className="relative flex flex-row justify-between">
            <div className="absolute right-0 top-0">
              {isSavedRecruiterCompany(recruiter) ? (
                <SavedToMyRecruiters />
              ) : (
                <AddToMyRecruitersButton
                  recruiter={recruiter}
                  variant="icon"
                  includeText
                />
              )}
            </div>
          </div>
        )}

        <div className="flex flex-col gap-4">
          <div className="text-xl font-medium">{recruiter.name}</div>

          <div className="flex items-center text-base font-normal">
            <div>{recruiter.city_state}</div>
          </div>

          <div className={`inline-flex gap-4 ${isMobile ? 'flex-col' : ''}`}>
            <FirmType recruiter={recruiter} />
            <SalaryTag salary={recruiter.salary} />
          </div>

          {isMobile && (
            <div className="mt-4 flex">
              {isSavedRecruiter(recruiter) ? (
                <SavedToMyRecruiters />
              ) : (
                <AddToMyRecruitersButton
                  recruiter={recruiter}
                  variant="icon"
                  includeText
                />
              )}
            </div>
          )}
        </div>

        {debug && <pre className="break-all text-xs">{queryStatusMessage}</pre>}
      </div>
    </>
  )
}

const RecruiterDetailsHeading = ({ className, children }) => {
  return (
    <div className={cn('text-base font-medium text-black', className)}>
      {children}
    </div>
  )
}

const RecruiterDetailsDetails = ({ className, children }) => {
  return (
    <div className={cn('text-base font-normal', className)}>{children}</div>
  )
}

const RecruiterDetailsLink = ({ className, children }) => {
  return (
    <RecruiterDetailsDetails
      className={cn('text-blue-600 underline', className)}
    >
      {children}
    </RecruiterDetailsDetails>
  )
}

export function RecruiterDetailsBottom() {
  const { selectedRecruiter: recruiter } = useRecruiterSearch()
  const { t } = useTranslation('RecruiterDetails')
  const currentYear = new Date().getFullYear()
  const isMobile = useIsMobile()

  const headingClasses = '!mr-16 w-40'

  if (!recruiter) return null

  return (
    <>
      <div
        className={`mb-${isMobile ? '0' : '5'} flex ${
          isMobile ? 'flex-col' : ''
        }`}
      >
        <RecruiterDetailsHeading className={headingClasses}>
          {t('physical_address')}:
        </RecruiterDetailsHeading>
        <RecruiterDetailsDetails>
          {recruiter.physical_address}
        </RecruiterDetailsDetails>
      </div>
      <div
        className={`mb-${isMobile ? '0' : '5'} flex ${
          isMobile ? 'flex-col' : ''
        }`}
      >
        <RecruiterDetailsHeading className={headingClasses}>
          {t('mailing_address')}:
        </RecruiterDetailsHeading>
        <RecruiterDetailsDetails>
          {recruiter.mailing_address}
        </RecruiterDetailsDetails>
      </div>

      {recruiter.phone !== '--' && (
        <div
          className={`mb-${isMobile ? '0' : '5'} flex ${
            isMobile ? 'flex-col' : ''
          }`}
        >
          <RecruiterDetailsHeading className={headingClasses}>
            {t('phone_number')}:
          </RecruiterDetailsHeading>
          <RecruiterDetailsDetails>{recruiter.phone}</RecruiterDetailsDetails>
        </div>
      )}
      <div
        className={`mb-${isMobile ? '0' : '5'} flex ${
          isMobile ? 'flex-col' : ''
        }`}
      >
        <RecruiterDetailsHeading className={headingClasses}>
          {t('email')}:
        </RecruiterDetailsHeading>
        <RecruiterDetailsLink>{recruiter.email}</RecruiterDetailsLink>
      </div>
      <div
        className={`mb-${isMobile ? '0' : '5'} flex ${
          isMobile ? 'flex-col' : ''
        }`}
      >
        <RecruiterDetailsHeading className={headingClasses}>
          {t('website')}:
        </RecruiterDetailsHeading>
        <RecruiterDetailsLink>{recruiter.url}</RecruiterDetailsLink>
      </div>
      <div
        className={`mb-${isMobile ? '0' : '5'} flex ${
          isMobile ? 'flex-col' : ''
        }`}
      >
        <RecruiterDetailsHeading className={headingClasses}>
          {t('min_salary_level_assignments')}:
        </RecruiterDetailsHeading>
        <RecruiterDetailsDetails>
          {recruiter.minimum_salary_value}
        </RecruiterDetailsDetails>
      </div>

      <div className={`mb-${isMobile ? '0' : '5'}`}>
        <RecruiterDetailsHeading>{t('description')}</RecruiterDetailsHeading>
        <RecruiterDetailsDetails>
          <Sanitize html={recruiter.description} addMarkup />
        </RecruiterDetailsDetails>
      </div>

      <div className={`mb-${isMobile ? '0' : '5'}`}>
        <RecruiterDetailsHeading>{t('industries')}</RecruiterDetailsHeading>
        <RecruiterDetailsDetails>
          {recruiter.industries}
        </RecruiterDetailsDetails>
      </div>
      <div className={`mb-${isMobile ? '0' : '5'}`}>
        <RecruiterDetailsHeading>{t('specialties')}</RecruiterDetailsHeading>
        <RecruiterDetailsDetails>
          {recruiter.specialties}
        </RecruiterDetailsDetails>
      </div>

      <div className={`mb-${isMobile ? '0' : '5'}`}>
        <RecruiterDetailsHeading>{t('contacts')}</RecruiterDetailsHeading>
        <div className="divide-y divide-[#B9B9B9] text-base font-normal">
          {recruiter.contacts.map((contact, index) => (
            <RecruiterContact
              key={`recruiter-contact-${index}`}
              contact={contact}
              recruiter={recruiter}
            />
          ))}
        </div>
      </div>

      <div>
        <RecruiterDetailsHeading>{t('source')}</RecruiterDetailsHeading>
        <RecruiterDetailsDetails>
          Custom Databanks, Inc - Copyright {currentYear}
        </RecruiterDetailsDetails>
      </div>
    </>
  )
}

const RecruiterContact = ({ contact, recruiter }) => {
  const { isSavedRecruiter } = useRecruiterSearch()
  const checkSavedContact = { id: recruiter.id, email: contact.email }
  return (
    <div className="grid grid-cols-5 pt-4">
      <div className="col-span-4 flex">
        <div>
          {contact.full_name} - {contact.title} {`- `}
          <span>
            <a
              href={`mailto:${contact.email}`}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-600 underline"
            >
              {contact.email}
            </a>
          </span>
        </div>{' '}
      </div>
      {isSavedRecruiter(checkSavedContact) ? (
        <SavedContact />
      ) : (
        <AddContactButton contact={contact} recruiter={recruiter} />
      )}
    </div>
  )
}
const SavedContact = () => {
  return (
    <div className="flex items-center gap-4 px-8 py-2">
      <CheckIcon />
      <div className="text-sm font-medium">Saved</div>
    </div>
  )
}

const AddContactButton = ({ contact, recruiter }) => {
  const { addRecruiterContact } = useRecruiterSearch()

  return (
    <Button
      onClick={() => addRecruiterContact(contact, recruiter)}
      variant="link"
    >
      + Add to My Recruiters
    </Button>
  )
}

const AddToMyRecruitersButton = ({ recruiter }) => {
  const { addToMyRecruiters } = useRecruiterSearch()

  return (
    <button
      onClick={() => addToMyRecruiters(recruiter)}
      className="flex rounded-full border border-white bg-transparent p-2 text-base font-medium text-white"
    >
      <HeartIcon /> Add to My Recruiters
    </button>
  )
}

const HeartIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M21 8.25c0-2.485-2.099-4.5-4.688-4.5-1.935 0-3.597 1.126-4.312 2.733-.715-1.607-2.377-2.733-4.313-2.733C5.1 3.75 3 5.765 3 8.25c0 7.22 9 12 9 12s9-4.78 9-12Z"
      />
    </svg>
  )
}

const TagIcon = () => {
  return (
    <div className="ml-3">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
      >
        <path
          d="M9.568 3H5.25C4.65326 3 4.08097 3.23705 3.65901 3.65901C3.23705 4.08097 3 4.65326 3 5.25V9.568C3 10.165 3.237 10.738 3.659 11.159L13.24 20.74C13.939 21.439 15.02 21.612 15.847 21.07C17.9286 19.7066 19.7066 17.9286 21.07 15.847C21.612 15.02 21.439 13.939 20.74 13.24L11.16 3.66C10.951 3.45077 10.7029 3.28478 10.4297 3.17154C10.1565 3.05829 9.86371 3 9.568 3Z"
          stroke="#08467C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
        <path
          d="M6 6H6.008V6.008H6V6Z"
          stroke="#08467C"
          strokeWidth="1.5"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m4.5 12.75 6 6 9-13.5"
      />
    </svg>
  )
}

const FirmType = ({ recruiter }) => {
  const { t } = useTranslation('RecruiterFirmType')
  const permanent = t('permanent')
  const temporary = t('temporary')
  const firmType =
    recruiter.firm_type == 'Temporary Staffing' ? temporary : permanent
  return (
    <div className="flex w-fit items-center rounded-xl bg-white px-4 py-2 font-roboto text-base font-medium text-[#08467C]">
      {firmType}
      <TagIcon />
    </div>
  )
}

const SalaryTag = ({ salary }) => {
  const { t } = useTranslation('SalaryTag')
  const isMobile = useIsMobile()

  const getSalaryRange = (salary) => {
    const numericSalary = parseInt(salary.replace(/[$,]/g, ''), 10)
    if (numericSalary < 50000) return 'under_50k'
    if (numericSalary < 75000) return '50k_75k'
    if (numericSalary < 100000) return '75k_100k'
    if (numericSalary < 125000) return '100k_125k'
    return '125k_plus'
  }

  const salaryRangeKey = getSalaryRange(salary)
  const translatedSalary = t(
    isMobile ? `${salaryRangeKey}_mobile` : salaryRangeKey,
  )

  return (
    <div
      className={`flex items-center rounded-xl bg-white px-4 py-2 font-roboto text-base font-medium text-[#08467C] ${
        isMobile ? 'w-fit' : ''
      }`}
    >
      {translatedSalary}
      <TagIcon />
    </div>
  )
}
export function SavedToMyRecruiters() {
  const { t } = useTranslation('RecruiterDetails')
  return (
    <SearchResultsSavedStatus title={t('saved_to_my_recruiters')}>
      <div className="text-sm font-medium text-white">
        {t('saved_to')}{' '}
        <span className="text-sm font-medium text-white">
          <a
            className="text-white !underline"
            href="/job-search/recruiters/my_recruiters"
          >
            {t('my_recruiters')}
          </a>
        </span>
      </div>
    </SearchResultsSavedStatus>
  )
}
