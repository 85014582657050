import React, { useEffect } from 'react'
import Cursor from './Cursor'

const TypingEffect = ({
  message,
  baseSpeed = 5,
  aiStreamComplete,
  setTypingComplete,
  displayedText,
  setDisplayedText,
  index,
  setIndex,
}) => {
  useEffect(() => {
    const getSpeed = (char) => {
      if (char === '.' || char === '!' || char === ',') {
        return baseSpeed
      } else if (char === ' ') {
        return baseSpeed
      } else {
        return baseSpeed
      }
    }

    if (index < message.length) {
      setTypingComplete(false)
      const currentChar = message.charAt(index)
      const timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev + currentChar)
        setIndex(index + 1)
      }, getSpeed(currentChar))

      return () => clearTimeout(timeoutId)
    } else {
      setTypingComplete(true)
    }
  }, [index, setIndex, setTypingComplete, setDisplayedText, message, baseSpeed])

  return (
    <p>
      {displayedText}
      <Cursor show={!aiStreamComplete} />
    </p>
  )
}
export default TypingEffect
