import React, { useState, createContext, useContext } from 'react'

export const JsonItemContext = createContext()

export const useJsonItem = () => useContext(JsonItemContext)

// JsonItemProvider manages updates to a data structure under a single key (sectionName) in a parent JSON document.
//
// sectionName: top level key in the parent JSON document for the item being edited
// item: the item being edited. When index is >=0 item will be replaced in the array under sectionName.
// index: index of the item in the array under sectionName. Use -1 when sectionName is an object.
// activeIndex: index of the item being edited in the array under sectionName (from useState)
// setActiveIndex: function to set the activeIndex (from useState)
// jsonDocument: the parent JSON document (from useState)
// setJsonDocument: function to set the parent JSON document (from useState)
export const JsonItemProvider = ({
  sectionName,
  item,
  index = -1,
  activeIndex,
  setActiveIndex,
  jsonDocument,
  setJsonDocument,
  children,
}) => {
  const [providedItem, setProvidedItem] = useState(item || {})

  const updateCollection = (
    collectionName = null,
    collection = null,
    immediateItem = null,
  ) => {
    const operatingItem = immediateItem == null ? providedItem : immediateItem
    const updatedEndDate = [null, 'present', 'Present', ''].includes(
      operatingItem.endDate,
    )
      ? undefined
      : operatingItem.endDate
    const sectionItems = jsonDocument[sectionName]
    if (index == -1) {
      // index of -1 means sectionName in the main document is an object and
      // not an array of objects
      const newResumeDocument = {
        ...jsonDocument,
        [sectionName]: operatingItem,
      }
      setJsonDocument(newResumeDocument)
      return
    }
    const updatedSection = sectionItems.map((element, _i) => {
      const keysAreEqual = element.key === operatingItem.key
      if (keysAreEqual) {
        let updatedItem = {
          ...element,
          ...operatingItem,
          endDate: updatedEndDate,
        }
        if (collectionName && collection) {
          updatedItem[collectionName] = collection
          setProvidedItem(updatedItem)
        }
        return updatedItem
      } else {
        return element
      }
    })
    setJsonDocument({ ...jsonDocument, [sectionName]: updatedSection })
  }

  const updateItem = (immediateItem = null) => {
    immediateItem && setProvidedItem(immediateItem)
    updateCollection(null, null, immediateItem)
  }

  const deleteItem = () => {
    const updatedItems = jsonDocument[sectionName].filter((_, i) => i !== index)
    setJsonDocument({ ...jsonDocument, [sectionName]: updatedItems })
  }

  const value = {
    item: providedItem,
    setItem: setProvidedItem,
    updateItem,
    deleteItem,
    updateCollection,
    index,
    activeIndex,
    setActiveIndex,
    sectionName,
  }

  return (
    <JsonItemContext.Provider value={value}>
      {children}
    </JsonItemContext.Provider>
  )
}
