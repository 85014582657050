import React from 'react'
import Results from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useMultipleChoicePointsQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'
import parse from 'html-react-parser'
import { useTranslation } from '../../../hooks/useTranslation'

const SelfEmploymentResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent } = useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()

  const { t } = useTranslation(formattedQuizName)

  const breakPoints = [15, 9]

  const [scoreSection, score] = useMultipleChoicePointsQuizScore(
    trackContent,
    t('questions'),
    breakPoints,
    'raw',
  )
  const resultsP1 = t(`results.${scoreSection}.p1`)
  const resultsP2 = t(`results.${scoreSection}.p2`)
  const resultsListItems = t(`results.1.list_items`)

  console.log(resultsListItems)

  return (
    <Results>
      <div className="flex pb-6 sm:justify-around">
        <div className="mt-1 flex flex-col gap-4 sm:w-9/12">
          <h2>{t(`results.score`, { score })}</h2>
          <div className="mt-4">
            <p>{parse(resultsP1)}</p>
            <p className="mt-4">{parse(resultsP2)}</p>
            <ul>
              {scoreSection === 1 &&
                resultsListItems.map((item, index) => (
                  <li key={index}>{parse(item)}</li>
                ))}
            </ul>
          </div>
        </div>
        {isDesktop && <Avatar src={avatar} />}
      </div>
    </Results>
  )
}

export default SelfEmploymentResults
