import React, { useState } from 'react'
import { useNetworkingBrief } from '../NetworkingBriefProvider'
import { EditorHeading, newKey } from '../../ResumeBuilder/Editor/common'
import { Button } from '../../../lib/ui/button'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { EditorItemProvider, EditorItemField } from './EditorItemProvider'

const TargetRolesEditor = () => {
  const { networkingBriefDocument, setNetworkingBriefDocument } =
    useNetworkingBrief()
  const targetRoles = networkingBriefDocument.targetRoles || []
  const [activeIndex, setActiveIndex] = useState(-1)

  const addTargetRole = () => {
    const updatedTargetRoles = [...targetRoles, {}]
    setActiveIndex(updatedTargetRoles.length - 1)
    setNetworkingBriefDocument({
      ...networkingBriefDocument,
      targetRoles: updatedTargetRoles,
    })
  }

  const removeTargetRole = (index) => {
    const updatedTargetRoles = targetRoles.filter((_, i) => i !== index)
    setNetworkingBriefDocument({
      ...networkingBriefDocument,
      targetRoles: updatedTargetRoles,
    })
  }

  return (
    <div className="flex flex-col">
      <EditorHeading>Target Roles</EditorHeading>
      <div className="pb-4 tracking-[0.024px]">
        List any roles you are targeting in your career search. (optional)
      </div>
      {targetRoles.map((targetRole, targetRoleIndex) => {
        targetRole.key = targetRole.key || newKey()
        return (
          <div key={targetRole.key}>
            <EditorItemProvider
              sectionName="targetRoles"
              index={targetRoleIndex}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              item={targetRole}
            >
              <div className="mb-4 flex flex-row items-center">
                <EditorItemField
                  fieldName="role"
                  containerClassName="flex items-center w-1/2"
                />
                <TrashIcon
                  className="ml-2 w-8 cursor-pointer"
                  onClick={() => removeTargetRole(targetRoleIndex)}
                />
              </div>
            </EditorItemProvider>
          </div>
        )
      })}
      <Button onClick={addTargetRole} className="mt-4 max-w-min">
        Add Role
        <PlusIcon className="ml-2 w-5" />
      </Button>
    </div>
  )
}

export default TargetRolesEditor
