import React, { useState, useMemo, useEffect } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '.././ExerciseProvider'
import { RadioGroup, RadioGroupItem } from '../../../lib/ui/radio-group'

const NetworkingPreferencesSectionOne = () => {
  const {
    updateRadioResponse,
    getResponse,
    exerciseIndex,
    formattedExerciseName,
    setContinueButtonDisabled,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const sectionOneTranslations = t('pages.0')
  const scenarios = useMemo(
    () => sectionOneTranslations.scenarios || {},
    [sectionOneTranslations],
  )

  const initialResponses = Object.keys(scenarios).reduce((acc, key) => {
    const response = getResponse(exerciseIndex, key)
    if (response !== undefined) {
      acc[key] = response
    }
    return acc
  }, {})

  const [responses, setResponses] = useState(initialResponses)

  const checkAllAnswered = (responses) => {
    const allAnswered = Object.keys(scenarios).every(
      (key) => responses[key] !== undefined,
    )
    setContinueButtonDisabled(!allAnswered)
  }

  useEffect(() => {
    checkAllAnswered(responses)
  }, [responses, scenarios])

  const handleValueChange = (scenarioSlug, value) => {
    updateRadioResponse(exerciseIndex, scenarioSlug, value)
    setResponses((prev) => {
      const newResponses = { ...prev, [scenarioSlug]: value }
      checkAllAnswered(newResponses)
      return newResponses
    })
  }

  const renderScenario = (scenario, index) => (
    <div key={index} className="mb-4">
      <p>{scenario.description}</p>
      <p>{sectionOneTranslations.do_you_find_this_event}</p>
      <RadioGroup
        className="mt-2"
        value={responses[scenario.slug] || ''}
        onValueChange={(value) => handleValueChange(scenario.slug, value)}
      >
        {Object.entries(scenario.options).map(([key, option]) => (
          <label key={key} className="mb-2 !flex items-center">
            <RadioGroupItem value={key} className="mr-2" />
            {option.option}
          </label>
        ))}
      </RadioGroup>
    </div>
  )

  return (
    <div className="flex flex-col">
      <p>{sectionOneTranslations.consider_these_scenarios}</p>
      {Object.values(scenarios).map(renderScenario)}
    </div>
  )
}

export default NetworkingPreferencesSectionOne
