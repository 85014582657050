import React from 'react'
import {
  Select,
  SelectValue,
  SelectTrigger,
  SelectGroup,
  SelectContent,
  SelectItem,
} from '~/lib/ui/select'

const IndustryFilterDropdown = ({ industries, selectedIndustry }) => {
  const [selection, setSelection] = React.useState(
    parseInt(selectedIndustry) || undefined,
  )
  const filterPage = (industryId) => {
    if (parseInt(industryId) > 0) {
      setSelection(industryId)
      window.location.href = `${window.location.pathname}?industry_id=${industryId}`
    }
  }
  return (
    <Select value={selection} onValueChange={filterPage}>
      <SelectTrigger className="text-lg font-medium">
        <SelectValue placeholder="Select an Option" />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          {industries.map((industry) => {
            return (
              <SelectItem key={industry.id} value={industry.id}>
                {industry.name}
              </SelectItem>
            )
          })}
        </SelectGroup>
      </SelectContent>
    </Select>
  )
}
export default IndustryFilterDropdown
