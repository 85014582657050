import * as React from 'react'

import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import { Button } from '~/components/_atoms'
import { ChevronLeft, ChevronRight } from 'lucide-react'

export function Calendar({
  calendar_events,
  initialView = 'dayGridWeek',
  homePageDisplay,
}) {
  const [view, setView] = React.useState(initialView)
  const [calendarDate, setCalendarDate] = React.useState(new Date())
  const [calendarMonthYear, setCalendarMonthYear] = React.useState('')
  const [calendarApi, setCalendarApi] = React.useState(null)
  // const [aspectRatio, setAspectRatio] = React.useState(
  //   view === 'dayGridWeek' ? 5.4 : 1.3,
  // )

  const changeViewText =
    view === 'dayGridMonth' ? 'View this week' : 'View full schedule'
  const aspectRatio = view === 'dayGridMonth' ? 1.3 : 5.4

  const calendarRef = React.useRef(null)

  React.useEffect(() => {
    setCalendarApi(calendarRef.current.getApi())
  }, [calendarRef])

  React.useEffect(() => {
    if (!calendarApi) return

    setCalendarDate(calendarApi.getDate())
  }, [calendarApi])

  React.useEffect(() => {
    if (!calendarDate) return

    setCalendarMonthYear(
      calendarDate.toLocaleString('default', {
        month: 'long',
        year: 'numeric',
      }),
    )
  }, [calendarDate])

  const events = calendar_events?.map((event) => {
    return {
      title: event.webinar_type,
      start: event.start_at,
    }
  }) || [{ title: ' Meeting ', start: new Date() }]

  function goNext() {
    calendarApi.next()
    setCalendarDate(calendarApi.getDate())
  }
  function goPrev() {
    calendarApi.prev()
    setCalendarDate(calendarApi.getDate())
  }
  function goToday() {
    calendarApi.today()
    setCalendarDate(calendarApi.getDate())
  }
  function toggleView() {
    if (view === 'dayGridMonth') {
      setView('dayGridWeek')
      calendarApi.changeView('dayGridWeek')
    } else {
      setView('dayGridMonth')
      calendarApi.changeView('dayGridMonth')
    }
  }

  return (
    <>
      <style
        // FullCalendar css variables found here:
        // https://github.com/fullcalendar/fullcalendar/blob/main/packages/core/src/styles/vars.css
        dangerouslySetInnerHTML={{
          __html: `
            .fc-css-vars {
              --fc-today-bg-color: #F0F0F0;
              --fc-border-color: #B1B1B1;
              --fc-more-link-bg-color: #fff;
            }
            .fc-col-header-cell-cushion {
              width: 100%;
              text-align: left;
              padding: 0.75rem !important;
            }
            .fc-scrollgrid-sync-inner {
            }
            .fc-col-header-cell {
            }
            .fc-col-header {
              border-radius: 8px !important;
            }

            .fc-scrollgrid-section > td {
              border-radius: 0px 0px 8px 8px !important;
            }

            .fc-scrollgrid-section > th {
              border-radius: 0px 16x 16px 0px !important;
            }

            .fc-scrollgrid {
              border-radius: 8px !important;
              background: #FFF;
            }
            .fc-daygrid-dot-event:hover {
              background-color: unset;
            }
        `,
        }}
      />
      {homePageDisplay && (
        <div className="lg:absolute lg:bottom-[calc(100%+1rem)] lg:right-0">
          <Button
            variant="link"
            color="secondary"
            onClick={toggleView}
            className="px-0 py-0"
          >
            {changeViewText}
          </Button>
        </div>
      )}
      <div className="fc-css-vars [&_thead_.fc-scrollgrid-section-header]:bg-intoo-green-medium-700">
        <div className="mb-2 flex items-end justify-between ">
          {!homePageDisplay && (
            <div className="ml-[-2rem] flex w-48 ">
              <Button variant="link" color="secondary" onClick={toggleView}>
                {changeViewText}
              </Button>
            </div>
          )}
          {view === 'dayGridMonth' && (
            <div className="mb-2 flex h-9 -space-x-px">
              <>
                <Button
                  onClick={goPrev}
                  variant="outline"
                  color="secondary"
                  size="sm"
                  className="rounded-r-none focus:z-10"
                >
                  <ChevronLeft />
                </Button>
                <Button
                  onClick={goToday}
                  variant="outline"
                  color="secondary"
                  size="sm"
                  className="rounded-none focus:z-10"
                >
                  Today
                </Button>
                <Button
                  onClick={goNext}
                  variant="outline"
                  size="sm"
                  className="rounded-l-none focus:z-10"
                >
                  <ChevronRight />
                </Button>
              </>
            </div>
          )}
          {view === 'dayGridMonth' && (
            <div className="mb-2 flex w-48 justify-end text-base">
              {calendarMonthYear}
            </div>
          )}
        </div>

        <FullCalendar
          ref={calendarRef}
          plugins={[dayGridPlugin]}
          initialView={view}
          aspectRatio={aspectRatio}
          weekends={false}
          events={events}
          eventContent={renderEventContent}
          fixedWeekCount={false}
          dayHeaderContent={weekNumberContent}
          headerToolbar={false}
        />
      </div>
    </>
  )
}

function renderEventContent(eventInfo) {
  return (
    <div className="m-2 w-full rounded-md border border-intoo-green-medium-700 px-4 py-2">
      <div className="cursor-pointer text-wrap p-1 text-left text-intoo-green-medium-700 underline hover:bg-transparent hover:text-intoo-green-medium-500">
        {eventInfo.event.title}
      </div>
    </div>
  )
}

function weekNumberContent(weekNumber) {
  return (
    <span className="font-roboto text-base font-medium uppercase text-white">
      {weekNumber.text}
    </span>
  )
}
