import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react'
import { usePDF } from '@react-pdf/renderer'
import { TemplateSwitcher, useTemplate } from '../../lib/pdf'
import { apiPost, apiPut } from '../../util/api'
import { sha256 } from 'js-sha256'
import { useToast } from '../../lib/ui/toast'

const NetworkingBriefContext = createContext()

export const useNetworkingBrief = () => {
  return useContext(NetworkingBriefContext)
}

const hashObject = (obj) => {
  const json = JSON.stringify(obj)
  return sha256(json)
}

const NetworkingBriefProvider = ({
  networking_brief_json,
  document_id,
  networking_brief_name,
  dev_mode,
  children,
}) => {
  const [networkingBriefDocument, setNetworkingBriefDocument] = useState(
    networking_brief_json,
  )
  const [networkingBriefName, setNetworkingBriefName] = useState(
    networking_brief_name,
  )
  const [lastPersistedHash, setLastPersistedHash] = useState(
    hashObject(networking_brief_json),
  )
  const { toast } = useToast()
  const documentId = document_id

  const { templateIndex, templateStyle, templates } = useTemplate()

  useEffect(() => {
    // If the networking brief has unsaved changes, warn the user before leaving the page
    if (hashObject(networkingBriefDocument) !== lastPersistedHash) {
      window.onbeforeunload = (event) => {
        event.preventDefault()
        event.returnValue = true // For legacy browser compatibility
      }
    } else {
      window.onbeforeunload = undefined
    }
  }, [networkingBriefDocument, lastPersistedHash])

  // use hook for fine-grained control over PDF rendering
  const [pdf, renderPdf] = usePDF({
    document: (
      <TemplateSwitcher
        jsonDocument={networkingBriefDocument}
        templateIndex={templateIndex}
        templateStyle={templateStyle}
        Template={templates[templateIndex]}
      />
    ),
  })

  // Re-render the PDF
  const updateNetworkingBrief = useCallback(() => {
    renderPdf(
      <TemplateSwitcher
        jsonDocument={networkingBriefDocument}
        templateIndex={templateIndex}
        templateStyle={templateStyle}
        Template={templates[templateIndex]}
      />,
    )
  }, [
    networkingBriefDocument,
    renderPdf,
    templateIndex,
    templateStyle,
    templates,
  ])

  // Automatically re-render pdf every time networkingBriefDocument changes
  useEffect(() => {
    updateNetworkingBrief()
  }, [networkingBriefDocument, updateNetworkingBrief])

  // Persist networking brief data to the server
  const persistNetworkingBrief = async () => {
    setLastPersistedHash(hashObject(networkingBriefDocument))
    if (documentId) {
      await saveExistingNetworkingBrief()
    } else {
      await saveNewNetworkingBrief()
    }
  }

  const saveNewNetworkingBrief = async () => {
    let response
    try {
      response = await apiPost('/networking/networking_brief_builder/', {
        networking_brief: networkingBriefDocument,
        template_index: templateIndex,
        template_settings: templateStyle,
        name: networkingBriefName,
      })
    } catch (e) {
      console.error(
        'Error saving new networking brief. Expected status 200. Got:',
        e.response.status,
        e.response,
      )
      toast({ description: 'Error! Networking brief failed to save' })
      return
    }
    if (response.status === 200) {
      window.location.href =
        '/networking/networking_brief_builder/' + response.data.document_id
    } else {
      console.error(
        'Error saving new networking brief. Expected status 200. Got:',
        response.status,
        response,
      )
      toast({ description: 'Error! Networking brief failed to save' })
    }
  }

  const saveExistingNetworkingBrief = async () => {
    let response
    toast({ description: 'Saving networking brief...' })
    try {
      response = await apiPut(
        `/networking/networking_brief_builder/${documentId}`,
        {
          networking_brief: networkingBriefDocument,
          template_index: templateIndex,
          template_settings: templateStyle,
          name: networkingBriefName,
        },
      )
    } catch (e) {
      console.error(
        'Error saving existing networking brief. Expected status 200. Got:',
        e.response.status,
        e.response,
      )
      toast({ description: 'Error! Networking brief failed to save' })
      return
    }
    if (response.status === 200) {
      toast({
        description: 'Networking brief saved successfully',
        duration: 1500,
      })
    } else {
      console.error(
        'Error saving existing networking brief. Expected status 200. Got:',
        response.status,
        response,
      )
      toast({ description: 'Error! Networking brief failed to save' })
    }
  }

  const value = {
    pdf,
    documentId,
    persistNetworkingBrief,
    networkingBriefDocument,
    setNetworkingBriefDocument,
    networkingBriefName,
    setNetworkingBriefName,
    devMode: dev_mode,
  }

  return (
    <NetworkingBriefContext.Provider value={value}>
      {children}
    </NetworkingBriefContext.Provider>
  )
}
export default NetworkingBriefProvider
