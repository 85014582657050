import React from 'react'
import { useResume } from '../ResumeProvider'
import {
  JsonItemProvider,
  JsonItemField,
  JsonYearMonthField,
  JsonTextArea,
  useJsonItem,
} from '~/lib/json-editor'

export const EditorItemField = ({ ...props }) => {
  return <JsonItemField {...props} />
}

export const EditorTextArea = ({ ...props }) => {
  return <JsonTextArea {...props} />
}

export const EditorYearMonthField = ({ ...props }) => {
  return <JsonYearMonthField {...props} />
}

export const useEditorItem = useJsonItem

export const EditorItemProvider = ({ ...props }) => {
  const { resumeDocument, setResumeDocument } = useResume()

  return (
    <JsonItemProvider
      jsonDocument={resumeDocument}
      setJsonDocument={setResumeDocument}
      {...props}
    />
  )
}
