import React, { useState } from 'react'
import { useNetworkingBrief } from '../NetworkingBriefProvider'
import { EditorHeading, newKey } from '../../ResumeBuilder/Editor/common'
import { Button } from '../../../lib/ui/button'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { EditorItemProvider, EditorItemField } from './EditorItemProvider'

const TargetCompaniesEditor = () => {
  const { networkingBriefDocument, setNetworkingBriefDocument } =
    useNetworkingBrief()
  const targetCompanies = networkingBriefDocument.targetCompanies || []
  const [activeIndex, setActiveIndex] = useState(-1)

  const addTargetCompany = () => {
    const updatedTargetCompanies = [...targetCompanies, {}]
    setActiveIndex(updatedTargetCompanies.length - 1)
    setNetworkingBriefDocument({
      ...networkingBriefDocument,
      targetCompanies: updatedTargetCompanies,
    })
  }

  const removeTargetCompany = (index) => {
    const updatedTargetCompanies = targetCompanies.filter((_, i) => i !== index)
    setNetworkingBriefDocument({
      ...networkingBriefDocument,
      targetCompanies: updatedTargetCompanies,
    })
  }

  return (
    <div className="flex flex-col">
      <EditorHeading>Target Companies</EditorHeading>
      <div className="pb-4 tracking-[0.024px]">
        List any specific companies/industries you are targeting in your career
        search. (optional)
      </div>
      {targetCompanies.map((targetCompany, targetCompanyIndex) => {
        targetCompany.key = targetCompany.key || newKey()
        return (
          <div key={targetCompany.key}>
            <EditorItemProvider
              sectionName="targetCompanies"
              index={targetCompanyIndex}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              item={targetCompany}
            >
              <div className="mb-4 flex flex-row items-center">
                <EditorItemField
                  fieldName="companyOrIndustry"
                  containerClassName="flex items-center w-1/2"
                />
                <TrashIcon
                  className="ml-2 w-8 cursor-pointer"
                  onClick={() => removeTargetCompany(targetCompanyIndex)}
                />
              </div>
            </EditorItemProvider>
          </div>
        )
      })}
      <Button onClick={addTargetCompany} className="mt-4 max-w-min">
        Add Company
        <PlusIcon className="ml-2 w-5" />
      </Button>
    </div>
  )
}

export default TargetCompaniesEditor
