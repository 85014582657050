import * as React from 'react'

import { Button } from '../../lib/ui/button'
import { Form } from '../../lib/ui/form'
import { Separator } from '../../lib/ui/separator'

import { SelectField } from './SelectField'
import { TextField } from './TextField'
import { TextareaField } from './TextareaField'
import { RatingField } from './RatingField'
import { DatePickerField } from './DatePickerField'
import { useSurveyForm } from './useSurveyForm'
import { CheckboxField } from '../CareerDevelopment/CoachingPrograms/ProgramEvaluations/FormFields'

export function TrainingSurveyForm({
  programs,
  instructors,
  questions,
  choices,
  setError,
  setThankYou,
}) {
  const { form, onSubmit } = useSurveyForm({
    surveyType: 'training_survey',
    questions,
    setError,
    setThankYou,
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="space-y-8">
        <div className="">
          <SectionHeader title="Excellency Rating">
            Please rate the following aspects of the course
          </SectionHeader>

          {questions.rating_excellence.map((question) => (
            <div className="mx-4 my-6" key={question.name}>
              <RatingField
                name={question.name}
                label={question.label}
                choices={choices.rating_excellence}
                control={form.control}
              />
            </div>
          ))}

          <SectionHeader title="Satisfaction Rating">
            Please rate the course overall
          </SectionHeader>

          {questions.rating_satisfaction.map((question) => (
            <div className="mx-4 my-6" key={question.name}>
              <RatingField
                name={question.name}
                label={question.label}
                choices={choices.rating_satisfaction}
                control={form.control}
              />
            </div>
          ))}

          <SectionHeader title="Open-ended Questions">
            Please describe your experience of the course
          </SectionHeader>

          {questions.open_ended.map((question) => (
            <div className="mx-4 my-6" key={question.name}>
              <TextareaField
                name={question.name}
                label={question.label}
                control={form.control}
              />
            </div>
          ))}

          <SectionHeader title="Additional Comments">
            Please share anything else you would like about the course,
            instructor, or any other aspect not covered in the questions above.
          </SectionHeader>

          {questions.additional_comments.map((question) => (
            <div className="mx-4 my-6" key={question.name}>
              <TextareaField
                name={question.name}
                label={question.label}
                control={form.control}
              />
            </div>
          ))}

          <SectionHeader title="Course details">
            Which course are you evaluating?
          </SectionHeader>

          <div className="mx-4 my-6 grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2">
            <DatePickerField
              name="program_date"
              label="Program Date"
              control={form.control}
            />
            <SelectField
              name="program"
              label="Program Name"
              placeholder="Select your training program"
              choices={programs}
              control={form.control}
            />
            <SelectField
              name="instructor"
              label="Name of Trainer"
              placeholder="Select your instructor"
              choices={instructors}
              control={form.control}
            />
            <CheckboxField
              name="marketing_consent"
              label="Yes, my feedback can be provided for marketing materials."
              control={form.control}
            />
          </div>

          <SectionHeader title="Your details">
            Please let us know who you are.
          </SectionHeader>

          <div className="mx-4 my-6 grid grid-cols-1 gap-x-8 gap-y-4 sm:grid-cols-2">
            <TextField
              name="participant_first_name"
              label="First Name"
              placeholder="Enter your first name"
              control={form.control}
            />
            <TextField
              name="participant_last_name"
              label="Last Name"
              placeholder="Enter your last name"
              control={form.control}
            />
            <TextField
              name="participant_email"
              label="Email"
              placeholder="Enter your email"
              control={form.control}
            />
            <TextField
              name="participant_company_name"
              label="Company Name"
              placeholder="Enter your company name"
              control={form.control}
            />
          </div>

          <Separator className="my-8" />

          <div className="mb-32 mt-12 flex flex-col space-y-4">
            <Button type="submit">Submit</Button>

            <p className="text-sm text-muted-foreground">
              By clicking submit, you agree to the{' '}
              <a
                className="underline underline-offset-4 hover:text-primary"
                href="/en/terms"
              >
                INTOO Terms of Service
              </a>{' '}
              and acknowledge that you have read and understand our{' '}
              <a
                className="underline underline-offset-4 hover:text-primary"
                href="/en/cookie_policy"
              >
                Cookie Policy
              </a>{' '}
              and{' '}
              <a
                className="underline underline-offset-4 hover:text-primary"
                href="/en/privacy"
              >
                Privacy Policy
              </a>
            </p>
          </div>
        </div>
      </form>
    </Form>
  )
}

function SectionHeader({ title, children }) {
  return (
    <>
      <Separator className="my-8" />

      <h2 className="text-lg font-semibold tracking-tight first:mt-0">
        {title}
      </h2>

      <p className="text-sm text-muted-foreground">{children}</p>
    </>
  )
}
