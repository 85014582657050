import React, { useState, useCallback, useEffect } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '../ExerciseProvider'
import { Textarea } from '../../../lib/ui/textarea'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../lib/ui/select'
import { Input } from '../../../lib/ui/input'

const PersonalBrandStatementPageTwo = () => {
  const { updateResponse, getResponse, formattedExerciseName, exerciseIndex } =
    useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const questions = t('pages.1.questions')
  const jobDescriptions = t('pages.1.job_descriptions')
  const [multiInputs, setMultiInputs] = useState({
    target_roles: getResponse(1, 'target_roles') || [''],
  })
  const [where, setWhere] = useState(getResponse(1, 'target_location') || '')
  const [what, setWhat] = useState(() => {
    const targetRoles = getResponse(1, 'target_roles')
    return targetRoles ? targetRoles[targetRoles.length - 1] : ''
  })

  useEffect(() => {
    window.initTypeahead()
  }, [multiInputs])

  const handleChange = useCallback(
    (section, slug, event, index) => {
      const response = event.target?.value
      if (response) {
        const currentResponses = getResponse(section, slug) || []
        if (index !== undefined) {
          currentResponses[index] = response
          updateResponse(section, slug, currentResponses)
        }
        // check if currentResponses is an array
        else {
          updateResponse(section, slug, response)
        }
        if (slug === 'target_roles') {
          setWhat(response)
        }
        if (slug === 'target_location') {
          setWhere(response)
        }
      }
    },
    [updateResponse, getResponse],
  )

  const addInputField = (slug) => {
    setMultiInputs((prev) => ({
      ...prev,
      [slug]: [...(prev[slug] || []), ''],
    }))
  }

  return (
    <div>
      <div className="questions">
        {questions.map((question, index) => (
          <div
            key={index}
            className="mb-6 w-full font-roboto text-base font-medium"
          >
            <div className="flex w-full flex-col">
              <label className="!mb-1">{question.question}</label>
              {question.type === 'input' && (
                <Input
                  slug={question.slug}
                  onChange={(e) =>
                    handleChange(exerciseIndex, question.slug, e)
                  }
                  value={getResponse(exerciseIndex, question.slug)?.[0] || ''}
                />
              )}
              {question.type === 'autocomplete' && (
                <>
                  {question.multi ? (
                    <>
                      {(multiInputs[question.slug] || []).map((_, idx) => (
                        <Input
                          slug={question.slug}
                          key={idx}
                          data-source={question.autocomplete}
                          className={`mb-2 ${question.autocomplete}-typeahead`}
                          onChange={(e) =>
                            handleChange(exerciseIndex, question.slug, e, idx)
                          }
                          defaultValue={
                            getResponse(exerciseIndex, question.slug)?.[idx] ||
                            ''
                          }
                        />
                      ))}
                      <a
                        href="#"
                        onClick={(e) => {
                          e.preventDefault()
                          addInputField(question.slug)
                        }}
                      >
                        + Add Role
                      </a>
                    </>
                  ) : (
                    <Input
                      slug={question.slug}
                      data-source={question.autocomplete}
                      className={`${question.autocomplete}-typeahead`}
                      onChange={(e) =>
                        handleChange(exerciseIndex, question.slug, e)
                      }
                      defaultValue={
                        getResponse(exerciseIndex, question.slug) || ''
                      }
                    />
                  )}
                </>
              )}
              {question.type === 'text' && (
                <Textarea
                  slug={question.slug}
                  onChange={(e) =>
                    handleChange(exerciseIndex, question.slug, e)
                  }
                  defaultValue={getResponse(exerciseIndex, question.slug) || ''}
                />
              )}
              {question.type === 'dropdown' && (
                <Select
                  onValueChange={(e) =>
                    updateResponse(exerciseIndex, question.slug, e)
                  }
                  defaultValue={getResponse(exerciseIndex, question.slug) || ''}
                  className="w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select an option" />
                  </SelectTrigger>
                  <SelectContent>
                    {question.options.map((option, index) => (
                      <SelectItem key={index} value={option}>
                        {option}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            </div>
          </div>
        ))}
        <p>{t('pages.1.identify_target_opportunities')}</p>
        <p>
          <a
            href={`/job-search/jobs/?where=${where}&what=${what}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t('pages.1.start_job_search')}
          </a>
        </p>
        {jobDescriptions.map((description, index) => (
          <div
            key={index}
            className="mb-6 w-full font-roboto text-base font-medium"
          >
            <div className="flex w-full flex-col">
              <label className="!mb-1">{description.description}</label>
              <Textarea
                className="min-h-[125px] w-full rounded-[20px]"
                onChange={(e) =>
                  handleChange(exerciseIndex, description.slug, e)
                }
                defaultValue={
                  getResponse(exerciseIndex, description.slug) || ''
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default PersonalBrandStatementPageTwo
