import React, { useState } from 'react'
import { useResume } from './ResumeProvider'
import { Button } from '../../lib/ui/button'
import { Input } from '../../lib/ui/input'
import { Label } from '../../lib/ui/label'
import { HeartIcon } from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../lib/ui/dialog'

const SaveModal = ({ buttonVariant = 'default' }) => {
  const [open, setOpen] = useState(false)
  const [isSaving, setIsSaving] = useState(false)

  const { persistResume, resumeName, setResumeName } = useResume()

  const persist = async () => {
    setIsSaving(true)
    await persistResume()
    setIsSaving(false)
  }

  const saveButtonKeyDown = async (e) => {
    if (e.key !== 'Enter') return
    await persist()
    setOpen(false)
  }

  const saveClick = async (e) => {
    e?.target && (e.target.disabled = true)
    await persist()
    setOpen(false)
  }

  const saveAndExitClick = async (e) => {
    e.target.disabled = true
    await persist()
    window.location.href = '/resume/builder'
  }

  return (
    <Dialog
      open={open}
      onOpenChange={() => setOpen(!open)}
      className="rounded-2xl"
    >
      <DialogTrigger asChild className="cursor-pointer">
        <Button variant={buttonVariant}>
          Save <HeartIcon className="ml-2 w-6" />
        </Button>
      </DialogTrigger>
      <DialogContent
        className="font-roboto lg:max-w-3xl xl:max-w-5xl"
        variant="default"
        dialogCloseClass="text-black"
      >
        <DialogHeader>
          <DialogTitle className="text-left" asChild>
            <h2 className="text-2xl">Save Changes</h2>
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Save your changes and rename this resume.
        </DialogDescription>
        <div className="px-4 pb-4">
          <div className="pb-4">
            <Label htmlFor="resumeName" className="text-lg">
              Resume Name
            </Label>
            <Input
              type="text"
              className="w-full"
              name="resumeName"
              value={resumeName}
              onChange={(e) => setResumeName(e.target.value)}
              onKeyDown={saveButtonKeyDown}
              disabled={isSaving}
            />
          </div>
          <div className="flex flex-row justify-start">
            <Button className="inline" onClick={saveClick} disabled={isSaving}>
              Save
            </Button>
            <Button
              className="ml-4 inline"
              onClick={saveAndExitClick}
              disabled={isSaving}
            >
              Save &amp; Exit
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default SaveModal
