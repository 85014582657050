import React from 'react'
import { itemTypeMap } from './ActiveTile'
import { useIsDesktop } from '../../../hooks/useMediaQuery'

const getSectionTitle = (sectionName, t) => {
  const parts = sectionName?.split('_')
  if (parts) {
    if (parts.length === 3) {
      return `${t('months')} ${t(parts[1])} - ${t(parts[2])} ${t('plan')}`
    } else {
      return t(`${parts[0]}_plan`, {
        number: t(parts[1]),
      })
    }
  }
}

const ActiveTileHeader = ({
  sectionName,
  calculateCompletedTasks,
  tileInfo,
  t,
}) => {
  const isDesktop = useIsDesktop()

  return (
    <div className="flex flex-col gap-6 lg:grid lg:grid-cols-12 lg:gap-0">
      <div className="col-span-7">
        <div className={`flex flex-col items-start justify-between`}>
          <div className="marker-container">
            <h2
              className="mt-[16px] font-roboto-slab text-2xl !font-bold text-primary"
              style={{
                marginTop: '0px',
                display: 'inline',
                whiteSpace: 'pre-line',
              }}
            >
              {getSectionTitle(sectionName, t)}
              {isDesktop
                ? ` (${calculateCompletedTasks()})`
                : `\n(${calculateCompletedTasks()})`}
            </h2>
            <div className="pt-[16px]">
              {t(`sections.descriptions.${tileInfo[sectionName]?.focus}`)}
            </div>
          </div>
        </div>
      </div>
      <div
        className="row-gap-4 col-span-5 flex flex-wrap items-center lg:flex-nowrap lg:justify-end"
        style={{ rowGap: '1.5rem' }}
      >
        {tileInfo[sectionName]?.type_count &&
          Object.entries(tileInfo[sectionName]['type_count']).map(
            ([type, count], idx) => {
              return (
                <div key={`${type}-${idx}`}>
                  <div
                    className={`flex flex-col items-center justify-center gap-2 pr-6 ${
                      idx !=
                        Object.entries(tileInfo[sectionName]['type_count'])
                          .length -
                          1 && 'border-r border-cod-gray-200'
                    } pl-6`}
                  >
                    {React.cloneElement(itemTypeMap[type].icon, {
                      variant: 'dark',
                      height: '24',
                    })}
                    <div className="text-center capitalize">{count}</div>
                  </div>
                </div>
              )
            },
          )}
      </div>
    </div>
  )
}

export default ActiveTileHeader
