import React, { useState, useEffect } from 'react'
import { ClockIcon } from '@heroicons/react/24/outline'
import { useTranslation } from '../../../hooks/useTranslation'
import { getApi } from '~/util/api'
import { Button, Card, Typography } from '~/components/_atoms'
import { Stack } from '~/components/_layouts'
import { itemTypeMap } from '../../ActionPlan/TrackTileComponents/ActiveTile'
import Spinner from '~/components/shared/Spinner'

export function RecommendedCard({ className }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [recommendations, setRecommendations] = useState([])

  useEffect(() => {
    setIsLoading(true)
    getApi()
      .get('/recommendations')
      .then((response) => {
        if (response.status == 200) {
          setRecommendations(response.data)
          return
        } else {
          throw new Error('json request failed')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('json request failed', error)
      })
  }, [setRecommendations])
  return (
    <Stack className={className} spacing={3}>
      <Typography variant="title4" color="primary">
        {t('recommended_card.title')}
      </Typography>
      <Typography>{t('recommended_card.description')}</Typography>
      {isLoading ? (
        <div className="flex items-center justify-center">
          <Spinner show size={9} />
        </div>
      ) : (
        <Stack spacing={4}>
          <div className="grid grid-cols-[1fr] gap-8 lg:grid-cols-[1fr_1fr]">
            {recommendations.map((recommendation, index) => {
              return (
                <Card
                  key={index}
                  title={recommendation.title}
                  titleColor="neutral"
                  image={recommendation.headerImageUrl}
                  imageAlt={recommendation.headerImageAlt}
                  imageBadge={
                    !!recommendation.length && (
                      <span className="flex flex-row items-center">
                        {recommendation.length}
                        <ClockIcon className="h-7 w-7 pl-2 text-white" />
                      </span>
                    )
                  }
                  actions={
                    <Button
                      href={recommendation.url}
                      fullWidth
                      endIcon={
                        itemTypeMap[recommendation.type]?.icon && (
                          <>
                            {React.cloneElement(
                              itemTypeMap[recommendation.type].icon,
                              { height: 24 },
                            )}
                          </>
                        )
                      }
                    >
                      {itemTypeMap[recommendation.type]?.text}
                    </Button>
                  }
                >
                  <Typography>{recommendation.description}</Typography>
                </Card>
              )
            })}
          </div>
        </Stack>
      )}
    </Stack>
  )
}
