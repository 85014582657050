import React from 'react'
import { IntroductionScriptProvider } from './IntroductionScriptProvider'
import { TranslationProvider } from '../../shared/TranslationProvider'
import translations from '../locales.json'
import { Page } from './Pages'

const IntroductionScriptInner = () => {
  return (
    <div className="flex flex-col p-10">
      <Page />
    </div>
  )
}

const IntroductionScript = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope={'javascript.components.job_search.IntroductionScript'}
    >
      <IntroductionScriptProvider {...props}>
        <IntroductionScriptInner />
      </IntroductionScriptProvider>
    </TranslationProvider>
  )
}

export default IntroductionScript
