import React from 'react'
import Joyride from 'react-joyride'
import { WalkthroughTooltip } from './WalkthroughTooltip'

const Walkthrough = ({
  run,
  steps,
  callback,
  continuous = true,
  styles,
  stepIndex,
  ...props
}) => {
  return (
    <Joyride
      callback={callback}
      continuous={continuous}
      tooltipComponent={WalkthroughTooltip}
      run={run}
      scrollOffset={10}
      scrollToFirstStep
      showProgress
      stepIndex={stepIndex}
      showSkipButton
      steps={steps}
      styles={{
        options: {
          zIndex: 10000,
          ...styles,
        },
      }}
      {...props}
    />
  )
}

export default Walkthrough
