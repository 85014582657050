import React, { useState } from 'react'
import { useData } from './DataProvider'
import useTheme from '../shared/ThemeProvider'
import { Box } from '@mui/joy'
import { LeafIcon, PlantIcon, TreeIcon } from './Icons'
import { NavButton, ButtonContainer } from './Buttons'
import { SpinnerWhite } from '../shared/Spinner'
import useTranslation from '../shared/TranslationProvider'
import { getApi } from '../../util/api'

const Step5 = () => {
  const {
    notificationPreference,
    setNotificationPreference,
    setCurrentStep,
    candidate,
  } = useData()
  const { t } = useTranslation()

  const [finished, setFinished] = useState(false)
  const handleFinish = () => {
    setFinished(true)
    setCurrentStep(6)
  }

  return (
    <>
      <Box className="pt-10">
        <h1 className="text-2xl font-bold md:text-4xl">{t('step5.title')}</h1>
        <p className="py-4 text-base md:text-xl">{t('step5.subtitle')}</p>
      </Box>

      <NotificationPreferenceSelector
        notificationPreference={notificationPreference}
        setNotificationPreference={setNotificationPreference}
        candidate={candidate}
      />

      <ButtonContainer>
        <NavButton type="back" onClick={() => setCurrentStep(4)}>
          {t('back')}
        </NavButton>
        <NavButton type="submit" onClick={handleFinish} disabled={finished}>
          {finished ? (
            <SpinnerWhite className="flex items-center" show />
          ) : (
            t('finished')
          )}
        </NavButton>
      </ButtonContainer>
    </>
  )
}

export const NotificationPreferenceSelector = ({
  notificationPreference,
  setNotificationPreference,
  candidate,
}) => {
  const { t } = useTranslation()
  const { primaryColor, platformName } = useTheme()

  const isNonUsCandidate = !candidate.isUsCandidate
  const recommendedSx = {
    border: `2px solid ${primaryColor}`,
    backgroundColor: '#fff',
    color: '#08467C',
  }
  const activeRecommendedSx = {
    border: '2px solid #fff',
    backgroundColor: primaryColor,
    color: '#fff',
  }
  const containerSx = {
    border: `2px solid ${primaryColor}`,
    marginTop: '50px',
    borderRadius: '10px',
  }
  const logoSx = {
    position: 'relative',
    top: '-66px',
    borderRadius: '52px',
    border: `2px solid ${primaryColor}`,
    padding: '25px',
    width: '100px',
    marginBottom: '-40px',
    height: '100px',
    backgroundColor: '#fff',
  }
  const activeContainerSx = {
    ...containerSx,
    backgroundColor: primaryColor,
    color: '#fff',
  }
  const handleClick = (preference) => {
    setNotificationPreference(preference)

    getApi().patch('/onboarding/', {
      candidate: {
        candidate_profile_attributes: {
          notification_preference: preference,
        },
      },
    })
  }

  return (
    <Box className="grid gap-8 pt-10 md:grid-cols-3">
      <Box
        className="flex cursor-pointer flex-col items-center p-4 text-center"
        sx={
          notificationPreference === 'basic' ? activeContainerSx : containerSx
        }
        onClick={() => handleClick('basic')}
      >
        <Box sx={logoSx}>
          <LeafIcon />
        </Box>
        <h3 className="text-2xl font-semibold">{t('step5.basic.title')}</h3>
        <div className="flex h-full flex-col justify-between">
          <p className="p-2 text-sm">{t('step5.basic.detail1')}</p>
          <p className="p-2 text-sm">{t('step5.basic.detail2')}</p>
        </div>
      </Box>
      <Box
        className="relative flex cursor-pointer flex-col items-center p-4 text-center"
        sx={
          notificationPreference === 'standard'
            ? activeContainerSx
            : containerSx
        }
        onClick={() => handleClick('standard')}
      >
        <Box sx={logoSx}>
          <PlantIcon />
        </Box>
        <Box
          className="absolute left-2 top-4 rounded-3xl px-4 py-3 text-sm font-bold"
          sx={
            notificationPreference === 'standard'
              ? recommendedSx
              : activeRecommendedSx
          }
        >
          {t('step5.recommended')}
        </Box>
        <h3 className="text-2xl font-semibold">{t('step5.standard.title')}</h3>
        <div className="flex h-full flex-col justify-between">
          <p className="p-2 text-sm">{t('step5.standard.detail')}</p>
          {isNonUsCandidate && (
            <p className="p-2 text-sm">{t('step5.standard.detail_non_us')}</p>
          )}
        </div>
      </Box>
      <Box
        className="flex cursor-pointer flex-col items-center p-4 text-center"
        sx={
          notificationPreference === 'comprehensive'
            ? activeContainerSx
            : containerSx
        }
        onClick={() => handleClick('comprehensive')}
      >
        <Box sx={logoSx}>
          <TreeIcon />
        </Box>
        <h3 className="text-2xl font-semibold">
          {t('step5.comprehensive.title')}
        </h3>
        <div className="flex h-full flex-col justify-between">
          <p className="p-2 text-sm">
            {t('step5.comprehensive.detail', { yns: platformName })}
          </p>
          {isNonUsCandidate && (
            <p className="p-2 text-sm">
              {t('step5.comprehensive.detail_non_us')}
            </p>
          )}
        </div>
      </Box>
    </Box>
  )
}

export default Step5
