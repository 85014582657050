import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  useReducer,
} from 'react'
import { apiPost, apiPatch } from '../../util/api'

const ExerciseContext = createContext()

export const useExercise = () => {
  return useContext(ExerciseContext)
}

const initialState = {
  additionalIdeas: [],
}

function reducer(state, action) {
  switch (action.type) {
    case 'ADD_IDEA': {
      const newIdeas = [
        ...state.additionalIdeas,
        { ...action.payload, completionDate: null },
      ]
      return {
        ...state,
        additionalIdeas: newIdeas,
      }
    }
    case 'DELETE_IDEA': {
      const filteredIdeas = state.additionalIdeas.filter(
        (_, index) => index !== action.payload,
      )
      return {
        ...state,
        additionalIdeas: filteredIdeas,
      }
    }
    case 'UPDATE_IDEA': {
      const updatedIdeas = state.additionalIdeas.map((idea, index) =>
        index === action.payload.index
          ? {
              ...idea,
              idea: action.payload.idea,
              completionDate: action.payload.completionDate,
            }
          : idea,
      )
      return {
        ...state,
        additionalIdeas: updatedIdeas,
      }
    }
    case 'UPDATE_COMPLETION_DATE': {
      const newIdeas = state.additionalIdeas.map((idea, index) => {
        if (index === action.payload.index) {
          return {
            ...idea,
            completionDate: action.payload.date,
          }
        }
        return idea
      })
      return {
        ...state,
        additionalIdeas: newIdeas,
      }
    }
    default:
      return state
  }
}
export const ExerciseProvider = ({
  exerciseId,
  exercisePartId,
  exerciseName,
  exerciseTime,
  content,
  bucketPath,
  candidateId,
  ssoName,
  candidateFirstName,
  dateAndTimeUpdated,
  module,
  isPremiumOrSelect,
  embedExercise,
  existingPersonalBrandStatement,
  children,
}) => {
  const [started, setStarted] = useState(
    (exercisePartId &&
      localStorage.getItem(`${exercisePartId}:started`) === 'true') ||
      false,
  )
  const queryParams = new URLSearchParams(location.search)
  const retake = queryParams.get('retake') === 'true'

  const [documentId, setDocumentId] = useState(exerciseId)
  const [documentPartId, setDocumentPartId] = useState(exercisePartId)
  const [trackContent, setTrackContent] = useState(content || {})
  const [exerciseIndex, setExerciseIndex] = useState(0)
  const [sectionIdx, setSectionIdx] = useState(0)
  const formattedExerciseName = exerciseName.toLowerCase().split(' ').join('_')
  const [showConclusion, setShowConclusion] = useState(
    (!embedExercise && content?.showConclusion) || false,
  )
  const [state, dispatch] = useReducer(reducer, initialState)

  const [continueButtonDisabled, setContinueButtonDisabled] = useState(
    exerciseName === 'Chart Your Course' &&
      exerciseIndex === 0 &&
      sectionIdx === 1,
  )

  useEffect(() => {
    if (!showConclusion && !embedExercise && exerciseName !== 'Pie Of Life') {
      const handleReload = (e) => {
        if (started || embedExercise) {
          e.preventDefault()
          e.returnValue = ''
        }
      }
      window.addEventListener('beforeunload', handleReload)
      return () => {
        window.removeEventListener('beforeunload', handleReload)
      }
    }
  }, [started, showConclusion, embedExercise])

  useEffect(() => {
    if (exercisePartId) {
      localStorage.setItem(`${exercisePartId}:started`, started)
      localStorage.setItem(`${exercisePartId}:conclusion`, showConclusion)
    }
  }, [started, showConclusion, exercisePartId])

  useEffect(() => {
    if (retake) {
      retakeExercise()
    }
  }, [retake])

  const sendAchievement = async () => {
    const module = window.location.pathname.split('/')[1]
    try {
      await apiPost(
        `/${module}/resources/exercises/${formattedExerciseName}/send_achievement`,
        { exercise_id: documentId },
      )
    } catch (error) {
      console.error('Error sending achievement:', error)
    }
  }

  const saveExercise = async (conclusion = false) => {
    const url = documentId
      ? `/documents/${documentId}/parts/${documentPartId}`
      : `/exercise/document/exercises?exercise_type=${formattedExerciseName}`

    const module = window.location.pathname.split('/')[1]

    try {
      if (!documentId) {
        const res = await apiPost(url, {})
        if (res.status === 200) {
          setStarted(true)
          const data = res.data
          setDocumentId(data.document_id)
          setDocumentPartId(data.document_part_id)
          return res
        } else {
          throw new Error('res not okay')
        }
      } else {
        const documentPartsInfo = {
          content: trackContent,
        }
        documentPartsInfo.content['showConclusion'] = conclusion
        const res = await apiPatch(url, documentPartsInfo)
        if (res.status === 200) {
          if (conclusion) {
            setShowConclusion(true)
            document.body.classList.add('exercise-conclusion')
            await sendAchievement()
          } else if (
            exerciseIndex === 0 &&
            exerciseName === 'Reason For Leaving Statement'
          ) {
            // dont redirect
          } else {
            window.location.pathname = `/${module}/resources`
          }
          setReminders()
          return res
        } else {
          throw new Error('res not okay')
        }
      }
    } catch (error) {
      console.error('Error saving exercise:', error)
    }
  }
  const updateAdditionalIdeas = (response, completionDate) => {
    const validResponse = response.filter((res) => res !== '' && res !== null)

    const updatedIdeas = validResponse.map((res) => ({
      idea: res.idea || res,
      completionDate: res.completionDate || completionDate || null,
    }))

    return updatedIdeas
  }

  const updateResponse = (section, slug, response, completionDate) => {
    const validResponse = Array.isArray(response)
      ? response.filter(
          (res) =>
            res !== '' && res !== null && res.idea !== '' && res.idea !== null,
        )
      : response

    setTrackContent((prevContent) => {
      let updatedSection = { ...prevContent[section] }
      if (slug === 'additional_ideas') {
        const updatedIdeas = updateAdditionalIdeas(
          validResponse,
          completionDate,
        )
        updatedSection[slug] = updatedIdeas
      } else if (completionDate) {
        updatedSection[slug] = completionDate
      } else {
        updatedSection[slug] = validResponse
      }

      return {
        ...prevContent,
        [section]: updatedSection,
      }
    })
  }

  const updateRadioResponse = (section, slug, value) => {
    setTrackContent((prevContent) => {
      let updatedSection = { ...prevContent[section] }
      updatedSection[slug] = value

      return {
        ...prevContent,
        [section]: updatedSection,
      }
    })
  }

  const updatePieOfLifeResponse = (slug, field, value) => {
    setTrackContent((prevContent) => {
      const updatedSection = {
        ...prevContent[slug],
        [field]: parseInt(value, 10) || 0,
      }
      return { ...prevContent, [slug]: updatedSection }
    })
  }

  const getResponse = (section, slug) => {
    const response = trackContent[section]?.[slug]
    if (response?.length || exerciseName !== 'Chart Your Course') {
      return response
    }
  }

  const setReminders = async () => {
    const reminders = []

    Object.keys(trackContent).forEach((sectionKey) => {
      const section = trackContent[sectionKey]

      if (section.additional_ideas) {
        section.additional_ideas.forEach((idea) => {
          if (idea.completionDate) {
            reminders.push({
              content: idea.idea,
              due_date: idea.completionDate,
            })
          }
        })
      }

      Object.keys(section).forEach((key) => {
        if (
          key.startsWith('checkbox_') &&
          section[key] &&
          !isNaN(Date.parse(section[key]))
        ) {
          reminders.push({
            content: key,
            due_date: section[key],
          })
        }

        if (
          key.startsWith('action') &&
          section[key] &&
          section[key].date &&
          !isNaN(Date.parse(section[key].date))
        ) {
          reminders.push({
            content: section[key].action,
            due_date: section[key].date,
          })
        }
      })
    })

    try {
      await apiPost(
        `/candidates/${candidateId}/reminders/set_exercise_reminders`,
        { reminders, document_id: documentId },
      )
    } catch (error) {
      console.error('Error setting reminders:', error)
    }
  }

  const handleBackClick = () => {
    if (exerciseIndex === 0) {
      setStarted(false)
    } else {
      setExerciseIndex((prev) => prev - 1)
    }
  }

  const requestAylaStatement = async (statementType) => {
    const url = `/exercise/create_ai_exit_statement?statement_type=${statementType}&document_part_id=${documentPartId}`

    try {
      const response = await apiPost(url, {})
      if (response.status !== 200) {
        throw new Error('Ayla request was not ok')
      }

      return response.data
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleChange = (section, slug, event, value) => {
    const response =
      value !== undefined // If a value is passed explicitly, use it. Used to format URLs in SocialMediaReview exercise
        ? value
        : event.target?.type === 'checkbox' || event.target?.type === 'radio'
          ? event.target.checked
          : event?.target?.value

    updateResponse(section, slug, response)
  }

  const handleDivClick = (section, slug) => {
    const currentValue = getResponse(section, slug) || false
    updateResponse(section, slug, !currentValue)
  }

  const retakeExercise = async () => {
    setStarted(true)
    setShowConclusion(false)

    resetTrackContentIfNeeded()
    resetExerciseState()

    const module = getModuleFromPath()
    await deleteAchievement(module)
  }

  const resetTrackContentIfNeeded = () => {
    if (
      exerciseName === 'Pie Of Life' ||
      exerciseName === 'Chart Your Course'
    ) {
      setTrackContent({})
    }
  }

  const resetExerciseState = () => {
    setSectionIdx(0)
    setExerciseIndex(0)
    document.body.classList.remove('exercise-conclusion')
  }

  const getModuleFromPath = () => {
    return window.location.pathname.split('/')[1]
  }

  const deleteAchievement = async (module) => {
    try {
      await apiPost(
        `/${module}/resources/exercises/${formattedExerciseName}/delete_achievement`,
        { exercise_id: documentId },
      )
    } catch (error) {
      console.error('Error retaking exercise:', error)
    }
  }

  const value = {
    exercisePartId,
    documentId,
    setDocumentId,
    documentPartId,
    setDocumentPartId,
    exerciseName,
    exerciseTime,
    content,
    bucketPath,
    started,
    setStarted,
    showConclusion,
    setShowConclusion,
    exerciseIndex,
    setExerciseIndex,
    saveExercise,
    trackContent,
    setTrackContent,
    formattedExerciseName,
    updateResponse,
    getResponse,
    updateAdditionalIdeas,
    additionalIdeas: state.additionalIdeas,
    dispatch,
    ...state,
    handleBackClick,
    ssoName,
    candidateFirstName,
    dateAndTimeUpdated,
    module,
    requestAylaStatement,
    handleChange,
    handleDivClick,
    updateRadioResponse,
    sectionIdx,
    setSectionIdx,
    continueButtonDisabled,
    setContinueButtonDisabled,
    retakeExercise,
    isPremiumOrSelect,
    embedExercise,
    existingPersonalBrandStatement,
    updatePieOfLifeResponse,
    sendAchievement,
  }

  return (
    <ExerciseContext.Provider value={value}>
      {children}
    </ExerciseContext.Provider>
  )
}
