import React, { useEffect, useState } from 'react'
import {
  ProductIcon,
  EarCatchingPhraseIcon,
  OfferIcon,
  PlusIcon,
} from './icons'
import { Button } from '../../lib/ui/button'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../lib/ui/select'
import { apiPatch } from '../../util/api'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
import { useTranslation } from '../../hooks/useTranslation'

const getRandomOption = (options) => {
  const randomIndex = Math.floor(Math.random() * options.length)
  return options[randomIndex]
}

const PitchSelectorCard = ({
  number,
  bgColor,
  textColor,
  title,
  icon,
  collection,
  split,
  selectedValue,
  handleChange,
}) => {
  const { t } = useTranslation()
  const formatOption = (option, split) => {
    if (split) {
      return option.split(',')[0].trim().concat('...')
    } else {
      return option
    }
  }

  return (
    <div
      className={`flex flex-col gap-4 rounded-xl p-6`}
      style={{
        backgroundColor: bgColor,
        color: textColor,
      }}
    >
      <div className="flex items-start justify-between gap-4">
        <h3>
          {number}. {title}
        </h3>
        {icon}
      </div>
      <div className="grid grid-cols-1 gap-4 text-black">
        <Select onValueChange={handleChange} value={selectedValue}>
          <SelectTrigger>
            <SelectValue placeholder={t('select_one')} />
          </SelectTrigger>
          <SelectContent>
            {(collection || []).map((option) => (
              <SelectItem value={option} key={option}>
                {formatOption(option, split)}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  )
}

const Pitch = ({
  product_options,
  ear_catching_phrase_options,
  offer_options,
  first_name,
  job_title,
  pitch,
}) => {
  const [selectedProduct, setSelectedProduct] = useState('')
  const [selectedEarCatchingPhrase, setSelectedEarCatchingPhrase] = useState('')
  const [selectedOffer, setSelectedOffer] = useState('')
  const [pitchStatementTemplate, setPitchStatementTemplate] = useState('')
  const [pitchStatement, setPitchStatement] = useState(pitch || '')
  const { t } = useTranslation()

  const usePitchStatement = () => {
    setPitchStatement(pitchStatementTemplate)
  }

  useEffect(() => {
    setPitchStatementTemplate(
      `${t('pitch_template_name', {
        first_name: first_name,
      })} ${selectedProduct} ${t('pitch_template_job_title', {
        job_title: job_title,
      })} ${selectedEarCatchingPhrase} ${selectedOffer}`,
    )
  }, [
    selectedProduct,
    selectedEarCatchingPhrase,
    selectedOffer,
    first_name,
    job_title,
    t,
  ])

  const savePitch = async () => {
    const url = `/personal_brand/document/pitch`
    const data = {
      pitch: {
        pitch_content_attributes: {
          text:
            pitchStatement.length > 0 ? pitchStatement : pitchStatementTemplate,
        },
      },
    }
    let response
    try {
      response = await apiPatch(url, data)
    } catch (err) {
      console.log(err)
    }
    if (response.status === 200) {
      window.location.href = '/personal_brand/marketing_toolkit'
    } else {
      console.log('Error updating pitch')
    }
  }

  const handleAutoGenerate = () => {
    setSelectedProduct(getRandomOption(product_options))
    setSelectedEarCatchingPhrase(getRandomOption(ear_catching_phrase_options))
    setSelectedOffer(getRandomOption(offer_options))
  }

  return (
    <div className="home-container m-4 md:m-10">
      <div className="mb-6 flex flex-col gap-4">
        <h1>
          <span className="!font-normal">{t('light_heading')}</span>{' '}
          {t('dark_heading')}
        </h1>
        <div className="md:w-3/4">
          <p>{t('intro')}</p>
        </div>
      </div>
      <div className="clex-col mb-9 flex flex-col gap-4">
        <h2>Sample</h2>
        <div className="rounded-xl border border-gray-300 p-6">
          {t('sample_1')}.<span className="text-primary">{t('sample_2')} </span>
          {t('sample_3')}
          <span className="text-[#A98300]">{t('sample_4')} </span>
          <span className="text-[#1C6F29]">{t('sample_5')}</span>
        </div>
      </div>
      <div className="subtitle mb-6">{t('need_help')}</div>
      <div className="grid grid-cols-1 gap-6 md:grid-cols-3">
        <PitchSelectorCard
          number={1}
          bgColor="#E0F2F9"
          textColor="#08467C"
          title="Your Product"
          icon={<ProductIcon />}
          collection={product_options}
          selectedValue={selectedProduct}
          handleChange={setSelectedProduct}
        />
        <PitchSelectorCard
          number={2}
          bgColor="#FFF6DB"
          textColor="#A98300"
          title="Ear-Catching Phrases"
          icon={<EarCatchingPhraseIcon />}
          collection={ear_catching_phrase_options}
          selectedValue={selectedEarCatchingPhrase}
          handleChange={setSelectedEarCatchingPhrase}
          split={true}
        />
        <PitchSelectorCard
          number={3}
          bgColor="#E9F2E2"
          textColor="#1C6F29"
          title="What You Offer"
          icon={<OfferIcon />}
          collection={offer_options}
          selectedValue={selectedOffer}
          handleChange={setSelectedOffer}
        />
      </div>
      <div className="mt-6">
        <Button onClick={handleAutoGenerate}>
          {t('auto_generate')} <PlusIcon className="ml-2" />
        </Button>
      </div>
      <div className="mb-4 mt-9">
        <div className="subtitle">{t('pitch_template')}</div>
      </div>
      <div className="mt-4 min-h-[300px] w-full rounded-[20px] border border-[#D3D3D3] bg-[white] p-3 font-roboto text-base md:min-h-[150px]">
        {pitchStatementTemplate}
      </div>
      <div className="mb-9 mt-6">
        <Button onClick={usePitchStatement}>{t('use_statement')}</Button>
      </div>
      <div className="subtitle mb-4">{t('own_touch')}</div>
      <textarea
        className="mt-4 min-h-[300px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base md:min-h-[150px]"
        value={pitchStatement}
        onChange={(e) => setPitchStatement(e.target.value)}
      />

      <div className="mt-6">
        <Button onClick={savePitch}>{t('save')}</Button>
      </div>
    </div>
  )
}

const Edit = ({ ...props }) => {
  return (
    <TranslationProvider
      translations={translations}
      scope="javascript.components.PersonalBrand.Pitch"
    >
      <Pitch {...props} />
    </TranslationProvider>
  )
}

export default Edit
