import React, { useState } from 'react'
import { api } from '../../../util/api'
import TypingEffect from '~/components/PersonalBrand/cover_letters/CreateAi/TypingEffect'
import Box from '@mui/material/Box'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '../../../lib/ui/dialog'
import NewThankYouNoteForm from './NewThankYouNoteForm'
import { Button } from '../../../lib/ui/button'
import { Download } from 'iconoir-react'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import StarFeedback from '../../shared/StarFeedback'
import { useAiResponseStream } from './useAiResponseStream'

export default function CreateAi({ id, t, jobPosting }) {
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const [targetJobTitle, setTargetJobTitle] = React.useState(jobPosting?.title)
  const [targetCompany, setTargetCompany] = React.useState(jobPosting?.company)
  const [interviewFormat, setInterviewFormat] = React.useState('')
  const [interviewersRole, setInterviewersRole] = React.useState('')
  const [keyTopics, setKeyTopics] = React.useState('')
  const [takeaways, setTakeaways] = React.useState('')
  const [nextSteps, setNextSteps] = React.useState('')
  const [showAiTyping, setShowAiTyping] = React.useState(false)
  const [typingComplete, setTypingComplete] = React.useState(false)
  const [parentThankYouNoteId, setParentThankYouNoteId] = React.useState()
  const [displayedText, setDisplayedText] = React.useState('')
  const [index, setIndex] = React.useState(0)
  const {
    aiResponse,
    thankYouNoteId,
    setThankYouNoteId,
    aiStreamComplete,
    setServerSentEvents,
  } = useAiResponseStream()
  const [showDialog, setShowDialog] = useState(false)
  const toggleDialog = () => setShowDialog((prev) => !prev)

  const handleOpen = () => {
    setShowDialog(true)
    setFormSubmitted(false)
  }
  const handleClose = () => {
    setShowDialog(false)
    setFormSubmitted(true)
  }
  const [thankYouNoteTone, setThankYouNoteTone] = React.useState(66)
  const [thankYouNotes, setThankYouNotes] = React.useState()
  const [fetchThankYouNotes, setFetchThankYouNotes] = React.useState(true)
  const [selectedThankYouNote, setSelectedThankYouNote] = React.useState()
  const [typingEffect, setTypingEffect] = React.useState(false)
  const [copyButtonText, setCopyButtonText] = React.useState(t.copy_button_text)
  const [goBack, setGoBack] = React.useState(true)
  const [regenerateThankYouNote, setRegenerateThankYouNote] =
    React.useState(false)

  const showContinueButton = typingComplete && aiStreamComplete && thankYouNotes
  const formComplete = targetJobTitle && targetCompany
  const submitButtonDisabled = formSubmitted || !formComplete

  const thankYouNoteTones = React.useMemo(
    () => ({
      0: 'in an informal tone',
      33: 'in a neutral tone',
      66: 'in a business formal tone',
      99: 'in a very formal tone',
    }),
    [],
  )

  const fetchThankYouNotesFromApi = React.useCallback(
    async (id) => {
      const {
        data: { thank_you_notes },
      } = await api.get(`/interview/thank_you_notes/ai_versions/${id}`, {
        id: id,
      })
      setThankYouNotes(thank_you_notes)
      thank_you_notes.forEach((element, idx) => {
        const documentElement = document.getElementById(
          `thank-you-note-${idx + 1}`,
        )
        if (documentElement) {
          documentElement.hidden = false
          documentElement.classList.add('not-selected')
        }

        if (element && element.id && element.id === parseInt(id)) {
          setTargetJobTitle(element.target_job_title)
          setTargetCompany(element.target_company)
          setInterviewFormat(element.interview_format)
          setInterviewersRole(element.interviewers_role)
          setKeyTopics(element.key_topics)
          setTakeaways(element.takeaways)
          setNextSteps(element.next_steps)
          setParentThankYouNoteId(element.parent_thank_you_note_id)
          setThankYouNoteTone(
            Object.keys(thankYouNoteTones).find(
              (key) => thankYouNoteTones[key] === element.tone,
            ),
          )
          if (documentElement) {
            documentElement.classList.remove('not-selected')
          }
        }
      })
    },
    [thankYouNoteTones],
  )

  React.useEffect(() => {
    if (aiStreamComplete) {
      fetchThankYouNotesFromApi(thankYouNoteId)
      setSelectedThankYouNote(thankYouNoteId)
      setRegenerateThankYouNote(false)
    }
  }, [
    aiStreamComplete,
    thankYouNoteId,
    setSelectedThankYouNote,
    fetchThankYouNotesFromApi,
  ])

  React.useEffect(() => {
    if (regenerateThankYouNote) {
      const copyButton = document.getElementById('copy-button')
      const downloadButton = document.getElementById(
        'download-button-container',
      )
      const aylaThankYouNoteText = document.getElementById(
        'ayla-thank-you-note-text',
      )
      const thankYouNoteButtons = document.getElementById(
        'thank-you-note-buttons',
      )
      const continueButton = document.getElementById('continue-button')

      if (
        copyButton &&
        downloadButton &&
        aylaThankYouNoteText &&
        thankYouNoteButtons &&
        continueButton
      ) {
        copyButton.style.display = 'none'
        downloadButton.style.display = 'none'
        aylaThankYouNoteText.style.display = 'none'
        thankYouNoteButtons.style.display = 'none'
        continueButton.style.display = 'none'
      }
    }
  })

  React.useEffect(() => {
    if (id && fetchThankYouNotes && goBack) {
      fetchThankYouNotesFromApi(parseInt(id))
      setFetchThankYouNotes(false)
      setThankYouNoteId(parseInt(id))
      setSelectedThankYouNote(parseInt(id))
      setShowAiTyping(true)
      setGoBack(false)
    }
  }, [
    id,
    thankYouNotes,
    fetchThankYouNotes,
    goBack,
    setThankYouNoteId,
    setShowAiTyping,
    setSelectedThankYouNote,
    setFetchThankYouNotes,
    setGoBack,
    fetchThankYouNotesFromApi,
  ])

  React.useEffect(() => {
    setIndex(0)
    setDisplayedText('')
  }, [thankYouNoteId])

  const handleSubmitButtonClick = async (event) => {
    event.preventDefault()
    setFormSubmitted(true)
    setShowAiTyping(true)
    setShowDialog(false)
    setTypingEffect(true)
    setTypingComplete(false)
    setDisplayedText('')
    setIndex(0)
    setRegenerateThankYouNote(true)

    const {
      data: { thank_you_note_id, use_sse },
    } = await api.post('/interview/thank_you_notes/create_ai', {
      ai_thank_you_note: {
        target_job_title: targetJobTitle,
        target_company: targetCompany,
        interview_format: interviewFormat,
        interviewers_role: interviewersRole,
        key_topics: keyTopics,
        takeaways: takeaways,
        next_steps: nextSteps,
        parent_thank_you_note_id: parentThankYouNoteId,
        tone: thankYouNoteTones[thankYouNoteTone],
      },
    })
    setServerSentEvents(use_sse)
    setThankYouNoteId(thank_you_note_id)

    if (!parentThankYouNoteId) {
      setParentThankYouNoteId(thank_you_note_id)
    }
    setFetchThankYouNotes(true)
  }

  const handleCopyButtonClick = (e) => {
    e.preventDefault()

    document.addEventListener(
      'copy',
      function (e) {
        const thankYouNote = thankYouNotes.find(
          (ele) => ele.id === parseInt(selectedThankYouNote),
        )
        const thankYouNoteText = thankYouNote.content
        e.clipboardData.setData('text/plain', thankYouNoteText)
        e.preventDefault()
      },
      true,
    )
    setCopyButtonText(t.copied_text)

    document.execCommand('copy')
  }

  const handleContinueButtonClick = () => {
    fetchThankYouNotesFromApi(selectedThankYouNote)
      .then(() => {
        window.location.href = `/interview/thank_you_note_builder/${selectedThankYouNote}`
        return true
      })
      .catch(() => {
        return false
      })
  }

  const handleSelectButtonClick = (index) => {
    const selected = thankYouNotes[index]
    setTypingEffect(false)
    setCopyButtonText(t.copy_button_text)

    for (let i = 0; i < thankYouNotes.length; i++) {
      document
        .getElementById(`thank-you-note-${i + 1}`)
        .classList.add('not-selected')
      if (i === index) {
        document
          .getElementById(`thank-you-note-${i + 1}`)
          .classList.remove('not-selected')
      }
    }
    const newSelectedId = selected.id
    setSelectedThankYouNote(newSelectedId)
  }

  if (showAiTyping) {
    return (
      <>
        {showContinueButton && (
          <div
            className="actions"
            id="download-button-container"
            style={{
              margin: '0 auto',
              padding: '1rem',
              paddingRight: '0',
              marginBottom: '1rem',
            }}
          >
            <div
              id="download-button"
              style={{ position: 'absolute', right: '4rem' }}
            >
              <a
                className="pull-right"
                href="#"
                id="download-dropdown"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <div className="flex items-center">
                  <Download />
                  <div>{t.download_button_text}</div>
                </div>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-right"
                role="menu"
                aria-labelledby="download-dropdown"
              >
                <li role="presentation">
                  <a
                    href={`/interview/document/thank_you_notes/${selectedThankYouNote}.pdf`}
                  >
                    Download - PDF
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href={`/interview/document/thank_you_notes/${selectedThankYouNote}.docx`}
                  >
                    Download - Word
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href={`/interview/document/thank_you_notes/${selectedThankYouNote}.txt`}
                  >
                    Download - Text
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        <Paper>
          <Box
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0.5rem',
              display: 'flex',
            }}
          >
            <div className="subtitle px-2">
              Here is your thank you note with the information you provided:
            </div>
            {showContinueButton && (
              <button
                id="copy-button"
                onClick={handleCopyButtonClick}
                style={{
                  fontWeight: 'bold',
                }}
              >
                <div className="flex items-center font-roboto text-primary">
                  <DocumentDuplicateIcon className="w-6 stroke-primary" />
                  <div className="font-roboto">&nbsp;{copyButtonText}</div>
                </div>
              </button>
            )}
          </Box>

          {aiStreamComplete &&
            thankYouNotes &&
            !typingEffect &&
            thankYouNotes.map((thankYouNote) => {
              if (
                thankYouNote &&
                thankYouNote.id === parseInt(selectedThankYouNote)
              ) {
                return thankYouNote.content
                  .split('\n\n')
                  .map((paragraph, index) => (
                    <p key={index} className="m-4">
                      {paragraph}
                    </p>
                  ))
              }
            })}

          {((!aiStreamComplete && !thankYouNotes) || typingEffect) && (
            <TypingEffect
              message={aiResponse}
              baseSpeed={5}
              aiStreamComplete={aiStreamComplete}
              setTypingComplete={setTypingComplete}
              displayedText={displayedText}
              setDisplayedText={setDisplayedText}
              index={index}
              setIndex={setIndex}
            />
          )}
          <>
            <br />
            <div className="items-top flex flex-row justify-between">
              <div>
                <Box
                  id="ayla-thank-you-note-text"
                  sx={{ display: 'none', padding: '0.5rem' }}
                  className="subtitle"
                >
                  {t.ayla_thank_you_notes_text}
                </Box>
                <Box
                  id="thank-you-note-buttons"
                  sx={{
                    display: 'none',
                    flexDirection: 'row',
                    justifyContent: 'left',
                    padding: '0.5rem',
                    gap: '1rem',
                    flexWrap: 'wrap',
                  }}
                >
                  <button
                    id="thank-you-note-1"
                    className="btn btn-primary !h-8 !w-8 !rounded-lg !p-0 !leading-none"
                    hidden={true}
                    onClick={() => handleSelectButtonClick(0)}
                    size="square"
                  >
                    1
                  </button>
                  <button
                    id="thank-you-note-2"
                    className="btn btn-primary !h-8 !w-8 !rounded-lg !p-0 !leading-none"
                    hidden={true}
                    onClick={() => handleSelectButtonClick(1)}
                    size="square"
                  >
                    2
                  </button>
                  <button
                    id="thank-you-note-3"
                    className="btn btn-primary !h-8 !w-8 !rounded-lg !p-0 !leading-none"
                    hidden={true}
                    onClick={() => handleSelectButtonClick(2)}
                    size="square"
                  >
                    3
                  </button>
                  <button
                    id="thank-you-note-4"
                    className="btn btn-primary !h-8 !w-8 !rounded-lg !p-0 !leading-none"
                    hidden={true}
                    onClick={() => handleSelectButtonClick(3)}
                    size="square"
                  >
                    4
                  </button>
                  <button
                    id="thank-you-note-5"
                    className="btn btn-primary !h-8 !w-8 !rounded-lg !p-0 !leading-none"
                    hidden={true}
                    onClick={() => handleSelectButtonClick(4)}
                    size="square"
                  >
                    5
                  </button>
                </Box>
              </div>
              <div>
                {showContinueButton && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '0.5rem',
                      gap: '0.5rem',
                    }}
                  >
                    <div className="text-base">Please rate this response:</div>
                    <StarFeedback
                      path="/interview/thank_you_notes_feedback"
                      documentId={selectedThankYouNote}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        </Paper>
        <Dialog open={showDialog} onOpenChange={toggleDialog}>
          <DialogTrigger asChild className="cursor-pointer"></DialogTrigger>
          <DialogContent className="max-w-7xl">
            <DialogHeader>
              <DialogTitle>{t.regenerate_title}</DialogTitle>
            </DialogHeader>
            <NewThankYouNoteForm
              t={t}
              targetJobTitle={targetJobTitle}
              setTargetJobTitle={setTargetJobTitle}
              targetCompany={targetCompany}
              setTargetCompany={setTargetCompany}
              interviewFormat={interviewFormat}
              setInterviewFormat={setInterviewFormat}
              interviewersRole={interviewersRole}
              setInterviewersRole={setInterviewersRole}
              keyTopics={keyTopics}
              setKeyTopics={setKeyTopics}
              takeaways={takeaways}
              setTakeaways={setTakeaways}
              nextSteps={nextSteps}
              setNextSteps={setNextSteps}
              handleSubmitButtonClick={handleSubmitButtonClick}
              submitButtonDisabled={submitButtonDisabled}
              tonePresent={true}
              thankYouNoteTone={thankYouNoteTone}
              setThankYouNoteTone={setThankYouNoteTone}
              handleClose={handleClose}
              jobPosting={jobPosting}
            />
            <DialogClose />
          </DialogContent>
        </Dialog>
        {showContinueButton && (
          <div id="continue-button">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ padding: '0.5rem' }}
              className="subtitle"
            >
              {t.want_to_change_text}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ padding: '0.5rem' }}
            >
              <Button
                id="regenerate_button"
                onClick={handleOpen}
                disabled={thankYouNotes && thankYouNotes.length > 4}
                variant="outline"
              >
                {t.regenerate_button_text}
              </Button>
            </Box>

            <div className="mx-auto my-4 h-[13px] w-[33%] border-b border-[#A8A8A8] text-center">
              <span className="bg-white px-[20px] text-[14px]">OR</span>
            </div>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              textAlign="center"
              sx={{ padding: '0.5rem' }}
              className="subtitle"
            >
              {t.looks_good_text}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ padding: '0.5rem' }}
            >
              <Button onClick={handleContinueButtonClick}>
                {t.continue_button_text}
              </Button>
            </Box>
          </div>
        )}
      </>
    )
  }

  return (
    <NewThankYouNoteForm
      t={t}
      targetJobTitle={targetJobTitle}
      setTargetJobTitle={setTargetJobTitle}
      targetCompany={targetCompany}
      setTargetCompany={setTargetCompany}
      interviewFormat={interviewFormat}
      setInterviewFormat={setInterviewFormat}
      interviewersRole={interviewersRole}
      setInterviewersRole={setInterviewersRole}
      keyTopics={keyTopics}
      setKeyTopics={setKeyTopics}
      takeaways={takeaways}
      setTakeaways={setTakeaways}
      nextSteps={nextSteps}
      setNextSteps={setNextSteps}
      handleSubmitButtonClick={handleSubmitButtonClick}
      submitButtonDisabled={submitButtonDisabled}
      thankYouNoteTone={thankYouNoteTone}
      setThankYouNoteTone={setThankYouNoteTone}
      tonePresent={false}
      jobPosting={jobPosting}
    />
  )
}

function Paper({ children, ...props }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: '#000',
        fontSize: '0.85rem',
        lineHeight: '1.5',
        fontFamily: 'Roboto',
        borderRadius: '0.3125rem',
        boxShadow: '0 0 0.625rem rgba(0, 0, 0, 0.5)',
      }}
      {...props}
      className="my-8 md:p-12"
    >
      {children}
    </Box>
  )
}
