import React from 'react'
import Results from '../QuizResults/Results'
import { useQuiz } from '../QuizProvider'
import useTranslation from '../../shared/TranslationProvider'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import Avatar from '../Avatar'

const CareerPivotResults = () => {
  const { t } = useTranslation('career_pivot')
  const {
    trackContent: { score },
    resultsPageAvatar,
  } = useQuiz()

  const totalScore = Object.values(score).reduce((acc, val) => acc + val, 0)
  const getScoreSection = () => {
    if (totalScore > 75) return 1
    if (totalScore > 40) return 2
    return 3
  }
  const scoreSection = getScoreSection()

  const readiness = t(`results.${scoreSection}.readiness`)
  const copy = t(`results.${scoreSection}.copy`, { readiness })

  const isDesktop = useIsDesktop()

  return (
    <Results>
      <div className="mb-8 flex pb-6 sm:justify-around">
        <div className="mt-1 flex h-96 flex-col gap-4 sm:w-9/12">
          <h2>{t(`results.score`, { score: totalScore })}</h2>
          <div className="mt-4">
            {copy.map((par, idx) => (
              <p key={`results-page-${idx}`}>{par}</p>
            ))}
          </div>
        </div>
        {isDesktop && <Avatar src={resultsPageAvatar} />}
      </div>
    </Results>
  )
}

export default CareerPivotResults
