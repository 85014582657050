import React from 'react'
import { DocumentPage, Text, View, StyleSheet, useStyle } from '../../lib/pdf'

const isNotBlank = (item) => ![null, undefined, ''].includes(item)

export const defaultStyle = {
  xMargin: 0.4,
  yMargin: 0.4,
  size: 'Letter',
  lineHeightMultiplier: 1.0,
  listLineHeightMultiplier: 1.0,
  primaryFontFamily: 'Times New Roman',
}

export const Template = ({ jsonDocument, templateStyle, candidateName }) => {
  const { basics, thankYouNote } = jsonDocument
  const {
    salutation,
    firstName,
    lastName,
    positionTitle,
    companyName,
    companyDivision,
    companyStreet,
    companyCity,
    companyState,
    companyZip,
  } = basics || {}
  const { content } = thankYouNote || {}

  const salutationWithLastName =
    salutation && lastName ? `${salutation} ${lastName}` : ''
  const salutationWithFullName =
    salutation && firstName && lastName
      ? `${salutation} ${firstName} ${lastName}`
      : ''
  const companyAddress = [companyStreet, companyCity, companyState, companyZip]
    .filter(isNotBlank)
    .join(', ')

  const addresseeItems = [
    salutationWithFullName,
    positionTitle,
    companyName,
    companyDivision,
    companyAddress,
  ].filter(isNotBlank)

  return (
    <DocumentPage templateStyle={templateStyle}>
      <MainView
        addresseeItems={addresseeItems}
        salutationWithLastName={salutationWithLastName}
        salutationWithFullName={salutationWithFullName}
        content={content}
        candidateName={candidateName}
      />
    </DocumentPage>
  )
}

const MainView = ({
  addresseeItems,
  salutationWithLastName,
  content,
  candidateName,
}) => {
  const {
    templateStyle: { lineHeightMultiplier },
  } = useStyle()
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      width: '100%',
      padding: '20px',
    },
    mainContainer: {
      color: '#000000',
      flexDirection: 'column',
      width: '100%',
      fontSize: 12,
      lineHeight: `${16 * lineHeightMultiplier}px`,
    },
  })
  return (
    <View style={styles.mainContainer}>
      <Text style={{ marginBottom: 10 }}>
        {new Date().toLocaleDateString('en-US', {
          month: 'short',
          day: 'numeric',
          year: 'numeric',
        })}
      </Text>
      <View style={{ marginBottom: 10 }}>
        {addresseeItems.map((item, index) => {
          return (
            <View key={index}>
              <Text>{item}</Text>
            </View>
          )
        })}
      </View>
      {salutationWithLastName && (
        <Text style={{ marginBottom: 10 }}>Dear {salutationWithLastName}:</Text>
      )}
      <Text style={{ marginBottom: 10 }}>{content}</Text>
      {content && candidateName && (
        <>
          <Text style={{ marginBottom: 10 }}>Sincerely,</Text>
          <Text>{candidateName}</Text>
        </>
      )}
    </View>
  )
}
