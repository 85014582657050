import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'

const useCompanySearchForm = ({
  urlSearchParams,
  lockParamString,
  noResultQuery,
  setPagesToShow,
  setPrefetchedPages,
}) => {
  const schema = {
    company_name: z.string().optional(),
    where: z.string().optional(),
    radius: z.union([z.number(), z.string()]).optional(),
    industry: z.union([z.number(), z.string()]).optional(),
    revenue: z.union([z.number(), z.string()]).optional(),
    company_size: z.union([z.number(), z.string()]).optional(),
    location_type: z.union([z.number(), z.string()]).optional(),
  }

  const defaultValues = {
    company_name: urlSearchParams.get('company_name') || '',
    where: urlSearchParams.get('where') || '',
    radius: urlSearchParams.get('radius') || '25',
    industry: urlSearchParams.get('industry') || '',
    revenue: urlSearchParams.get('revenue') || '',
    company_size: urlSearchParams.get('company_size') || '',
    location_type: urlSearchParams.get('location_type') || '',
  }

  const form = useForm({
    resolver: zodResolver(z.object(schema)),
    defaultValues,
  })

  const onSubmit = async () => {
    lockParamString()
    setPagesToShow(1)
    setPrefetchedPages(false)
  }

  return {
    form,
    onSubmit,
    noResultQuery,
  }
}

export default useCompanySearchForm
