import React from 'react'
import LandingItemCard from './LandingItemCard'

const LandingItemList = ({ exercises, cardClassName }) => {
  return (
    <div className="grid grid-cols-1 gap-x-12 gap-y-6 max-lg:m-4">
      {exercises.map((exercise, idx) => (
        <LandingItemCard
          key={`exercise-${idx}`}
          exercise={exercise}
          className={'col-span-2 md:col-span-1'}
          cardClassName={cardClassName}
        />
      ))}
    </div>
  )
}

export default LandingItemList
