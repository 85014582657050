import React from 'react'
import Results from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useMultipleChoiceQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'
import parse from 'html-react-parser'
import { useTranslation } from '../../../hooks/useTranslation'

const HowAdaptableResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, flexOrAbove } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()

  const { t } = useTranslation(formattedQuizName)

  const breakPoints = [7, 4]

  const [scoreSection, score] = useMultipleChoiceQuizScore(
    trackContent,
    t('questions'),
    breakPoints,
    'percent',
  )
  const copy = t(`results.${scoreSection}`, { score })

  return (
    <Results>
      <div className="flex pb-6 sm:justify-around">
        <div className="mt-2 flex flex-col gap-4 sm:w-9/12">
          <p className="!mb-0">{t(`results.congratulations`)}</p>
          <p className="!mb-0">{t(`results.based_upon_responses`)}</p>
          <h2>{t(`results.score`, { score })}</h2>
          <p className="!mb-0">{copy}</p>
          <p className="!mb-0">{parse(t(`results.all`))}</p>
          {flexOrAbove && <p className="!mb-0">{parse(t(`results.all_p2`))}</p>}
        </div>
        {isDesktop && <Avatar src={avatar} />}
      </div>
    </Results>
  )
}

export default HowAdaptableResults
