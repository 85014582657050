import React from 'react'
import Results from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useMultipleChoiceQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'
import parse from 'html-react-parser'
import { useTranslation } from '../../../hooks/useTranslation'

const GoalSettingResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent } = useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()

  const { t } = useTranslation(formattedQuizName)

  const breakPoints = [4, 3]

  const [scoreSection, score] = useMultipleChoiceQuizScore(
    trackContent,
    t('questions'),
    breakPoints,
    'raw',
  )
  const copy = t(`results.${scoreSection}`, { score })

  return (
    <Results>
      <div className="flex pb-6 sm:justify-around">
        <div className="mt-2 flex flex-col gap-4 sm:w-9/12">
          <h2>{t(`results.score`, { score })}</h2>
          <p className="!mb-0">{copy}</p>
          {scoreSection !== 1 && (
            <GoalSettingCorrectAnswers scoreSection={scoreSection} />
          )}
          <p className="!mb-0">{parse(t(`results.all`))}</p>
        </div>
        {isDesktop && <Avatar src={avatar} />}
      </div>
    </Results>
  )
}

export default GoalSettingResults

const GoalSettingCorrectAnswers = (results) => {
  const { formattedQuizName, trackContent } = useQuiz()
  const { t } = useTranslation(formattedQuizName)
  const questions = t('questions')
  const contentKeys = Object.keys(trackContent)

  return (
    <>
      {results.scoreSection === 2 && (
        <p className="!mb-0">{t('results.only_question')}</p>
      )}
      {contentKeys.map((key) => {
        const answerChoice = trackContent[key]
        const response = questions[key].options[answerChoice].feedback_key
        const incorrect = answerChoice
        const correct = Object.keys(questions[key].options).find(
          (ele) => questions[key].options[ele].feedback_key === 'yes_response',
        )

        return (
          response === 'no_response' && (
            <>
              <p className="!mb-0">
                <strong>
                  {t('results.question') + questions[key].question}
                </strong>
              </p>
              <p className="!mb-0">
                {t(`results.correct_answer`, {
                  incorrect,
                  correct,
                })}
              </p>
              <p className="!mb-0">{t(`results.q${key}`)}</p>
              <hr />
            </>
          )
        )
      })}
    </>
  )
}
