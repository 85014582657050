import React from 'react'
import useTranslation from '../../shared/TranslationProvider'
import { CheckListLayout, DefaultLayout } from './PartOneLayouts'
import { useExercise } from '../ExerciseProvider'

const PartOne = () => {
  const { exerciseIndex, sectionIdx } = useExercise()
  const { t } = useTranslation('chart_your_course')
  const section_info = t(`pages.${exerciseIndex}`).section_info
  const questions = section_info[sectionIdx].questions
  const description = section_info[sectionIdx].description

  return (
    <div>
      {sectionIdx === 0 && <DefaultLayout questions={questions} />}
      {sectionIdx === 1 && (
        <CheckListLayout description={description} questions={questions} />
      )}
    </div>
  )
}

export default PartOne
