import React, { useState, useEffect } from 'react'
import PieChart from './PieChart'
import { useExercise } from '.././ExerciseProvider'

const formatFieldName = (field) =>
  field.replace(/([A-Z])/g, ' $1').replace(/^./, (str) => str.toUpperCase())

const ExerciseForm = ({ data, slug }) => {
  const { updatePieOfLifeResponse } = useExercise()
  const [localData, setLocalData] = useState(data || {})
  const [chartData, setChartData] = useState(data || {})

  // Synchronize local state with updated data prop
  useEffect(() => {
    setLocalData(data || {})
    setChartData(data || {})
  }, [data])

  const handleInputChange = (field, value) => {
    setLocalData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleInputBlur = (field, value) => {
    updatePieOfLifeResponse(slug, field, value)
    setChartData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  return (
    <div className="flex items-center justify-center">
      <div className="mr-40 flex flex-col space-y-4">
        {[
          'career',
          'healthAndWellness',
          'financial',
          'personalDevelopment',
          'relationships',
          'leisure',
        ].map((field) => (
          <div key={field} className="flex w-full items-center justify-between">
            <label className="text-base" htmlFor={`${slug}-${field}`}>
              {formatFieldName(field)}
            </label>
            <input
              type="number"
              id={`${slug}-${field}`}
              name={field}
              className="ml-2 w-20"
              value={localData?.[field] || ''}
              onChange={(e) => handleInputChange(field, e.target.value)}
              onBlur={(e) => handleInputBlur(field, e.target.value)}
              autoComplete="off"
            />
          </div>
        ))}
      </div>
      <PieChart data={chartData} />
    </div>
  )
}

export default ExerciseForm
