import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import StartQuiz from './StartQuiz'
import Quiz from './Quiz'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
import { QuizProvider, useQuiz } from './QuizProvider'
import IdealWorkplaceEnvironmentResults from './QuizResults/IdealWorkplaceEnvironmentResults'
import PersonalBrandQuizResults from './QuizResults/PersonalBrandQuizResults'
import JobSearchQuizResults from './QuizResults/JobSearchQuizResults'
import JobSearchStrategyKnowledgeResults from './QuizResults/JobSearchStrategyKnowledgeResults'
import SpotSoftSkillResults from './QuizResults/SpotSoftSkillResults'
import ResumeReadyToImpressResults from './QuizResults/ResumeReadyToImpressResults'
import LinkedinAllStarResults from './QuizResults/LinkedinAllStarResults'
import CriticalThinkerResults from './QuizResults/CriticalThinkerResults'
import HowAdaptableResults from './QuizResults/HowAdaptableResults'
import BehavioralInterviewResults from './QuizResults/BehavioralInterviewResults'
import GoalSettingResults from './QuizResults/GoalSettingResults'
import AceTheInterviewResults from './QuizResults/AceTheInterviewResults'
import PortfolioCareerAptitudeResults from './QuizResults/PortfolioCareerAptitude'
import SelfEmploymentResults from './QuizResults/SelfEmploymentResults'
import Questions from './Questions'
const Index = ({ ...props }) => {
  return (
    <Router>
      <QuizProvider {...props}>
        <QuizComponents {...props} />
      </QuizProvider>
    </Router>
  )
}
export default Index

const QuizComponents = ({ locale }) => {
  const { documentId, started, showResults } = useQuiz()
  return (
    <TranslationProvider
      locale={locale || 'en'}
      translations={translations}
      scope={'javascript.components.Quizzes'}
    >
      <div className="quiz flex justify-center border-l border-[#DDD] px-4 py-6">
        {showResults && <ResultsComponent />}
        {!started && !showResults && <StartQuiz quizExists={!!documentId} />}
        {started && !showResults && (
          <Quiz>
            <Questions />
          </Quiz>
        )}
      </div>
    </TranslationProvider>
  )
}

const ResultsComponent = () => {
  const { quizName, resultsTasks } = useQuiz()

  if (quizName === 'Job Search') {
    return <JobSearchQuizResults tasks={resultsTasks} />
  }
  if (quizName === 'Personal Brand Iq') {
    return <PersonalBrandQuizResults tasks={resultsTasks} />
  }
  if (quizName === 'Job Search Strategy Knowledge') {
    return <JobSearchStrategyKnowledgeResults tasks={resultsTasks} />
  }
  if (quizName === 'Spot Soft Skill') {
    return <SpotSoftSkillResults tasks={resultsTasks} />
  }
  if (quizName === 'Ideal Workplace Environment') {
    return <IdealWorkplaceEnvironmentResults tasks={resultsTasks} />
  }
  if (quizName === 'Resume Ready to Impress') {
    return <ResumeReadyToImpressResults tasks={resultsTasks} />
  }
  if (quizName === 'Critical Thinker') {
    return <CriticalThinkerResults />
  }
  if (quizName === 'LinkedIn All Star') {
    return <LinkedinAllStarResults tasks={resultsTasks} />
  }
  if (quizName === 'How Adaptable') {
    return <HowAdaptableResults />
  }
  if (quizName == 'Behavioral Interview Questions') {
    return <BehavioralInterviewResults />
  }
  if (quizName === 'Goal Setting') {
    return <GoalSettingResults />
  }
  if (quizName === 'Ace The Interview') {
    return <AceTheInterviewResults />
  }
  if (quizName === 'Portfolio Career Aptitude') {
    return <PortfolioCareerAptitudeResults />
  }
  if (quizName === 'Self Employment') {
    return <SelfEmploymentResults />
  }
}
