import React, { useState } from 'react'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../../lib/ui/select'
import { Button } from '../../../../lib/ui/button'
import { apiPost } from '../../../../util/api'
import useTranslation, {
  TranslationProvider,
} from '../../../shared/TranslationProvider'
import translations from '../../locales.json'
import AylaResponseScrollArea from '../../AylaResponseScrollArea'

const LocationPriorities = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope={
        'javascript.components.ShortCourses.work-environment.location-preferences'
      }
    >
      <LocationPrioritiesInner {...props} />
    </TranslationProvider>
  )
}
export default LocationPriorities
const LocationPrioritiesInner = ({
  documentId,
  selectedAttributes,
  attributes,
}) => {
  const { t } = useTranslation()
  const questions = t('questions')
  const [locationType, setLocationType] = useState()
  const [locationRestrictons, setLocationRestrictions] = useState('')
  const [typingComplete, setTypingComplete] = useState(false)
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const [aylaResponse, setAylaResponse] = useState(null)
  const [submitted, setSubmitted] = useState(false)
  const showCursor = submitted && displayedText.length <= 0
  const showBorder = submitted || aylaResponse

  const getAylaPrompt = (attr) => {
    if (!attributes) return null
    return attributes.find((a) => a.text === attr.criteria)?.ayla_prompt
  }

  const getLocationPreferences = () => {
    const list = []
    selectedAttributes.forEach((attr) => {
      const aylaPrompt = getAylaPrompt(attr)
      if (aylaPrompt) {
        list.push(aylaPrompt)
      }
    })
    return list.join(', ')
  }

  const locationPreferences = getLocationPreferences()
  const urbanSuburban = locationType
    ? ` In addition, ensure these target locations are ${locationType}.`
    : ''
  const userRestriction =
    locationRestrictons.length > 0
      ? ` I have a restriction on my target locations which is: ${locationRestrictons}. `
      : ' '

  const prompt = `Provide a list of 3 target cities/towns that would prioritize: ${locationPreferences}.${urbanSuburban}${userRestriction}When you provide the list of cities/towns include why they were selected and how well they match my specific location preferences. Include a list of drawbacks for each location that would need to be considered if I was moving there to work. Organize the feedback by suggested location and display details in clean lists with professional prose language to describe why a location matches the preferences. Avoid unnecessary use of special characters in the response.`

  const requestAylaStatement = async () => {
    const url = `/job-search/create_ai_response?document_id=${documentId}`
    const data = { ayla_prompt: { prompt } }
    let res
    try {
      res = await apiPost(url, data)
    } catch (err) {
      console.log(err)
    }

    if (res.status === 200) {
      setAylaResponse(res.data.ayla_response)
      setSubmitted(false)
    } else {
      console.log('error')
    }
    return res
  }

  const handleButtonClick = () => {
    setAylaResponse(null)
    setDisplayedText('')
    setIndex(0)
    setTypingComplete(false)
    setSubmitted(true)
    requestAylaStatement()
  }

  return (
    <div>
      <div className="flex flex-col gap-2">
        <div className="flex flex-col gap-2">
          <div className="subtitle">{t('ayla_title')}</div>
          <div>
            <ul className="list-disc pl-4">
              {selectedAttributes.map((attribute, idx) => (
                <li key={`selected-location-attributes-${idx}`}>
                  {attribute.criteria}
                </li>
              ))}
            </ul>
          </div>
          <p>{t('ayla_description')}</p>
        </div>
        <p>{t('ayla_activity')}</p>
        <div>
          {questions.map((questionInfo, idx) => (
            <div key={`location-priorities-ayla-${idx}`}>
              <label htmlFor={questionInfo.slug}>{questionInfo.question}</label>
              {questionInfo.type === 'text' && (
                <textarea
                  className="min-h-[125px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
                  onChange={(e) => setLocationRestrictions(e.target.value)}
                  value={locationRestrictons || ''}
                  id={questionInfo.slug}
                />
              )}
              {questionInfo.type !== 'text' && (
                <Select
                  onValueChange={(e) => setLocationType(e)}
                  value={locationType || ''}
                  className="w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
                  id={questionInfo.slug}
                >
                  <SelectTrigger>
                    <SelectValue placeholder="Select an option" />
                  </SelectTrigger>
                  <SelectContent>
                    {questionInfo.options.map((option, index) => (
                      <SelectItem key={index} value={option.value}>
                        {option.label}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              )}
            </div>
          ))}
        </div>
        <Button
          disabled={submitted}
          className="self-start"
          onClick={handleButtonClick}
        >
          {t('cta')}
        </Button>
      </div>
      <AylaResponseScrollArea
        showBorder={showBorder}
        showCursor={showCursor}
        aylaResponse={aylaResponse}
        typingComplete={typingComplete}
        setTypingComplete={setTypingComplete}
        displayedText={displayedText}
        setDisplayedText={setDisplayedText}
        index={index}
        setIndex={setIndex}
      />
    </div>
  )
}
