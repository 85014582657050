import * as React from 'react'
import parse from 'html-react-parser'
import { useTranslation } from '../../../hooks/useTranslation'
import { Button, Card, Typography } from '~/components/_atoms'
import { MagicWand } from 'iconoir-react'

export function AylaCard({ className }) {
  const { t } = useTranslation()
  return (
    <Card
      className={className}
      title={parse(t('ayla_card.title'))}
      titleColor="secondary"
      icon={<MagicWand className="h-10 w-10" />}
      actions={
        <Button
          variant="ghost"
          color="secondary"
          href="story/cover_letters/ai"
          className="ga-trackable"
        >
          {parse(t('ayla_card.action_text'))}
        </Button>
      }
    >
      <Typography>{parse(t('ayla_card.description'))}</Typography>
    </Card>
  )
}
