import React from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '~/lib/utils'

export const boxVariants = cva('box', {
  variants: {
    variant: { default: 'relative', outlined: 'relative rounded-2.5xl border' },
    density: { default: 'p-6', compact: 'p-3', roomy: 'p-10' },
    color: {
      default: 'bg-white border-cod-gray-300',
      primary: 'bg-[#F7FCFF] border-[#C0E2FF]',
    },
    shadow: { default: 'shadow-none', medium: 'shadow-md' },
  },
  defaultVariants: {
    variant: 'default',
    density: 'default',
    color: 'default',
    shadow: 'default',
  },
})

const Box = React.forwardRef(
  ({ className, variant, density, color, shadow, ...props }, ref) => {
    return (
      <div
        className={cn(
          boxVariants({ variant, density, color, shadow, className }),
        )}
        ref={ref}
        {...props}
      />
    )
  },
)
Box.displayName = 'Box'

export default Box
