import React from 'react'
import SocialMediaSectionOne from './SocialMediaSectionOne'
import SocialMediaSectionTwo from './SocialMediaSectionTwo'

const SocialMediaReviewExercise = ({ section }) => {
  return (
    <div>
      {section === 0 && <SocialMediaSectionOne />}
      {section === 1 && <SocialMediaSectionTwo />}
    </div>
  )
}

export default SocialMediaReviewExercise
