import React from 'react'
import { useIntroductionScript } from './IntroductionScriptProvider'
import useTranslation from '../../shared/TranslationProvider'
import useIntroductionScriptForm from './useIntroductionScriptForm'
import { Form } from '../../../lib/ui/form'
import { InputField, SelectField } from '../../shared/FormFields'
import { Button } from '../../../lib/ui/button'

const AylaInputForm = () => {
  const { industryChoices } = useIntroductionScript()
  const { form, onSubmit, submitted } = useIntroductionScriptForm()
  const { control, handleSubmit } = form
  const { t } = useTranslation('form')

  const careerLevelChoices = [
    { value: 'entry_level', label: t('career_levels_options.entry_level') },
    {
      value: 'individual_contributor',
      label: t('career_levels_options.individual_contributor'),
    },
    { value: 'manager', label: t('career_levels_options.manager') },
    { value: 'director', label: t('career_levels_options.director') },
    { value: 'vp_level', label: t('career_levels_options.vp') },
    { value: 'executive', label: t('career_levels_options.executive') },
  ]

  const organizationSizeChoices = [
    { value: 'large', label: t('organization_size_options.large') },
    { value: 'small', label: t('organization_size_options.small') },
  ]

  const organizationTypeChoices = [
    { value: 'public', label: t('organization_type_options.public') },
    { value: 'private', label: t('organization_type_options.private') },
    { value: 'startup', label: t('organization_type_options.startup') },
  ]

  return (
    <Form {...form}>
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="flex flex-col gap-4">
          <div className="grid grid-cols-8 gap-8">
            <InputField
              control={control}
              name={'target_role'}
              label={t('target_role_label')}
              description={t('target_role_description')}
              className={'col-span-4 col-start-1'}
              placeholder={t('target_role_placeholder')}
            />
            <SelectField
              control={control}
              name={'career_level'}
              label={t('career_levels_label')}
              choices={careerLevelChoices}
              className={'col-span-4 col-start-1'}
            />
            <SelectField
              control={control}
              name={'industry'}
              label={t('industry_label')}
              description={t('industry_description')}
              choices={industryChoices}
              className={'col-span-4 col-start-1'}
            />
            <SelectField
              control={control}
              name={'organization_size'}
              label={t('organization_size_label')}
              description={t('leave_blank')}
              choices={organizationSizeChoices}
              className={'col-span-4 col-start-1'}
            />
            <SelectField
              control={control}
              name={'organization_type'}
              label={t('organization_type_label')}
              description={t('leave_blank')}
              choices={organizationTypeChoices}
              className={'col-span-4 col-start-1'}
            />
          </div>
          <Button className="self-start" disabled={submitted} type="submit">
            {t('cta')}
          </Button>
        </div>
      </form>
    </Form>
  )
}

export default AylaInputForm
