import React, { useState } from 'react'
import { useResume } from './ResumeProvider'
import { ArrowDownTrayIcon } from '@heroicons/react/24/outline'
import { Button } from '../../lib/ui/button'
import DownloadPdfButton from './DownloadPdfButton'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogDescription,
} from '../../lib/ui/dialog'

const DownloadModal = () => {
  const [open, setOpen] = useState(false)
  const [isOpening, setIsOpening] = useState(false)

  const { resumeName, persistResumeIfChanged, documentId } = useResume()

  const downloadText = async () => {
    await persistResumeIfChanged()
    const downloadPath = `/resume/builder/${documentId}.text`
    setIsOpening(true)
    try {
      const response = await fetch(downloadPath)
      setIsOpening(false)
      const text = await response.text()
      const blob = new Blob([text], { type: 'text/plain' })
      const url = URL.createObjectURL(blob)
      const a = document.createElement('a')
      a.href = url
      a.download = `${resumeName}.txt`
      a.click()
      setOpen(false)
    } catch (e) {
      setIsOpening(false)
      console.error(e)
    }
  }

  return (
    <Dialog open={open} onOpenChange={() => setOpen(!open)}>
      <DialogTrigger asChild className="cursor-pointer">
        <Button variant="outline">
          Download <ArrowDownTrayIcon className="ml-2 w-6" />
        </Button>
      </DialogTrigger>
      <DialogContent
        className="max-w-sm overflow-hidden font-roboto"
        variant="default"
        dialogCloseClass="text-black"
      >
        <DialogHeader>
          <DialogTitle className="text-left" asChild>
            <h2 className="text-2xl">Download Resume</h2>
          </DialogTitle>
        </DialogHeader>
        <DialogDescription>
          Select a file format to download your resume
        </DialogDescription>
        <div className="px-4 pb-4">
          <div className="flex flex-row justify-start">
            <DownloadPdfButton
              className="inline"
              text="PDF"
              disabled={isOpening}
              onClick={() => setOpen(false)}
            >
              PDF
            </DownloadPdfButton>
            <Button
              className="ml-4 inline"
              onClick={downloadText}
              disabled={isOpening}
              variant="outline"
            >
              Text
            </Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}
export default DownloadModal
