import React from 'react'
import NetworkingPreferencesSectionOne from './NetworkingPreferencesSectionOne'
import NetworkingPreferencesSectionTwo from './NetworkingPreferencesSectionTwo'
import NetworkingPreferencesSectionThree from './NetworkingPreferencesSectionThree'

const NetworkingPreferencesExercise = ({ section, trackContent }) => {
  return (
    <div>
      {section === 0 && <NetworkingPreferencesSectionOne data={trackContent} />}
      {section === 1 && <NetworkingPreferencesSectionTwo data={trackContent} />}
      {section === 2 && (
        <NetworkingPreferencesSectionThree data={trackContent} />
      )}
    </div>
  )
}

export default NetworkingPreferencesExercise
