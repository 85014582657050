import React from 'react'
import { useLandingPage } from './LandingPageProvider'
import RightNavListElement from './RightNavListElement'

const RightNavList = () => {
  const { exercises } = useLandingPage()
  const rightNavItems = exercises.filter(
    (exercise) => exercise.right_nav_title?.length > 0,
  )
  return (
    <div className="flex flex-col px-4 md:px-0">
      {rightNavItems.map((exercise, idx) => {
        if (idx !== rightNavItems.length - 1) {
          return (
            <div className="flex flex-col" key={`right-nav-exercise-${idx}`}>
              <RightNavListElement
                exercise={exercise}
                index={idx}
                className={'col-span-2 md:col-span-1'}
              />
            </div>
          )
        } else {
          return (
            <RightNavListElement
              key={`right-nav-exercise-${idx}`}
              exercise={exercise}
              index={idx}
              className={'col-span-2 md:col-span-1'}
            />
          )
        }
      })}
    </div>
  )
}

export default RightNavList
