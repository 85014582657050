import React from 'react'
import { Label } from '../../lib/ui/label'
import { cn } from '../../lib/utils'
import { Checkbox } from '../../lib/ui/checkbox'
import { cva } from 'class-variance-authority'

const checkboxVariants = cva(
  'flex items-center justify-center whitespace-nowrap rounded-full p-4 font-medium capitalize ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 has-[:focus-visible]:outline-none has-[:focus-visible]:ring-2 has-[:focus-visible]:ring-ring has-[:focus-visible]:ring-offset-2',
  {
    variants: {
      variant: {
        default:
          'bg-primary-button text-primary-foreground hover:bg-primary-button-darker-75 focus:bg-primary-button-darker-75 has-[:checked]:bg-primary-button-darker-75 cursor-pointer',
        outline:
          'border border-input bg-background hover:bg-accent hover:text-accent-foreground cursor-pointer',
        selected:
          'bg-primary-button-darker-75 text-primary-foreground cursor-pointer',
        unselected:
          'text-black hover:bg-[#cae4fc] bg-white focus:bg-primary-button-darker-75 has-[:checked]:bg-primary-button-darker-75 border border-primary-button cursor-pointer',
        disabled:
          'bg-primary-button text-primary-foreground hover:bg-primary-button-darker-75 focus:bg-primary-button-darker-75 has-[:checked]:bg-primary-button-darker-75 pointer-events-none opacity-50',
      },
    },
    defaultVariants: {
      variant: 'default',
    },
  },
)

const FancyCheckBoxComponent = ({
  className,
  children,
  label,
  variant,
  ...props
}) => {
  return (
    <Label className={cn(checkboxVariants({ variant, className }))} {...props}>
      {children}
      <div className="flex items-center justify-center">{label}</div>
    </Label>
  )
}

const FancyCheckBox = ({ children, checked, onCheckedCHange, ...props }) => {
  return (
    <Checkbox
      className="sr-only"
      checked={checked}
      onCheckedChange={onCheckedCHange}
      {...props}
    />
  )
}

FancyCheckBoxComponent.FancyCheckBox = FancyCheckBox
FancyCheckBox.displayName = 'FancyCheckBox'

export { FancyCheckBoxComponent, FancyCheckBox, checkboxVariants }
