import React from 'react'
import { useThankYouNote } from '../ThankYouNoteProvider'
import {
  JsonItemProvider,
  JsonItemField,
  useJsonItem,
  JsonTextArea,
} from '../../../lib/json-editor'

export const EditorItemField = ({ ...props }) => {
  return <JsonItemField {...props} />
}

export const EditorItemTextField = ({ ...props }) => {
  return <JsonTextArea {...props} />
}

export const useEditorItem = useJsonItem

export const EditorItemProvider = ({ ...props }) => {
  const { thankYouNoteDocument, setThankYouNoteDocument } = useThankYouNote()

  return (
    <JsonItemProvider
      jsonDocument={thankYouNoteDocument}
      setJsonDocument={setThankYouNoteDocument}
      {...props}
    />
  )
}
