import React from 'react'
import { Stack } from '~/components/_layouts'
import { ActionPlanCard } from '../cards/ActionPlanCard'
import { SelectCoachCard } from '../cards/SelectCoachCard'
import { CoachingOverviewCard } from '../cards/CoachingOverviewCard'
import { UpcomingWebinarsCard } from '../cards/UpcomingWebinarsCard'
import { RecommendedJobsCard } from '../cards/RecommendedJobsCard'
import { RecommendedBlogsCard } from '../cards/RecommendedBlogsCard'
import { RecommendedCard } from '../cards/RecommendedCard'

export function Select() {
  return (
    <Stack spacing={8}>
      <SelectCoachCard className="select-coach-card" />
      <div className="grid gap-8 pt-7 xl:grid-cols-[1fr_1fr] 2xl:grid-cols-[2fr_1fr]">
        <CoachingOverviewCard className="coaching-overview-card" />
        <ActionPlanCard className="action-plan-card xl:col-span-2 2xl:col-span-1" />
      </div>
      <UpcomingWebinarsCard />
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-[1fr_1fr]">
        <RecommendedJobsCard className="recommended-jobs" />
        <RecommendedBlogsCard className="recommended-blogs" />
      </div>
      <RecommendedCard />
    </Stack>
  )
}
