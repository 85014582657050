import React from 'react'
import parse from 'html-react-parser'

const CalloutBox = ({ calloutObject }) => {
  const { title, icon, content, plugins } = calloutObject

  const text =
    plugins && plugins.includes('parse-html') ? parse(content) : content

  return (
    <div className="mt-4 rounded-3xl bg-[#EAF4FF] px-4 pb-16 pt-4 first:mt-0 sm:px-24 sm:pt-4">
      <div className="mb-4 flex font-bold">
        {icon}
        {title && <span className="ml-2 text-xl">{title}</span>}
      </div>
      <div className="h-max rounded-2xl bg-white px-4 pt-6 sm:px-6 sm:pb-8 sm:pt-8">
        <div>{text}</div>
      </div>
    </div>
  )
}
export default CalloutBox
