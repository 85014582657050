import React, { useEffect } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '../ExerciseProvider'
import { ReturnToResourceCenter, DownloadExerciseButton } from '../Buttons'
import {
  CalendarIcon,
  ConclusionPageImage,
  ExerciseIcon,
  PersonIcon,
} from '../icons'
import { useIsMobile } from '../../../hooks/useMediaQuery'
import parse from 'html-react-parser'

const SocialMediaConclusion = () => {
  const {
    formattedExerciseName,
    documentId,
    dateAndTimeUpdated,
    candidateFirstName,
    ssoName,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const link = {
    linkPath: `/documents/${documentId}.pdf`,
    linkText: t('conclusion_link'),
  }

  return (
    <div className="exercise mb-0">
      <Header
        t={t}
        ssoName={ssoName}
        candidateFirstName={candidateFirstName}
        dateAndTimeUpdated={dateAndTimeUpdated}
      />
      <SummaryContent link={link} t={t} />
    </div>
  )
}

const Header = ({ t, ssoName, candidateFirstName, dateAndTimeUpdated }) => {
  const isMobile = useIsMobile()
  const { t: tExercise } = useTranslation('exercise')
  return (
    <div className="bg-intoo-blue-dark text-white">
      <div
        className={`${
          isMobile ? 'mx-4' : 'mx-8'
        } flex items-center justify-between ${isMobile ? 'pb-10' : 'pb-14'}`}
      >
        <div>
          <h1 className="mb-0 mt-10 !font-roboto-slab text-2xl text-[32px] font-medium leading-normal !text-white">
            {t(`start_exercise.title`)}
          </h1>
          <hr className="!b-0 my-4 h-[1px] w-32 bg-white text-white" />
          <div className="flex flex-col !font-roboto-slab text-[20px] sm:flex-row">
            {!ssoName && (
              <div
                className={`${
                  isMobile ? 'mb-4' : ''
                } mr-0 flex items-center sm:mr-12`}
              >
                <div className="mr-4">
                  <PersonIcon />
                </div>
                {`${candidateFirstName}'s`} {tExercise('results')}
              </div>
            )}
            <div className="flex items-center">
              <div className="mr-4">
                <CalendarIcon />
              </div>
              {dateAndTimeUpdated}
            </div>
          </div>
        </div>
        <div className="mt-10 flex">
          <ExerciseIcon />
        </div>
      </div>
    </div>
  )
}

const SummaryContent = ({ link, t }) => {
  const isMobile = useIsMobile()
  const { retakeExercise, trackContent, isPremiumOrSelect } = useExercise()
  const socialMediaData = trackContent[1]
  const { t: tExercise } = useTranslation('exercise')
  const linkedIn = t('linkedin')
  const x = t('x')
  const facebook = t('facebook')
  const instagram = t('instagram')
  const tiktok = t('tiktok')
  const intooCoachReview = t('intoo_coach_review')

  return (
    <div
      className={`summary-content ${
        isMobile ? 'p-6' : 'p-10'
      } flex flex-col bg-[#E0F2F9]`}
    >
      <div
        className={`rounded-[20px] bg-white ${
          isMobile ? 'px-4 py-12' : 'px-12 py-12'
        }`}
      >
        <div className="text-center">
          <h2 className="mb-4 text-2xl font-semibold">{t('great_job')}</h2>
          <p className="!mb-6 font-roboto text-base font-normal">
            {' '}
            {t('conclusion_description')}
          </p>
          {socialMediaData && (
            <div className="!mb-6 font-roboto text-base font-normal">
              {socialMediaData.linkedin_link && (
                <p>
                  {linkedIn} -{' '}
                  <a
                    href={socialMediaData.linkedin_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {socialMediaData.linkedin_link}
                  </a>
                </p>
              )}
              {socialMediaData.facebook_link && (
                <p>
                  {facebook} -{' '}
                  <a
                    href={socialMediaData.facebook_link}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {socialMediaData.facebook_link}
                  </a>
                </p>
              )}
              {socialMediaData.x_link && (
                <p>
                  {x} -{' '}
                  <a
                    href={`https://x.com/${
                      socialMediaData.x_link.startsWith('@')
                        ? socialMediaData.x_link.slice(1)
                        : socialMediaData.x_link
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {socialMediaData.x_link}
                  </a>
                </p>
              )}
              {socialMediaData.instagram_link && (
                <p>
                  {instagram} -{' '}
                  <a
                    href={`https://instagram.com/${
                      socialMediaData.instagram_link.startsWith('@')
                        ? socialMediaData.instagram_link.slice(1)
                        : socialMediaData.instagram_link
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {socialMediaData.instagram_link}
                  </a>
                </p>
              )}
              {socialMediaData.tiktok_link && (
                <p>
                  {tiktok} -{' '}
                  <a
                    href={`https://tiktok.com/@${
                      socialMediaData.tiktok_link.startsWith('@')
                        ? socialMediaData.tiktok_link.slice(1)
                        : socialMediaData.tiktok_link
                    }`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {socialMediaData.tiktok_link}
                  </a>
                </p>
              )}
            </div>
          )}
          <div className="mx-auto my-6 flex justify-center">
            <ConclusionPageImage />
          </div>
          {isPremiumOrSelect && (
            <p className="!mb-6 font-roboto text-base font-normal">
              {' '}
              {parse(intooCoachReview)}
            </p>
          )}
          <p className="!mb-6 font-roboto text-base font-normal">
            {' '}
            {t('constantly_changing')}
          </p>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-center">
            <DownloadExerciseButton
              href={link.linkPath}
              text={t('conclusion_link')}
            />
          </div>
          <div className="flex justify-center">
            <a
              onClick={retakeExercise}
              className="mt-4 cursor-pointer !underline"
            >
              {tExercise('retake_exercise')}
            </a>
          </div>
        </div>
      </div>
      <ReturnToResourceCenter />
    </div>
  )
}
export default SocialMediaConclusion
