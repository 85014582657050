import React from 'react'
import { XMarkIcon } from '@heroicons/react/24/outline'
import { Box, Button, Typography } from '~/components/_atoms'
import { Stack } from '~/components/_layouts'

const Page = ({ children, rightDrawer, title, header, footer }) => {
  const [showRightDrawer, setShowRightDrawer] = React.useState(
    rightDrawer?.open || false,
  )
  const inChatFrame = parent.document.getElementById('chat_browse') !== null

  const gridCols = () => {
    if (inChatFrame || !rightDrawer) {
      return 'md:grid-cols-[1fr]'
    } else {
      return '3xl:grid-cols-[1fr_350px]'
    }
  }

  return (
    <div
      className={`flex flex-col text-cod-gray-800 md:grid md:p-4 ${gridCols()} lg:gap-6 lg:p-6`}
    >
      <Stack spacing={8}>
        <Box variant="outlined" color="primary">
          <Stack spacing={8}>
            <div className="flex flex-col gap-2">
              <div className="flex flex-col gap-4 md:flex-row md:items-center md:justify-between">
                <Typography variant="title1">{title}</Typography>
                {rightDrawer && (
                  <Button
                    className="order-first md:order-last 3xl:hidden"
                    variant="outline"
                    onClick={() => setShowRightDrawer(true)}
                  >
                    {rightDrawer.viewActionText || 'View More'}
                  </Button>
                )}
              </div>
              {header && <div className="page-header-content">{header}</div>}
            </div>
            <div className="page-content">{children}</div>
          </Stack>
        </Box>
        {footer && <div className="page-footer">{footer}</div>}
      </Stack>
      {!!rightDrawer && (
        <div
          className={`fixed right-0 top-0 z-40 h-screen w-96 overflow-y-auto p-4 transition-transform 3xl:relative 3xl:h-full 3xl:w-full 3xl:translate-x-0 3xl:p-0 ${
            showRightDrawer ? 'transform-none' : 'translate-x-full'
          }`}
        >
          <Box className={`h-full`} variant="outlined" color="primary">
            <Stack spacing={4}>
              <div className="flex items-start justify-between gap-2">
                <Typography variant="subtitle">
                  {rightDrawer.title || ''}
                </Typography>
                <XMarkIcon
                  className="h-5 w-5 cursor-pointer 3xl:hidden"
                  onClick={() => setShowRightDrawer(false)}
                />
              </div>
              <div>{rightDrawer.children}</div>
            </Stack>
          </Box>
        </div>
      )}
    </div>
  )
}

export default Page
