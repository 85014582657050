import React, { useState } from 'react'
import { useToast } from '../../lib/ui/toast'
import Spinner from '../shared/Spinner'
import { apiPost } from '../../util/api'
import { useDropzone } from 'react-dropzone'

const DropzoneThankYouNoteUpload = () => {
  const [thankYouNoteIsUploading, setThankYouNoteIsUploading] = useState(false)
  const [isDraggingOver, setIsDraggingOver] = useState(false)
  const { toast } = useToast()

  const { getRootProps, getInputProps } = useDropzone({
    maxFiles: 1,
    accept: {
      'application/pdf': ['.pdf'],
      'application/msword': ['.doc'],
      'application/vnd.openxmlformats-officedocument.wordprocessingml.document':
        ['.docx'],
    },
    onDropAccepted: (incomingFiles) => saveNewThankYouNote(incomingFiles[0]),
    onDropRejected: (incomingFiles) => rejectionMessage(incomingFiles),
    onDragEnter: () => setIsDraggingOver(true),
    onDragLeave: () => setIsDraggingOver(false),
    onDrop: () => setIsDraggingOver(false),
    maxSize: 15_000_000, // 15MB
    disabled: thankYouNoteIsUploading,
  })

  const rejectionMessage = (incomingFiles) => {
    const {
      file: { type },
      errors,
    } = incomingFiles[0]
    const description = errors.map((e) => ` • ${e.message}`).join('\n')
    console.error('File upload rejected:', description)
    if (type) {
      toast({
        description,
        title: 'Could Not Upload Thank You Note',
        variant: 'destructive',
        duration: 10_000,
      })
    }
  }

  const saveNewThankYouNote = async (file) => {
    setThankYouNoteIsUploading(true)
    const name = file.name.split('.')[0]
    const lastModified = file.lastModifiedDate.toISOString()
    const formData = new FormData()
    formData.append('imported_thank_you_note', file)
    formData.append('revision_date', lastModified)
    formData.append('name', name)

    try {
      const response = await apiPost('/thank_you_note/builder', formData, true)
      setThankYouNoteIsUploading(false)
      window.location.href = `/thank_you_note/builder/${response.data.document_id}`
      toast({ description: 'Thank You Note uploaded successfully' })
    } catch (e) {
      setThankYouNoteIsUploading(false)
      console.error('Error saving new thank you note', e)
      toast({
        description: 'Error: Thank You Note upload failed.',
        variant: 'destructive',
      })
      return
    }
  }

  const baseRootClassName =
    'min-h-[20rem] p-4 border-dashed border text-center content-center justify-items-center'
  const defaultRootClassName = isDraggingOver
    ? baseRootClassName + ' border-primary border-2 bg-lily-blue'
    : baseRootClassName
  const rootClassName = thankYouNoteIsUploading
    ? defaultRootClassName
    : defaultRootClassName + ' cursor-pointer'

  return (
    <div className="px-4 pb-4">
      <div className="mb-4 text-lg font-medium">Upload your thank you note</div>
      <div
        {...getRootProps({
          className: rootClassName,
        })}
      >
        <input {...getInputProps()} />
        {thankYouNoteIsUploading && <Spinner show={true} className="w-14" />}
        {!thankYouNoteIsUploading && (
          <div>
            <p>Drag and drop your thank you note here, or click to select</p>
            <em>(Files must be in .pdf, .doc, or .docx format)</em>
          </div>
        )}
      </div>
    </div>
  )
}
export default DropzoneThankYouNoteUpload
