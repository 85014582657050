import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react'
import { usePDF } from '@react-pdf/renderer'
import { TemplateSwitcher, useTemplate } from '../../lib/pdf'
import { apiPost, apiPut } from '../../util/api'
import { useToast } from '../../lib/ui/toast'
import { sha256 } from 'js-sha256'

const ResumeContext = createContext()

export const useResume = () => {
  return useContext(ResumeContext)
}

const hashObject = (obj) => {
  const json = JSON.stringify(obj)
  return sha256(json)
}

const ResumeProvider = ({
  resume_json,
  document_id,
  resume_name,
  dev_mode,
  children,
}) => {
  const documentId = document_id

  const [resumeDocument, setResumeDocument] = useState(resume_json)
  const [resumeName, setResumeName] = useState(resume_name)
  const [lastPersistedHash, setLastPersistedHash] = useState(
    hashObject(documentId ? resume_json : {}),
  )

  const { toast } = useToast()
  const { templateIndex, templateStyle, templates } = useTemplate()

  const [lastSettingsHash, setLastSettingsHash] = useState(
    hashObject(documentId ? templateStyle : {}),
  )

  useEffect(() => {
    const documentChanged = hashObject(resumeDocument) !== lastPersistedHash
    const settingsChanged = hashObject(templateStyle) !== lastSettingsHash

    // If the resume has unsaved changes, warn the user before leaving the page
    if (documentChanged || settingsChanged) {
      window.onbeforeunload = (event) => {
        event.preventDefault()
        event.returnValue = true // For legacy browser compatibility
      }
    } else {
      window.onbeforeunload = undefined
    }
  }, [resumeDocument, lastPersistedHash, templateStyle, lastSettingsHash])

  // use hook for fine-grained control over PDF rendering
  const [pdf, renderPdf] = usePDF({
    document: (
      <TemplateSwitcher
        jsonDocument={resumeDocument}
        templateIndex={templateIndex}
        templateStyle={templateStyle}
        Template={templates[templateIndex]}
      />
    ),
  })

  // Re-render the PDF
  const updateResume = useCallback(() => {
    renderPdf(
      <TemplateSwitcher
        jsonDocument={resumeDocument}
        templateIndex={templateIndex}
        templateStyle={templateStyle}
        Template={templates[templateIndex]}
      />,
    )
  }, [resumeDocument, renderPdf, templateIndex, templateStyle, templates])

  // Automatically re-render pdf when screen is resized
  useEffect(() => {
    window.addEventListener('resize', updateResume)

    return () => window.removeEventListener('resize', updateResume)
  }, [updateResume])

  // Automatically re-render pdf every time resumeDocument changes
  useEffect(() => {
    updateResume()
  }, [resumeDocument, updateResume])

  // Persist resume data to the server
  const persistResume = async () => {
    setLastPersistedHash(hashObject(resumeDocument))
    setLastSettingsHash(hashObject(templateStyle))
    if (documentId) {
      await saveExistingResume()
    } else {
      await saveNewResume()
    }
  }

  const persistResumeIfChanged = async () => {
    const documentChanged = hashObject(resumeDocument) !== lastPersistedHash
    const settingsChanged = hashObject(templateStyle) !== lastSettingsHash
    if (documentChanged || settingsChanged) {
      await persistResume()
    }
  }

  const saveNewResume = async () => {
    let response
    try {
      response = await apiPost('/resume/builder/', {
        resume: resumeDocument,
        template_index: templateIndex,
        template_settings: templateStyle,
        name: resumeName,
      })
    } catch (e) {
      console.error(
        'Error saving new resume. Expected status 200. Got:',
        e.response.status,
        e.response,
      )
      toast({ description: 'Error! Resume failed to save' })
      return
    }
    if (response.status === 200) {
      window.location.href = '/resume/builder/' + response.data.document_id
    } else {
      console.error(
        'Error saving new resume. Expected status 200. Got:',
        response.status,
        response,
      )
      toast({ description: 'Error: Failed to create resume' })
    }
  }

  const saveExistingResume = async () => {
    let response
    toast({ description: 'Saving resume...' })
    try {
      response = await apiPut(`/resume/builder/${documentId}`, {
        resume: resumeDocument,
        template_index: templateIndex,
        template_settings: templateStyle,
        name: resumeName,
      })
    } catch (e) {
      console.error(
        'Error saving existing resume. Expected status 200. Got:',
        e.response.status,
        e.response,
      )
      toast({ description: 'Error: Resume failed to save' })
      return
    }
    if (response.status === 200) {
      toast({ description: 'Resume saved successfully', duration: 1500 })
    } else {
      console.error(
        'Error saving existing resume. Expected status 200. Got:',
        response.status,
        response,
      )
      toast({ description: 'Error! Resume failed to save' })
    }
  }

  const value = {
    pdf,
    documentId,
    persistResume,
    persistResumeIfChanged,
    resumeDocument,
    setResumeDocument,
    resumeName,
    setResumeName,
    devMode: dev_mode,
  }

  return (
    <ResumeContext.Provider value={value}>{children}</ResumeContext.Provider>
  )
}
export default ResumeProvider
