import * as React from 'react'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
  DropdownMenuTrigger,
} from '../../../lib/ui/dropdown-menu'
import { Button } from '../../../lib/ui/button'
import { ChevronDown } from 'lucide-react'
import { useMutation } from '@tanstack/react-query'
import { useTranslation } from '../../../hooks/useTranslation'
import { useSavedJobs } from './SavedJobsProvider'
import { useToast } from '../../../lib/ui/toast'

export function StatusCell({ row, column, table }) {
  const {
    patchSavedJob,
    jobArchiveNotification,
    setJobArchiveNotification,
    appliedWithIntoo,
  } = useSavedJobs()
  const savedJob = row.original
  const initialStatus =
    appliedWithIntoo(savedJob) && savedJob.status === 'saved'
      ? 'applied'
      : savedJob.status

  const [status, setStatus] = React.useState(initialStatus)
  const { toast } = useToast()

  const handleStatusChange = (status) => {
    setStatus(status)
    table.options.meta?.updateData(row.index, column.id, status)
    table.options.meta?.updateData(row.index, 'updated_at', new Date())
    mutation.mutate({ status })
    if (status === 'archive' && !jobArchiveNotification) {
      setJobArchiveNotification(true)
      toast({
        title: 'Job Archived',
        description:
          'To view your archived jobs, choose "Archive" from the "Sort by" filter.',
      })
    }
  }

  const mutation = useMutation({
    mutationFn: (updates) => patchSavedJob(savedJob, updates),
  })

  const { t } = useTranslation('sort_by')
  const label = t(status)

  return (
    <div className="flex flex-row items-center">
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="default"
            className="flex flex-row gap-2 rounded-full border border-[#B9B9B9] text-black data-[status=applied]:bg-[#FFB74D] data-[status=archive]:bg-white data-[status=closed]:bg-[#D8D8D8] data-[status=interview]:bg-[#FFE082] data-[status=offer]:bg-[#65E083] data-[status=saved]:bg-[#5CA9E6] sm:px-4 sm:text-base"
            data-status={status}
          >
            <div className="">{label}</div>
            <ChevronDown className="h-3 w-3 sm:h-4 sm:w-4" />
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="start">
          <DropdownMenuRadioGroup
            value={status}
            onValueChange={handleStatusChange}
            className="text-base"
          >
            <DropdownMenuRadioItem value="saved" className="text-base">
              {t('saved')}
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="applied" className="text-base">
              {t('applied')}
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="interview" className="text-base">
              {t('interview')}
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="offer" className="text-base">
              {t('offer')}
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="closed" className="text-base">
              {t('closed')}
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem value="archive" className="text-base">
              {t('archive')}
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}
