import React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '../ExerciseProvider'
import parse from 'html-react-parser'

const PersonalBrandStatementPageFour = () => {
  const { formattedExerciseName, getResponse } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const placesToUse = t('pages.3.places_to_use')
  const statement = getResponse(2, 'personal_brand_statement') || ''

  return (
    <div>
      {statement ? (
        <>
          <p>{t('pages.3.description_populated')}</p>
          <p className="ml-8">
            <em>{statement}</em>
          </p>
        </>
      ) : (
        <p>{parse(t('pages.3.description_empty'))}</p>
      )}
      <p>{t('pages.3.share_statement')}</p>
      <div className="questions mb-4 ml-8">
        <ul>
          {placesToUse.map((example, index) => (
            <li className="list-disc" key={index}>
              {example}
            </li>
          ))}
        </ul>
      </div>
      <p>{t('pages.3.conclusion')}</p>
    </div>
  )
}

export default PersonalBrandStatementPageFour
