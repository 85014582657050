import React, { useEffect, useMemo } from 'react'
import {
  TabNav,
  PanelContainer,
  ButtonContainer,
  TabButton,
} from '~/components/shared/TabNav'
import { useTranslation } from '~/hooks/useTranslation'
import ActiveTile from './TrackTileComponents/ActiveTile'
import { CompleteIcon } from './TrackTileComponents/icons'

const stepCompleted = (step) => {
  const completedSubsections = Object.values(step)[0]['subsections'].filter(
    (subsection) => subsection.completed || subsection.skipped,
  )
  return (
    completedSubsections.length === Object.values(step)[0]['subsections'].length
  )
}

const MobileMenu = ({ activeTab, setActiveTab, totalSteps }) => {
  const updateActiveTab = (newActiveTab) => {
    const newTab = totalSteps.find((step) => {
      return Object.keys(step)[0] === newActiveTab
    })
    setActiveTab(newTab)
  }

  const activeTabName = Object.keys(activeTab)[0]
  useEffect(() => {
    const lastCompletedStep = totalSteps.findIndex(
      (step) => !stepCompleted(step),
    )
    if (lastCompletedStep === -1) {
      setActiveTab(totalSteps[totalSteps.length - 1])
    } else {
      setActiveTab(totalSteps[lastCompletedStep])
    }
  }, [totalSteps, setActiveTab])

  return (
    <TabNav
      forcedActiveTab={activeTabName}
      onChangeActiveTab={updateActiveTab}
      mobileMode="swipe"
    >
      <ButtonContainer carouselWrapperClass="min-h-[4.5rem]">
        {totalSteps.map((step, index) => (
          <MobileMenuButton key={index} step={step} />
        ))}
      </ButtonContainer>
      <PanelContainer>
        <ActiveTile tileInfo={activeTab} />
      </PanelContainer>
    </TabNav>
  )
}
export default MobileMenu

const MobileMenuButton = ({ step }) => {
  const stepName = Object.keys(step)[0]
  const { t } = useTranslation()

  const completed = useMemo(() => stepCompleted(step), [step])

  const sectionTitle = useMemo(() => {
    const parts = stepName?.split('_')
    if (parts) {
      if (parts.length === 3) {
        return `${t('months')} ${parts[1]} - ${parts[2]}`
      } else {
        return t(`${stepName.split('_')[0]}_indicator`, {
          number: stepName.split('_')[1],
        })
      }
    }
  }, [stepName, t])

  return (
    <TabButton
      name={stepName}
      text={sectionTitle}
      titleClass={completed && 'text-intoo-green-coachcta'}
      Icon={completed ? CompleteIcon : null}
    />
  )
}
