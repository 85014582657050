import React from 'react'
import DataProvider, { useData } from '../DataProvider'
// import TranslationProvider, {
//   TranslationProvider,
// } from '../../shared/TranslationProvider'
import translations from '../locales.json'
import { ThemeProvider } from '../../shared/ThemeProvider'
import { NotificationPreferenceSelector } from '../Step5'
import { TranslationProvider } from '../../shared/TranslationProvider'

const NotificationPreferenceSettingsInternal = ({
  userNotificationPreference,
  candidate,
  ...props
}) => {
  const { notificationPreference, setNotificationPreference } = useData()

  setNotificationPreference(
    notificationPreference || userNotificationPreference,
  )

  return (
    <NotificationPreferenceSelector
      notificationPreference={notificationPreference}
      setNotificationPreference={setNotificationPreference}
      candidate={candidate}
      {...props}
    />
  )
}
const NotificationPreferenceSettings = ({
  locale = null,
  userNotificationPreference,
  candidate,
  ...props
}) => {
  const scope = 'javascript.components.OnboardingSteps'
  return (
    <TranslationProvider
      translations={translations}
      locale={locale || 'en'}
      scope={scope}
    >
      <ThemeProvider>
        <DataProvider>
          <NotificationPreferenceSettingsInternal
            userNotificationPreference={userNotificationPreference}
            candidate={candidate}
            {...props}
          />
        </DataProvider>
      </ThemeProvider>
    </TranslationProvider>
  )
}

export default NotificationPreferenceSettings
