import React from 'react'
import { DocumentPage, Text, View, StyleSheet, useStyle } from '../../lib/pdf'
import Moment from 'moment'

export const defaultStyle = {
  xMargin: 0.4,
  yMargin: 0.4,
  size: 'Letter',
  accentColor: '#005BA2',
  listLineHeightMultiplier: 1.0,
  primaryFontFamily: 'Inter',
  secondaryFontFamily: 'Lora',
}

export const optionalEditorFields = {
  basicsJobTitle: true,
  basicsLocation: false,
  basicsSummary: false,
  basicsWebsite: true,
  basicsLinkedin: true,
  volunteer: true,
}

export const Template = ({ jsonDocument, templateStyle }) => {
  const { secondaryFontFamily, accentColor } = templateStyle

  const styles = StyleSheet.create({
    headingContainer: {
      border: '0px solid #000000',
      minHeight: '90px',
    },
    heading: {
      fontFamily: secondaryFontFamily,
      fontSize: 24,
      fontWeight: 'bold',
      color: accentColor,
      lineHeight: '30.72px',
    },
    leftSection: {
      flexBasis: '67%',
      flexGrow: 1,
      paddingRight: '40px',
    },
    rightSection: {
      border: '0px solid #000000',
      flexBasis: '33%',
      flexGrow: 1,
    },
    contactSection: {
      color: accentColor,
      fontFamily: secondaryFontFamily,
      fontSize: '10px',
      minHeight: '90px',
    },
    contactItem: {
      paddingBottom: '4px',
    },
    sectionTitle: {
      fontSize: '12px',
      fontWeight: 'bold',
      marginTop: '16px',
    },
    skillsSection: { paddingBottom: '12px', paddingTop: '12px' },
  })

  const stripHttp = (url) => {
    return url?.replace(/https{0,1}:\/\//, '')
  }

  const { basics, work, skills, education } = jsonDocument
  const { name, label, email, phone, url, profiles } = basics || {}

  const linkedinProfile =
    profiles &&
    profiles.find((element) => {
      return element.network?.toLowerCase() === 'linkedin'
    })

  const displayUrl = stripHttp(url)

  const linkedinProfileUrl = linkedinProfile && stripHttp(linkedinProfile.url)

  return (
    <DocumentPage templateStyle={templateStyle}>
      <View style={{ flexDirection: 'row' }}>
        <View style={styles.leftSection}>
          <View style={styles.headingContainer}>
            {name && <Text style={styles.heading}>{name},</Text>}
            <Text style={styles.heading}>{label}</Text>
          </View>
          <View>
            {work && work.length > 0 && (
              <WorkSection work={work} styles={styles} />
            )}
            {education && education.length > 0 && (
              <EducationSection education={education} styles={styles} />
            )}
          </View>
        </View>
        <View style={styles.rightSection}>
          <View style={styles.contactSection}>
            <Text style={styles.contactItem}>{email}</Text>
            <Text style={styles.contactItem}>{phone}</Text>
            <Text style={styles.contactItem}>{linkedinProfileUrl}</Text>
            <Text style={styles.contactItem}>{displayUrl}</Text>
          </View>
          {skills &&
            skills.map((skill, index) => {
              return (
                <View key={`skill-${skill.name}-${index}`}>
                  <Text style={styles.sectionTitle}>{skill.name}</Text>
                  <View style={styles.skillsSection}>
                    {skill?.keywords?.map((keyword, kwIndex) => {
                      if (!keyword) return null
                      return (
                        <BulletLine key={`keyword-${keyword}-${kwIndex}`}>
                          {keyword}
                        </BulletLine>
                      )
                    })}
                  </View>
                </View>
              )
            })}
        </View>
      </View>
    </DocumentPage>
  )
}

const EducationSection = ({ education, styles }) => {
  return (
    <>
      <Text style={styles.sectionTitle}>Education</Text>
      {education.map((edItem, index) => (
        <EducationView education={edItem} key={`education-${index}`} />
      ))}
    </>
  )
}

const WorkSection = ({ work, styles }) => {
  return (
    <>
      <Text style={styles.sectionTitle}>Work Experience</Text>
      {work.map((job, index) => (
        <JobView job={job} key={`job-${index}`} />
      ))}
    </>
  )
}

const JobView = ({ job }) => {
  const {
    templateStyle: { accentColor },
  } = useStyle()

  const styles = StyleSheet.create({
    jobContainer: {
      paddingTop: '12px',
    },
    jobCompany: {
      fontSize: '12px',
      fontWeight: 'semibold',
      color: accentColor,
      paddingRight: '8px',
    },
    jobPosition: {
      fontSize: '12px',
      fontWeight: 'normal',
      color: '#000000',
    },
    jobTenure: {
      fontSize: '8px',
      fontStyle: 'italic',
      color: '#000000',
      paddingBottom: '12px',
    },
  })
  if (!job.position && !job.name) {
    return null
  }

  return (
    <View style={styles.jobContainer} wrap={false}>
      <View style={{ flexDirection: 'row' }}>
        {job?.name && <Text style={styles.jobCompany}>{job.name}</Text>}
        <Text style={styles.jobPosition}>{job.position}</Text>
      </View>
      <Text style={styles.jobTenure}>
        <Tenure item={job} />
      </Text>
      {job.highlights &&
        job.highlights.map((highlight, index) => (
          <BulletLine key={`highlight-${index}`}>{highlight}</BulletLine>
        ))}
    </View>
  )
}

const EducationView = ({ education }) => {
  const {
    templateStyle: { accentColor },
  } = useStyle()

  const styles = StyleSheet.create({
    jobContainer: {
      paddingTop: '12px',
    },
    jobCompany: {
      fontSize: '12px',
      fontWeight: 'semibold',
      color: accentColor,
      paddingRight: '8px',
    },
    jobPosition: {
      fontSize: '12px',
      fontWeight: 'normal',
      color: '#000000',
    },
    jobTenure: {
      fontSize: '8px',
      fontStyle: 'italic',
      color: '#000000',
      paddingBottom: '12px',
    },
  })

  if (!education.institution && !education.studyType) {
    return null
  }

  return (
    <View style={styles.jobContainer} wrap={false}>
      <View style={{ flexDirection: 'row' }}>
        <Text style={styles.jobCompany}>{education.institution}</Text>
        <Text style={styles.jobPosition}>
          {education.studyType} {education.area}
        </Text>
      </View>
      <Text style={styles.jobTenure}>
        <Tenure item={education} />
      </Text>
      {education.courses &&
        education.courses.map((course, index) => (
          <BulletLine key={`highlight-${index}`}>{course}</BulletLine>
        ))}
    </View>
  )
}

const BulletLine = ({ children }) => {
  const {
    templateStyle: { listLineHeightMultiplier },
  } = useStyle()
  const styles = StyleSheet.create({
    highlightBullet: {
      padding: '0 4px',
      fontSize: '10px',
      fontWeight: 'normal',
      color: '#000000',
      lineHeight: `${16 * listLineHeightMultiplier}px`,
    },
    highlightText: {
      fontSize: '10px',
      fontWeight: 'normal',
      color: '#000000',
      lineHeight: `${16 * listLineHeightMultiplier}px`,
    },
    container: {
      flexDirection: 'row',
      paddingBottom: `${4 * listLineHeightMultiplier}px`,
    },
  })

  const hcbNone = (word) => [word]

  return (
    <View style={styles.container}>
      <Text style={styles.highlightBullet}>&#8226;</Text>
      <Text hyphenationCallback={hcbNone} style={styles.highlightText}>
        {children}
      </Text>
    </View>
  )
}

const momentFormat = (date, nullValue = '') => {
  if (!date || [undefined, null, ''].includes(date)) {
    return nullValue
  }
  try {
    const result = Moment(date).format('MMM YYYY')
    if (result === 'Invalid date') return nullValue
    return result
  } catch {
    return nullValue
  }
}

const isNotBlank = (item) => ![null, undefined, ''].includes(item)

const Tenure = ({ item }) => {
  if (!item.startDate && !item.endDate) {
    return null
  }

  const formattedStart = momentFormat(item.startDate)
  const formattedEnd = momentFormat(item.endDate, 'Present')

  return [formattedStart, formattedEnd].filter(isNotBlank).join(' - ')
}
