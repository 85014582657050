import React, { useState, createContext, useContext, useMemo } from 'react'

const walkthroughState = {
  run: false,
  stepIndex: 0,
  tourActive: false,
  steps: [],
  started: false,
  openAllFilters: false,
  typingOver: false,
}

export const WalkthroughContext = createContext({
  state: walkthroughState,
  setState: () => undefined,
})
WalkthroughContext.displayName = 'WalkthroughContext'

export function WalkthroughStateProvider(props) {
  const [state, setState] = useState(walkthroughState)

  const value = useMemo(
    () => ({
      state,
      setState,
    }),
    [setState, state],
  )

  return <WalkthroughContext.Provider value={value} {...props} />
}

export function useWalkthrough() {
  const context = useContext(WalkthroughContext)

  if (!context) {
    throw new Error('useAppContext must be used within a AppProvider')
  }

  return context
}
