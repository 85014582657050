import React, { useState, useEffect } from 'react'
import _ from 'lodash'
import { useTranslation } from '~/hooks/useTranslation'
import { CoachButton } from '../../coach_services/OutplacementBenefits/CoachButton.js'
import { getApi } from '~/util/api'
import { Button, Card, Typography } from '~/components/_atoms'
import { Stack } from '~/components/_layouts'
import Spinner from '~/components/shared/Spinner'
import { useDashboard } from '../DashboardProvider'
import CoachInfoDialog from '../CoachInfoDialog'
import { useBenefits } from '../../coach_services/BenefitsProvider'
import CalendlyCallPopup, {
  CalendlyCallScheduled,
} from '../../coach_services/CalendlyCallPopup.js'

export function PremiumCoachCard({ className }) {
  // const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [coachInfo, setCoachInfo] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    getApi()
      .get('/coach_info')
      .then((response) => {
        if (response.status == 200) {
          setCoachInfo(response.data)
          return
        } else {
          throw new Error('json request failed')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('json request failed', error)
      })
  }, [setCoachInfo])

  return isLoading || _.isEmpty(coachInfo) ? (
    <div className="flex items-center justify-center">
      <Spinner show size={9} />
    </div>
  ) : (
    <>
      {coachInfo?.liaison ? (
        <LiaisonCard className={className} coach={coachInfo} />
      ) : (
        <DedicatedCoachCard className={className} coach={coachInfo} />
      )}
    </>
  )
}

function LiaisonCard({ className, coach }) {
  const { t } = useTranslation()
  const { setShowCoachInfoDialog } = useDashboard()
  return (
    <Card
      className={className}
      actions={
        <CoachButton onClick={() => setShowCoachInfoDialog(true)}>
          {t('premium_liason_coach_card.view_action')}
        </CoachButton>
      }
    >
      <Stack className="h-full justify-between" spacing={3}>
        <div className="flex items-end gap-4">
          {coach?.headshot ? (
            <img
              className="mt-[-80px] h-60 w-60 object-contain"
              src={coach?.headshot}
              alt="Coach headshot"
            />
          ) : (
            <img
              className="mt-[-80px] h-48 w-48 rounded-full"
              src={coach?.image}
              alt="Coach Image"
            />
          )}
          <Stack spacing={3}>
            <Typography variant="title4" color="secondary">
              {coach.name}
            </Typography>
            <Typography>
              {t('premium_liason_coach_card.description', { name: coach.name })}
            </Typography>
          </Stack>
        </div>
      </Stack>
    </Card>
  )
}

function DedicatedCoachCard({ className, coach }) {
  const { t } = useTranslation()
  const { setShowCoachInfoDialog } = useDashboard()
  const { calendlyEvent } = useBenefits()

  return (
    <Card
      className={className}
      actions={
        !_.isEmpty(coach) ? (
          <div className="grid w-full grid-cols-1 gap-4 @lg:grid-cols-2">
            <div>
              {!calendlyEvent && (
                <CalendlyCallPopup eventType={'orientation'} />
              )}
              {calendlyEvent && (
                <CalendlyCallScheduled
                  eventType="orientation"
                  calendlyEvent={calendlyEvent}
                />
              )}
            </div>
            <div>
              <Button
                variant="outline"
                onClick={() => setShowCoachInfoDialog(true)}
                color="secondary"
                fullWidth
              >
                {t('premium_dedicated_coach_card.view_action')}
              </Button>
              <CoachInfoDialog coach={coach} />
            </div>
          </div>
        ) : null
      }
    >
      <Stack className="h-full justify-between" spacing={3}>
        <div className="flex flex-col items-end gap-4 @md:flex-row">
          {coach?.headshot ? (
            <img
              className="mt-[-80px] h-60 w-60 object-contain"
              src={coach?.headshot}
              alt="Coach headshot"
            />
          ) : (
            <img
              className="mt-[-60px] h-48 w-48 rounded-full"
              src={coach?.image}
              alt="Coach Image"
            />
          )}
          <Stack spacing={3}>
            <Typography variant="title4" color="secondary">
              {coach.name}
            </Typography>
            {calendlyEvent ? (
              <Typography>
                {t('premium_dedicated_coach_card.continue_journey')}
              </Typography>
            ) : (
              <Typography>
                {t('premium_dedicated_coach_card.get_to_know')}
              </Typography>
            )}
          </Stack>
        </div>
      </Stack>
    </Card>
  )
}
