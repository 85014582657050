import React from 'react'
import { useExercise } from '.././ExerciseProvider'
import PieChartExample from './PieChartExample'
import ExerciseForm from './ExerciseForm'
import { PrimaryExerciseButton, BackExerciseButton } from '.././Buttons'
import { useIsMobile } from '~/hooks/useMediaQuery'
import { cn } from '../../../lib/utils'
import { useTranslation } from '../../../hooks/useTranslation'
import parse from 'html-react-parser'

const PieOfLifeExercise = () => {
  const exampleData = {
    healthAndWellness: 1,
    personalDevelopment: 1,
    career: 1,
    financial: 1,
    relationships: 1,
    leisure: 1,
  }

  const defaultData = {
    healthAndWellness: 0,
    personalDevelopment: 0,
    career: 100,
    financial: 0,
    relationships: 0,
    leisure: 0,
  }

  const isMobile = useIsMobile()
  const {
    trackContent,
    saveExercise,
    embedExercise,
    setStarted,
    formattedExerciseName,
    sendAchievement,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)

  const currentlySpendingTimeData =
    trackContent.currentlySpendingTime || defaultData
  const likeToSpendTimeData = trackContent.likeToSpendTime || defaultData

  const handleSaveExitClick = () => {
    sendAchievement()
    saveExercise()
  }

  const handleBackClick = () => {
    setStarted(false)
  }

  const title = t('exercise_title')
  const description = t('exercise_description')
  const quoteCopy = t('quote')

  return (
    <div className="p-10 font-roboto text-lg">
      <h1 className="mb-4 font-roboto-slab text-3xl font-bold">{title}</h1>
      <p className="!mb-6">{description}</p>

      <div className="flex flex-col items-center justify-center">
        <PieChartExample data={exampleData} />
        <p
          className="!mt-6 rounded-md border-none p-4 text-center"
          style={{ backgroundColor: '#d1e7ed' }}
        >
          {parse(quoteCopy)}
        </p>
      </div>

      <div>
        <p className="!mb-8 mt-16">{t('currently_spend_your_time')}</p>
        <ExerciseForm
          data={currentlySpendingTimeData}
          slug="currentlySpendingTime"
        />
        <p className="!mb-8 mt-16">{t('like_to_spend_your_time')}</p>
        <ExerciseForm data={likeToSpendTimeData} slug="likeToSpendTime" />
      </div>

      {!embedExercise && (
        <div
          className={cn(
            'mt-10 flex w-full items-center justify-between',
            isMobile && 'self-center',
          )}
        >
          <BackExerciseButton
            onClick={handleBackClick}
            className={isMobile ? 'mb-5' : ''}
          >
            {t('back')}
          </BackExerciseButton>
          <PrimaryExerciseButton
            onClick={handleSaveExitClick}
            className={isMobile ? 'mb-5' : ''}
          >
            {t('save_and_exit')}
          </PrimaryExerciseButton>
        </div>
      )}
    </div>
  )
}

export default PieOfLifeExercise
