import React, { useState, useEffect } from 'react'
import { useTranslation } from '~/hooks/useTranslation'
import { CoachButton } from '../../coach_services/OutplacementBenefits/CoachButton.js'
import { getApi } from '~/util/api'
import { Card, Typography } from '~/components/_atoms'
import { Stack } from '~/components/_layouts'
import Spinner from '~/components/shared/Spinner'

export function CoachingOverviewCard({ className }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [coachInfo, setCoachInfo] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    getApi()
      .get('/coach_info')
      .then((response) => {
        if (response.status == 200) {
          setCoachInfo(response.data)
          return
        } else {
          throw new Error('json request failed')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('json request failed', error)
      })
  }, [setCoachInfo])

  return (
    <Card className={className} color="secondary">
      <Stack spacing={3}>
        <Typography variant="title4" color="secondary">
          {t('coach_support_card.title')}
        </Typography>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <Spinner show size={9} />
          </div>
        ) : (
          <>
            <br />
            <CoachButton
              className="open-chat-modal w-full"
              data-toggle="modal"
              data-target="#coach_chat_list"
              data-action={t('coach_support_card.view_action')}
            >
              {t('coach_support_card.view_action')}
            </CoachButton>
            {coachInfo?.time_since_last_check_in && (
              <Typography variant="small">
                {coachInfo?.time_since_last_check_in}
              </Typography>
            )}
          </>
        )}
      </Stack>
    </Card>
  )
}
