import React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { Textarea } from '../../../lib/ui/textarea'
import { Button } from '../../../lib/ui/button'
import {
  Form,
  FormField,
  FormItem,
  FormLabel,
  FormControl,
  FormMessage,
} from '../../../lib/ui/form'
import { Input } from '../../../lib/ui/input'
import { cn } from '../../../lib/utils'
import { PaperclipIcon } from 'lucide-react'
import { DownloadIcon } from './Icons'
import useSavedJobsForm from './useSavedJobsForm'
import { LinkedInButton } from '../JobSearchPage/buttons'

const ExpandedCell = ({ row }) => {
  const { form, onSubmit, submitted } = useSavedJobsForm(row.original)

  const acceptList = ['.pdf', '.rtf', '.doc', '.docx', '.txt']
  const rowClasses = cn('flex flex-col md:flex-row')

  const linkedConnectionsURL =
    'https://www-yns-com.s3.us-east-2.amazonaws.com/images/linkedin_connections.png'

  const { t } = useTranslation('ExpandedCell')

  const jobId = row.original.id

  const handleResumeButtonClick = () => {
    document.querySelector(`.resume_upload_${jobId}`).click()
  }

  const handleCoverLetterButtonClick = () => {
    document.querySelector(`.cover_letter_upload_${jobId}`).click()
  }

  const { watch, handleSubmit, control } = form

  const uploadedResume = watch('resume')
  const uploadedCoverLetter = watch('cover_letter')

  const resumeUrl = row.original.resume_url
  const resumeName = row.original.resume
  const coverLetterUrl = row.original.cover_letter_url
  const coverLetterName = row.original.cover_letter

  return (
    <div>
      <Form {...form}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="p-4">
            <div className="flex flex-col gap-4">
              <div className={rowClasses}>
                <div className="flex w-1/4 flex-col gap-2 border-r border-solid border-gray-300 px-12">
                  <FileField
                    control={control}
                    label={t('resume_applied_with')}
                    name="resume"
                    accept={acceptList.join(',')}
                    className={`resume_upload_${jobId} hidden`}
                  />
                  <div className="flex items-center gap-2">
                    {resumeUrl ? (
                      <DownloadResumeButton
                        resumeName={resumeName || t('download_resume')}
                        resumeUrl={resumeUrl}
                        onClick={handleResumeButtonClick}
                        t={t}
                      />
                    ) : (
                      <UploadResumeButton
                        onClick={handleResumeButtonClick}
                        t={t}
                      />
                    )}
                  </div>
                  {uploadedResume?.name && (
                    <div className="flex items-center">
                      {uploadedResume?.name || ''}
                    </div>
                  )}
                  <div className="hint">{t('hint')}</div>
                </div>
                <div className="flex w-1/4 flex-col gap-2 border-r border-solid border-gray-300 px-12">
                  <FileField
                    control={control}
                    label={t('cover_letter_applied_with')}
                    name="cover_letter"
                    accept={acceptList.join(',')}
                    className={`cover_letter_upload_${jobId} hidden`}
                  />
                  <div className="flex items-center gap-2">
                    {coverLetterUrl ? (
                      <DownloadCoverLetterButton
                        coverLetterName={
                          coverLetterName || t('download_cover_letter')
                        }
                        coverLetterUrl={coverLetterUrl}
                        onClick={handleCoverLetterButtonClick}
                        t={t}
                      />
                    ) : (
                      <UploadCoverLetterButton
                        onClick={handleCoverLetterButtonClick}
                        t={t}
                      />
                    )}
                  </div>
                  {uploadedCoverLetter?.name && (
                    <div className="flex items-center">
                      {uploadedCoverLetter?.name || ''}
                    </div>
                  )}
                  <div className="hint">{t('hint')}</div>
                </div>
                <div className="flex flex-col gap-4 px-12">
                  <div className="text-base font-medium leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70">
                    {t('see_if_you_have_any_connections')}
                  </div>
                  <div className="flex w-3/4 justify-between">
                    <img src={linkedConnectionsURL} />
                    <LinkedInButton job={row.original} />
                  </div>
                </div>
              </div>
              <TextField control={form.control} label="Notes" name={'notes'} />
              <div className="flex gap-4 self-end">
                <Button disabled={submitted} type="submit">
                  {'Save'}
                </Button>
              </div>
            </div>
          </div>
        </form>
      </Form>
    </div>
  )
}

export default ExpandedCell

const UploadResumeButton = ({ t, ...props }) => {
  return (
    <Button
      className="w-52 border border-solid border-gray-300 bg-white text-black hover:bg-accent focus:bg-accent active:bg-accent"
      {...props}
      type="button"
    >
      <div className="flex items-center gap-2 text-black">
        {t('upload_resume')}
        <PaperclipIcon />
      </div>
    </Button>
  )
}

const DownloadResumeButton = ({ resumeName, resumeUrl, onClick, t }) => {
  return (
    <div className="flex flex-col items-start">
      <div className="flex items-center gap-4">
        <Button
          type="button"
          className="w-52 border border-solid border-gray-300 bg-white text-black hover:bg-accent focus:bg-accent active:bg-accent"
        >
          <a
            className="gap-2 !overflow-hidden truncate text-black"
            href={resumeUrl}
            target="_blank"
            rel="noreferrer"
          >
            {resumeName}
          </a>
          <DownloadIcon className="w-24" />
        </Button>
      </div>
      <Button
        type="button"
        onClick={onClick}
        variant="link"
        className="px-0 text-center"
      >
        {t('upload_different_resume')}
      </Button>
    </div>
  )
}

const UploadCoverLetterButton = ({ t, ...props }) => {
  return (
    <Button
      className="w-52 border border-solid border-gray-300 bg-white hover:bg-accent focus:bg-accent active:bg-accent"
      {...props}
      type="button"
    >
      <div className="flex items-center gap-2 text-black">
        {t('upload_cover_letter')}
        <PaperclipIcon />
      </div>
    </Button>
  )
}

const DownloadCoverLetterButton = ({
  coverLetterName,
  coverLetterUrl,
  onClick,
  t,
}) => {
  return (
    <div className="flex flex-col items-start">
      <div className="flex items-center gap-4">
        <Button
          type="button"
          className="w-52 border border-solid border-gray-300 bg-white text-black hover:bg-accent focus:bg-accent active:bg-accent"
        >
          <a
            className="gap-2 !overflow-hidden truncate text-black"
            href={coverLetterUrl}
            target="_blank"
            rel="noreferrer"
          >
            {coverLetterName}
          </a>
          <DownloadIcon className="w-24" />
        </Button>
      </div>
      <Button
        type="button"
        onClick={onClick}
        variant="link"
        className="px-0 text-center"
      >
        {t('upload_different_cover_letter')}
      </Button>
    </div>
  )
}

const TextField = ({ control, label, name }) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Textarea className={'resize-none'} {...field} />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}

const FileField = ({ control, label, name, accept, className }) => {
  return (
    <FormField
      control={control}
      name={name}
      render={({ field: { value, onChange, ...fieldProps } }) => (
        <FormItem>
          <FormLabel>{label}</FormLabel>
          <FormControl>
            <Input
              {...fieldProps}
              type="file"
              accept={accept}
              name={name}
              onChange={(e) => onChange(e.target.files && e.target.files[0])}
              className={cn(
                'mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-base shadow-sm focus:border-accent focus:ring-accent sm:text-sm',
                className,
              )}
            />
          </FormControl>
          <FormMessage />
        </FormItem>
      )}
    />
  )
}
