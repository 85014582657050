import React, { useState } from 'react'
import { Button } from '~/lib/ui/button'
import {
  Dialog,
  DialogHeader,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from '~/lib/ui/dialog'
import { apiDelete } from '~/util/api'
import { TrashIcon } from '@heroicons/react/24/outline'
import translations from './locales.json'
import useTranslation, {
  TranslationProvider,
} from '~/components/shared/TranslationProvider'

const DeleteButton = ({ ...props }) => {
  return (
    <TranslationProvider
      translations={translations}
      scope="javascript.components.shared.delete_button"
    >
      <DeleteButtonInner {...props} />
    </TranslationProvider>
  )
}

const DeleteButtonInner = ({
  path,
  titleText,
  confirmationText,
  deleteText,
  cancelText,
  onConfirm = () => {
    window.location.reload()
  },
}) => {
  const [open, setOpen] = useState(false)
  const { t } = useTranslation()

  const toggleOpen = () => {
    setOpen(!open)
  }

  const handleConfirmed = async () => {
    try {
      await apiDelete(path)
    } catch (error) {
      console.error(error)
    }
    toggleOpen()
    onConfirm()
  }

  return (
    <Dialog open={open} onOpenChange={toggleOpen}>
      <DialogTrigger>
        <TrashIcon className="w-6 text-primary" />
      </DialogTrigger>
      <DialogContent
        className="max-w-md rounded-2xl font-roboto"
        variant="default"
        dialogCloseClass="text-black"
      >
        <DialogHeader>
          <DialogTitle className="text-left" asChild>
            <h2 className="text-2xl">{titleText || t('title')}</h2>
          </DialogTitle>
        </DialogHeader>
        <div className="mx-4 mb-4">
          <div className="mb-4">{confirmationText || t('confirmation')}</div>
          <div className="flex justify-end space-x-2">
            <Button onClick={handleConfirmed}>
              {deleteText || t('delete')}
            </Button>
            <Button onClick={toggleOpen}>{cancelText || t('cancel')}</Button>
          </div>
        </div>
      </DialogContent>
    </Dialog>
  )
}

export default DeleteButton
