import React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '../ExerciseProvider'
import { Textarea } from '../../../lib/ui/textarea'
import ExampleScripts from '../../Article/ExampleScripts'
import parse from 'html-react-parser'

const ReasonForLeavingSectionThree = () => {
  const {
    updateResponse,
    getResponse,
    formattedExerciseName,
    exerciseIndex,
    existingPersonalBrandStatement,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const examples = t('pages.2.examples')
  const tips = t('pages.2.tips')
  const questions = t('pages.2.questions')

  const handleChange = (section, slug, event) => {
    const response = event.target.value
    updateResponse(section, slug, response)
  }

  return (
    <div>
      <div className="questions">
        <ExampleScripts exampleScriptsObject={examples} />
        <p className="mt-4">{t('pages.2.additional_tips')}</p>
        {tips.map((tip, index) => (
          <div className="ml-8 flex flex-col gap-2" key={index}>
            <p>
              <span className="mr-2 font-bold">TIP:</span>
              {parse(tip)}
            </p>
          </div>
        ))}
        {questions.map((question, index) => (
          <div
            key={index}
            className="mb-6 w-full font-roboto text-base font-medium"
          >
            <div className="flex w-full flex-col">
              <label className="!mb-1">{question.question}</label>
              <Textarea
                className="min-h-[125px] w-full rounded-[20px]"
                onChange={(e) => handleChange(exerciseIndex, question.slug, e)}
                value={
                  getResponse(exerciseIndex, question.slug) ||
                  existingPersonalBrandStatement ||
                  ''
                }
              />
            </div>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ReasonForLeavingSectionThree
