import React from 'react'
import { useNetworkingBrief } from '../NetworkingBriefProvider'
import { EditorHeading } from '../../ResumeBuilder/Editor/common'
import { EditorItemTextField, EditorItemProvider } from './EditorItemProvider'

const ProfessionalIdentityEditor = () => {
  const { networkingBriefDocument: { professionalIdentity } = {} } =
    useNetworkingBrief()

  return (
    <EditorItemProvider
      sectionName="professionalIdentity"
      item={professionalIdentity}
    >
      <div>
        <EditorHeading>Professional identity</EditorHeading>
        <p>
          {' '}
          Personal brand statement. This should be a couple of sentences
          describing who you are and what value you bring to your roles.
        </p>
        <span className="font-bold">Example:</span>
        <p>
          &quot;Hi, I&apos;m Lee. I&apos;m a marketing specialist with a proven
          track record of driving customer engagement and increasing brand
          awareness. In my previous role at ABC Company, I spearheaded a social
          media campaign that boosted our followers by 40% and generated a 15%
          increase in leads. I&apos;m currently exploring new opportunities in
          the tech industry where I can leverage my skills to help innovative
          companies achieve their growth objectives.&quot;
        </p>
        <span className="font-bold">Example:</span>
        <p>
          &quot;Have you ever felt frustrated trying to find healthy meals on
          the go? I&apos;m Jayce, and I&apos;m the founder of Simply Savory, a
          meal delivery service that provides fresh, chef-prepared meals
          tailored to specific dietary needs. We use locally sourced ingredients
          and sustainable packaging. We&apos;re currently seeking funding to
          expand our operations and reach a wider audience.&quot;
        </p>
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          <EditorItemTextField
            containerClassName="md:col-span-2"
            fieldName="personalBrandStatement"
            label="Personal Brand Statement"
            areaClassName="h-[150px]"
          />
        </div>
      </div>
    </EditorItemProvider>
  )
}
export default ProfessionalIdentityEditor
