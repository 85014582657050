import * as React from 'react'

import { FollowupButton } from './FollowupButton'

export function FollowupCell({ row, table }) {
  return (
    <div className="flex w-[112px] flex-row items-center rounded-xl bg-transparent py-2 text-center">
      <FollowupButton
        rowIndex={row.index}
        savedJob={row.original}
        updateData={table.options.meta?.updateData}
      />
    </div>
  )
}
