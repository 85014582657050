import * as React from 'react'

import { useCompanySearch } from './CompanySearchProvider'
import { ScrollArea } from '../../../lib/ui/scroll-area'
import { useIsDesktop, useIsMobile } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { Separator } from '../../../lib/ui/separator'
import { Drawer, DrawerContent } from '../../../lib/ui/drawer'
import { Button } from '../../../lib/ui/button'
import CompanyCard from './CompanyCard'
import { CompanyDetailsBottom, CompanyDetailsTop } from './CompanyDetails'
import { SpinnerWhite } from '~/components/shared/Spinner'
import { DialogTitle } from '~/lib/ui/dialog'
import ArrowLongLeftIcon from '~/components/job_search/JobSearchPage/ArrowLongLeftIcon'
import { SearchResultDetails } from '~/components/shared/SearchResultDetails'
import NoResultsImage from './NoResultsImage'

export default function SearchResults() {
  const { t } = useTranslation('SearchResults')
  const viewPortProps = { tabIndex: 0, role: 'document' }

  const {
    selectedCompanyDrawerOpen,
    setSelectedCompanyDrawerOpen,
    resultDetailScrollRef,
    noResultQuery,
  } = useCompanySearch()
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()
  const scrollAreaRef = React.useRef(null)

  React.useEffect(() => {
    const handleResize = () => {
      if (scrollAreaRef.current) {
        const rect = scrollAreaRef.current.getBoundingClientRect()
        const viewHeight = Math.max(
          document.documentElement.clientHeight,
          window.innerHeight,
        )
        const scrollHeight = document.documentElement.scrollHeight
        const scrollTop = document.documentElement.scrollTop
        const remainingScroll = scrollHeight - scrollTop - viewHeight

        let dynamicHeight = viewHeight - rect.top - 16
        const footerHeight = 160

        if (remainingScroll < footerHeight) {
          dynamicHeight -= footerHeight - remainingScroll
        }

        scrollAreaRef.current.style.height = `${dynamicHeight}px`
      }
    }

    window.addEventListener('scroll', handleResize)
    window.addEventListener('resize', handleResize)
    handleResize()

    return () => {
      window.removeEventListener('scroll', handleResize)
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  if (noResultQuery) {
    return (
      <div className="flex w-full justify-center">
        <div className="flex flex-col items-center gap-4">
          <NoResultsImage />
          <div className="text-balance text-center text-2xl font-medium">
            {t('no_results')}
          </div>
        </div>
      </div>
    )
  }

  if (isDesktop) {
    return (
      <div className="flex w-full flex-row gap-2">
        <div className="min-h-[80vh] min-w-96 basis-2/5 pr-4">
          <CompanyCardList />
        </div>

        <div
          ref={scrollAreaRef}
          className="sticky top-32 flex max-h-[825px] basis-3/5 flex-col overflow-hidden rounded-2xl border-2"
        >
          <div className="shadow-bottom relative bg-primary p-4 shadow-md">
            <div className="flex flex-col gap-4">
              <CompanyDetailsTop />
            </div>
          </div>
          <ScrollArea
            viewPortProps={viewPortProps}
            className="mb-4 mr-1 mt-1 h-auto px-4"
            viewPortRef={resultDetailScrollRef}
          >
            <div className="py-4">
              <CompanyDetailsBottom />
            </div>
          </ScrollArea>
        </div>
      </div>
    )
  }
  return (
    <div className="flex w-full flex-col">
      <CompanyCardList />

      <Drawer
        direction="right"
        open={selectedCompanyDrawerOpen}
        onClose={setSelectedCompanyDrawerOpen}
      >
        <DrawerContent>
          <DialogTitle className="sr-only">details</DialogTitle>
          <ScrollArea className="h-screen" viewPortProps={viewPortProps}>
            <div className={`${isMobile ? 'mt-4' : 'mt-40'}`}>
              <Button
                type="button"
                variant="link"
                className="flex flex-row gap-2"
                onClick={() => setSelectedCompanyDrawerOpen(false)}
              >
                <ArrowLongLeftIcon />
                {t('return')}
              </Button>
            </div>
            <div className={`mb-16 ${!isMobile ? 'p-4' : ''} font-roboto`}>
              <SearchResultDetails>
                <CompanyDetailsTop />
                <CompanyDetailsBottom />
              </SearchResultDetails>
            </div>
          </ScrollArea>
        </DrawerContent>
      </Drawer>
      <Separator className="mt-16" />
    </div>
  )
}

function CompanyCardList() {
  const { companyResults, amountShown, amountTotal, showViewMoreButton } =
    useCompanySearch()

  const { t } = useTranslation('SearchResults')

  return (
    <>
      <ul className="flex flex-col gap-4">
        {companyResults.map((company, i) => (
          <CompanyCard key={i} company={company} />
        ))}
      </ul>
      <div className="my-4 flex flex-col gap-4">
        <div className="text-center text-xs">
          {t('displaying', {
            amountShown,
            amountTotal,
          })}
        </div>
        {showViewMoreButton && <ViewMoreButton t={t} />}
      </div>
    </>
  )
}

function ViewMoreButton({ t }) {
  const { handleViewMore, viewMoreButtonDisabled } = useCompanySearch()
  return (
    <Button
      className="w-full"
      onClick={handleViewMore}
      disabled={viewMoreButtonDisabled}
    >
      {t('view_more')}
      <SpinnerWhite show={viewMoreButtonDisabled} className="pl-4" />
    </Button>
  )
}
