import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import StartQuiz from '../StartQuiz'
import { TranslationProvider } from '../../shared/TranslationProvider'
import translations from '../locales.json'
import { QuizProvider, useQuiz } from '../QuizProvider'
import Section from '../LikertLayout/Section'
import SalaryNegotiatorResults from './SalaryNegotiatorResults'
import useLikertLayout, {
  LikertLayoutProvider,
} from '../LikertLayout/LikertLayoutProvider'

const SalaryNegotiator = ({ locale, ...props }) => {
  const sectionQuestions = {
    0: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
    1: [11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
    2: [22, 23, 24, 25, 26, 27, 28, 29, 30],
    3: [31, 32, 33, 34],
  }
  return (
    <Router>
      <TranslationProvider
        locale={locale || 'en'}
        translations={translations}
        scope={'javascript.components.Quizzes'}
      >
        <QuizProvider {...props}>
          <LikertLayoutProvider
            sectionQuestions={sectionQuestions}
            numQuestions={34}
            translationKey="salary_negotiator"
          >
            <SalaryNegotiatorInner {...props} />
          </LikertLayoutProvider>
        </QuizProvider>
      </TranslationProvider>
    </Router>
  )
}

export default SalaryNegotiator

const SalaryNegotiatorInner = () => {
  const { documentId, started, showResults, quizIndex, setQuizIndex } =
    useQuiz()
  const { hideOverview } = useLikertLayout()
  const handleContinueLogic = () => {
    hideOverview()
    if (quizIndex > 0) {
      setQuizIndex((prev) => prev + 1)
    }
  }

  const handleBackClickLogic = ({
    sectionIndex,
    setQuizIndex,
    setSectionIndex,
    hideOverview,
    setStarted,
    setShowResults,
  }) => {
    if (sectionIndex === 0) {
      setStarted(false)
      setShowResults(false)
    } else {
      setQuizIndex((prev) => prev - 1)
      setSectionIndex((prev) => prev - 1)
      hideOverview()
    }
  }
  if (started && !showResults) {
    return (
      <Section
        translationKey="salary_negotiator"
        handleContinueLogic={handleContinueLogic}
        handleBackClickLogic={handleBackClickLogic}
      />
    )
  }
  return (
    <div className="quiz flex justify-center px-4 py-6">
      {showResults && <SalaryNegotiatorResults />}
      {!started && !showResults && (
        <StartQuiz questionCount={34} quizExists={!!documentId} />
      )}
    </div>
  )
}
