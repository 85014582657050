import React, { useState } from 'react'
import Rating from '@mui/material/Rating'
import { api } from '../../../util/api'

export default function JobBoardRating({ jobBoardId, stars, ratingCount, t }) {
  const ratingCountText = () => t.rating_singular
  const [starRating, setStarRating] = useState(stars)
  const title = starRating > 0 ? starRating : ''
  const [subTitle, setSubTitle] = useState(ratingCountText(ratingCount))

  const handleChange = (_event, newStarRating) => {
    if (!newStarRating) return

    setStarRating(newStarRating)
    saveRatingToJobBoard(newStarRating)
  }

  const rateJobBoard = (newStarRating) => {
    return api.patch(`/job-search/boards/${jobBoardId}`, {
      stars: newStarRating,
    })
  }

  const saveRatingToJobBoard = async (newStarRating) => {
    await rateJobBoard(newStarRating)
    setSubTitle(t.thank_you)
  }

  const name = jobBoardId + '-rating'

  return (
    <div className="m-1 rounded-lg pb-2 has-[:focus-visible]:outline-none has-[:focus-visible]:ring-2 has-[:focus-visible]:ring-ring has-[:focus-visible]:ring-offset-2">
      <div className="text-center text-sm font-normal text-muted-foreground">
        {title}
      </div>
      <Rating
        value={Number(starRating)}
        onChange={handleChange}
        name={name}
        size="small"
      />
      <div className="text-center text-sm font-normal text-muted-foreground">
        {subTitle}
      </div>
    </div>
  )
}
