import React, { createContext, useContext, useEffect, useState } from 'react'
import { useExercise } from '../ExerciseProvider'

const ChartYourCourseContext = createContext()

export const useChartYourCourse = () => {
  return useContext(ChartYourCourseContext)
}

export const ChartYourCourseProvider = ({ children }) => {
  const {
    updateResponse,
    getResponse,
    setContinueButtonDisabled,
    sectionIdx,
    exerciseIndex,
  } = useExercise()
  const [selectedValues, setSelectedValues] = useState(
    getResponse(0, 'top_values') || [],
  )

  const [selectedCareers, setSelectedCareers] = useState(
    getResponse(1, 'top_careers') || [],
  )

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [exerciseIndex, sectionIdx])

  useEffect(() => {
    if (sectionIdx === 1 && exerciseIndex === 0) {
      setContinueButtonDisabled(selectedValues.length < 5)
    } else if (exerciseIndex === 1) {
      setContinueButtonDisabled(selectedCareers.length < 1)
    } else {
      setContinueButtonDisabled(false)
    }
  }, [selectedValues, selectedCareers, sectionIdx, exerciseIndex])

  const sluggifyCareer = (career) => {
    return career
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-')
      .replace(/(^-|-$)+/g, '')
  }

  const addSelectedValue = (value) => {
    const newSelectedVals = [...selectedValues, value]
    setSelectedValues(newSelectedVals)
    updateResponse(0, 'top_values', newSelectedVals)
  }

  const clearSelectedValues = () => {
    setSelectedValues([])
    setSelectedCareers([])
    updateResponse(0, 'top_values', [])
    updateResponse(1, 'top_careers', [])
  }

  const removeValue = (value) => {
    const newSelectedValues = selectedValues.filter((v) => v !== value)
    setSelectedValues(newSelectedValues)
    updateResponse(0, 'top_values', newSelectedValues)
  }

  const handleSelectValue = (value) => {
    if (selectedValues.includes(value)) {
      removeValue(value)
    } else {
      addSelectedValue(value)
    }
  }

  const clearSelectedCareers = () => {
    setSelectedCareers([])
    updateResponse(1, 'top_careers', [])
  }

  const addSelectedCareer = (career) => {
    const newSelectedCareers = [...selectedCareers, career]
    setSelectedCareers(newSelectedCareers)
    updateResponse(1, 'top_careers', newSelectedCareers)
  }

  const removeCareer = (career) => {
    const newSelectedCareers = selectedCareers.filter((v) => v !== career)
    setSelectedCareers(newSelectedCareers)
    updateResponse(1, 'top_careers', newSelectedCareers)
  }

  const handleSelectCareer = (career) => {
    if (selectedCareers.includes(career)) {
      removeCareer(career)
    } else {
      addSelectedCareer(career)
    }
  }

  const value = {
    selectedValues,
    handleSelectValue,
    selectedCareers,
    handleSelectCareer,
    sluggifyCareer,
    clearSelectedValues,
    clearSelectedCareers,
  }

  return (
    <ChartYourCourseContext.Provider value={value}>
      {children}
    </ChartYourCourseContext.Provider>
  )
}
