import React from 'react'
import {
  ArticlesIcon,
  VideoIcon,
  ExercisesIcon,
  QuizzesIcon,
  FavoriteIcon,
  ShortCourseIcon,
  ScriptsIcon,
  ChecklistIcon,
} from './icons'
import { ButtonContainer, TabButton } from '~/components/shared/TabNav'
import { useResources } from './ResourcesProvider'
import { useIsMobile } from '~/hooks/useMediaQuery'

const Wrapper = ({ children }) => {
  const isMobile = useIsMobile()
  if (isMobile) return children

  return (
    <div className="relative w-full overflow-x-scroll sm:static sm:overflow-auto">
      {children}
    </div>
  )
}

const TabNavButtons = () => {
  const { hasResources, hasFavorites } = useResources()
  const isMobile = useIsMobile()
  const titleClass = isMobile ? '' : 'text-sm mx-0 md:mx-0 lg:mx-0'

  return (
    <Wrapper>
      <ButtonContainer
        carouselWrapperClass="min-h-[4.5rem] mb-8"
        className="mb-6 flex flex-row justify-between gap-5 min-[530px]:justify-start min-[530px]:gap-10"
        buttonClass="min-w-16"
        titleClass={titleClass}
      >
        {hasResources('articles') && (
          <TabButton name="articles" text="Articles" Icon={ArticlesIcon} />
        )}
        {hasResources('audio') && (
          <TabButton name="audio" text="Videos &amp; Audio" Icon={VideoIcon} />
        )}
        {hasResources('quizzes') && (
          <TabButton name="quizzes" text="Quizzes" Icon={QuizzesIcon} />
        )}
        {hasResources('exercises') && (
          <TabButton name="exercises" text="Exercises" Icon={ExercisesIcon} />
        )}
        {hasResources('workshops') && (
          <TabButton
            name="short_courses"
            text="Short Courses"
            Icon={ShortCourseIcon}
          />
        )}
        {hasResources('scripts') && (
          <TabButton name="scripts" text="Tips & Scripts" Icon={ScriptsIcon} />
        )}
        {hasResources('checklists') && (
          <TabButton name="checklists" text="Checklists" Icon={ChecklistIcon} />
        )}
        {hasFavorites() && (
          <TabButton name="favorites" text="Bookmarks" Icon={FavoriteIcon} />
        )}
      </ButtonContainer>
    </Wrapper>
  )
}
export default TabNavButtons
