import React, { useState, useEffect } from 'react'
import { RadioGroup, RadioGroupItem } from '../../../lib/ui/radio-group'
import { InputField, SelectField, CheckboxField } from '../../shared/FormFields'
import { Form } from '../../../lib/ui/form'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '../../../lib/ui/button'
import AylaResponseScrollArea from '../../ShortCourses/AylaResponseScrollArea'
import { apiPost } from '../../../util/api'
import { useAiResponseStream } from '../useAiResponseStream'
import StarFeedback from '../../shared/StarFeedback'
import { TranslationProvider } from '../../shared/TranslationProvider'
import translations from '../locales.json'
import { useTranslation } from '../../../hooks/useTranslation'

const AylaCompanyResearch = (props) => {
  const [lookingFor, setLookingFor] = useState('')
  const [lastLookingFor, setLastLookingFor] = useState('')
  const [industry, setIndustry] = useState('')
  const [location, setLocation] = useState('')
  const [companySizes, setCompanySizes] = useState([])
  const [company, setCompany] = useState('')
  const [lastCompany, setLastCompany] = useState('')

  const [submitted, setSubmitted] = useState(false)
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const showCursor = submitted && displayedText.length <= 0
  const { t } = useTranslation()
  const {
    aiResponse,
    setAiResponse,
    researchId,
    setResearchId,
    aiStreamComplete,
    setAiStreamComplete,
  } = useAiResponseStream()
  const form = useForm({
    resolver: zodResolver(),
  })

  useEffect(() => {
    if (
      typeof window.initTypeahead === 'function' &&
      typeof Bloodhound === 'function'
    ) {
      window.initTypeahead()
    }
  }, [lookingFor])

  const handleSubmitButtonClick = async () => {
    setSubmitted(true)
    setAiResponse('')
    setDisplayedText('')
    setIndex(0)
    setAiStreamComplete(false)
    setLastLookingFor(lookingFor)
    setLastCompany(company)

    const {
      data: { company_research_id },
    } = await apiPost('/research_learning/ayla/create_company_research', {
      ai_company_research: {
        looking_for: lookingFor || '',
        industry: industry || '',
        company: company || '',
        location: location || '',
        company_sizes: companySizes.join(', ') || '',
      },
    })
    setResearchId(company_research_id)
  }

  const transformAylaResponse = (response) => {
    const urlRegex = /((https?:\/\/|www\.)[^\s]+)/g
    return response.replace(urlRegex, (url) => {
      const formattedUrl = url.startsWith('http') ? url : `https://${url}`
      return `<a href="${formattedUrl}" target="_blank" rel="noopener noreferrer">${formattedUrl}</a>`
    })
  }

  return (
    <div className="m-4 flex flex-col items-baseline gap-2 md:m-8">
      <h1>{t('title')}</h1>
      <p className="w-3/4">{t('description')}</p>
      <p>{t('tell_ayla')}</p>
      <RadioGroup
        className="mt-2"
        onValueChange={(value) => setLookingFor(value)}
      >
        <label className="mb-2 !flex items-center">
          <RadioGroupItem value="list" className="mr-2" />
          {t('list_of_companies')}
        </label>
        <label className="mb-2 !flex items-center">
          <RadioGroupItem value="info" className="mr-2" />
          {t('more_information')}
        </label>
      </RadioGroup>
      <Form {...form}>
        {lookingFor === 'list' && (
          <ListComponent
            form={form}
            props={props}
            setIndustry={setIndustry}
            setLocation={setLocation}
            companySizes={companySizes}
            setCompanySizes={setCompanySizes}
          />
        )}
        {lookingFor === 'info' && (
          <InfoComponent
            form={form}
            props={props}
            company={company}
            setCompany={setCompany}
            location={location}
            setLocation={setLocation}
          />
        )}
      </Form>
      <div className="my-4">
        {lookingFor && (
          <Button
            disabled={submitted && !aiStreamComplete}
            onClick={() => handleSubmitButtonClick()}
          >
            {t('lets_go_ayla')}
          </Button>
        )}
      </div>
      {submitted && (
        <div className="subtitle">
          {lastLookingFor == 'list' && t('heres_list')}
          {lastLookingFor == 'info' &&
            t('heres_found', { company: lastCompany })}
        </div>
      )}
      {submitted && (
        <AylaResponseScrollArea
          showBorder={submitted}
          showCursor={showCursor}
          aylaResponse={aiResponse}
          typingComplete={aiStreamComplete}
          setTypingComplete={setAiStreamComplete}
          displayedText={
            aiStreamComplete
              ? transformAylaResponse(displayedText).replace(/\n/g, '<br>')
              : displayedText.replace(/\n/g, '<br>')
          }
          setDisplayedText={setDisplayedText}
          index={index}
          setIndex={setIndex}
          className="mb-4 h-64"
        ></AylaResponseScrollArea>
      )}
      {submitted && aiStreamComplete && (
        <div className="flex flex-col gap-4">
          <a
            href={`company-research.pdf?id=${researchId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>
              {lookingFor == 'list' ? t('download_list') : t('download_report')}
            </Button>
          </a>
          <div>{t('rate_response')}</div>
          <StarFeedback
            path="/research_learning/ayla/feedback?feedback_type=Feedback::AylaCompanyResearch"
            documentId={researchId}
          />
        </div>
      )}
    </div>
  )
}

const ListComponent = ({
  form,
  props,
  companySizes,
  setCompanySizes,
  setIndustry,
  setLocation,
}) => {
  const { industries } = props
  const { t } = useTranslation()
  const toggleSmallBusinessChecked = (checked) => {
    updateCompanySizes(
      'Small businesses with fewer than 500 employees',
      checked,
    )
  }

  const toggleMediumOrganizationsChecked = (checked) => {
    updateCompanySizes(
      'Medium sized organizations with 500-2500 employees',
      checked,
    )
  }

  const toggleLargeBusinessesChecked = (checked) => {
    updateCompanySizes(
      'Large businesses with more than 2500 employees',
      checked,
    )
  }

  const toggleGlobalEnterprisesChecked = (checked) => {
    updateCompanySizes('Global enterprise organizations', checked)
  }

  const updateCompanySizes = (size, checked) => {
    if (checked) {
      setCompanySizes([...companySizes, size])
    } else {
      setCompanySizes(companySizes.filter((s) => s !== size))
    }
  }

  return (
    <div className="flex flex-col items-start gap-4">
      <SelectField
        name="industry"
        control={form.control}
        label={t('what_industry')}
        choices={industries.map((industry) => ({
          value: industry,
          label: industry,
        }))}
        onChange={(value) => setIndustry(value)}
      />
      <InputField
        name="location"
        control={form.control}
        label={t('what_location')}
        onChange={(e) => setLocation(e.target.value)}
        inputClassName="locations-typeahead"
        data-source="locations"
      />
      <label>
        What size companies are you targeting? Select all relevant categories.
      </label>
      <CheckboxField
        className="flex items-center gap-2"
        name="small_businesses"
        label={t('small_businesses')}
        control={form.control}
        onChange={toggleSmallBusinessChecked}
      />
      <CheckboxField
        className="flex items-center gap-2"
        name="medium_organizations"
        label={t('medium_organizations')}
        control={form.control}
        onChange={toggleMediumOrganizationsChecked}
      />
      <CheckboxField
        className="flex items-center gap-2"
        name="large_businesses"
        label={t('large_businesses')}
        control={form.control}
        onChange={toggleLargeBusinessesChecked}
      />
      <CheckboxField
        className="flex items-center gap-2"
        name="global_enterprises"
        label={t('global_enterprises')}
        control={form.control}
        onChange={toggleGlobalEnterprisesChecked}
      />
    </div>
  )
}

const InfoComponent = ({
  form,
  props,
  company,
  setCompany,
  location,
  setLocation,
}) => {
  const { savedCompanies } = props
  const { t } = useTranslation()
  const setCompanyInputField = (value) => {
    const selectedCompany = savedCompanies.find(
      (company) => company.name === value,
    )
    if (selectedCompany) {
      setCompany(selectedCompany.name)
      setLocation(selectedCompany.location)
    }
  }

  return (
    <div className="flex flex-col items-start gap-4">
      <InputField
        name="company_name"
        control={form.control}
        onChange={(e) => setCompany(e.target.value)}
        value={company}
        label={t('what_company')}
      />
      <InputField
        name="location"
        control={form.control}
        inputClassName="locations-typeahead"
        onChange={(e) => setLocation(e.target.value)}
        value={location}
        data-source="locations"
        label={t('where_located')}
      />
      {savedCompanies.length > 0 && (
        <>
          <div className="subtitle">{t('or')}</div>
          <SelectField
            name="company_name"
            control={form.control}
            label={t('select_company')}
            choices={savedCompanies.map((company) => ({
              value: company.name,
              label: company.name,
            }))}
            onChange={(value) => setCompanyInputField(value)}
          />
        </>
      )}
    </div>
  )
}

const Index = (props) => {
  const { locale } = props
  return (
    <TranslationProvider
      translations={translations}
      locale={locale}
      scope="javascript.components.ResearchLearning.AylaCompanyResearch"
    >
      <AylaCompanyResearch {...props} />
    </TranslationProvider>
  )
}

export default Index
