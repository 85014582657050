import React, { useEffect, useState } from 'react'
import Results, { LinkifiedCopy } from './Results'
import { useTranslation } from '../../../hooks/useTranslation'
import { useQuiz } from '../QuizProvider'
import { useMultipleChoiceQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import Avatar from '../Avatar'
import { api } from '../../../util/api'
import CourseTile from '../CourseTile'

const CriticalThinkerResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent } = useQuiz()
  const isDesktop = useIsDesktop()

  const partOneKeys = [1, 2, 3, 4, 5]
  const partTwoKeys = [6, 7, 8, 9, 10]

  const filterContent = (filterCriteria) => {
    const filtered = {}
    filterCriteria.forEach((key) => {
      filtered[key] = trackContent[key]
    })
    return filtered
  }

  const partOneContent = filterContent(partOneKeys)
  const partTwoContent = filterContent(partTwoKeys)

  const { t } = useTranslation(formattedQuizName)

  const breakPoints = [3, 2]

  const [scoreSection, score] = useMultipleChoiceQuizScore(
    partTwoContent,
    t('questions'),
    breakPoints,
    'percent',
  )

  return (
    <Results className={score < 60 && `h-[calc(100vh+3rem)]`}>
      <div className="flex pb-6 sm:justify-around">
        <div className="mt-1 flex h-96 flex-col gap-4 sm:w-9/12">
          <PartOneResults
            formattedQuizName={formattedQuizName}
            content={partOneContent}
          />
          <PartTwoResults
            scoreSection={scoreSection}
            score={score}
            formattedQuizName={formattedQuizName}
          />
        </div>
        {isDesktop && <Avatar src={resultsPageAvatar} />}
      </div>
    </Results>
  )
}

export default CriticalThinkerResults

const PartOneResults = ({ formattedQuizName, content }) => {
  const { t } = useTranslation(formattedQuizName)

  const breakPoints = [4, 2]

  const [scoreSection, score] = useMultipleChoiceQuizScore(
    content,
    t('questions'),
    breakPoints,
    'raw',
  )

  const header = t(`results.part_1.${scoreSection}.header`)
  const copy = t(`results.part_1.${scoreSection}.copy`, { score })

  return (
    <div>
      <h1>{t(`results.part_1.title`)}</h1>
      <h2>{t(`results.part_1.score`, { score })}</h2>
      {header.length > 0 && <h3>{header}</h3>}
      <div className="mt-4">
        {copy.map((copy, idx) => (
          <p key={`copy-${idx}`}>{copy}</p>
        ))}
      </div>
    </div>
  )
}

const PartTwoResults = ({ scoreSection, score, formattedQuizName }) => {
  const { t } = useTranslation(formattedQuizName)

  const header = t(`results.part_2.${scoreSection}.header`)
  const copy = t(`results.part_2.${scoreSection}.copy`, { score })

  const link = {
    linkPath: '/orientation/learn',
    linkText: 'Online Learning',
  }

  return (
    <div>
      <h1>{t(`results.part_2.title`)}</h1>
      <h2>{t(`results.part_2.score`, { score })}</h2>
      {header.length > 0 && <h3>{header}</h3>}
      <div className="mt-4">
        {copy.map((copy, idx) => (
          <LinkifiedCopy key={`copy-${idx}`} copy={copy} link={link} />
        ))}
      </div>
      {score < 60 && <LearningLinks t={t} />}
    </div>
  )
}

const LearningLinks = ({ t }) => {
  const getCourses = async () => {
    const response = await api.get(
      `/orientation/learn/search?query=critical thinking`,
    )
    if (response?.data?.results) {
      setCourses(JSON.parse(response.data.results))
      return response
    }
  }
  const [courses, setCourses] = useState()

  useEffect(() => {
    getCourses()
  }, [])

  const rejectTeachingCourses = (courses) => {
    const filteredCourses = courses.filter(
      (course) => !course.name.includes('Teaching'),
    )
    if (filteredCourses.length < 3) {
      return courses
    }
    return filteredCourses
  }
  const topThreeCourses = courses && rejectTeachingCourses(courses).slice(1, 4)
  return (
    <div className="grid grid-cols-3">
      {topThreeCourses &&
        topThreeCourses.map((course, idx) => (
          <CourseTile key={`course-tile-${idx}`} course={course} t={t} />
        ))}
    </div>
  )
}
