import React, { useState, useEffect } from 'react'
import parse from 'html-react-parser'
import ProgressContainer from '../../ActionPlan/ProgressContainer'
import { useTranslation } from '~/hooks/useTranslation'
import { getApi } from '~/util/api'
import { Button, Card, Typography } from '~/components/_atoms'
import { Stack } from '~/components/_layouts'
import Spinner from '~/components/shared/Spinner'
import { useActionPlan } from '../../ActionPlan/ActionPlanProvider'

export function ActionPlanCard({ className }) {
  const [isLoading, setIsLoading] = useState(false)
  const [timePeriod, setTimePeriod] = useState('')
  const [trackerStepName, setTrackerStepName] = useState('')
  const [stepUrl, setStepUrl] = useState('')
  const [progressPercent, setProgressPercent] = useState(0)
  const { goal } = useActionPlan()
  const { t } = useTranslation()

  const coachChatSubstitution = (text, timePeriod) => {
    const coachChatSplit = text.split('@COACH_CHAT_LINK@')

    if (coachChatSplit.length > 1) {
      const linkText = t(`action_plan_card.${timePeriod}.coach_chat_text`)
      return (
        coachChatSplit[0] +
        `<a class="open-chat-modal" data-toggle="modal" data-target="#coach_chat_list" data-action="Start a Coach Chat">${linkText}</a>` +
        coachChatSplit[1]
      )
    } else {
      return text
    }
  }

  const NextActionText = (timePeriod = 'day_1', nextActionText, url) => {
    const nextActionLink = `<a href="${url}">${nextActionText}</a>`
    const translationKey = `action_plan_card.${timePeriod}.next_step`
    let translationString = t(translationKey, { next_action: nextActionLink })
    translationString = coachChatSubstitution(translationString, timePeriod)

    const feedbackSplit = translationString.split('@FEEDBACK_LINK@')
    if (timePeriod === '') {
      return ''
    }

    return feedbackSplit.length > 1 ? (
      <>
        {parse(feedbackSplit[0])}
        {FeedbackLink()}
        {parse(feedbackSplit[1])}
      </>
    ) : (
      parse(translationString)
    )
  }

  const TimePeriodText = (timePeriod = 'day_1') => {
    let translationString = t(`action_plan_card.${timePeriod}.current_status`)
    translationString = coachChatSubstitution(translationString, timePeriod)

    const feedbackSplit = translationString.split('@FEEDBACK_LINK@')
    return feedbackSplit.length > 1 ? (
      <>
        {parse(feedbackSplit[0])}
        {FeedbackLink()}
        {parse(feedbackSplit[1])}
      </>
    ) : (
      parse(translationString)
    )
  }

  const FeedbackLink = () => {
    const popupFeedback = () => {
      window.candidateFeedback()
    }

    return (
      <a onClick={popupFeedback} className="cursor-pointer">
        Feedback
      </a>
    )
  }

  useEffect(() => {
    setIsLoading(true)
    getApi()
      .get('/tracker/current_step')
      .then((response) => {
        if (response.status == 200) {
          const {
            data: { time_period, name, progress, url },
          } = response
          setTimePeriod(time_period)
          setTrackerStepName(name)
          setStepUrl(url)
          setProgressPercent(progress)
          return
        } else {
          throw new Error('json request failed')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('json request failed', error)
      })
  }, [goal, setTimePeriod, setTrackerStepName, setStepUrl])

  return (
    <Card
      className={className}
      color="primary"
      title={t('action_plan_card.title')}
      actions={
        <Button
          href="orientation/action_plan"
          fullWidth
          className="ga-trackable @lg:tw-hidden"
        >
          {t('action_plan_card.cta_text')}
        </Button>
      }
    >
      <div className="grid grid-cols-1 gap-3 @sm:grid-cols-[1fr_auto]">
        <Stack spacing={2}>
          {isLoading ? (
            <div> </div>
          ) : (
            <Typography>{TimePeriodText(timePeriod)}</Typography>
          )}
          {isLoading ? (
            <Spinner show size={9} />
          ) : (
            <>
              <Typography>
                {NextActionText(timePeriod, trackerStepName, stepUrl)}
              </Typography>
            </>
          )}
          <div className="tw-hidden @lg:block @lg:pt-4">
            <Button href="orientation/action_plan" className="ga-trackable">
              {t('action_plan_card.cta_text')}
            </Button>
          </div>
        </Stack>
        <ProgressContainer
          progressPercent={progressPercent}
          className="w-24 justify-self-center @sm:w-32"
        />
      </div>
    </Card>
  )
}
