import React, { useState } from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import { useExercise } from './ExerciseProvider'
import { AylaButton } from './Buttons'
import { Textarea } from '../../lib/ui/textarea'
import AylaResponseScrollArea from '../ShortCourses/AylaResponseScrollArea'

const ReasonForLeavingSectionTwo = () => {
  const {
    updateResponse,
    getResponse,
    formattedExerciseName,
    exerciseIndex,
    requestAylaStatement,
    saveExercise,
    embedExercise,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const craftYourResponse = t('pages.1.craft_your_response')
  const askAyla = t('pages.1.ask_ayla')
  const aylaInstructions = t('pages.1.ayla_instructions')

  const [submitted, setSubmitted] = useState(false)
  const [aylaResponse, setAylaResponse] = useState('')
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const [typingComplete, setTypingComplete] = useState(false)
  const [statementType, setStatementType] = useState('')
  const showCursor = submitted && displayedText.length <= 0

  const handleChange = (section, slug, event) => {
    const response = event.target.value
    updateResponse(section, slug, response)
  }

  const handleAylaStatement = async (statementType) => {
    setSubmitted(true)
    setAylaResponse('')
    setDisplayedText('')
    setIndex(0)
    setTypingComplete(false)
    try {
      if (embedExercise) {
        await saveExercise()
      }
      await getAylaData(statementType)
    } catch (error) {
      console.error('Error saving exercise:', error)
    }
  }

  const getAylaData = async (statementType) => {
    try {
      const data = await requestAylaStatement(statementType)
      setAylaResponse(data.response)
      setStatementType(statementType)
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const handleUseThisStatement = () => {
    updateResponse(exerciseIndex, 'user_crafted_statement', aylaResponse)
  }

  return (
    <>
      <div>
        <div className="subtitle mb-4">{craftYourResponse}</div>
        <Textarea
          className="min-h-[175px] w-full rounded-[20px]"
          onChange={(e) =>
            handleChange(exerciseIndex, 'user_crafted_statement', e)
          }
          value={getResponse(exerciseIndex, 'user_crafted_statement') || ''}
        />
      </div>
      <div className="mt-8">
        <div className="subtitle mb-4">{askAyla}</div>
        <p className="mb-4">{aylaInstructions}</p>
        <div className="flex gap-12">
          <AylaButton onClick={() => handleAylaStatement('LinkedIn')}>
            LinkedIn
          </AylaButton>
          <AylaButton
            onClick={() => handleAylaStatement('Professional Connections')}
          >
            Professional Connections
          </AylaButton>
        </div>
        {submitted && (
          <div className="subtitle my-4">
            {statementType === 'LinkedIn'
              ? "Ayla's Response For LinkedIn Network:"
              : "Ayla's Response for Professional Connections:"}
          </div>
        )}
        {submitted && (
          <AylaResponseScrollArea
            showBorder={submitted}
            showCursor={showCursor}
            aylaResponse={aylaResponse}
            typingComplete={typingComplete}
            setTypingComplete={setTypingComplete}
            displayedText={displayedText}
            setDisplayedText={setDisplayedText}
            index={index}
            setIndex={setIndex}
            className="mb-4 h-48"
          ></AylaResponseScrollArea>
        )}
        {typingComplete && (
          <AylaButton onClick={handleUseThisStatement}>
            Use This Statement
          </AylaButton>
        )}
      </div>
    </>
  )
}

export default ReasonForLeavingSectionTwo
