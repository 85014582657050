import React from 'react'
import { cn } from '../../lib/utils'
import { NavProvider, useNav } from './NavProvider'
import { UserIcon, ChevronRightIcon } from '@heroicons/react/24/outline'
import NavGroup from './NavGroup'
import GoalTile from '../ActionPlan/GoalTile'
import {
  ActionPlanProvider,
  useActionPlan,
} from '../ActionPlan/ActionPlanProvider'
import { Provider } from 'react-redux'
import { store } from '../../lib/store'
import { setGoal, setTimeline } from '../ActionPlan/ActionPlanSlice'

const LeftNav = ({ ...props }) => {
  return (
    <NavProvider {...props}>
      <Provider store={store}>
        <ActionPlanProvider {...props}>
          <LeftNavInner />
        </ActionPlanProvider>
      </Provider>
    </NavProvider>
  )
}
export default LeftNav

const LeftNavInner = () => {
  const { userName, userGoal, userTimeline, navItems } = useNav()
  store.dispatch(setGoal(userGoal))
  store.dispatch(setTimeline(userTimeline))
  return (
    <VerticalNav>
      <div className="grid h-full min-h-0 grid-rows-[auto_auto_1fr] [&_.nav-divider:first-child]:mt-0">
        <UsernameHeader>{userName}</UsernameHeader>
        <GoalHeader></GoalHeader>
        <div className="flex h-full min-h-0 flex-col gap-y-2 overflow-y-auto">
          {navItems.map((section, sectionIndex) => (
            <NavGroup key={sectionIndex} section={section}></NavGroup>
          ))}
        </div>
      </div>
    </VerticalNav>
  )
}

const GoalHeader = () => {
  const { isExpanded, showGoal } = useNav()
  const { goal, setTimeline } = useActionPlan()
  if (isExpanded && showGoal) {
    return (
      <div className="mb-5 px-4 pt-6">
        <GoalTile variant="goal" goal={goal} setTimeline={setTimeline} />
      </div>
    )
  }
}

const UsernameHeader = ({ children }) => {
  const { isExpanded, navigateTo, showUser } = useNav()
  const onClick = () => {
    navigateTo({ path: '/users/edit' })
  }
  return showUser ? (
    <a
      role="link"
      title="profile"
      onClick={onClick}
      className="cursor-pointer hover:text-cod-gray-800"
      tabIndex="0"
    >
      <div className="mb-6 flex h-11 w-full flex-row items-center justify-center">
        <div className="m-3 h-6 w-6 text-cod-gray-800">
          <UserIcon />
        </div>
        {isExpanded && (
          <div className="ml-3 flex-grow text-sm font-medium text-cod-gray-800">
            {children}
          </div>
        )}
      </div>
    </a>
  ) : (
    <div />
  )
}

const ToggleExpanded = () => {
  const { toggleExpanded, isExpanded, isMobile } = useNav()
  const title = isExpanded ? 'Collapse menu' : 'Expand menu'
  const defaultToggleClasses = cn(
    'absolute -right-4 top-8 rounded border border-solid border-[#F6F6F6] bg-white p-1.5 cursor-pointer z-10',
    isMobile ? 'hidden' : 'block',
  )

  const toggleClasses = isExpanded
    ? defaultToggleClasses
    : cn(defaultToggleClasses, 'top-6')

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      toggleExpanded()
    }
  }
  return (
    <a
      onClick={toggleExpanded}
      onKeyDown={handleKeyDown}
      className={toggleClasses}
      aria-checked={isExpanded}
      role="switch"
      title={title}
      tabIndex="0"
    >
      <ChevronRightIcon
        role="img"
        className={cn(
          'h-r w-4 transition-all duration-500',
          isExpanded && 'rotate-180',
        )}
      />
    </a>
  )
}

const VerticalNav = ({ children }) => {
  const { isExpanded } = useNav()
  return (
    <nav
      className={cn(
        'relative h-[calc(100vh-80px)] border-b border-r border-[#0000001a] pb-6 font-roboto subpixel-antialiased transition-all duration-300',
        isExpanded ? 'w-full sm:w-64' : 'w-24',
      )}
    >
      <ToggleExpanded />
      {children}
    </nav>
  )
}
