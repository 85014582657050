import React, { useState } from 'react'
import { Button } from '../../lib/ui/button'
import { Toaster } from '../../lib/ui/toast'
import {
  ArrowUpTrayIcon,
  SparklesIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import { MagicWand } from 'iconoir-react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../lib/ui/dialog'
import DropzoneCoverLetterUpload from './DropzoneCoverLetterUpload'

const NewCoverLetterOptions = ({ uploadCoverLetterClick }) => {
  return (
    <div className="px-4 pb-4">
      <div className="mb-4 text-lg font-medium">Choose your approach</div>
      <div className="pb-4">
        You can create a Cover Letter using any of the options below.
      </div>
      <div className="pb-4">
        <a
          href="/personal_brand/cover_letters/ai"
          className="flex h-28 w-full flex-row rounded-2.5xl bg-willow-brook text-primary"
        >
          <div className="flex items-center justify-center p-6">
            <MagicWand width="2.5rem" height="2.5rem" />
          </div>
          <div className="flex flex-col items-start justify-evenly">
            <div className="font-roboto-slab text-xl font-medium">
              Custom Cover Letter with Ayla
            </div>
            <div>
              Create a custom cover letter using our AI-powered creator tool,
              Ayla.
            </div>
          </div>
        </a>
      </div>
      <div className="pb-4">
        <a
          href="/personal_brand/cover_letter_builder/new"
          className="flex h-28 w-full flex-row rounded-2.5xl bg-lily-blue text-primary"
        >
          <div className="flex items-center justify-center p-6">
            <PlusIcon className="w-10" />
          </div>
          <div className="flex flex-col items-start justify-evenly">
            <div className="font-roboto-slab text-xl font-medium">
              Create General Cover Letter
            </div>
            <div>Choose a blank template and fill in the fields yourself.</div>
          </div>
        </a>
      </div>
      <div className="pb-4">
        <button
          onClick={uploadCoverLetterClick}
          className="flex h-28 w-full flex-row rounded-2.5xl bg-lily-blue text-primary"
        >
          <div className="flex items-center justify-center p-6">
            <ArrowUpTrayIcon className="w-10" />
          </div>
          <div className="flex flex-col items-start justify-evenly">
            <div className="font-roboto-slab text-xl font-medium">
              Upload Your Cover Letter
            </div>
            <div>
              Upload your existing cover letter in DOC, DOCX, or PDF formats.
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

const NewCoverLetterModal = ({
  buttonVariant,
  buttonText,
  buttonIcon,
  buttonClass,
}) => {
  const [open, setOpen] = useState(false)
  const [uploadCoverLetterMode, setUploadCoverLetterMode] = useState(false)

  const uploadCoverLetterClick = () => {
    setUploadCoverLetterMode(true)
  }

  const toggleOpen = () => {
    setOpen(!open)
    setUploadCoverLetterMode(false)
  }

  return (
    <Dialog
      open={open}
      onOpenChange={toggleOpen}
      className="rounded-2xl lg:max-w-[37rem] xl:max-w-[37rem]"
    >
      <DialogTrigger asChild className="cursor-pointer">
        <Button variant={buttonVariant} className={buttonClass}>
          {buttonText || 'Create a New Cover Letter'}{' '}
          {buttonIcon &&
            React.cloneElement(buttonIcon, {
              className: 'size-5 w-6 inline pl-1',
            })}
        </Button>
      </DialogTrigger>
      <DialogContent
        className="fix-legacy-hack font-roboto lg:max-w-3xl xl:max-w-5xl"
        variant="default"
        dialogCloseClass="text-black"
      >
        <DialogHeader>
          <DialogTitle className="text-left" asChild>
            <h2 className="text-2xl">
              Create Your Cover Letter
              <SparklesIcon className="ml-2 inline w-6" />
            </h2>
          </DialogTitle>
        </DialogHeader>
        {!uploadCoverLetterMode && (
          <NewCoverLetterOptions
            uploadCoverLetterClick={uploadCoverLetterClick}
          />
        )}
        {uploadCoverLetterMode && <DropzoneCoverLetterUpload />}
      </DialogContent>
      <Toaster />
    </Dialog>
  )
}
export default NewCoverLetterModal
