import React from 'react'
import { useCoverLetter } from '../CoverLetterProvider'
import {
  JsonItemProvider,
  JsonItemField,
  useJsonItem,
  JsonTextArea,
} from '../../../lib/json-editor'

export const EditorItemField = ({ ...props }) => {
  return <JsonItemField {...props} />
}

export const EditorItemTextField = ({ ...props }) => {
  return <JsonTextArea {...props} />
}

export const useEditorItem = useJsonItem

export const EditorItemProvider = ({ ...props }) => {
  const { coverLetterDocument, setCoverLetterDocument } = useCoverLetter()

  return (
    <JsonItemProvider
      jsonDocument={coverLetterDocument}
      setJsonDocument={setCoverLetterDocument}
      {...props}
    />
  )
}
