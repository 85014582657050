import * as React from 'react'

import translations from '../locales.json'
import { BrowserRouter, useSearchParams } from 'react-router-dom'
import { TranslationProvider } from '../../shared/TranslationProvider'
import { CoachHeader } from '../CoachHeader'
import {
  TabNav,
  TabPanel,
  PanelContainer,
  ButtonContainer,
  TabButton,
} from '~/components/shared/TabNav'
import {
  DocMagnifyingGlass,
  Golf,
  ChatBubble,
  Clock,
  LightBulbOn,
  Phone,
} from 'iconoir-react'
import { LinkedInIcon } from '../LinkedInIcon'
import { Feedback } from './Feedback'
import { useMediaQueryScreen } from '../../../hooks/useMediaQuery'
import { ChatHistory } from './ChatHistory'

function CoachFeedback({ feedback }) {
  const [searchParams, _setSearchParams] = useSearchParams()
  const defaultTab = searchParams.get('tab') || 'orientation'
  const { isFlex } = feedback
  const fullLabels = useMediaQueryScreen('xl')

  const TextResume = fullLabels ? 'Resume Review' : 'Resume Review'
  const TextSocial = fullLabels ? 'Social Media Review' : 'Social Review'
  const TextTarget = fullLabels ? 'Target Company Research' : 'Target Research'
  const TextInterview = fullLabels ? 'Interview Review' : 'Interview Review'
  const TextChatHistory = fullLabels ? 'Chat History' : 'Chat History'
  const TextOrientation = fullLabels ? 'Orientation Call Review' : 'Orientation'
  const TextCareer = fullLabels
    ? 'Career Consultation Call Review'
    : 'Career Call'

  return (
    <div className="mx-2 my-2 flex flex-col gap-10 font-roboto md:my-6 lg:mx-6">
      <div className="flex flex-col gap-6">
        <CoachHeader
          title="Your Coach Feedback"
          description="Your coach's feedback will be shown below"
        />
        <TabNav defaultActiveTab={defaultTab}>
          <ButtonContainer
            className="overflow-x-scroll"
            buttonClass="md:min-w-[65px] lg:min-w-[105px]"
            titleClass="md:text-xs lg:text-sm"
            iconClass="text-intoo-green-coachcta md:w-4 lg:w-6"
          >
            <TabButton name="orientation" text={TextOrientation} Icon={Phone} />
            <TabButton name="career" text={TextCareer} Icon={LightBulbOn} />
            <TabButton
              name="resume"
              text={TextResume}
              Icon={DocMagnifyingGlass}
            />
            {!isFlex && (
              <>
                <TabButton
                  name="social"
                  text={TextSocial}
                  Icon={LinkedInIcon}
                />
                <TabButton name="target" text={TextTarget} Icon={Golf} />
                <TabButton
                  name="interview"
                  text={TextInterview}
                  Icon={ChatBubble}
                />
                <TabButton name="history" text={TextChatHistory} Icon={Clock} />
              </>
            )}
          </ButtonContainer>
          <PanelContainer>
            <TabPanel name="orientation">
              <Feedback
                title="Orientation Call Review"
                feedback={feedback}
                current="orientation"
              />
            </TabPanel>
            <TabPanel name="career">
              <Feedback
                title="Career Consultation Review"
                feedback={feedback}
                current="career"
              />
            </TabPanel>
            <TabPanel name="resume">
              <Feedback
                title="Resume Review"
                feedback={feedback}
                current="resume"
              />
            </TabPanel>
            <TabPanel name="social">
              <Feedback
                title="Social Media Review"
                feedback={feedback}
                current="social"
              />
            </TabPanel>
            <TabPanel name="target">
              <Feedback
                title="Target Company Research"
                feedback={null}
                current="target"
              />
            </TabPanel>
            <TabPanel name="interview">
              <Feedback
                title="Interview Review"
                feedback={null}
                current="interview"
              />
            </TabPanel>
            <TabPanel name="history">
              <ChatHistory title="Chat History" feedback={feedback} />
            </TabPanel>
          </PanelContainer>
        </TabNav>
      </div>
    </div>
  )
}

export default function CoachFeedbackWithProviders({ locale, ...props }) {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.coach_services.CoachFeedback"
    >
      <BrowserRouter>
        <CoachFeedback {...props} />
      </BrowserRouter>
    </TranslationProvider>
  )
}
