import React from 'react'
import Results from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useMultipleChoiceQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'
import parse from 'html-react-parser'
import { useTranslation } from '../../../hooks/useTranslation'

const PortfolioCareerAptitudeResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent } = useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()

  const { t } = useTranslation(formattedQuizName)

  const breakPoints = [16, 8]

  const [scoreSection, score] = useMultipleChoiceQuizScore(
    trackContent,
    t('questions'),
    breakPoints,
    'raw',
  )
  const copy = t(`results.${scoreSection}`, { score })

  return (
    <Results>
      <div className="flex pb-6 sm:justify-around">
        <div className="mt-2 flex flex-col gap-4 sm:w-9/12">
          <h2>{t(`results.score`, { score })}</h2>
          <p className="!mb-0">{copy}</p>
          {scoreSection == 1 && (
            <>
              {parse(t(`results.soft_skills_list`))}
              <p className="!mb-0">{parse(t(`results.soft_skills`))}</p>
              <p className="!mb-0">{t(`results.great_fit_p1`)}</p>
            </>
          )}
          {scoreSection == 2 && (
            <>
              <PortfolioCareerAptitudeQuestions />
              <p className="!mb-0">{t(`results.good_fit_p1`)}</p>
              <p className="!mb-0">{t(`results.good_fit_p2`)}</p>
              {parse(t(`results.soft_skills_list`))}
              <p className="!mb-0">{parse(t(`results.soft_skills`))}</p>
              <p className="!mb-0">{parse(t(`results.good_fit_p3`))}</p>
            </>
          )}
          {scoreSection == 3 && (
            <>
              <p className="!mb-0">{parse(t(`results.poor_fit_p1`))}</p>
              <p className="!mb-0">{t(`results.poor_fit_p2`)}</p>
            </>
          )}
          <p className="!mb-0">{t(`results.all_p1`)}</p>
          <p className="!mb-0">{t(`results.all_p2`)}</p>
          <p className="!mb-0">{t(`results.all_p3`)}</p>
        </div>
        {isDesktop && <Avatar src={avatar} />}
      </div>
    </Results>
  )
}

export default PortfolioCareerAptitudeResults

const PortfolioCareerAptitudeQuestions = () => {
  const { formattedQuizName, trackContent } = useQuiz()
  const { t } = useTranslation(formattedQuizName)
  const questions = t('questions')
  const contentKeys = Object.keys(trackContent)

  return contentKeys.map((key) => {
    const answerChoice = trackContent[key]
    const response = questions[key].options[answerChoice].feedback_key

    return (
      response === 'no_response' && (
        <p className="!mb-0">
          <strong>{questions[key].question}</strong>
        </p>
      )
    )
  })
}
