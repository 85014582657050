import React from 'react'
import useLikertLayout from '../LikertLayout/LikertLayoutProvider'
import { useQuiz } from '../QuizProvider'
import Quiz from '../Quiz'
import { Button } from '../../../lib/ui/button'
import Questions from './Questions'
import useTranslation from '../../shared/TranslationProvider'

const Section = ({ handleContinueLogic, handleBackClickLogic }) => {
  const { showSectionOverview, numQuestions } = useLikertLayout()

  if (showSectionOverview)
    return (
      <SectionOverview
        handleContinueLogic={handleContinueLogic}
        handleBackClickLogic={handleBackClickLogic}
      />
    )
  return (
    <div className="quiz flex justify-center border-l border-[#DDD] px-4 py-6">
      <Quiz progressTotal={numQuestions}>
        <Questions />
      </Quiz>
    </div>
  )
}

export default Section

const SectionOverview = ({ handleContinueLogic, handleBackClickLogic }) => {
  const {
    hideOverview,
    sectionIndex,
    setSectionIndex,
    sectionInfo: { title, description, continue_button },
    translationKey,
  } = useLikertLayout()

  const { t } = useTranslation(translationKey)

  const { setQuizIndex, quizIndex, setStarted, setShowResults } = useQuiz()

  const handleContinue = () => {
    hideOverview()
    handleContinueLogic({ sectionIndex, setQuizIndex, quizIndex })
  }

  const handleBackClick = () => {
    handleBackClickLogic({
      sectionIndex,
      setQuizIndex,
      setSectionIndex,
      hideOverview,
      setStarted,
      setShowResults,
    })
  }

  return (
    <div className="p-4">
      <div className="quiz flex px-4 py-6">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h2>{title}</h2>
            {description.map((part, i) => (
              <p key={`description-${i}`}>{part}</p>
            ))}
          </div>
          <div className="flex gap-4 self-start">
            <Button variant="outline" onClick={handleBackClick}>
              {t('overview.back_button')}
            </Button>
            <Button onClick={handleContinue}>{continue_button}</Button>
          </div>
        </div>
      </div>
    </div>
  )
}
