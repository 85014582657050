import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react'
import { usePDF } from '@react-pdf/renderer'
import { TemplateSwitcher, useTemplate } from '../../lib/pdf'
import { apiPost, apiPut } from '../../util/api'
import { useToast } from '../../lib/ui/toast'
import { sha256 } from 'js-sha256'

const CoverLetterContext = createContext()

export const useCoverLetter = () => {
  return useContext(CoverLetterContext)
}

const hashObject = (obj) => {
  const json = JSON.stringify(obj)
  return sha256(json)
}

const CoverLetterProvider = ({
  cover_letter_json,
  document_id,
  cover_letter_name,
  dev_mode,
  candidate_name,
  ayla_cover_letter,
  children,
}) => {
  const [coverLetterDocument, setCoverLetterDocument] =
    useState(cover_letter_json)
  const [coverLetterName, setCoverLetterName] = useState(cover_letter_name)
  const [lastPersistedHash, setLastPersistedHash] = useState(
    hashObject(cover_letter_json),
  )
  const { toast } = useToast()
  const documentId = document_id
  const candidateName = candidate_name
  const aylaCoverLetter = ayla_cover_letter

  const { templateIndex, templateStyle, templates } = useTemplate()

  useEffect(() => {
    // If the cover letter has unsaved changes, warn the user before leaving the page
    if (hashObject(coverLetterDocument) !== lastPersistedHash) {
      window.onbeforeunload = (event) => {
        event.preventDefault()
        event.returnValue = true // For legacy browser compatibility
      }
    } else {
      window.onbeforeunload = undefined
    }
  }, [coverLetterDocument, lastPersistedHash])

  // use hook for fine-grained control over PDF rendering
  const [pdf, renderPdf] = usePDF({
    document: (
      <TemplateSwitcher
        jsonDocument={coverLetterDocument}
        templateIndex={templateIndex}
        templateStyle={templateStyle}
        Template={templates[templateIndex]}
        candidateName={candidateName}
      />
    ),
  })

  // Re-render the PDF
  const updateCoverLetter = useCallback(() => {
    renderPdf(
      <TemplateSwitcher
        jsonDocument={coverLetterDocument}
        templateIndex={templateIndex}
        templateStyle={templateStyle}
        Template={templates[templateIndex]}
        candidateName={candidateName}
      />,
    )
  }, [
    coverLetterDocument,
    renderPdf,
    templateIndex,
    templateStyle,
    templates,
    candidateName,
  ])

  // Automatically re-render pdf every time coverLetterDocument changes
  useEffect(() => {
    updateCoverLetter()
  }, [coverLetterDocument, updateCoverLetter])

  // Persist cover letter data to the server
  const persistCoverLetter = async () => {
    setLastPersistedHash(hashObject(coverLetterDocument))
    if (documentId) {
      await saveExistingCoverLetter()
    } else {
      await saveNewCoverLetter()
    }
  }

  const saveNewCoverLetter = async () => {
    let response
    try {
      response = await apiPost('/personal_brand/cover_letter_builder/', {
        cover_letter: coverLetterDocument,
        template_index: templateIndex,
        template_settings: templateStyle,
        name: coverLetterName,
      })
    } catch (e) {
      console.error(
        'Error saving new cover letter. Expected status 200. Got:',
        e.response.status,
        e.response,
      )
      toast({ description: 'Error! Cover letter failed to save' })
      return
    }
    if (response.status === 200) {
      window.location.href =
        '/personal_brand/cover_letter_builder/' + response.data.document_id
    } else {
      console.error(
        'Error saving new cover letter. Expected status 200. Got:',
        response.status,
        response,
      )
      toast({ description: 'Error: Failed to create cover letter' })
    }
  }

  const saveExistingCoverLetter = async () => {
    let response
    toast({ description: 'Saving cover letter...' })
    try {
      response = await apiPut(
        `/personal_brand/cover_letter_builder/${documentId}`,
        {
          cover_letter: coverLetterDocument,
          template_index: templateIndex,
          template_settings: templateStyle,
          name: coverLetterName,
        },
      )
    } catch (e) {
      console.error(
        'Error saving existing cover letter. Expected status 200. Got:',
        e.response.status,
        e.response,
      )
      toast({ description: 'Error: Cover letter failed to save' })
      return
    }
    if (response.status === 200) {
      toast({ description: 'Cover letter saved successfully', duration: 1500 })
    } else {
      console.error(
        'Error saving existing cover letter. Expected status 200. Got:',
        response.status,
        response,
      )
      toast({ description: 'Error! Cover letter failed to save' })
    }
  }

  const value = {
    pdf,
    documentId,
    persistCoverLetter,
    coverLetterDocument,
    setCoverLetterDocument,
    coverLetterName,
    setCoverLetterName,
    aylaCoverLetter,
    devMode: dev_mode,
  }

  return (
    <CoverLetterContext.Provider value={value}>
      {children}
    </CoverLetterContext.Provider>
  )
}
export default CoverLetterProvider
