import React from 'react'
import useTranslation from '../../shared/TranslationProvider'
import Question from './Question'
import { useQuiz } from '../QuizProvider'

const Questions = () => {
  const { formattedQuizName } = useQuiz()
  const { t } = useTranslation(formattedQuizName)
  const questionsInfo = t('questions')
  const questionsArr = Object.values(questionsInfo)
  const numQuestions = questionsArr.length
  return (
    <>
      {questionsArr.map((question, idx) => {
        return (
          <Question
            key={`question-${idx}`}
            idx={idx}
            questionInfo={question}
            numQuestions={numQuestions}
          />
        )
      })}
    </>
  )
}

export default Questions
