import React, { useState } from 'react'

import { useJobSearch } from './JobSearchProvider'
import { Button } from '../../../lib/ui/button'
import LinkedInIcon from './LinkedInIcon'
import { useTranslation } from '../../../hooks/useTranslation'
import LeaveSiteWarning from '../../shared/LeaveSiteWarning'
import { SpinnerWhite } from '../../shared/Spinner'
import JobApplicationModal from './JobApplicationModal'
import SkillsMatchModal from './SkillsMatchModal'
import { cn } from '../../../lib/utils'
import { MagicWand } from 'iconoir-react'

export function PrimaryButton({ className, children, ...props }) {
  const { formDisabled } = useJobSearch()
  const [fakeDisabled, setFakeDisabled] = React.useState(false)
  const fakeQuickSearch = () => {
    setFakeDisabled(true)
    setTimeout(() => {
      setFakeDisabled(false)
    }, 500)
  }

  const showSpinner = formDisabled || fakeDisabled

  return (
    <Button
      size="lg"
      className={cn('w-full', className)}
      disabled={formDisabled}
      type="submit"
      onClick={fakeQuickSearch}
      {...props}
    >
      {children}
      <SpinnerWhite show={showSpinner} className="pl-4" />
    </Button>
  )
}

export function LearnMoreButton({ job }) {
  const { handleSelectJob } = useJobSearch()
  const { t } = useTranslation('LearnMoreButton')

  return (
    <Button onClick={handleSelectJob(job)} className="px-6 sm:px-8">
      {t('learn_more')}
    </Button>
  )
}

export function ApplyNowButton({ job, compact, className }) {
  const { t } = useTranslation('ApplyNowButton')

  return (
    <LeaveSiteWarning url={job.job_posting_url}>
      <Button
        className={cn(
          'cursor-pointer hover:text-white data-[compact=true]:px-4',
          className,
        )}
        data-compact={compact}
      >
        {compact ? t('apply') : t('apply_now')}
      </Button>
    </LeaveSiteWarning>
  )
}

export function ShowResultsButton({ ...props }) {
  const { formDisabled } = useJobSearch()
  const { t } = useTranslation('ShowResultsButton')

  return (
    <Button type="submit" disabled={formDisabled} {...props}>
      {t('show_results')}
    </Button>
  )
}

export function LinkedInButton({ job, compact, className }) {
  const companyName = encodeURIComponent(job.company_name)
  const { t } = useTranslation('LinkedInButton')
  const label = compact ? t('connections') : t('see_connections')
  const linkedInURL = `https://www.linkedin.com/search/results/people?company=${companyName}&facetNetwork=%5B%22F%22%2C%22S%22%5D&origin=FACETED_SEARCH`

  return (
    <LeaveSiteWarning url={linkedInURL}>
      <Button
        type="button"
        variant="outline"
        className={cn(
          'flex flex-row justify-center gap-3 px-6 data-[compact=true]:gap-2 data-[compact=true]:px-4 sm:px-8',
          className,
        )}
        data-compact={compact}
      >
        <div>{label}</div> <LinkedInIcon size="20" />
      </Button>
    </LeaveSiteWarning>
  )
}

export function ViewMoreButton({ className }) {
  const { handleViewMore, viewMoreButtonDisabled } = useJobSearch()
  const { t } = useTranslation('ViewMoreButton')

  return (
    <Button
      className={cn('w-full', className)}
      onClick={handleViewMore}
      disabled={viewMoreButtonDisabled}
    >
      {t('view_more')}
      <SpinnerWhite show={viewMoreButtonDisabled} className="pl-4" />
    </Button>
  )
}

export function ApplyWithIntooButton({ job, className }) {
  const { t } = useTranslation('ApplyWithIntooButton')
  const [isModalOpen, setIsModalOpen] = React.useState(false)

  const handleButtonClick = () => {
    setIsModalOpen(true)
  }

  const handleCloseModal = () => {
    setIsModalOpen(false)
  }

  return (
    <>
      <Button
        className={cn(
          'cursor-pointer hover:text-white data-[compact=true]:px-4',
          className,
        )}
        onClick={handleButtonClick}
      >
        {t('apply_with_intoo')}
      </Button>
      {isModalOpen && (
        <JobApplicationModal job={job} onClose={handleCloseModal} />
      )}
    </>
  )
}

export function SkillsMatchButton({ job, compact, className }) {
  const { t } = useTranslation('SkillsMatchButton')
  const [showSkillsDialog, setShowGoalDialog] = useState(false)
  const toggleSkillsDialog = () => setShowGoalDialog((prev) => !prev)

  return (
    <>
      <Button
        type="button"
        variant="outline"
        className={cn(
          'flex flex-row justify-center gap-3 px-6 data-[compact=true]:gap-2 data-[compact=true]:px-4 sm:px-8',
          className,
        )}
        data-compact={compact}
        onClick={toggleSkillsDialog}
      >
        <div>{t('good_fit')}</div> <MagicWand className="h-5 w-5" />
      </Button>
      {
        <SkillsMatchModal
          showDialog={showSkillsDialog}
          toggleDialog={toggleSkillsDialog}
          job={job}
        />
      }
    </>
  )
}
