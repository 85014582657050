import React from 'react'
import { useCoverLetter } from '../CoverLetterProvider'
import { EditorHeading } from '../../ResumeBuilder/Editor/common'
import { EditorItemField, EditorItemProvider } from './EditorItemProvider'

const BasicsEditor = ({ templateIndex }) => {
  const { coverLetterDocument: { basics } = {} } = useCoverLetter()
  const { aylaCoverLetter } = useCoverLetter()

  return (
    <EditorItemProvider sectionName="basics" item={basics}>
      <div>
        {aylaCoverLetter && (
          <div className="mb-8">
            <EditorHeading>Disclaimer</EditorHeading>
            <p>
              Ayla INTOO cover letters are designed to help you quickly get
              started with custom cover letters for any role you are pursuing.
              Ayla always recommends that you review the content provided to
              confirm it is accurate and appropriate for your specific
              experience.
            </p>
          </div>
        )}
        <EditorHeading>Addressee Details</EditorHeading>
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-6">
          {templateIndex == 0 && (
            <>
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Email Address"
                fieldName="emailAddress"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Email Subject"
                fieldName="emailSubject"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Salutation"
                fieldName="salutation"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Last Name"
                fieldName="lastName"
              />
            </>
          )}
          {(templateIndex == 1 || templateIndex == 2) && (
            <>
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Salutation"
                fieldName="salutation"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="First Name"
                fieldName="firstName"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Last Name"
                fieldName="lastName"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Position Title"
                fieldName="positionTitle"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Company Name"
                fieldName="companyName"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Company Division"
                fieldName="companyDivision"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Address Line 1"
                fieldName="address1"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Address Line 2"
                fieldName="address2"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="City, State, Zip Code"
                fieldName="cityStateZip"
              />
            </>
          )}
        </div>
      </div>
    </EditorItemProvider>
  )
}
export default BasicsEditor
