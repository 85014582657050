import React from 'react'
import { FavoriteIcon, ArticlesIcon, ClockIcon, CheckMarkIcon } from './icons'
import { useResources } from './ResourcesProvider'
import { useTranslation } from '../../hooks/useTranslation'
import { cn } from '../../lib/utils'
import { useFavoritesActive } from './ResourceCardList'

export const ArticleCards = () => {
  const { articles, checkFavorite } = useResources()
  const dateSort = (a, b) => new Date(b.created_at) - new Date(a.created_at)
  const displayArticles = useFavoritesActive()
    ? articles.filter(checkFavorite).sort(dateSort)
    : articles.sort(dateSort)
  return (
    <>
      {displayArticles.map((article) => (
        <ArticleCard key={article.id} resource={article} />
      ))}
    </>
  )
}

const ArticleCard = ({ resource }) => {
  const {
    headerImageUrl,
    headerImageAlt,
    title,
    description,
    length,
    hasAchievement,
  } = resource
  const { checkFavorite, toggleFavorite, getArticlePath, isRecentlyAdded } =
    useResources()
  const articlePath = getArticlePath(resource)
  const { t } = useTranslation('index')

  const ctaClasses = hasAchievement
    ? 'bg-white flex border text-[#2F2F2F] hover:!bg-accent focus:bg-accent focus:border-accent focus:text-[#2F2F2F] active:bg-accent active:border-accent hover:text-[#2F2F2F] border-[#2F2F2F] active:text-[#2F2F2F]'
    : 'bg-primary text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white'

  const ctaText = hasAchievement ? 'Again' : 'Article'

  const isFavorite = checkFavorite(resource)
  const favoriteIconClass = isFavorite
    ? 'fill-primary stroke-primary'
    : 'stroke-primary'
  const favoriteTitle = isFavorite
    ? t('bookmarked')
    : t('bookmark_this_resource')

  const recentlyAdded = isRecentlyAdded(resource)

  return (
    <div className="relative mb-8 overflow-hidden rounded-3xl border border-solid border-[#B9B9B9]">
      {recentlyAdded && (
        <div className="absolute bottom-[12.6rem] left-0 z-10 h-11 rounded-tr-[99999px] bg-[#E0F2F9] pb-[6px] pl-5 pr-8 pt-[10px] text-base font-medium text-primary">
          Recently Added
        </div>
      )}
      <div className="absolute right-4 top-4">
        <button
          className="rounded-lg bg-white p-2 hover:bg-gray-100"
          title={favoriteTitle}
          onClick={() => toggleFavorite(resource)}
        >
          <FavoriteIcon
            className={favoriteIconClass}
            title={favoriteTitle}
            height={24}
          />
        </button>
      </div>
      <div className="absolute bottom-[12.6rem] right-0 z-10">
        <div className="h-11 rounded-tl-[99999px] bg-primary py-2 pl-7 pr-5 text-base font-normal text-[#FFFFFF]">
          <span className="flex flex-row items-center">
            {length}
            <ClockIcon
              width={28}
              height={28}
              strokeWidth={1.5}
              className="stroke-[#FFFFFF] pl-2"
            ></ClockIcon>
          </span>
        </div>
      </div>
      <img
        src={headerImageUrl}
        alt={headerImageAlt}
        className="aspect-[22/11] w-full object-cover object-center"
      />
      <div className="flex flex-col p-4 md:justify-between">
        <div className="h-28">
          <p className="!my-0 mb-8 line-clamp-4 flex-grow overflow-hidden md:leading-relaxed">
            <span
              tabIndex="0"
              aria-level="2"
              className="!mb-2 !mt-0 break-normal font-roboto text-lg focus:overflow-visible"
              title={title}
            >
              {title}
            </span>
            <br />
            {description}
          </p>
        </div>
        <div className="mt-4">
          <a
            href={articlePath}
            className={cn(
              'flex w-full items-center justify-center rounded-3xl px-4 py-3 text-base font-medium sm:py-2',
              ctaClasses,
            )}
          >
            Read {ctaText}
            <CtaIcon hasAchievement={hasAchievement} />
          </a>
        </div>
      </div>
    </div>
  )
}

export default ArticleCard

const CtaIcon = ({ hasAchievement }) => {
  if (hasAchievement) return <CheckMarkIcon className="ml-2 stroke-[#2F2F2F]" />
  return <ArticlesIcon className="ml-2 stroke-white" />
}
