import React, {
  useState,
  useRef,
  createContext,
  useContext,
  useCallback,
  Fragment,
} from 'react'
import { Carousel } from '~/lib/ui/carousel'
import { Select, SelectValue, SelectTrigger } from '~/lib/ui/select'
import { cn } from '~/lib/utils'
import { useIsMobile } from '~/hooks/useMediaQuery'

const TabNavContext = createContext()

export const useTabNav = () => {
  return useContext(TabNavContext)
}

const TabNav = ({
  mobileMode = 'dropdown',
  className,
  defaultActiveTab,
  forcedActiveTab = null,
  children,
  onChangeActiveTab = () => {},
}) => {
  const [activeTab, setActiveTabInner] = useState(defaultActiveTab)
  const tabList = useRef([])
  const tabButtonClick = (e) => {
    setActiveTab(e.target.closest('button').getAttribute('name'))
  }
  const isMobile = useIsMobile()
  const setActiveTab = useCallback(
    (newActiveTabName) => {
      setActiveTabInner(newActiveTabName)
      onChangeActiveTab(newActiveTabName)
    },
    [onChangeActiveTab, setActiveTabInner],
  )
  const registerTab = useCallback((name) => {
    if (!tabList.current.includes(name)) {
      tabList.current = [...tabList.current, name]
    }
  }, [])
  const activeTabIndex = useCallback(() => {
    const thisTab = forcedActiveTab || activeTab
    return tabList.current.indexOf(thisTab)
  }, [tabList, activeTab, forcedActiveTab])
  const setActiveTabIndex = useCallback(
    (index) => setActiveTab(tabList.current[index]),
    [setActiveTab],
  )
  const isMobileSwipe = isMobile && mobileMode === 'swipe'
  const isMobileDropdown = isMobile && mobileMode === 'dropdown'

  const providerValue = {
    activeTab: forcedActiveTab || activeTab,
    setActiveTab,
    tabButtonClick,
    isMobileSwipe,
    isMobileDropdown,
    registerTab,
    activeTabIndex,
    setActiveTabIndex,
  }

  if (isMobileDropdown) {
    return (
      <TabNavContext.Provider value={providerValue}>
        <Select value={activeTab} onValueChange={setActiveTab}>
          <SelectTrigger className="text-lg font-medium">
            <SelectValue />
          </SelectTrigger>
          {children}
        </Select>
      </TabNavContext.Provider>
    )
  }

  const Comp = isMobileSwipe ? Carousel : Fragment

  return (
    <TabNavContext.Provider value={providerValue}>
      <Comp>
        <div className={cn('flex h-full w-full flex-col', className)}>
          {children}
        </div>
      </Comp>
    </TabNavContext.Provider>
  )
}
export default TabNav
