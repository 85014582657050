import React from 'react'
import AssessmentCard from '../../AssessmentCenter/AssessmentCard'
import ExerciseCard from '../../AssessmentCenter/ExerciseCard'
import { useSkills } from '../SkillsProvider'
import { Separator } from '../../../lib/ui/separator'
import { useTranslation } from '../../../hooks/useTranslation'

const SkillsAssessments = () => {
  const { assessments, exercises, s3_base_url } = useSkills()
  const { t } = useTranslation('SkillsAssessments')
  return (
    <>
      <Separator className="mt-8" />
      <div className="flex flex-col gap-8 px-4 pb-6 pt-8 md:px-0">
        <div>
          <h2 className="pb-4 font-roboto-slab">
            {t('title')} <span className="font-bold">{t('title_2')}</span>
          </h2>
          <p>{t('intro')}</p>
        </div>
        <div className="grid grid-cols-1 gap-x-12 gap-y-6">
          {assessments.map((assessment, idx) => (
            <AssessmentCard
              key={`assessment-${idx}`}
              assessment={assessment}
              className={'col-span-2 md:col-span-1'}
              s3_base_url={s3_base_url}
            />
          ))}
          {exercises.map((exercise, idx) => (
            <ExerciseCard
              key={`exercise-${idx}`}
              exercise={exercise}
              className={'col-span-2 md:col-span-1'}
              s3_base_url={s3_base_url}
            />
          ))}
        </div>
      </div>
    </>
  )
}

export default SkillsAssessments
