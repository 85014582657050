import SliderControl from './SliderControl'
import SelectControl from './SelectControl'
import ColorPickerControl from './ColorPickerControl'
import { TemplateProvider, useTemplate } from './TemplateProvider'
import TemplateSwitcher from './TemplateSwitcher'
import {
  Text,
  View,
  Font,
  StyleSheet,
  DocumentPage,
  useStyle,
} from './DocumentPage'
import fonts from './fonts'

const getTemplateStyle = (templates) =>
  templates
    .map((t, index) => ({
      [`template-${index}`]: t.defaultStyle || {},
    }))
    .reduce((acc, val) => ({ ...acc, ...val }), {})

const getTemplateSupported = (templates) =>
  templates
    .map((t, index) => ({
      [`template-${index}`]: Object.keys(t?.defaultStyle || {}),
    }))
    .reduce((acc, val) => ({ ...acc, ...val }), {})

const initializeTemplates = (templates) => {
  const templateStyle = getTemplateStyle(templates)
  const templateSupported = getTemplateSupported(templates)
  return [templates, templateStyle, templateSupported]
}

export {
  SliderControl,
  SelectControl,
  ColorPickerControl,
  TemplateProvider,
  useTemplate,
  useStyle,
  TemplateSwitcher,
  Text,
  View,
  Font,
  fonts,
  StyleSheet,
  DocumentPage,
  initializeTemplates,
}
