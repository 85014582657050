import React from 'react'
import { useExercise } from './ExerciseProvider'
import DesignThinkingExercise from './DesignThinkingExercise'
import MotivationReflectionExercise from './MotivationReflectionExercise'
import CareerBrainstormExercise from './CareerBrainstormExercise'
import { useIsDesktop } from '../../hooks/useMediaQuery'
import { ExerciseIcon } from './icons'
import ReasonForLeavingExercise from './ReasonForLeavingExercise'
import parse from 'html-react-parser'
import NetworkingPreferencesExercise from './NetworkingPreferences/NetworkingPreferencesExercise'
import ChartYourCourseExercise from './ChartYourCourse/ChartYourCourseExercise'
import SocialMediaReviewExercise from './SocialMediaReview/SocialMediaReviewExercise'
import PersonalBrandStatementExercise from './PersonalBrandStatement/PersonalBrandStatementExercise'
import { cn } from '../../lib/utils'
import PieOfLifeExercise from './PieOfLife/PieOfLifeExercise'

const Layout = ({ pageInfo, section }) => {
  const {
    getResponse,
    exerciseName,
    exerciseIndex,
    trackContent,
    sectionIdx,
    embedExercise,
  } = useExercise()
  const description =
    pageInfo.description ?? pageInfo.section_info?.[sectionIdx]?.description
  const description2 = pageInfo.description_2
  const description3 = pageInfo.description_3
  const conclusion = pageInfo.conclusion
  const questions = Object?.values(pageInfo.questions ?? {})
  const title = pageInfo.title
  const isDesktop = useIsDesktop()
  return (
    <div className="pb-8">
      <div className="grid grid-cols-10">
        <div
          className={`flex flex-col ${
            isDesktop ? 'col-span-9' : 'col-span-10'
          }`}
        >
          {!embedExercise && (
            <h1 className="mb-4 font-roboto-slab text-3xl font-bold">
              {title}
            </h1>
          )}
          {!embedExercise && description && (
            <p
              className={cn(
                'font-roboto text-lg',
                description2 ? '!mb-1' : '!mb-6',
              )}
            >
              {parse(description)}
            </p>
          )}
        </div>
        {!embedExercise && isDesktop && (
          <ExerciseIcon source="ExerciseSteps" className="ml-auto" />
        )}
      </div>
      {description2 && (
        <p
          className={cn(
            'font-roboto text-lg',
            description3 ? '!mb-1' : '!mb-6',
          )}
        >
          {parse(description2)}
        </p>
      )}
      {description3 && (
        <p className="!mb-6 font-roboto text-lg">{description3}</p>
      )}
      {exerciseName === 'Design Thinking' && (
        <DesignThinkingExercise
          section={section}
          questions={questions}
          conclusion={conclusion}
        />
      )}
      {exerciseName === 'Motivation Reflection' && (
        <MotivationReflectionExercise
          questions={questions}
          getResponse={getResponse}
          exerciseIndex={exerciseIndex}
          conclusion={conclusion}
          section={section}
          trackContent={trackContent}
        />
      )}
      {exerciseName === 'Career Brainstorm' && (
        <CareerBrainstormExercise
          questions={questions}
          getResponse={getResponse}
          exerciseIndex={exerciseIndex}
          conclusion={conclusion}
          section={section}
          trackContent={trackContent}
        />
      )}
      {exerciseName === 'Reason For Leaving Statement' && (
        <ReasonForLeavingExercise
          questions={questions}
          getResponse={getResponse}
          exerciseIndex={exerciseIndex}
          conclusion={conclusion}
          section={section}
          trackContent={trackContent}
        />
      )}
      {exerciseName === 'Networking Preferences' && (
        <NetworkingPreferencesExercise
          pageInfo={pageInfo}
          getResponse={getResponse}
          exerciseIndex={exerciseIndex}
          section={section}
          trackContent={trackContent}
        />
      )}
      {exerciseName === 'Chart Your Course' && <ChartYourCourseExercise />}
      {exerciseName === 'Social Media Review' && (
        <SocialMediaReviewExercise section={section} />
      )}
      {exerciseName === 'Personal Brand Statement' && (
        <PersonalBrandStatementExercise
          questions={questions}
          getResponse={getResponse}
          exerciseIndex={exerciseIndex}
          conclusion={conclusion}
          section={section}
          trackContent={trackContent}
        />
      )}
      {exerciseName === 'Pie Of Life' && (
        <PieOfLifeExercise
          getResponse={getResponse}
          trackContent={trackContent}
        />
      )}
    </div>
  )
}

export default Layout
