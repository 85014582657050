import React from 'react'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import ExpandyPanel from '../../../lib/ui/ExpandyPanel'
import { ArrayFields } from './common'
import {
  EditorItemField,
  useEditorItem,
  EditorYearMonthField,
} from './EditorItemProvider'

const EmploymentItem = () => {
  const {
    index,
    activeIndex,
    setActiveIndex,
    item,
    setItem,
    updateItem,
    deleteItem,
    updateCollection,
  } = useEditorItem()

  const headingLabel =
    item.position && item.name
      ? `${item.position} at ${item.name}`
      : item.position || item.name || 'New Work History'

  const toggleExpanded = () => {
    if (activeIndex == index) {
      setActiveIndex(-1)
    } else {
      setActiveIndex(index)
    }
  }

  const addHighlight = () => {
    setHighlights([...(item.highlights || []), ''])
  }

  const setHighlights = (highlights) => {
    const updatedItem = { ...item, highlights }
    setItem(updatedItem)
  }

  const deleteHighlight = (highlightIndex) => {
    const updatedHighlights =
      item.highlights?.filter((_, i) => i !== highlightIndex) || []
    updateCollection('highlights', updatedHighlights)
  }

  const expanded = activeIndex == index

  return (
    <div className="mb-4 flex flex-row items-center">
      <ExpandyPanel
        headingText={headingLabel}
        expanded={expanded}
        index={index}
        onClick={toggleExpanded}
        className="flex-grow"
      >
        <div
          id={`employment-${index}`}
          className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2"
        >
          <EditorItemField
            label="Employer"
            fieldName="name"
            containerClassName="md:col-span-2"
          />
          <EditorItemField label="Position" fieldName="position" />
          <EditorItemField label="Location" fieldName="location" />
          <EditorYearMonthField label="Start Date" fieldName="startDate" />
          <EditorYearMonthField label="End Date" fieldName="endDate" />
        </div>
        <div>
          <div className="flex flex-row gap-2 pt-4">
            <span className="font-bold">Highlights</span>
            <button
              className="flex flex-row items-center text-primary"
              onClick={addHighlight}
            >
              Add
              <PlusIcon className="ml-1 inline w-4 stroke-2" />
            </button>
          </div>
          <div className="flex flex-col gap-2 pt-4">
            <ArrayFields
              items={item.highlights || []}
              keyPrefix="highlight"
              onChange={setHighlights}
              onBlur={updateItem}
              onDeleteClick={deleteHighlight}
            />
          </div>
        </div>
      </ExpandyPanel>
      {!expanded && (
        <button
          onClick={deleteItem}
          className="max-w-min text-[#b9b9b9]"
          aria-label="remove employment"
        >
          <TrashIcon className="ml-2 w-8" />
        </button>
      )}
    </div>
  )
}
export default EmploymentItem
