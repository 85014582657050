import React, { useEffect } from 'react'
import { cn } from '~/lib/utils'
import { useTabNav } from './TabNav'
import { useButtonStyle } from './ButtonContainer'
import { CarouselItem } from '~/lib/ui/carousel'
import { SelectItem } from '~/lib/ui/select'

const TabButton = ({
  Icon,
  name,
  text,
  buttonClass,
  titleClass,
  activeTitleClass,
  iconClass,
  activeDecorationClass,
}) => {
  const {
    activeTab,
    tabButtonClick,
    isMobileSwipe,
    isMobileDropdown,
    registerTab,
  } = useTabNav()
  const style = useButtonStyle()

  useEffect(() => {
    if (isMobileSwipe) registerTab(name)
  }, [isMobileSwipe, name, registerTab])

  if (isMobileDropdown) {
    return (
      <SelectItem value={name} className="text-lg">
        {text}
      </SelectItem>
    )
  }

  const active = activeTab === name
  const decorationClass = active
    ? cn(
        'w-full rounded-t-sm h-1 bg-primary',
        style.activeDecorationClass,
        activeDecorationClass,
      )
    : 'h-1'
  const defaultTitleClass = cn(
    'my-2 md:mx-0.5 lg:mx-1 whitespace-nowrap text-center text-lg',
    style.titleClass,
    titleClass,
  )
  const titleDisplayClass = active
    ? cn(
        'font-medium',
        defaultTitleClass,
        style.activeTitleClass,
        activeTitleClass,
      )
    : defaultTitleClass

  const className = cn(
    'flex min-w-32 flex-col items-center',
    style.buttonClass,
    buttonClass,
  )

  if (isMobileSwipe) {
    return (
      <CarouselItem className={className}>
        <div className={cn('flex flex-row items-center', titleDisplayClass)}>
          {Icon && (
            <Icon className={cn('mr-1 w-6', style.iconClass, iconClass)} />
          )}
          <div>{text}</div>
        </div>
        <div className={decorationClass}></div>
      </CarouselItem>
    )
  }

  return (
    <button className={className} onClick={tabButtonClick} name={name}>
      {Icon && <Icon className={cn('w-8', style.iconClass, iconClass)} />}
      <div className={titleDisplayClass}>{text}</div>
      <div className={decorationClass}></div>
    </button>
  )
}
export default TabButton
