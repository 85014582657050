import React from 'react'
import { DocumentPage, Text, View, StyleSheet, useStyle } from '../../lib/pdf'
import Moment from 'moment'

const isNotBlank = (item) => ![null, undefined, ''].includes(item)

export const defaultStyle = {
  xMargin: 0.4,
  yMargin: 0.4,
  size: 'Letter',
  lineHeightMultiplier: 1.0,
  listLineHeightMultiplier: 1.0,
  primaryFontFamily: 'Times New Roman',
}

export const optionalEditorFields = {
  basicsJobTitle: false,
  basicsLocation: true,
  basicsSummary: false,
  basicsWebsite: false,
  basicsLinkedin: false,
  volunteer: true,
}

export const Template = ({ jsonDocument, templateStyle }) => {
  const styles = StyleSheet.create({
    heading: {
      width: '100%',
      textAlign: 'center',
      fontSize: 24,
    },
    contactSection: {
      flexDirection: 'row',
      fontSize: 12,
      justifyContent: 'center',
      paddingBottom: 4,
    },
    contactItem: {},
    contactSpacer: {
      padding: '0 8px',
    },
    sectionTitle: {
      fontSize: '12px',
      fontWeight: 'bold',
      marginTop: '15px',
      borderBottom: '1px solid #000000',
      marginBottom: '3px',
    },
    skillsSection: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
    },
  })

  const { basics, education, work, volunteer, skills } = jsonDocument
  const { name, email, phone, location } = basics || {}
  const { address, city, region, postalCode } = location || {}

  const regionPostalCode = [region, postalCode].filter(isNotBlank).join(' ')
  const cityStateZip = city ? `${city}, ${regionPostalCode}` : regionPostalCode
  const contactItems = [address, cityStateZip, email, phone].filter(isNotBlank)

  return (
    <DocumentPage templateStyle={templateStyle}>
      <Text style={styles.heading}>{name}</Text>
      <View style={styles.contactSection}>
        {contactItems.map((item, index) => {
          return (
            <View key={index} style={{ flexDirection: 'row' }}>
              <Text style={styles.contactItem}>{item}</Text>
              {index < contactItems.length - 1 && (
                <Text style={styles.contactSpacer}>|</Text>
              )}
            </View>
          )
        })}
      </View>
      <View>
        {education && education.length > 0 && (
          <EducationSection education={education} styles={styles} />
        )}
        {work && work.length > 0 && <WorkSection work={work} styles={styles} />}
        {volunteer && volunteer.length > 0 && (
          <VolunteerSection volunteer={volunteer} styles={styles} />
        )}
        {skills && skills.length > 0 && (
          <SkillSection skills={skills} styles={styles} />
        )}
      </View>
    </DocumentPage>
  )
}

const EducationSection = ({ education, styles }) => {
  return (
    <>
      <Text style={styles.sectionTitle}>Education</Text>
      {education.map((edItem, index) => (
        <EducationView education={edItem} key={`education-${index}`} />
      ))}
    </>
  )
}

const WorkSection = ({ work, styles }) => {
  return (
    <>
      <Text style={styles.sectionTitle}>Experience</Text>
      {work.map((job, index) => (
        <JobView job={job} key={`job-${index}`} />
      ))}
    </>
  )
}

const VolunteerSection = ({ volunteer, styles }) => {
  return (
    <>
      <Text style={styles.sectionTitle}>Leadership &amp; Activities</Text>
      {volunteer.map((item, index) => (
        <VolunteerView volunteer={item} key={`volunteer-${index}`} />
      ))}
    </>
  )
}

const SkillSection = ({ skills, styles }) => {
  return (
    <View>
      <Text style={styles.sectionTitle}>Skills &amp; Interests</Text>
      <View style={{ width: '100%' }}>
        {skills?.map((skill, index) => (
          <SkillView skill={skill} key={`skill-${index}`} />
        ))}
      </View>
    </View>
  )
}

const SkillView = ({ skill }) => {
  const styles = StyleSheet.create({
    container: {
      fontSize: '12px',
      paddingBottom: '3px',
      flexDirection: 'row',
      justifyItems: 'start',
    },
    skillName: {
      fontWeight: 'bold',
      paddingRight: '4px',
    },
    skillList: { textOverflow: 'wrap' },
  })
  if (!skill.name) {
    return null
  }
  return (
    <View style={styles.container}>
      <View style={styles.skillName}>
        <Text>{skill.name}:</Text>
      </View>
      <View style={styles.skillList}>
        <Text style={{ maxWidth: '90%' }}>{skill.keywords?.join(', ')}</Text>
      </View>
    </View>
  )
}

const VolunteerView = ({ volunteer }) => {
  const styles = StyleSheet.create({
    container: {
      fontSize: '12px',
      paddingBottom: '15px',
    },
    row: { flexDirection: 'row', justifyContent: 'space-between' },
    organization: {
      fontWeight: 'bold',
    },
    position: {
      fontWeight: 'bold',
    },
    tenure: {
      fontStyle: 'italic',
    },
  })
  if (!volunteer.position) {
    return null
  }
  return (
    <View style={styles.container} wrap={false}>
      <View style={styles.row}>
        <Text style={styles.organization}>{volunteer.organization}</Text>
        <Text>{volunteer.location}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.position}>{volunteer.position}</Text>
        <Text style={styles.tenure}>
          <Tenure item={volunteer} />
        </Text>
      </View>
      {volunteer.highlights &&
        volunteer.highlights.map((highlight, index) => (
          <BulletLine key={`volunteer-highlight-${index}`}>
            {highlight}
          </BulletLine>
        ))}
    </View>
  )
}

const JobView = ({ job }) => {
  const styles = StyleSheet.create({
    jobContainer: {
      fontSize: '12px',
      paddingBottom: '15px',
    },
    jobRow: { flexDirection: 'row', justifyContent: 'space-between' },
    jobCompany: {
      fontWeight: 'bold',
    },
    jobPosition: {
      fontWeight: 'bold',
    },
    jobTenure: {
      fontStyle: 'italic',
    },
  })
  if (!job.position && !job.name) {
    return null
  }

  return (
    <View style={styles.jobContainer} wrap={false}>
      <View style={styles.jobRow}>
        <Text style={styles.jobCompany}>{job.name}</Text>
        <Text>{job.location}</Text>
      </View>
      <View style={styles.jobRow}>
        <Text style={styles.jobPosition}>{job.position}</Text>
        <Text style={styles.jobTenure}>
          <Tenure item={job} />
        </Text>
      </View>
      {job.highlights &&
        job.highlights.map((highlight, index) => (
          <BulletLine key={`highlight-${index}`}>{highlight}</BulletLine>
        ))}
    </View>
  )
}

const EducationView = ({ education }) => {
  const {
    templateStyle: { lineHeightMultiplier },
  } = useStyle()
  const styles = StyleSheet.create({
    container: {
      paddingBottom: '15px',
      fontSize: '12px',
    },
    institution: {
      fontWeight: 'bold',
      paddingRight: '8px',
    },
    tenure: {
      fontStyle: 'italic',
    },
    highlight: {
      marginTop: '4px',
      lineHeight: `${16 * lineHeightMultiplier}px`,
    },
    educationRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '3px',
    },
  })

  if (!education.institution && !education.studyType) {
    return null
  }

  const { institution, area, studyType, location, score } = education

  const degreeConcentration = [studyType, area].filter(isNotBlank).join(', ')
  const degreeConcentrationGpa = score
    ? `${degreeConcentration}. GPA: ${score}`
    : degreeConcentration

  return (
    <View style={styles.container} wrap={false}>
      <View style={styles.educationRow}>
        <Text style={styles.institution}>{institution}</Text>
        <Text>{location}</Text>
      </View>
      <View style={styles.educationRow}>
        <Text>{degreeConcentrationGpa}</Text>
        <Text style={styles.tenure}>
          <Tenure item={education} />
        </Text>
      </View>
      {education.courses &&
        education.courses.map((course, index) => (
          <Text key={`highlight-${index}`} style={styles.highlight}>
            {course}
          </Text>
        ))}
    </View>
  )
}
const BulletLine = ({ children }) => {
  const {
    templateStyle: { listLineHeightMultiplier },
  } = useStyle()

  const styles = StyleSheet.create({
    highlightBullet: {
      padding: '0 8px',
    },
    highlightText: {
      lineHeight: `${16 * listLineHeightMultiplier}px`,
    },
    container: { flexDirection: 'row', paddingRight: '20px' },
  })

  const hcbNone = (word) => [word]

  return (
    <View style={styles.container}>
      <Text style={styles.highlightBullet}>&#8226;</Text>
      <Text hyphenationCallback={hcbNone} style={styles.highlightText}>
        {children}
      </Text>
    </View>
  )
}

const momentFormat = (date, nullValue = '') => {
  if (!date || [undefined, null, ''].includes(date)) {
    return nullValue
  }
  try {
    const result = Moment(date).format('MMM YYYY')
    if (result === 'Invalid date') return nullValue
    return result
  } catch {
    return nullValue
  }
}

const Tenure = ({ item }) => {
  if (!item.startDate && !item.endDate) {
    return null
  }

  const formattedStart = momentFormat(item.startDate)
  const formattedEnd = momentFormat(item.endDate, 'Present')

  return [formattedStart, formattedEnd].filter(isNotBlank).join(' - ')
}
