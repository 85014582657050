import React from 'react'
import LandingPageProvider, { useLandingPage } from './LandingPageProvider'
import RightNav from './RightNav'
import ExerciseList from './LandingItemList'
import useTranslation, { TranslationProvider } from '../TranslationProvider'
import translations from '../locales.json'
import { cn } from '../../../lib/utils'

const Inner = (props) => {
  const { exercises } = useLandingPage()
  const { translationScope } = props
  const { t } = useTranslation(translationScope)
  const lightHeading = t('light_heading')
  const darkHeading = t('dark_heading')
  return (
    <div
      className={`home-container flex min-h-0 flex-col overflow-y-auto p-4 md:p-9`}
    >
      <div className="tw-enable font-[Roboto] lg:flex lg:flex-row">
        <div className="flex-grow">
          <h1 className="mb-4 !font-roboto-slab text-2xl text-[32px] font-medium text-cloud-burst">
            <span className="font-normal">{lightHeading}</span> {darkHeading}
          </h1>
          <IntroParagraphs paragraphs={t('intro')} />
          <ExerciseList exercises={exercises} />
        </div>
      </div>
    </div>
  )
}

const IntroParagraphs = ({ paragraphs }) => {
  const length = paragraphs.length
  return paragraphs.map((paragraph, index) => (
    <div key={index} className={cn('', index === length - 1 ? 'mb-6' : 'mb-4')}>
      {paragraph}
    </div>
  ))
}

const LandingPage = ({ locale, ...props }) => {
  const { show_right_nav } = props
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.shared.LandingPage"
    >
      <LandingPageProvider {...props}>
        <div
          className={cn(
            'flex flex-col font-roboto text-cod-gray-800 xl:grid xl:h-[calc(100vh-80px)]',
            show_right_nav ? 'xl:grid-cols-[1fr_350px]' : 'xl:grid-cols-[1fr]',
          )}
        >
          <Inner {...props} />
          {show_right_nav && <RightNav />}
        </div>
      </LandingPageProvider>
    </TranslationProvider>
  )
}

export default LandingPage
