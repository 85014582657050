import React from 'react'
import { useExercise } from '../ExerciseProvider'
import { useChartYourCourse } from './ChartYourCourseProvider'
import { cn } from '../../../lib/utils'
import useTranslation from '../../shared/TranslationProvider'
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from '../../../lib/ui/select'
import {
  FancyCheckBox,
  FancyCheckBoxComponent,
} from '../../shared/FancyCheckBox'
import { ClearSelectionsButton } from '../../shared/Buttons'

export const DefaultLayout = ({ questions }) => {
  const { exerciseIndex, getResponse, updateResponse } = useExercise()

  return (
    <div className="questions">
      {questions.map((question, index) => (
        <div
          key={index}
          className="mb-6 w-full font-roboto text-base font-medium"
        >
          <div className="flex w-full flex-col">
            <label className="font-roboto-light !mb-1 text-sm font-medium">
              {question.question}
            </label>
            {question.type === 'text' && <TextArea slug={question.slug} />}
            {question.type === 'dropdown' && (
              <Select
                onValueChange={(e) =>
                  updateResponse(exerciseIndex, question.slug, e)
                }
                value={getResponse(exerciseIndex, question.slug) || ''}
                className="w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
              >
                <SelectTrigger>
                  <SelectValue placeholder="Select an option" />
                </SelectTrigger>
                <SelectContent>
                  {question.options.map((option, index) => (
                    <SelectItem key={index} value={option}>
                      {option}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          </div>
        </div>
      ))}
    </div>
  )
}

export const CheckListLayout = ({ questions }) => {
  const { t } = useTranslation('chart_your_course')
  const { clearSelectedValues } = useChartYourCourse()
  return (
    <div className="questions flex flex-col">
      <div className="grid grid-cols-2 gap-4">
        {questions.map((question, idx) => (
          <ChecklistItem
            item={question}
            key={`values-checklist-${idx}`}
            className={'col-span-2 md:col-span-1'}
          />
        ))}
      </div>
      <ClearSelectionsButton className="self-end" onClick={clearSelectedValues}>
        {t('clear_selections')}
      </ClearSelectionsButton>
    </div>
  )
}

const ChecklistItem = ({ item, className }) => {
  const { handleSelectValue, selectedValues } = useChartYourCourse()
  const { value, description } = item
  const disabled = selectedValues.length >= 5 && !selectedValues.includes(value)
  const selected = selectedValues.includes(value)

  const handleCheck = () => {
    if (disabled) {
      return
    }
    handleSelectValue(value)
  }

  return (
    <div className={cn('flex flex-col', className)}>
      <FancyCheckBoxComponent
        variant={selected ? 'selected' : disabled ? 'disabled' : 'unselected'}
        label={value}
      >
        <FancyCheckBox checked={selected} onCheckedChange={handleCheck} />
      </FancyCheckBoxComponent>
      <div>{description} </div>
    </div>
  )
}

const TextArea = ({ slug }) => {
  const { exerciseIndex, getResponse, handleChange } = useExercise()

  const handleTextChange = (e) => {
    handleChange(exerciseIndex, slug, e)
  }

  const textResponse = getResponse(exerciseIndex, slug) || ''

  return (
    <textarea
      className="min-h-[125px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
      onChange={handleTextChange}
      value={textResponse}
    />
  )
}
