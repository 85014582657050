import React, { useEffect, useRef } from 'react'
import { Textarea } from '../ui/textarea'
import { Label } from '../ui/label'
import { useJsonItem } from './JsonItemProvider'

const JsonTextArea = ({
  containerClassName,
  label,
  fieldName,
  childField,
  autoExpand = false,
  handleItemUpdate = null,
  getItemValue = null,
  areaClassName = {},
}) => {
  const { index, sectionName, item, setItem, updateItem } = useJsonItem()

  const id = [sectionName, fieldName, childField, index].join('-')

  const textAreaRef = useRef(null)

  useEffect(() => {
    if (autoExpand) {
      textAreaRef.current.style.height = 'auto'
      textAreaRef.current.style.height = textAreaRef.current.scrollHeight + 'px'
    }
  }, [item, autoExpand])

  const onBlur = () => {
    updateItem()
  }

  const onChange = (event) => {
    const value = event.target.value || ''

    const updatedItem = handleItemUpdate
      ? handleItemUpdate(item, value)
      : childField
        ? {
            ...item,
            [fieldName]: { ...item[fieldName], [childField]: value },
          }
        : { ...item, [fieldName]: value }
    setItem(updatedItem)
  }

  const itemValue = getItemValue
    ? getItemValue(item)
    : childField
      ? item[fieldName]
        ? item[fieldName][childField]
        : ''
      : item[fieldName] || ''

  return (
    <div className={containerClassName}>
      <Label htmlFor={id}>{label}</Label>
      <Textarea
        ref={textAreaRef}
        onBlur={onBlur}
        value={itemValue}
        id={id}
        name={id}
        onChange={onChange}
        className={areaClassName}
      />
    </div>
  )
}

export default JsonTextArea
