import React from 'react'
import MotivationReflectionSectionFive from './MotivationReflectionSectionFive'
import { useExercise } from './ExerciseProvider'
import { Textarea } from '../../lib/ui/textarea'

const MotivationReflectionExercise = ({ questions, conclusion, section }) => {
  const { handleChange, getResponse, exerciseIndex, trackContent } =
    useExercise()
  return (
    <div>
      {section !== 4 && (
        <div className="questions">
          {questions.map((question, index) => (
            <div
              key={index}
              className="mb-8 w-full font-roboto text-base font-medium"
            >
              <div className="flex w-full flex-col">
                <label className="!mb-1">{question.question}</label>
                <Textarea
                  className="min-h-[125px] w-full rounded-[20px]"
                  onChange={(e) =>
                    handleChange(exerciseIndex, question.slug, e)
                  }
                  value={getResponse(exerciseIndex, question.slug) || ''}
                />
              </div>
            </div>
          ))}
        </div>
      )}
      <div className="mt-6 font-roboto text-lg">{conclusion}</div>
      {section === 4 && <MotivationReflectionSectionFive data={trackContent} />}
    </div>
  )
}

export default MotivationReflectionExercise
