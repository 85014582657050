import React, { useState, useEffect } from 'react'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
} from '../../../lib/ui/dialog'
import { MagicWand } from 'iconoir-react'
import { Button } from '../../../lib/ui/button'
import AylaResponseScrollArea from '../../ShortCourses/AylaResponseScrollArea'
import { useAiResponseStream } from './useAiResponseStream'
import { useTranslation } from '../../../hooks/useTranslation'

export default function SkillsMatchModal({
  showDialog,
  toggleDialog,
  job,
  children,
}) {
  const { t } = useTranslation('SkillsMatchButton')
  const {
    aiResponse,
    setAiResponse,
    setTargetJob,
    aiStreamComplete,
    setAiStreamComplete,
  } = useAiResponseStream()
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const showCursor = displayedText.length <= 0
  useEffect(() => {
    if (showDialog) {
      setAiResponse('')
      setAiStreamComplete(false)
      setDisplayedText('')
      setIndex(0)
      setTargetJob(job)
    }
  }, [showDialog, setAiStreamComplete, setTargetJob, job, setAiResponse])

  return (
    <Dialog open={showDialog} onOpenChange={toggleDialog}>
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>
      <DialogContent className="max-w-7xl">
        <DialogHeader>
          <DialogTitle>{t('ask_ayla')}</DialogTitle>
          <MagicWand className="h-6 w-6" variant="light" />
        </DialogHeader>
        <div className="px-4 text-center">
          {aiStreamComplete
            ? t('skills_match', { job_title: job.title })
            : t('ayla_working', { job_title: job.title })}
          <AylaResponseScrollArea
            showBorder={true}
            showCursor={showCursor}
            aylaResponse={aiResponse}
            typingComplete={aiStreamComplete}
            setTypingComplete={setAiStreamComplete}
            displayedText={displayedText.replace(/\n/g, '<br>')}
            setDisplayedText={setDisplayedText}
            index={index}
            setIndex={setIndex}
            className="mb-4 h-64 text-left"
          ></AylaResponseScrollArea>
          {aiStreamComplete && (
            <>
              <div className="mb-4">
                <Button>{t('tailor_resume')}</Button>
              </div>
              <div>
                <a
                  href={`/personal_brand/cover_letters/ai?use_skills_match=true&job_posting_id=${job.id}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Button>{t('tailor_cover_letter')}</Button>
                </a>
              </div>
            </>
          )}
        </div>
        <DialogFooter>
          <Button onClick={toggleDialog}>{t('close')}</Button>
        </DialogFooter>
        <DialogClose />
      </DialogContent>
    </Dialog>
  )
}
