import React from 'react'
import Results from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { useMultipleChoiceQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'
import parse from 'html-react-parser'

const AceTheInterviewResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)

  const breakPoints = [11, 7, 4]
  const [scoreSection, score] = useMultipleChoiceQuizScore(
    trackContent,
    t('questions'),
    breakPoints,
    'raw',
  )

  const resultsTitle = t(`results.${scoreSection}.title`, { score })
  const resultsP1 = t(`results.${scoreSection}.p1`)
  const resultsP2 = t(`results.${scoreSection}.p2`)
  const resultsListItems = t(`results.${scoreSection}.list_items`)
  const resultsResourceItems = t(`results.all.resource_items`)

  return (
    quizName === 'Ace The Interview' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 flex flex-col gap-4 sm:w-9/12">
            <h2>{resultsTitle}</h2>
            <div className="mt-4">
              <p>{parse(resultsP1)}</p>
              <p className="!font-bold">{resultsP2}</p>
              <ul>
                {resultsListItems.map((item, index) => (
                  <li key={index}>{parse(item)}</li>
                ))}
              </ul>
              <div className="mt-4">
                <p className="!font-bold">
                  {t(`results.all.ready_to_improve`)}
                </p>
                <p>{t(`results.all.additional_resources`)}</p>
                <ul>
                  {resultsResourceItems.map((item, index) => (
                    <li key={index}>{parse(item)}</li>
                  ))}
                </ul>
                <p className="mt-2">{t(`results.all.good_luck`)}</p>
              </div>
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}

export default AceTheInterviewResults
