import React from 'react'
import Results from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useMultipleChoiceQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'
import parse from 'html-react-parser'
import { useTranslation } from '../../../hooks/useTranslation'

const LinkedinAllStarResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const breakPoints = [8, 5]
  const { t } = useTranslation(formattedQuizName)
  const [scoreSection, score] = useMultipleChoiceQuizScore(
    trackContent,
    t('questions'),
    breakPoints,
    'raw',
  )
  const copy = t(`results.${scoreSection}`, { score })
  const numberedList = t('results.numbered_list', { returnObjects: true })
  const more_tips = t('results.more_tips')

  return (
    quizName === 'LinkedIn All Star' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-2 flex flex-col gap-4 sm:w-9/12">
            <h2>{t(`results.score`, { score })}</h2>
            <p className="!mb-0">{copy}</p>
            <p className="!mb-0">{t(`results.all`)}</p>
            <ol className="list-decimal pl-10">
              {numberedList.map((item, index) => (
                <li className="mb-2" key={index}>
                  {parse(item)}
                </li>
              ))}
            </ol>
            <p className="!mb-0">{parse(more_tips)}</p>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}

export default LinkedinAllStarResults
