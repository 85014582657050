import React from 'react'
import Results, { LinkifiedCopy } from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { useCalculateMultipleChoiceScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'

const ResumeReadyToImpressResults = () => {
  const {
    formattedQuizName,
    resultsPageAvatar,
    trackContent,
    quizName,
    hasResumeReview,
  } = useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)
  const [score, numCorrect] = useCalculateMultipleChoiceScore(
    trackContent,
    t('questions'),
  )
  const scoreSection = numCorrect > 11 ? 1 : numCorrect < 8 ? 3 : 2
  const copy = t(`results.${scoreSection}.copy`)
  const link = {
    linkPath: '/resume/resources/article/15-great-resume-tips',
    linkText: '15 Great Resume Tips',
  }
  const contentValues = Object.values(trackContent)

  return (
    quizName === 'Resume Ready to Impress' && (
      <Results isScrollable={true}>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 flex h-96 flex-col gap-4 sm:w-9/12">
            <h2>{t(`results.score`, { score })}</h2>
            <div className="mt-4">
              <div className="mt-4 grid grid-cols-1 gap-2">
                {copy.map((copy, idx) => (
                  <LinkifiedCopy key={`copy-${idx}`} copy={copy} link={link} />
                ))}
                {hasResumeReview && scoreSection === 3 && (
                  <p>{t(`results.offer_resume_review`)}</p>
                )}
              </div>

              {numCorrect < 15 && (
                <div className="mt-2 grid grid-cols-1 gap-1">
                  <p>
                    <strong>{t(`results.${scoreSection}.incorrect`)}</strong>
                  </p>
                  {contentValues.map((response, idx) => {
                    if (
                      t(
                        `questions.${idx + 1}.options.${response}.feedback_key`,
                      ) === 'no_response'
                    ) {
                      return (
                        <div key={`content-${idx + 1}`} className="mt-1">
                          <p>
                            <strong>
                              {t(`questions.${idx + 1}.question`)}
                            </strong>
                          </p>
                          <p>
                            {t(`questions.${idx + 1}.feedback.no_response`)[0]}
                          </p>
                        </div>
                      )
                    }
                  })}
                </div>
              )}
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}

export default ResumeReadyToImpressResults
