import React from 'react'
import { ArrowLeft, ArrowRight } from 'lucide-react'
import { Button } from '../../lib/ui/button'
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
  useCarousel,
} from '../../lib/ui/carousel'
import parse from 'html-react-parser'
import { DotButton, useDotButton } from './DotButton'

const NextButton = () => {
  const { scrollNext, canScrollNext } = useCarousel()
  return (
    <Button
      disabled={!canScrollNext}
      onClick={scrollNext}
      className="h-8 w-8 rounded-full"
      size="icon"
      variant="outline"
    >
      <ArrowRight className="h-4 w-4" />
      <span className="sr-only">Next slide</span>
    </Button>
  )
}

const PreviousButton = () => {
  const { scrollPrev, canScrollPrev } = useCarousel()
  return (
    <Button
      disabled={!canScrollPrev}
      onClick={scrollPrev}
      className="h-8 w-8 rounded-full"
      size="icon"
      variant="outline"
    >
      <ArrowLeft className="h-4 w-4" />
      <span className="sr-only">Previous slide</span>
    </Button>
  )
}

const NavBubbles = () => {
  const { api } = useCarousel()
  const { selectedIndex, scrollSnaps, onDotButtonClick } = useDotButton(api)
  return (
    <div className="relative -bottom-[2.5rem] flex flex-row justify-center gap-4 sm:-bottom-[4.375rem]">
      {scrollSnaps.map((_, index) => (
        <DotButton
          key={index}
          onClick={() => onDotButtonClick(index)}
          className={'embla__dot'.concat(
            index === selectedIndex ? ' embla__dot--selected' : '',
          )}
          aria-label={`Select example ${index + 1}`}
        />
      ))}
    </div>
  )
}

const CarouselBox = ({ carouselObject }) => {
  const { boxes, title, icon } = carouselObject

  return (
    <div className="mt-4 rounded-3xl bg-[#EAF4FF] px-4 pb-16 pt-4 first:mt-0 sm:px-24 sm:pt-4">
      <div className="mb-4 flex font-bold">
        {icon}
        {title && <span className="ml-2 text-xl">{title}</span>}
      </div>
      <Carousel className="h-max rounded-2xl bg-white px-4 pt-6 sm:px-6 sm:pb-8 sm:pt-8">
        <CarouselPrevious className="-left-16 hidden sm:!visible sm:!inline-flex" />
        <CarouselContent>
          {boxes.map((box, index) => {
            const { subtitle, content, plugins } = box
            return (
              <CarouselItem key={index}>
                {subtitle && <div className="subtitle">{subtitle}</div>}
                {content.map((example) => {
                  const text =
                    plugins && plugins.includes('parse-html')
                      ? parse(example)
                      : example
                  return text
                })}
              </CarouselItem>
            )
          })}
        </CarouselContent>
        <div className="mt-4 items-center sm:-mb-4 sm:mt-0">
          <div className="flex h-10 w-full flex-row justify-center gap-6 sm:hidden">
            <PreviousButton />
            <NextButton />
          </div>
        </div>
        <CarouselNext className="-right-16 hidden sm:!visible sm:!inline-flex" />
        <NavBubbles />
      </Carousel>
    </div>
  )
}
export default CarouselBox
