import React from 'react'

const Stack = ({ children, className, direction = 'column', spacing = 0 }) => {
  return (
    <div
      className={`yns-stack flex ${
        direction === 'row' ? 'flex-row' : 'flex-col'
      } ${
        direction === 'row' ? `flex-wrap gap-${spacing}` : `gap-${spacing}`
      } ${direction === 'row' ? `items-center` : ``} ${className || ''}`}
    >
      {children}
    </div>
  )
}

export default Stack
