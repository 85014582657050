import React, { useEffect, useState } from 'react'
import { ArrayInputs } from './common'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import ExpandyPanel from '../../../lib/ui/ExpandyPanel'
import { EditorItemField, useEditorItem } from './EditorItemProvider'

const SkillsItem = () => {
  const {
    index,
    activeIndex,
    setActiveIndex,
    item,
    setItem,
    updateItem,
    deleteItem,
    updateCollection,
  } = useEditorItem()

  const [newKeyword, setNewKeyword] = useState(-1)

  const headingLabel = item.name || 'New Skill Section'

  const toggleExpanded = () => {
    if (activeIndex == index) {
      setActiveIndex(-1)
    } else {
      setActiveIndex(index)
    }
  }

  const addKeyword = () => {
    setKeywords([...(item.keywords || []), ''])
    setNewKeyword(item.keywords?.length || 0)
  }

  const setKeywords = (keywords) => {
    const newSkill = { ...item, keywords }
    setItem(newSkill)
  }

  const deleteKeyword = (keywordIndex) => {
    const updatedKeywords = item.keywords.filter((_, i) => i !== keywordIndex)
    updateCollection('keywords', updatedKeywords)
  }

  const onKeyPress = (e) => {
    if (e.target.value === '') return
    if (e.key === 'Enter') {
      const eventTargetIndex = parseInt(e.target.name.split('-')[2])
      if (eventTargetIndex < item.keywords.length - 1) {
        setNewKeyword(eventTargetIndex + 1)
      } else {
        addKeyword()
      }
    }
  }

  useEffect(() => {
    if (newKeyword >= 0) {
      document
        .querySelector(
          `#skill-${index} input[name=keyword-arrayInput-${newKeyword}`,
        )
        ?.focus()
      setNewKeyword(-1)
    }
  }, [newKeyword, index])

  const expanded = activeIndex === index

  return (
    <div className="mb-4 flex flex-row items-center" id={`skill-${index}`}>
      <ExpandyPanel
        headingText={headingLabel}
        expanded={expanded}
        index={index}
        onClick={toggleExpanded}
        className="flex-grow"
      >
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          <EditorItemField label="Skill Group" fieldName="name" />
          <EditorItemField label="Proficiency Level" fieldName="level" />
        </div>
        <div>
          <div className="flex flex-row gap-2 pt-4">
            <span className="font-bold">Skills</span>
            <button
              className="flex flex-row items-center text-primary"
              onClick={addKeyword}
            >
              Add
              <PlusIcon className="ml-1 inline w-4 stroke-2" />
            </button>
          </div>
          <div className="flex flex-col gap-2 pt-4">
            <ArrayInputs
              items={item?.keywords || []}
              keyPrefix="keyword"
              onChange={setKeywords}
              onBlur={updateItem}
              onKeyPress={onKeyPress}
              onDeleteClick={deleteKeyword}
            />
          </div>
        </div>
      </ExpandyPanel>
      {!expanded && (
        <button
          onClick={deleteItem}
          className="max-w-min text-[#b9b9b9]"
          aria-label="remove skill"
        >
          <TrashIcon className="ml-2 w-8" />
        </button>
      )}
    </div>
  )
}
export default SkillsItem
