import React from 'react'
import { Stack } from '~/components/_layouts'
import { ActionPlanCard } from '../cards/ActionPlanCard'
import { MyJobsCard } from '../cards/MyJobsCard'
import { AylaCard } from '../cards/AylaCard'
import { RecommendedJobsCard } from '../cards/RecommendedJobsCard'
import { RecommendedBlogsCard } from '../cards/RecommendedBlogsCard'
import { RecommendedCard } from '../cards/RecommendedCard'

export function Solo() {
  return (
    <Stack spacing={8}>
      <div className="grid gap-8 xl:grid-cols-[1fr_1fr] 2xl:grid-cols-[4fr_3fr_3fr]">
        <ActionPlanCard className="action-plan-card xl:col-span-2 2xl:col-span-1" />
        <MyJobsCard className="my-jobs-card" />
        <AylaCard className="ayla-card" />
      </div>
      <div className="grid grid-cols-1 gap-8 lg:grid-cols-[1fr_1fr]">
        <RecommendedJobsCard className="recommended-jobs" />
        <RecommendedBlogsCard className="recommended-blogs" />
      </div>
      <RecommendedCard />
    </Stack>
  )
}
