import React from 'react'
import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
} from '@react-pdf/renderer'
import { lora, inter } from '../../lib/pdf/fonts'

Font.register(lora)
Font.register(inter)

const Template1 = ({ referenceSheetDocument }) => {
  const styles = StyleSheet.create({
    page: {
      width: '100%',
      padding: '40px',
    },
    mainContainer: {
      width: '100%',
      flexDirection: 'column',
    },
    headingContainer: {
      flexDirection: 'row',
      border: '0px solid #000000',
    },
    heading: {
      fontFamily: 'Lora',
      fontSize: 24,
      fontWeight: 'bold',
      color: '#005BA2',
      lineHeight: '30.72px',
    },
    leftSection: {
      flexBasis: '67%',
      flexGrow: 1,
      paddingRight: '40px',
    },
    rightSection: {
      flexBasis: '33%',
      flexGrow: 1,
    },
    contactSection: {
      color: '#005BA2',
      fontFamily: 'Lora',
      fontSize: '10px',
      minHeight: '60px',
    },
    contactItem: {
      paddingBottom: '4px',
    },
    sectionTitle: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 'bold',
      marginTop: '16px',
    },
  })

  const stripHttp = (url) => {
    return url?.replace(/https{0,1}:\/\//, '')
  }

  const { basics, references } = referenceSheetDocument
  const { name, label, email, phone, url, profiles } = basics || {}

  const linkedinProfile =
    profiles &&
    profiles.find((element) => {
      return element.network?.toLowerCase() === 'linkedin'
    })

  const displayUrl = stripHttp(url)

  const linkedinProfileUrl = linkedinProfile && stripHttp(linkedinProfile.url)

  return (
    <Document pageMode="useNone" pageLayout="OneColumn">
      <Page size="Letter" style={styles.page}>
        <View style={styles.mainContainer}>
          <View style={styles.headingContainer}>
            <View style={styles.leftSection}>
              {name && <Text style={styles.heading}>{name},</Text>}
              <Text style={styles.heading}>{label}</Text>
            </View>
            <View style={styles.rightSection}>
              <View style={styles.contactSection}>
                <Text style={styles.contactItem}>{email}</Text>
                <Text style={styles.contactItem}>{phone}</Text>
                <Text style={styles.contactItem}>{linkedinProfileUrl}</Text>
                <Text style={styles.contactItem}>{displayUrl}</Text>
              </View>
            </View>
          </View>
          <View>
            {references && references.length > 0 && (
              <ReferencesSection references={references} styles={styles} />
            )}
          </View>
        </View>
      </Page>
    </Document>
  )
}
export default Template1

const ReferencesSection = ({ references, styles }) => {
  return (
    <>
      <Text style={styles.sectionTitle}>References</Text>
      {references.map((refItem, index) => (
        <ReferenceView reference={refItem} key={`reference-${index}`} />
      ))}
    </>
  )
}

const ReferenceView = ({ reference }) => {
  const styles = StyleSheet.create({
    container: {
      paddingTop: '15px',
      fontSize: '12px',
      marginRight: '40px',
    },
    title: {
      fontFamily: 'Inter',
      fontSize: '12px',
      fontWeight: 'semibold',
      color: '#005BA2',
      paddingRight: '8px',
    },
    referenceRow: {
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginBottom: '3px',
      paddingRight: '40px',
    },
    connectionRow: {
      marginTop: '6px',
      paddingRight: '40px',
    },
  })

  if (!reference.name) {
    return null
  }

  const {
    name,
    positionTitle,
    companyName,
    streetAddress,
    cityStateZip,
    phoneNumber,
    email,
    connection,
  } = reference

  return (
    <View style={styles.container} wrap={false}>
      <View style={styles.referenceRow}>
        <Text style={styles.title}>{name}</Text>
      </View>
      {positionTitle && positionTitle.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{positionTitle}</Text>
        </View>
      )}
      {companyName && companyName.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{companyName}</Text>
        </View>
      )}
      {streetAddress && streetAddress.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{streetAddress}</Text>
        </View>
      )}
      {cityStateZip && cityStateZip.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{cityStateZip}</Text>
        </View>
      )}
      {phoneNumber && phoneNumber.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{phoneNumber}</Text>
        </View>
      )}
      {email && email.length > 0 && (
        <View style={styles.referenceRow}>
          <Text>{email}</Text>
        </View>
      )}
      {connection && connection.length > 0 && (
        <View style={styles.connectionRow}>
          <Text>{connection}</Text>
        </View>
      )}
    </View>
  )
}
