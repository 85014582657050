import React from 'react'
import { useQuiz } from '../QuizProvider'
import { cn } from '../../../lib/utils'
import { Button } from '../../../lib/ui/button'
import useLikertLayout from './LikertLayoutProvider'

const LayoutFiveOptions = ({ options, handleNextClick, children }) => {
  const { quizIndex } = useQuiz()

  const alphabet = ['A', 'B', 'C', 'D', 'E']

  return (
    <div className="z-10 justify-between sm:flex">
      <div className="question-options flex flex-col gap-6 sm:gap-8 md:w-2/5">
        {Object.values(options).map((optionInfo, index) => {
          const { option, points } = optionInfo
          return (
            <Option
              key={`response-${index}`}
              id={`btn-${quizIndex + 1}-${index}`}
              index={index}
              points={points}
              handleNextClick={handleNextClick}
            >
              <div
                className={
                  'flex h-8 w-8 items-center justify-center rounded-full bg-[#E5F6FF] !text-[#2F2F2F]'
                }
              >
                {alphabet[index]}
              </div>
              <div className="col-span-5 ml-2 text-left xl:col-span-9">
                {option}
              </div>
            </Option>
          )
        })}
      </div>
      {children}
    </div>
  )
}

export default LayoutFiveOptions

const Option = ({ index, points, handleNextClick, children, ...props }) => {
  const { trackContent, quizIndex } = useQuiz()
  const { handleOptionClick } = useLikertLayout()
  const selected = trackContent[quizIndex + 1] === index + 1

  const buttonClass =
    'whitespace-pre-line rounded-[10px] border border-solid border-[#8E8E8E hover:bg-primary hover:text-white'
  const selectedClass = 'bg-primary text-white'
  const nonSelectedCLass = 'bg-white text-[#2F2F2F]'

  const handleClick = () => {
    handleOptionClick(index, points)
    handleNextClick()
  }
  return (
    <Button
      className={cn(
        'grid h-fit grid-cols-6 gap-4 md:min-h-[60px] md:justify-start md:px-4 xl:grid-cols-10',
        buttonClass,
        selected ? selectedClass : nonSelectedCLass,
      )}
      type="button"
      onClick={handleClick}
      {...props}
    >
      {children}
    </Button>
  )
}
