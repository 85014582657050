import React from 'react'
import { DocumentPage, Text, View, StyleSheet, useStyle } from '../../lib/pdf'

const isNotBlank = (item) => ![null, undefined, ''].includes(item)

export const defaultStyle = {
  xMargin: 0.4,
  yMargin: 0.4,
  size: 'Letter',
  lineHeightMultiplier: 1.0,
  listLineHeightMultiplier: 1.0,
  primaryFontFamily: 'Times New Roman',
}

export const Template = ({ jsonDocument, templateStyle, candidateName }) => {
  const { basics, coverLetter } = jsonDocument
  const {
    salutation,
    firstName,
    lastName,
    positionTitle,
    companyName,
    companyDivision,
    address1,
    address2,
    cityStateZip,
  } = basics || {}
  const { content } = coverLetter || {}
  const fullName = [firstName, lastName].filter(isNotBlank).join(' ')
  const completeSalutation =
    salutation && lastName ? `${salutation} ${fullName}` : ''
  const addresseeItems = [
    completeSalutation,
    positionTitle,
    companyName,
    companyDivision,
    address1,
    address2,
    cityStateZip,
  ].filter(isNotBlank)

  return (
    <DocumentPage templateStyle={templateStyle}>
      <MainView
        addresseeItems={addresseeItems}
        salutation={salutation}
        lastName={lastName}
        completeSalutation={completeSalutation}
        content={content}
        candidateName={candidateName}
      />
    </DocumentPage>
  )
}

const MainView = ({
  addresseeItems,
  salutation,
  lastName,
  completeSalutation,
  content,
  candidateName,
}) => {
  const {
    templateStyle: { lineHeightMultiplier },
  } = useStyle()
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'row',
      width: '100%',
      padding: '20px',
    },
    mainContainer: {
      color: '#000000',
      flexDirection: 'column',
      width: '100%',
      fontSize: 12,
      lineHeight: `${16 * lineHeightMultiplier}px`,
    },
  })
  return (
    <View style={styles.mainContainer}>
      <View style={{ marginBottom: 10 }}>
        {addresseeItems.map((item, index) => {
          return (
            <View key={index}>
              <Text>{item}</Text>
            </View>
          )
        })}
      </View>
      {salutation && lastName && (
        <Text style={{ marginBottom: 10 }}>Dear {completeSalutation}:</Text>
      )}
      <Text style={{ marginBottom: 10 }}>{content}</Text>
      {content && candidateName && (
        <>
          <Text style={{ marginBottom: 10 }}>Sincerely,</Text>
          <Text>{candidateName}</Text>
        </>
      )}
    </View>
  )
}
