import React, { useState } from 'react'
import { ExerciseIcon, BuilderIcon } from './icons'
import { useLandingPage } from './LandingPageProvider'
import useTranslation from '../TranslationProvider'
import { iconMap } from './LandingItemCard'
import { Button } from '../../../lib/ui/button'
import {
  ClipboardDocumentListIcon,
  HeartIcon,
} from '@heroicons/react/24/outline'
import { TrashIcon } from '../../job_search/SavedRecruitersPage/Icons'
import { apiDelete } from '../../../util/api'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
  DialogFooter,
  DialogDescription,
} from '../../../lib/ui/dialog'
import { cn } from '../../../lib/utils'
import NewCoverLetterModal from '../../CoverLetterBuilder/NewCoverLetterModal'

const RightNavListElement = ({ exercise, index }) => {
  const {
    right_nav_title,
    path,
    type,
    right_nav_cta_path,
    right_nav_cta_description,
    right_nav_cta_icon,
    right_nav_title_icon,
    collection,
    right_nav_empty_state,
    right_nav_primary_cta,
    right_nav_cta_title,
    include_heart_icon,
  } = exercise

  const { pitch, pitch_complete } = useLandingPage()
  const { t } = useTranslation()
  const [items, setItems] = useState(collection && JSON.parse(collection))
  const [showDialog, setShowDialog] = useState(false)
  const [itemToDelete, setItemToDelete] = useState(null)
  const toggleDialog = () => setShowDialog((prev) => !prev)

  const RightNavCtaIcon = ({ className }) => {
    return right_nav_cta_icon === 'checklist' ? (
      <ClipboardDocumentListIcon className={cn('inline w-6 pl-1', className)} />
    ) : (
      <ExerciseIcon className={cn('inline w-6 pl-1', className)} />
    )
  }

  const RightNavTitleIcon = ({ className }) => {
    return right_nav_title_icon === 'builder' ? (
      <BuilderIcon className={className} />
    ) : (
      <RightNavCtaIcon className={className} />
    )
  }

  const CtaContent = () => {
    return (
      <div className="mb-6 w-full rounded-2.5xl border border-solid border-[#B9B9B9] bg-[#F9FCFE] px-6 py-5">
        <div className="flex items-center gap-1 font-roboto-slab text-lg font-medium text-primary">
          {right_nav_cta_title} <RightNavCtaIcon />
        </div>
        <div className="mt-6">{right_nav_cta_description}</div>
        <CtaButton
          buttonPath={right_nav_cta_path}
          text={
            right_nav_cta_icon == 'checklist'
              ? t('view_checklist')
              : t('take_exercise')
          }
          icon={<RightNavCtaIcon />}
        />
      </div>
    )
  }

  const PitchContent = () => {
    return (
      <a
        href={path}
        className="!w-fit hover:!underline focus:!underline active:!underline"
      >
        {pitch.content.text}
      </a>
    )
  }

  const confirmDelete = (e, item) => {
    e.preventDefault()
    setItemToDelete(item)
    setShowDialog(true)
  }

  const handleConfirmDelete = () => {
    if (itemToDelete) {
      handleDelete(itemToDelete)
      setShowDialog(false)
      setItemToDelete(null)
    }
  }

  const handleDelete = async (doc) => {
    let deletePath = `/personal_brand/reference_sheet_builder/${doc.id}`
    if (doc.type.includes('UploadedCoverLetter')) {
      deletePath = `/story/document/cover_letters/${doc.id}`
    } else if (doc.type.includes('CoverLetter')) {
      deletePath = `/personal_brand/cover_letter_builder/${doc.id}`
    }
    try {
      const response = await apiDelete(deletePath)
      if (response.status === 200) {
        setItems((prevItems) => prevItems.filter((item) => item.id !== doc.id))
      } else {
        console.error('Failed to delete the item')
      }
    } catch (error) {
      console.error('Error:', error)
    }
  }

  const ConfirmDialog = () => {
    return (
      <Dialog open={showDialog} onOpenChange={toggleDialog}>
        <DialogTrigger asChild className="cursor-pointer"></DialogTrigger>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>{t('please_confirm')}</DialogTitle>
          </DialogHeader>
          <DialogDescription>
            {t('confirm_text', { item_name: itemToDelete?.name })}
          </DialogDescription>
          <DialogFooter>
            <Button onClick={handleConfirmDelete}>{t('yes_sure')}</Button>
          </DialogFooter>
          <DialogClose />
        </DialogContent>
      </Dialog>
    )
  }

  const CtaButton = ({ buttonPath, text, icon }) => {
    return (
      <a
        href={buttonPath}
        rel="noreferrer"
        className="ga-trackable mt-6 flex flex-row"
      >
        <div className="flex w-full justify-center">
          <Button variant="outline" className="gap-4">
            {text}{' '}
            {React.cloneElement(icon, {
              className: 'size-5 w-6 inline pl-1',
            })}
          </Button>
        </div>
      </a>
    )
  }

  const CollectionContent = () => {
    const icon = iconMap[type] || ExerciseIcon
    if (
      !items ||
      items.length === 0 ||
      (items[0].type == 'Pitch' && !pitch_complete)
    ) {
      return (
        <div className="mt-6 flex flex-col">
          {right_nav_empty_state && <div>{right_nav_empty_state}</div>}
          {path.match('cover_letters/new_ai_or_builder') ? (
            <NewCoverLetterModal
              buttonVariant="outline"
              buttonText={right_nav_primary_cta}
              buttonIcon={icon}
              buttonClass="mt-6 gap-4"
            />
          ) : (
            <CtaButton
              buttonPath={path}
              text={right_nav_primary_cta}
              icon={icon}
            />
          )}
        </div>
      )
    } else {
      return (
        <div className="mt-6 flex flex-col gap-2">
          {items.map((item, idx) => {
            return item.type === 'Pitch' && pitch_complete ? (
              <PitchContent
                key={`${right_nav_title
                  .toLowerCase()
                  .replace(/\s+/g, '_')}-${idx}`}
              />
            ) : (
              <ul className="list-inside list-disc text-intoo-text" key={idx}>
                <li className="flex justify-between pb-1">
                  <a
                    className="hover:text-primary hover:!underline"
                    href={item.path}
                  >
                    {item.name}
                  </a>
                  <div className="flex">
                    {item.type != 'PersonalBrandStatement' &&
                      item.type != 'ReasonForLeavingStatement' && (
                        <div
                          className="cursor-pointer"
                          key={`${right_nav_title
                            .toLowerCase()
                            .replace(/\s+/g, '_')}-${idx}`}
                          onClick={(e) => confirmDelete(e, item)}
                          title={`Delete ${item.name}`}
                        >
                          <TrashIcon className="mb-2 h-6 w-6 stroke-primary" />
                        </div>
                      )}
                  </div>
                </li>
              </ul>
            )
          })}
        </div>
      )
    }
  }

  return (
    <>
      <div
        className={cn(
          'mb-6 w-full rounded-2.5xl border border-solid border-[#B9B9B9] bg-[#F9FCFE] px-6 py-5',
          index == 0 ? 'mt-8' : '',
        )}
      >
        <div className="pr-4 font-roboto-slab text-lg font-medium text-primary">
          {right_nav_title}{' '}
          {include_heart_icon ? (
            <HeartIcon className="inline size-5 w-6" />
          ) : (
            <RightNavTitleIcon className="inline size-5 w-6"></RightNavTitleIcon>
          )}
        </div>
        <CollectionContent />
      </div>
      {right_nav_cta_path && <CtaContent />}
      <ConfirmDialog />
    </>
  )
}

export default RightNavListElement
