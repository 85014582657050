import React from 'react'

export const NavBubble = ({ active = false }) => {
  const displayClass = active
    ? 'fill-primary'
    : 'hover:fill-[#EAF4FF] fill-white'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
    >
      <title>Navigation bubble</title>
      <circle cx="8" cy="8" r="7.5" stroke="#B9B9B9" />
    </svg>
  )
}

export const BackArrow = ({ className = 'stroke-white' }) => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      width="22"
      height="18"
      viewBox="0 0 22 18"
      fill="none"
    >
      <title>Back arrow</title>
      <path
        d="M1 16.4276C3.4465 13.7925 5.619 12.2974 7.518 11.9418C9.4165 11.5866 11.224 11.5328 12.941 11.7807V16.5L21 8.79926L12.941 1.5V5.98544C9.7665 6.0075 7.068 7.0125 4.845 9C2.6225 10.9875 1.3405 13.4634 1 16.4276Z"
        strokeWidth="1.5"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Bookmark = ({ active = false }) => {
  const className = active ? 'stroke-primary fill-primary' : 'stroke-black'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <title>Bookmark</title>
      <path
        d="M17.593 3.322C18.693 3.45 19.5 4.399 19.5 5.507V21L12 17.25L4.5 21V5.507C4.5 4.399 5.306 3.45 6.407 3.322C10.1232 2.89063 13.8768 2.89063 17.593 3.322Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const CommentBadge = ({ title, className, active = false }) => {
  const circleFill = active ? 'var(--theme-primary)' : 'white'
  const pathFill = active ? 'white' : 'var(--theme-primary)'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <title>{title}</title>
      <circle
        cx="24"
        cy="24"
        r="23.5"
        fill={circleFill}
        stroke="var(--theme-primary)"
      />
      <path
        d="M17.625 16.25C16.9951 16.25 16.391 16.5002 15.9456 16.9456C15.5002 17.391 15.25 17.9951 15.25 18.625V26.375C15.25 27.0049 15.5002 27.609 15.9456 28.0544C16.391 28.4998 16.9951 28.75 17.625 28.75H19.25V32.669L24.8015 28.75H30.375C31.0049 28.75 31.609 28.4998 32.0544 28.0544C32.4998 27.609 32.75 27.0049 32.75 26.375V18.625C32.75 17.9951 32.4998 17.391 32.0544 16.9456C31.609 16.5002 31.0049 16.25 30.375 16.25H17.625ZM14 18.625C14 17.6636 14.3819 16.7416 15.0617 16.0617C15.7416 15.3819 16.6636 15 17.625 15H30.375C31.3364 15 32.2584 15.3819 32.9383 16.0617C33.6181 16.7416 34 17.6636 34 18.625V26.375C34 27.3364 33.6181 28.2584 32.9383 28.9383C32.2584 29.6181 31.3364 30 30.375 30H25.1985L19.7735 33.829C19.029 34.355 18 33.822 18 32.91V30H17.625C16.6636 30 15.7416 29.6181 15.0617 28.9383C14.3819 28.2584 14 27.3364 14 26.375V18.625Z"
        fill={pathFill}
      />
    </svg>
  )
}

export const CommentBubble = ({ size = 25, active = false }) => {
  const className = active
    ? 'stroke-[1px] stroke-white fill-white'
    : 'stroke-black fill-black'
  const strokeWidth = active ? '0.5' : '0.25'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      width={size}
      height={size}
      viewBox="0 0 25 25"
      fill="none"
    >
      <title>Comment bubble</title>
      <path
        d="M5.85935 4.42708C5.20322 4.42708 4.57396 4.68773 4.11 5.15169C3.64604 5.61565 3.3854 6.24491 3.3854 6.90104V14.974C3.3854 15.6301 3.64604 16.2594 4.11 16.7233C4.57396 17.1873 5.20322 17.4479 5.85935 17.4479H7.55206V21.5302L13.3349 17.4479H19.1406C19.7967 17.4479 20.426 17.1873 20.89 16.7233C21.3539 16.2594 21.6146 15.6301 21.6146 14.974V6.90104C21.6146 6.24491 21.3539 5.61565 20.89 5.15169C20.426 4.68773 19.7967 4.42708 19.1406 4.42708H5.85935ZM2.08331 6.90104C2.08331 5.89957 2.48114 4.93912 3.18929 4.23098C3.89744 3.52283 4.85789 3.125 5.85935 3.125H19.1406C20.1421 3.125 21.1025 3.52283 21.8107 4.23098C22.5188 4.93912 22.9166 5.89957 22.9166 6.90104V14.974C22.9166 15.9754 22.5188 16.9359 21.8107 17.644C21.1025 18.3522 20.1421 18.75 19.1406 18.75H13.7484L8.09738 22.7385C7.32186 23.2865 6.24998 22.7312 6.24998 21.7812V18.75H5.85935C4.85789 18.75 3.89744 18.3522 3.18929 17.644C2.48114 16.9359 2.08331 15.9754 2.08331 14.974V6.90104Z"
        strokeWidth={strokeWidth}
      />
    </svg>
  )
}

export const HighlightBadge = ({ title, active = false }) => {
  const circleFill = active ? 'var(--theme-primary)' : 'white'
  const circleStroke = active ? 'white' : 'var(--theme-primary)'
  const pathFill = active ? 'white' : 'var(--theme-primary)'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <title>{title}</title>
      <circle
        cx="24"
        cy="24"
        r="23.5"
        fill={circleFill}
        stroke={circleStroke}
      />
      <g clipPath="url(#clip0_839_4700)">
        <path
          d="M22.5459 29.3733C22.4582 29.3738 22.3712 29.357 22.29 29.3238C22.2088 29.2907 22.1349 29.2418 22.0726 29.18L17.7793 24.8867C17.7175 24.8244 17.6686 24.7505 17.6354 24.6693C17.6023 24.588 17.5854 24.5011 17.5859 24.4133C17.5854 24.3256 17.6023 24.2386 17.6354 24.1574C17.6686 24.0762 17.7175 24.0023 17.7793 23.94L27.3326 14.36C27.6767 14.0164 28.0851 13.7439 28.5344 13.5581C28.9838 13.3723 29.4654 13.2768 29.9516 13.2771C30.4379 13.2774 30.9194 13.3735 31.3685 13.5599C31.8176 13.7463 32.2257 14.0193 32.5693 14.3633C32.9129 14.7074 33.1854 15.1158 33.3712 15.5651C33.557 16.0145 33.6525 16.4961 33.6522 16.9824C33.6519 17.4686 33.5558 17.9501 33.3694 18.3992C33.183 18.8483 32.91 19.2564 32.5659 19.6L23.0193 29.18C22.957 29.2418 22.8831 29.2907 22.8019 29.3238C22.7207 29.357 22.6337 29.3738 22.5459 29.3733ZM19.2126 24.4133L22.5459 27.7467L31.6526 18.6667C32.0939 18.2243 32.3417 17.6249 32.3417 17C32.3417 16.3751 32.0939 15.7757 31.6526 15.3333C31.2029 14.9061 30.6063 14.6679 29.9859 14.6679C29.3656 14.6679 28.769 14.9061 28.3193 15.3333L19.2126 24.4133Z"
          fill={pathFill}
        />
        <path
          d="M18.9203 30.8533C18.7441 30.8561 18.5739 30.789 18.447 30.6667L16.3003 28.5133C16.2067 28.4203 16.1428 28.3016 16.1167 28.1723C16.0906 28.0429 16.1035 27.9087 16.1537 27.7867L17.6337 24.16C17.6753 24.0586 17.7413 23.9689 17.8258 23.8991C17.9103 23.8292 18.0107 23.7813 18.1182 23.7595C18.2257 23.7378 18.3368 23.7429 18.4419 23.7743C18.5469 23.8058 18.6426 23.8627 18.7203 23.94L23.0203 28.2333C23.1006 28.3109 23.1601 28.4075 23.1934 28.5141C23.2267 28.6207 23.2326 28.7339 23.2107 28.8434C23.1888 28.9529 23.1398 29.0551 23.0681 29.1408C22.9964 29.2264 22.9043 29.2926 22.8003 29.3333L19.1737 30.8133C19.0916 30.8389 19.0063 30.8523 18.9203 30.8533ZM17.5537 27.88L19.0803 29.4L21.367 28.4667L18.4937 25.5933L17.5537 27.88Z"
          fill={pathFill}
        />
        <path
          d="M17.9603 32H14.6269C14.5063 32.0006 14.3878 31.9684 14.284 31.907C14.1802 31.8455 14.095 31.757 14.0376 31.651C13.9801 31.5449 13.9525 31.4253 13.9576 31.3048C13.9628 31.1843 14.0006 31.0674 14.0669 30.9667L16.2136 27.6733C16.2678 27.5897 16.34 27.5192 16.425 27.4672C16.51 27.4151 16.6056 27.3828 16.7047 27.3726C16.8039 27.3623 16.904 27.3745 16.9979 27.408C17.0917 27.4416 17.1768 27.4958 17.2469 27.5667L19.3869 29.7133C19.505 29.8312 19.5745 29.9889 19.5819 30.1556C19.5894 30.3222 19.534 30.4855 19.4269 30.6133L18.4669 31.76C18.405 31.8344 18.3275 31.8944 18.24 31.9358C18.1526 31.9773 18.0571 31.9992 17.9603 32ZM15.8536 30.6667H17.6469L18.0003 30.2267L16.8669 29.0933L15.8536 30.6667Z"
          fill={pathFill}
        />
        <path
          d="M14.0391 32.6667H34.0391V34.6667H14.0391V32.6667Z"
          fill={pathFill}
        />
      </g>
      <defs>
        <clipPath id="clip0_839_4700">
          <rect
            width="24"
            height="24"
            fill={circleFill}
            transform="translate(12 12)"
          />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Highlighter = ({ active = false }) => {
  const className = active
    ? 'stroke-white fill-white'
    : 'stroke-black fill-black'
  const strokeWidth = active ? '0.5' : '0.2'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      width="25"
      height="25"
      viewBox="0 0 25 25"
      fill="none"
    >
      <title>Highlighter</title>
      <g clipPath="url(#clip0_532_653)">
        <path
          d="M10.9861 18.0972C10.8947 18.0977 10.8041 18.0802 10.7195 18.0457C10.6349 18.0111 10.558 17.9602 10.4931 17.8958L6.02086 13.4236C5.9565 13.3587 5.90558 13.2818 5.87102 13.1971C5.83646 13.1125 5.81894 13.0219 5.81947 12.9305C5.81894 12.8392 5.83646 12.7486 5.87102 12.6639C5.90558 12.5793 5.9565 12.5024 6.02086 12.4375L15.9722 2.45833C16.3306 2.10038 16.756 1.81654 17.2241 1.62299C17.6922 1.42945 18.1939 1.33 18.7004 1.33032C19.2069 1.33065 19.7084 1.43073 20.1763 1.62487C20.6441 1.81901 21.0692 2.1034 21.4271 2.4618C21.7851 2.8202 22.0689 3.24559 22.2624 3.71369C22.456 4.18178 22.5554 4.68342 22.5551 5.18995C22.5548 5.69648 22.4547 6.19799 22.2606 6.66584C22.0664 7.13369 21.782 7.55872 21.4236 7.91666L11.4792 17.8958C11.4143 17.9602 11.3373 18.0111 11.2527 18.0457C11.1681 18.0802 11.0775 18.0977 10.9861 18.0972ZM7.51391 12.9305L10.9861 16.4028L20.4722 6.94444C20.9319 6.48359 21.1901 5.85924 21.1901 5.20833C21.1901 4.55741 20.9319 3.93306 20.4722 3.47221C20.0038 3.02721 19.3823 2.77909 18.7361 2.77909C18.09 2.77909 17.4685 3.02721 17 3.47221L7.51391 12.9305Z"
          strokeWidth={strokeWidth}
        />
        <path
          d="M7.2083 19.6389C7.02471 19.6418 6.84743 19.5719 6.71525 19.4444L4.47914 17.2014C4.38161 17.1045 4.31503 16.9809 4.28784 16.8461C4.26064 16.7114 4.27405 16.5716 4.32636 16.4444L5.86803 12.6667C5.91136 12.561 5.9801 12.4676 6.06813 12.3949C6.15617 12.3221 6.2608 12.2722 6.37274 12.2495C6.48469 12.2269 6.6005 12.2322 6.7099 12.2649C6.81931 12.2977 6.91894 12.357 6.99997 12.4375L11.4791 16.9097C11.5628 16.9906 11.6248 17.0911 11.6594 17.2022C11.6941 17.3132 11.7003 17.4312 11.6774 17.5452C11.6546 17.6593 11.6035 17.7658 11.5288 17.855C11.4542 17.9441 11.3583 18.0131 11.25 18.0556L7.47219 19.5972C7.38671 19.6238 7.29782 19.6379 7.2083 19.6389ZM5.78469 16.5417L7.37497 18.125L9.75692 17.1528L6.76386 14.1597L5.78469 16.5417Z"
          strokeWidth={strokeWidth}
        />
        <path
          d="M6.20836 20.8333H2.73614C2.61049 20.8339 2.48703 20.8004 2.37892 20.7364C2.27081 20.6724 2.18209 20.5802 2.12222 20.4698C2.06236 20.3593 2.03358 20.2346 2.03897 20.1091C2.04435 19.9836 2.0837 19.8619 2.1528 19.7569L4.38892 16.3264C4.44535 16.2392 4.5206 16.1658 4.60913 16.1116C4.69767 16.0574 4.79724 16.0237 4.9005 16.0131C5.00377 16.0024 5.10812 16.015 5.20586 16.05C5.30361 16.085 5.39226 16.1415 5.4653 16.2153L7.69447 18.4514C7.81742 18.5741 7.88991 18.7385 7.89762 18.912C7.90533 19.0856 7.84772 19.2557 7.73614 19.3889L6.73614 20.5833C6.6716 20.6608 6.59095 20.7233 6.49981 20.7665C6.40866 20.8097 6.30921 20.8325 6.20836 20.8333ZM4.01392 19.4444H5.88197L6.25003 18.9861L5.06947 17.8055L4.01392 19.4444Z"
          strokeWidth={strokeWidth}
        />
        <path
          d="M2.125 21.5278H22.9583V23.6111H2.125V21.5278Z"
          strokeWidth={strokeWidth}
        />
      </g>
      <defs>
        <clipPath id="clip0_532_653">
          <rect width="25" height="25" fill="white" />
        </clipPath>
      </defs>
    </svg>
  )
}

export const Speaker = ({ active = false }) => {
  const className = active ? 'stroke-white' : 'stroke-black'
  const strokeWidth = active ? '1.5' : '1.25'
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      role="img"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
    >
      <title>Speaker</title>
      <path
        d="M19.114 5.63601C19.9497 6.47174 20.6127 7.4639 21.065 8.55583C21.5173 9.64777 21.7501 10.8181 21.7501 12C21.7501 13.1819 21.5173 14.3522 21.065 15.4442C20.6127 16.5361 19.9497 17.5283 19.114 18.364M16.463 8.28801C17.4474 9.27255 18.0004 10.6078 18.0004 12C18.0004 13.3922 17.4474 14.7275 16.463 15.712M6.75 8.25001L11.47 3.53001C11.5749 3.42525 11.7085 3.35393 11.8539 3.32504C11.9993 3.29616 12.15 3.31101 12.2869 3.36772C12.4239 3.42443 12.541 3.52046 12.6234 3.64368C12.7058 3.76689 12.7499 3.91177 12.75 4.06001V19.94C12.7499 20.0883 12.7058 20.2331 12.6234 20.3563C12.541 20.4796 12.4239 20.5756 12.2869 20.6323C12.15 20.689 11.9993 20.7039 11.8539 20.675C11.7085 20.6461 11.5749 20.5748 11.47 20.47L6.75 15.75H4.51C3.63 15.75 2.806 15.243 2.572 14.396C2.35751 13.6154 2.2492 12.8095 2.25 12C2.25 11.17 2.362 10.367 2.572 9.60401C2.806 8.75601 3.63 8.25001 4.51 8.25001H6.75Z"
        strokeWidth={strokeWidth}
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const LeftArrowCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <title>Left arrow circle</title>
      <path
        d="M22.5 18L16.5 24M16.5 24L22.5 30M16.5 24H31.5M42 24C42 26.3638 41.5344 28.7044 40.6298 30.8883C39.7252 33.0722 38.3994 35.0565 36.7279 36.7279C35.0565 38.3994 33.0722 39.7252 30.8883 40.6298C28.7044 41.5344 26.3638 42 24 42C21.6362 42 19.2956 41.5344 17.1117 40.6298C14.9278 39.7252 12.9435 38.3994 11.2721 36.7279C9.60062 35.0565 8.27475 33.0722 7.37017 30.8883C6.46558 28.7044 6 26.3638 6 24C6 19.2261 7.89642 14.6477 11.2721 11.2721C14.6477 7.89642 19.2261 6 24 6C28.7739 6 33.3523 7.89642 36.7279 11.2721C40.1036 14.6477 42 19.2261 42 24Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const RightArrowCircle = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      width="48"
      height="48"
      viewBox="0 0 48 48"
      fill="none"
    >
      <title>Right arrow circle</title>
      <path
        d="M25.5 30L31.5 24M31.5 24L25.5 18M31.5 24L16.5 24M6 24C6 21.6362 6.46558 19.2956 7.37017 17.1117C8.27475 14.9278 9.60062 12.9435 11.2721 11.2721C12.9435 9.60062 14.9278 8.27475 17.1117 7.37017C19.2956 6.46558 21.6362 6 24 6C26.3638 6 28.7044 6.46558 30.8883 7.37017C33.0722 8.27475 35.0565 9.60062 36.7279 11.2721C38.3994 12.9435 39.7252 14.9278 40.6298 17.1117C41.5344 19.2956 42 21.6362 42 24C42 28.7739 40.1036 33.3523 36.7279 36.7279C33.3523 40.1036 28.7739 42 24 42C19.2261 42 14.6477 40.1036 11.2721 36.7279C7.89642 33.3523 6 28.7739 6 24Z"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const Quote = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      role="img"
      width="32"
      height="32"
      viewBox="0 0 24 24"
      fill="none"
    >
      <title>Quote</title>
      <path
        d="M3 20.2895V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V15C21 16.1046 20.1046 17 19 17H7.96125C7.35368 17 6.77906 17.2762 6.39951 17.7506L4.06852 20.6643C3.71421 21.1072 3 20.8567 3 20.2895Z"
        stroke="black"
        strokeWidth="1.5"
      />
      <path
        d="M10.5 10H8.5C7.94772 10 7.5 9.55228 7.5 9V8C7.5 7.44772 7.94772 7 8.5 7H9.5C10.0523 7 10.5 7.44772 10.5 8V10ZM10.5 10C10.5 11 9.5 12 8.5 13"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
      <path
        d="M16.5 10H14.5C13.9477 10 13.5 9.55228 13.5 9V8C13.5 7.44772 13.9477 7 14.5 7H15.5C16.0523 7 16.5 7.44772 16.5 8V10ZM16.5 10C16.5 11 15.5 12 14.5 13"
        stroke="black"
        strokeWidth="1.5"
        strokeLinecap="round"
      />
    </svg>
  )
}

export const SampleDocIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth="1.5"
      stroke="black"
      width="32"
      height="32"
    >
      <title>Sample Document</title>
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M19.5 14.25v-2.625a3.375 3.375 0 0 0-3.375-3.375h-1.5A1.125 1.125 0 0 1 13.5 7.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H8.25m2.25 0H5.625c-.621 0-1.125.504-1.125 1.125v17.25c0 .621.504 1.125 1.125 1.125h12.75c.621 0 1.125-.504 1.125-1.125V11.25a9 9 0 0 0-9-9Z"
      />
    </svg>
  )
}
