import React, { useState } from 'react'
import { useJsonItem } from './JsonItemProvider'
import { Label } from '../ui/label'

const YearMonthSelect = ({ value, onChange, onBlur }) => {
  const [yyyy, mm] = value.split('-')
  const [year, setYear] = useState(yyyy)
  const [month, setMonth] = useState(mm)
  const years = Array.from(
    { length: 60 },
    (_, i) => new Date().getFullYear() - i,
  )
  const months = [
    ['January', '01'],
    ['February', '02'],
    ['March', '03'],
    ['April', '04'],
    ['May', '05'],
    ['June', '06'],
    ['July', '07'],
    ['August', '08'],
    ['September', '09'],
    ['October', '10'],
    ['November', '11'],
    ['December', '12'],
  ]

  const yearChange = (e) => {
    const newYear = e.target.value
    setYear(newYear)
    const paddedMonth = String(month).padStart(2, '0')
    const newDate = month != '' ? `${newYear}-${paddedMonth}` : String(newYear)
    if (newYear == '') {
      onChange({ target: { value: '' } })
    } else {
      onChange({ target: { value: newDate } })
    }
  }

  const monthChange = (e) => {
    const newMonth = e.target.value
    setMonth(newMonth)
    const paddedMonth = String(newMonth).padStart(2, '0')
    const newDate = newMonth != '' ? `${year}-${paddedMonth}` : String(year)
    if (year == '') {
      onChange({ target: { value: '' } })
    } else {
      onChange({ target: { value: newDate } })
    }
  }

  const selectClasses = 'border-input bg-input-background rounded-md inline'

  return (
    <div className="flex flex-row">
      <select
        value={year}
        onChange={yearChange}
        onBlur={onBlur}
        className={selectClasses}
      >
        <option></option>
        {years.map((y) => (
          <option key={y} value={y}>
            {y}
          </option>
        ))}
      </select>
      <select
        value={month}
        onChange={monthChange}
        onBlur={onBlur}
        style={{ marginLeft: '0.5rem' }}
        className={selectClasses}
      >
        <option></option>
        {months.map((m) => (
          <option key={m[1]} value={m[1]}>
            {m[0]}
          </option>
        ))}
      </select>
    </div>
  )
}

const JsonYearMonthField = ({
  containerClassName,
  label,
  fieldName,
  childField,
  handleItemUpdate = null,
  getItemValue = null,
}) => {
  const { index, sectionName, item, setItem, updateItem } = useJsonItem()

  const id = [sectionName, fieldName, childField, index].join('-')

  const onBlur = () => {
    updateItem()
  }

  const onChange = (event) => {
    const value = event.target.value || ''

    const updatedItem = handleItemUpdate
      ? handleItemUpdate(item, value)
      : childField
        ? { ...item, [fieldName]: { ...item[fieldName], [childField]: value } }
        : { ...item, [fieldName]: value }
    setItem(updatedItem)
  }

  const itemValue = getItemValue
    ? getItemValue(item)
    : childField
      ? item[fieldName]
        ? item[fieldName][childField]
        : ''
      : item[fieldName] || ''

  return (
    <div className={containerClassName}>
      <Label htmlFor={id}>{label}</Label>
      <YearMonthSelect
        onBlur={onBlur}
        value={itemValue}
        id={id}
        name={id}
        onChange={onChange}
      />
    </div>
  )
}
export default JsonYearMonthField
