import React, { createContext, useContext } from 'react'
import { useTabNav } from './TabNav'
import { cn } from '~/lib/utils'
import CarouselWrapper from './CarouselWrapper'
import { SelectGroup, SelectContent } from '~/lib/ui/select'

const ButtonStyleContext = createContext()

export const useButtonStyle = () => {
  return useContext(ButtonStyleContext)
}

const ButtonContainer = ({
  className,
  children,
  buttonClass,
  titleClass,
  activeTitleClass,
  iconClass,
  activeDecorationClass,
  carouselWrapperClass,
}) => {
  const { isMobileSwipe, isMobileDropdown } = useTabNav()

  const value = {
    buttonClass,
    titleClass,
    activeTitleClass,
    iconClass,
    activeDecorationClass,
  }

  if (isMobileDropdown) {
    return (
      <SelectContent>
        <SelectGroup>
          <ButtonStyleContext.Provider value={value}>
            {children}
          </ButtonStyleContext.Provider>
        </SelectGroup>
      </SelectContent>
    )
  }

  const Comp = isMobileSwipe ? CarouselWrapper : 'div'

  const desktopClasses = cn(
    'flex w-full flex-row justify-evenly border-b border-solid border-[#979797] text-[#2F2F2F]',
    className,
  )

  return (
    <Comp className={isMobileSwipe ? carouselWrapperClass : desktopClasses}>
      <ButtonStyleContext.Provider value={value}>
        {children}
      </ButtonStyleContext.Provider>
    </Comp>
  )
}
export default ButtonContainer
