import React from 'react'
import {
  TabNav,
  TabPanel,
  PanelContainer,
  ButtonContainer,
  TabButton,
} from '~/components/shared/TabNav'
import { DocumentTextIcon } from '@heroicons/react/24/outline'

const SampleCard = ({ sample, imageUrl }) => {
  const [title, url] = sample
  return (
    <a
      href={url}
      target="_blank"
      rel="noreferrer"
      className="col-span-12 lg:col-span-6 xl:col-span-4"
    >
      <div className="flex w-full items-center gap-8 rounded-3xl border border-[#B9B9B9] px-4 py-2 hover:border-black focus:border-black active:border-black">
        <img
          src={imageUrl}
          alt="sample resume"
          className="aspect-[8/5] h-24 rounded-xl object-cover"
        />
        <div className="flex flex-col gap-2 text-base !text-black">
          <DocumentTextIcon className="w-10 stroke-1" />
          <div>{title}</div>
        </div>
      </div>
    </a>
  )
}

const Samples = ({ image_url, resume_templates, resume_samples }) => {
  const defaultActiveTab = 'resumeTemplates'
  return (
    <div className="home-container flex flex-col p-4 md:m-10 md:p-0">
      <h1 className="mb-4 !font-roboto-slab text-2xl text-[32px] font-medium text-cloud-burst">
        Resume Sample Documents
      </h1>
      <div className="mb-6 md:w-3/4">
        <p>
          The resume templates below are provided for you to download and
          populate with your personal information. Templates are labeled based
          on the most general use case but can be used for any profession -
          utilize the template that best meets your experience and personal
          brand strategy. The resume samples are provided for reference and as
          inspiration as you prepare your resume.
        </p>
      </div>
      <TabNav defaultActiveTab={defaultActiveTab} mobileMode="dropdown">
        <ButtonContainer
          className="mb-8 justify-start"
          buttonClass="md:ml-4 md:first:ml-0 px-6 md:px-0"
        >
          <TabButton name="resumeTemplates" text="Resume Templates" />
          <TabButton name="resumeSamples" text="Resume Samples" />
        </ButtonContainer>
        <PanelContainer className="pt-6 md:min-h-[91vh] md:pt-0">
          <TabPanel name="resumeTemplates">
            <div className="grid w-full grid-cols-12 gap-6">
              {resume_templates.map((sample, index) => (
                <SampleCard key={index} sample={sample} imageUrl={image_url} />
              ))}
            </div>
          </TabPanel>
          <TabPanel name="resumeSamples">
            <div className="grid w-full grid-cols-12 gap-6">
              {resume_samples.map((sample, index) => (
                <SampleCard key={index} sample={sample} imageUrl={image_url} />
              ))}
            </div>
          </TabPanel>
        </PanelContainer>
      </TabNav>
    </div>
  )
}

export default Samples
