import React, { useState } from 'react'
import { useReferenceSheet } from '../ReferenceSheetProvider'
import { Button } from '../../../lib/ui/button'
import { PlusIcon } from '@heroicons/react/24/outline'
import { EditorHeading, newKey } from '../../ResumeBuilder/Editor/common'
import ReferenceItem from './ReferenceItem'
import { EditorItemProvider } from './EditorItemProvider'

const ReferenceEditor = () => {
  const { referenceSheetDocument, setReferenceSheetDocument } =
    useReferenceSheet()
  const references = referenceSheetDocument?.references || []
  const [activeIndex, setActiveIndex] = useState(-1)

  const addReference = () => {
    const newIndex = references.length
    const updatedReferences = [...references, {}]
    setActiveIndex(newIndex)

    setReferenceSheetDocument({
      ...referenceSheetDocument,
      references: updatedReferences,
    })
  }

  return (
    <div className="flex flex-col">
      <EditorHeading>References</EditorHeading>
      <div className="pb-4 tracking-[0.024px]">
        Add two or three sentences that describe your relationship to each
        reference and highlight the specific skills and characteristics that you
        used in the position. Include the types of skills and characteristics
        most attractive to the prospective employer.
      </div>
      {references.map((ref, index) => {
        ref.key = ref.key || newKey()
        return (
          <div key={ref.key}>
            <EditorItemProvider
              sectionName="references"
              index={index}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              item={ref}
            >
              <ReferenceItem />
            </EditorItemProvider>
          </div>
        )
      })}
      <Button onClick={addReference} className="max-w-min">
        Add Reference
        <PlusIcon className="ml-2 w-5" />
      </Button>
    </div>
  )
}
export default ReferenceEditor
