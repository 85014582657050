import React, { useRef, useState } from 'react'
import { Button } from '../../lib/ui/button'
import { useIsMobile } from '../../hooks/useMediaQuery'
import { useUploadVideoForm } from './useUploadVideoForm'
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormMessage,
} from '../../lib/ui/form'
import { Input } from '../../lib/ui/input'

const CoachVideo = () => {
  const [videoBlob, setVideoBlob] = useState(null)
  const { form, onSubmit, submitted } = useUploadVideoForm({
    videoBlob,
  })

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <h1>Please upload your video below</h1>
        <Button className="btn btn-primary" disabled={submitted} type="submit">
          Submit Video
        </Button>
        <div>
          <h2>Upload from file</h2>
          <FormField
            control={form.control}
            name="video_file"
            render={({ field: { value, onChange, ...fieldProps } }) => (
              <FormItem className="">
                <FormControl>
                  <Input
                    {...fieldProps}
                    type="file"
                    name="vidoe_file"
                    onChange={(e) =>
                      onChange(e.target.files && e.target.files[0])
                    }
                    accept="video/*"
                    className="mt-1 block w-full rounded-md border border-gray-300 px-3 py-2 text-base shadow-sm focus:border-accent focus:ring-accent sm:text-sm"
                  />
                </FormControl>
                <FormMessage />
              </FormItem>
            )}
          />
        </div>
        <div style={{ marginTop: '1rem' }}>Or</div>
        <div>
          <h2>Instructions</h2>
          <ol>
            <li>{`Click the "Get camera" button to allow access to your camera`}</li>
            <li>
              {`Click the Red "Record" button when you're ready to start recording.`}
            </li>
            <li>{`Click "Submit Video" when you are happy with your recording.`}</li>
            <li>
              {`Once the video is approved by an administrator, candidates will be able to see it in the coach chat modal.`}
            </li>
          </ol>
          <div>
            <VideoRecorder setVideoBlob={setVideoBlob} />
          </div>
        </div>
      </form>
    </Form>
  )
}
export default CoachVideo

const mimeType = 'video/webm'

const VideoRecorder = ({ setVideoBlob }) => {
  const isMobile = useIsMobile()
  const [permission, setPermission] = useState(false)
  const [stream, setStream] = useState(null)
  const mediaRecorder = useRef(null)
  const liveVideoFeed = useRef(null)
  const [recordingStatus, setRecordingStatus] = useState('idle')
  const [videoChunks, setVideoChunks] = useState([])
  const [recordedVideo, setRecordedVideo] = useState(null)

  const getCameraPermission = async (e) => {
    e.preventDefault()
    if ('MediaRecorder' in window) {
      try {
        const videoConstraints = {
          audio: true,
          video: true,
        }

        const streamData =
          await navigator.mediaDevices.getUserMedia(videoConstraints)
        setPermission(true)
        setStream(streamData)
        liveVideoFeed.current.srcObject = streamData
      } catch (err) {
        alert(err.message)
      }
    } else {
      alert('MediaRecorder not available')
    }
  }

  const startRecording = async (e) => {
    e.preventDefault()
    setRecordingStatus('recording')
    const media = new MediaRecorder(stream, { mimeType })
    mediaRecorder.current = media
    mediaRecorder.current.start()
    let localVideoChunks = []
    mediaRecorder.current.ondataavailable = (event) => {
      if (typeof event.data === 'undefined') return
      if (event.data.size === 0) return
      localVideoChunks.push(event.data)
    }
    setVideoChunks(localVideoChunks)
  }

  const stopRecording = (e) => {
    e.preventDefault()
    setRecordingStatus('idle')
    mediaRecorder.current.stop()
    mediaRecorder.current.onstop = () => {
      const tempVideoBlob = new Blob(videoChunks, { type: mimeType })
      const videoUrl = URL.createObjectURL(tempVideoBlob)
      setRecordedVideo(videoUrl)
      setVideoBlob(tempVideoBlob)
      setVideoChunks([])
    }
  }

  const divStyles = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    gap: '1rem',
  }

  return (
    <div style={divStyles}>
      {liveVideoFeed && !recordedVideo && (
        <video
          ref={liveVideoFeed}
          autoPlay
          controls
          width={isMobile ? 350 : 500}
          height={isMobile ? 350 : 500}
          muted={true}
        />
      )}

      {recordedVideo && (
        <video
          src={recordedVideo}
          autoPlay
          controls
          width={isMobile ? 350 : 500}
          height={isMobile ? 350 : 500}
          muted={false}
        />
      )}
      {!permission && (
        <Button className={'btn btn-cta'} onClick={getCameraPermission}>
          Get camera
        </Button>
      )}
      <Button
        title={
          recordingStatus === 'recording' ? 'Stop Recording' : 'Start Recording'
        }
        disabled={!permission}
        style={{ backgroundColor: 'transparent', border: 'none' }}
        onClick={
          recordingStatus === 'recording' ? stopRecording : startRecording
        }
      >
        <RecordingIcon
          permission={permission}
          recordingStatus={recordingStatus}
        />
      </Button>
    </div>
  )
}

const RecordingIcon = ({ permission, height, width, recordingStatus }) => {
  const recordingStatusClass = {
    recording: '5px',
    idle: '99999px',
  }
  const styles = {
    border: '2px solid black',
    backgroundColor: 'red',
    height: height || '4rem',
    width: width || '4rem',
    borderRadius: recordingStatusClass[recordingStatus || 'idle'],
    opacity: permission ? 1 : 0.5,
  }
  return <div style={styles}></div>
}
