import React, { useState } from 'react'
import { Button } from '../../lib/ui/button'
import { apiPost } from '../../util/api'
import AylaResponseScrollArea from '../ShortCourses/AylaResponseScrollArea'

const MemoryHookSuggestions = ({ documentId, ctaText, userTargetRole }) => {
  const [submitted, setSubmitted] = useState(false)
  const [targetRole, setTargetRole] = useState(userTargetRole)
  const [typingComplete, setTypingComplete] = useState(false)
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const [aylaResponse, setAylaResponse] = useState(null)
  const showCursor = submitted && displayedText.length <= 0

  const prompt = `Write 3 sample memory hook sentences that could be used in a personal pitch statement for a ${targetRole}. The memory hook should be creative, humorous, and memorable to make the person stand out. Separate each memory hook with line breaks.`

  const requestAylaStatement = async () => {
    const url = `/personal_brand/create_ai_response?document_id=${documentId}`
    setDisplayedText('')
    setAylaResponse(null)
    setIndex(0)
    const data = { ayla_prompt: { prompt } }
    let res
    try {
      res = await apiPost(url, data)
    } catch (err) {
      console.log(err)
    }

    if (res.status === 200) {
      setAylaResponse(res.data.ayla_response)
    } else {
      console.log('error')
    }
    return res
  }

  const handleButtonClick = () => {
    if (targetRole) {
      setSubmitted(true)
      setTypingComplete(false)
      requestAylaStatement()
    }
  }

  return (
    <div>
      <div className="mb-4 flex items-end justify-between rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3">
        <fieldset>
          <div className="flex flex-col">
            <label htmlFor="target_role">My target role:</label>
            <input
              id="target_role"
              defaultValue={userTargetRole}
              onChange={(e) => setTargetRole(e.target.value)}
            />
          </div>
        </fieldset>
        <Button
          disabled={submitted && !typingComplete}
          onClick={handleButtonClick}
        >
          {ctaText}
        </Button>
      </div>
      {submitted && (
        <AylaResponseScrollArea
          showBorder={submitted}
          showCursor={showCursor}
          aylaResponse={aylaResponse}
          typingComplete={typingComplete}
          setTypingComplete={setTypingComplete}
          displayedText={displayedText}
          setDisplayedText={setDisplayedText}
          index={index}
          setIndex={setIndex}
          className="mb-4 h-60"
        />
      )}
      {submitted && (
        <div className="mb-4">
          If one of these suggestions sounds like a good start, copy it below
          and customize it to really match your personality:
        </div>
      )}
    </div>
  )
}
export default MemoryHookSuggestions
