import React, { useState, useEffect } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { getApi } from '~/util/api'
import { Button, Card, Typography } from '~/components/_atoms'
import { Stack } from '~/components/_layouts'
import Spinner from '~/components/shared/Spinner'

export function RecommendedBlogsCard({ className }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [recommendedBlogs, setRecommendedBlogs] = useState([])

  useEffect(() => {
    setIsLoading(true)
    getApi()
      .get('/recommended_blogs')
      .then((response) => {
        if (response.status == 200) {
          setRecommendedBlogs(response.data)
          return
        } else {
          throw new Error('json request failed')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('json request failed', error)
      })
  }, [setRecommendedBlogs])

  return (
    <Card className={className} title={t('recommended_blogs_card.title')}>
      <Stack spacing={3}>
        {isLoading ? (
          <div className="flex items-center justify-center">
            <Spinner show size={9} />
          </div>
        ) : (
          <Stack spacing={4}>
            {recommendedBlogs.map((blog, index) => (
              <>
                {index > 0 && <hr />}
                <div
                  key={index}
                  className="flex flex-col gap-3 @sm:flex-row @sm:items-center @sm:justify-between"
                >
                  <div className="flex gap-3">
                    <a href={blog['url']}>
                      <img
                        src={blog['headerImageUrl']}
                        alt={blog['title']}
                        className="h-12 w-12 rounded-sm object-cover"
                      />
                    </a>
                    <Stack>
                      <Typography className="font-bold">
                        {blog['title']}
                      </Typography>
                    </Stack>
                  </div>
                  <div>
                    <Button
                      variant="outline"
                      href={blog['url']}
                      rel="noopener noreferrer"
                    >
                      {t('recommended_blogs_card.view_action')}
                    </Button>
                  </div>
                </div>
              </>
            ))}
          </Stack>
        )}
      </Stack>
    </Card>
  )
}
