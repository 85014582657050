import React, { useContext, createContext, useState } from 'react'
import { useIsDesktop, useIsMobile } from '../../hooks/useMediaQuery'
import usePersistState from '../../hooks/usePersistState'
import { ReminderProvider } from './ReminderDialog'

const DashboardContext = createContext()

export const useDashboard = () => {
  return useContext(DashboardContext)
}

export const DashboardProvider = ({ current_user, children }) => {
  const isMobile = useIsMobile()
  const isDesktop = useIsDesktop()

  const candidateId = current_user.candidate_id

  const [showCoachInfoDialog, setShowCoachInfoDialog] = useState(false)

  const [onlyReminders, setOnlyReminders] = usePersistState(
    false,
    'onlyReminders',
  )

  const toggleShowCoachInfoDialog = () =>
    setShowCoachInfoDialog(!showCoachInfoDialog)

  const value = {
    current_user,
    isDesktop,
    isMobile,
    onlyReminders,
    setOnlyReminders,
    setShowCoachInfoDialog,
    showCoachInfoDialog,
    toggleShowCoachInfoDialog,
  }

  const reminderValue = {
    candidateId,
  }

  return (
    <DashboardContext.Provider value={value}>
      <ReminderProvider {...reminderValue}>{children}</ReminderProvider>
    </DashboardContext.Provider>
  )
}
