import * as React from 'react'

import { useQuery, useMutation, useQueryClient } from '@tanstack/react-query'
import { api } from '../../../util/api'
import { Button } from '../../../lib/ui/button'
import { useTranslation } from '../../../hooks/useTranslation'
import { useJobSearch } from './JobSearchProvider'
import { useSavedJobs } from '../SavedJobsPage/SavedJobsProvider'
import { HeartOutlineIcon, HeartSolidIcon } from './icons'
import sha256 from 'crypto-js/sha256'
import { cn } from '../../../lib/utils'

export default function SavedJobButton({ job, variant, includeText }) {
  const queryClient = useQueryClient()
  const [jobPostingIsSaved, setJobPostingIsSaved] = React.useState(false)
  const { savedJobIds, setSavedJobIds } = useJobSearch()
  const { t } = useTranslation('SaveJobButton')
  const { postSavedJobMutation } = useSavedJobs()

  const { data, isPending } = useQuery({
    queryKey: ['saved-job-ids'],
    queryFn: getSavedJobIds,
  })
  React.useEffect(() => {
    if (!isPending && data) {
      setSavedJobIds(data)
    }
  }, [data, isPending, setSavedJobIds])

  React.useEffect(() => {
    setJobPostingIsSaved(
      savedJobIds.includes(generateHash(uniqueJobFields(job))) ||
        savedJobIds.includes(job.id),
    )
  }, [savedJobIds, job])

  const mutation = useMutation({
    mutationFn: () => postSavedJob({ job_posting_id: job.id }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['saved-job-ids'] })
    },
  })

  const saveJobPosting = async () => {
    setJobPostingIsSaved(true)
    setSavedJobIds((prevSavedJobIds) => [...prevSavedJobIds, job.id])
    mutation.mutate()
  }

  const saveApiJobAsOutsideJob = async () => {
    setJobPostingIsSaved(true)
    setSavedJobIds((prevSavedJobIds) => [...prevSavedJobIds, job.id])
    postSavedJobMutation.mutate(job)
  }

  const jobIsFromApi =
    job.job_publisher_name === 'CareerArc' ||
    job.job_publisher_name === 'Jobtome'

  const handleClick = jobIsFromApi ? saveApiJobAsOutsideJob : saveJobPosting

  if (variant === 'icon') {
    if (isPending) return <Skeleton />

    return (
      <div className="flex flex-row items-center">
        <div className="text-sm italic">
          {includeText && jobPostingIsSaved && t('job_saved')}
        </div>
        <IconButton
          handleClick={handleClick}
          jobPostingIsSaved={jobPostingIsSaved}
        />
      </div>
    )
  } else {
    return (
      <SaveJobButton
        handleClick={handleClick}
        jobPostingIsSaved={jobPostingIsSaved}
        className="step6c"
      />
    )
  }
}

function SaveJobButton({
  className,
  jobPostingIsSaved,
  handleClick,
  disabled,
}) {
  const { t } = useTranslation('SaveJobButton')

  if (jobPostingIsSaved) return null

  return (
    <Button
      className={cn(
        'flex flex-row justify-center gap-1 px-4 sm:gap-2 sm:px-6',
        className,
      )}
      variant="outline"
      onClick={handleClick}
      disabled={disabled}
      title={t('save_this_job')}
    >
      <div>{t('save_job')}</div> <HeartOutlineIcon className="h-5 w-5" />
    </Button>
  )
}

function IconButton({ jobPostingIsSaved, handleClick, disabled }) {
  const { t } = useTranslation('SaveJobButton')

  if (jobPostingIsSaved)
    return (
      <div title={t('job_saved_to_my_jobs')} className="p-2 text-[#A50000]">
        <HeartSolidIcon />
      </div>
    )

  return (
    <Button
      variant="ghost"
      className="rounded-lg px-2"
      onClick={handleClick}
      disabled={disabled}
      title={t('save_this_job')}
    >
      <HeartOutlineIcon className="h-5 w-5" />
    </Button>
  )
}

function Skeleton() {
  return (
    <Button variant="ghost" className="rounded-lg px-2 text-gray-300">
      <HeartOutlineIcon className="h-5 w-5" />
    </Button>
  )
}

const getSavedJobIds = async () => {
  const response = await api.get(
    '/job-search/saved_jobs?job_posting_ids_only=true',
  )
  return response.data
}

const postSavedJob = async ({ job_posting_id }) => {
  const response = await api.post('/job-search/saved_jobs', {
    saved_job: { job_posting_id },
  })
  return response.data
}

const uniqueJobFields = (job) => {
  const { title, city, state, country, description, company_name, location } =
    job
  return [title, city, state, country, description, company_name, location]
    .filter(Boolean)
    .join('')
}

const generateHash = (string) => {
  if (string) {
    const hash = sha256(string).toString()
    return hash
  }
}
