import React from 'react'
import Results from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useJobSearchStrategyKnowledgeScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'

const JobSearchStrategyKnowledgeResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { score, section, proficiency, t } = useJobSearchStrategyKnowledgeScore(
    trackContent,
    formattedQuizName,
  )

  const copy = t(`results.${section}`, { score })

  return (
    quizName === 'Job Search Strategy Knowledge' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-2 flex h-96 flex-col gap-4 sm:w-9/12">
            <h2>{t(`results.score`, { proficiency })}</h2>
            <div className="mt-4">
              <p>{copy}</p>
              <br />
              <p>{t(`results.all`)}</p>
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}

export default JobSearchStrategyKnowledgeResults
