import React from 'react'
import Results from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { useMultipleChoiceQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'

const BehavioralInterviewResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)

  const breakPoints = [7, 4]
  const [scoreSection, score] = useMultipleChoiceQuizScore(
    trackContent,
    t('questions'),
    breakPoints,
    'raw',
  )
  const headerCopy = t(`results.final_results`, { score })
  const resultsCopy = t(`results.${scoreSection}`)

  return (
    quizName === 'Behavioral Interview Questions' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 flex h-96 flex-col gap-4 sm:w-9/12">
            <h2>{headerCopy}</h2>
            <div className="mt-4">
              <p>{resultsCopy}</p>
              <p>{t(`results.mastering_the_art`)}</p>
              <p>{t(`results.focus_on_real_experiences`)}</p>
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}

export default BehavioralInterviewResults
