import * as React from 'react'
import { cn } from '../../lib/utils'

const SaveResult = ({ className, children, ...props }) => {
  return (
    <div {...props} className={cn('absolute right-0', className)}>
      {children}
    </div>
  )
}

const ItemDetails = ({ className, title, children, ...props }) => {
  return (
    <div {...props} className="flex flex-row justify-start gap-4">
      <div className="flex flex-col gap-1">
        <h4 className="pr-10 font-extrabold">{title}</h4>
        {children}
      </div>
    </div>
  )
}

function SearchResultsCard({
  className,
  selected,
  description,
  children,
  ...props
}) {
  return (
    <li
      className={cn(
        'relative flex cursor-pointer flex-row gap-4 rounded-2xl border-2 p-4 transition-colors hover:border-gray-400 hover:shadow-lg data-[selected=true]:border-intoo-blue-bright',
        className,
      )}
      data-selected={selected}
      {...props}
    >
      <div className="relative flex min-w-full flex-col gap-4">
        {children}

        <p className="line-clamp-3 text-xs">{description}</p>
      </div>
    </li>
  )
}

export { SearchResultsCard, ItemDetails, SaveResult }
