import React from 'react'
import AylaResponse from './AylaResponse'
import { useIntroductionScript } from './IntroductionScriptProvider'
import useTranslation from '../../shared/TranslationProvider'

const Scripts = () => {
  const { customScript, setCustomScript } = useIntroductionScript()
  const { t } = useTranslation('ayla_response')
  return (
    <div className="flex flex-col gap-8">
      <AylaResponse />
      <div>
        <h2 className="subtitle">{t('customize_script')}</h2>
        <textarea
          className="mt-4 min-h-[400px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
          value={customScript}
          onChange={(e) => setCustomScript(e.target.value)}
        />
      </div>
    </div>
  )
}

export default Scripts
