import React, { useEffect, useState } from 'react'
import { ChevronRightIcon } from '@heroicons/react/24/solid'
import { XmarkCircleSolid } from 'iconoir-react'
import { Button } from '../../../lib/ui/button'
import { ArrowDownWideNarrow, ArrowUpWideNarrow } from 'lucide-react'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuRadioGroup,
  DropdownMenuRadioItem,
} from '../../../lib/ui/dropdown-menu'
import { useTranslation } from '../../../hooks/useTranslation'

export function ChatHistory({ feedback }) {
  const { t } = useTranslation('ChatHistory')
  const Content = feedback?.chatHistories ? ChatHistoryContent : NoChatHistory
  return (
    <div className="mx-2 my-6">
      <div className="flex flex-col gap-4">
        <h1 className="text-2xl font-bold">{t('title')}</h1>

        <Content feedback={feedback} />
      </div>
    </div>
  )
}

function NoChatHistory() {
  const { t } = useTranslation('ChatHistory')
  return (
    <div className="text-center text-2xl">
      <p>{t('no_messages')}</p>
    </div>
  )
}

function ChatHistoryLinks({ chatHistory }) {
  const { t } = useTranslation('ChatHistory')
  return (
    <div className="flex w-3/4 flex-row justify-between">
      <div className="flex flex-row">
        <ChevronRightIcon className="h-6 w-6" />{' '}
        {chatHistory?.type === 'orientation_call'
          ? t('orientation_call')
          : chatHistory?.type === 'career_consultation'
            ? t('career_consultation_call')
            : t('coach_chat')}{' '}
        {chatHistory?.type === 'chat_history' ? chatHistory?.count : ''}{' '}
        {chatHistory?.created_date}
      </div>
      <div className="flex flex-row justify-between">
        <a className="mr-4" href={chatHistory?.email_link}>
          {t('email')}
        </a>
        <a className="mr-4" href={chatHistory?.download_link}>
          {t('download')}
        </a>
        <a
          href={chatHistory?.delete_link}
          data-confirm={t('are_you_sure')}
          data-action={t('delete_chat_transcript')}
          data-title={t('please_confirm')}
          data-cancel={t('cancel')}
          data-proceed={t('yes_im_sure')}
          aria-label={t('delete_aria_label')}
        >
          <XmarkCircleSolid className="text-red-600 hover:text-red-800" />
        </a>
      </div>
    </div>
  )
}

function SortByDropdownAndLabel({
  sortByValue,
  setSortByValue,
  sortDir,
  setSortDir,
}) {
  const isDesktop = useIsDesktop()
  const size = isDesktop ? 20 : 14
  const [sortDirBoolean, setSortDirBoolean] = useState(true)
  const { t } = useTranslation('ChatHistory')

  const changeSortByValue = (value) => {
    setSortByValue(value)
  }

  const changeSortDir = (value) => {
    setSortDir(value)
    setSortDirBoolean(!sortDirBoolean)
  }

  return (
    <div className="flex flex-row items-center gap-2 whitespace-nowrap">
      <Button
        variant="link"
        className="flex h-6 flex-row gap-2 rounded-full border border-[#B9B9B9] bg-white pl-3 pr-2 text-xs text-black hover:bg-white focus:bg-accent sm:h-8 sm:px-4 sm:text-base"
        onClick={() => {
          sortDir === 'asc' ? changeSortDir('desc') : changeSortDir('asc')
        }}
      >
        <ArrowDownWideNarrow
          size={size}
          hidden={!sortDirBoolean}
          aria-hidden={!sortDirBoolean}
          title={t('asc_title')}
        />
        <ArrowUpWideNarrow
          size={size}
          hidden={sortDirBoolean}
          aria-hidden={sortDirBoolean}
          title={t('desc_title')}
        />
      </Button>
      <div className="text-sm font-medium sm:text-base">{t('sort_by')}</div>
      <DropdownMenu>
        <DropdownMenuTrigger asChild>
          <Button
            variant="link"
            className="flex h-6 flex-row gap-2 rounded-full border border-[#B9B9B9] bg-white pl-3 pr-2 text-xs text-black hover:bg-white focus:bg-accent sm:h-8 sm:px-4 sm:text-base"
          >
            {sortByValue === 'created_at' ? t('date') : t('type')}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent>
          <DropdownMenuLabel></DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuRadioGroup
            value={sortByValue}
            onChange={(value) => setSortByValue(value)}
          >
            <DropdownMenuRadioItem
              onClick={() => changeSortByValue('created_at')}
              value="created_at"
            >
              {t('date')}
            </DropdownMenuRadioItem>
            <DropdownMenuRadioItem
              onClick={() => changeSortByValue('type')}
              value="type"
            >
              {t('type')}
            </DropdownMenuRadioItem>
          </DropdownMenuRadioGroup>
        </DropdownMenuContent>
      </DropdownMenu>
    </div>
  )
}

function ChatHistoryContent({ feedback }) {
  const [sortByValue, setSortByValue] = useState('created_at')
  const [sortDir, setSortDir] = useState('desc')
  const [chatHistories, setChatHistories] = useState(feedback?.chatHistories)
  const { t } = useTranslation('ChatHistory')

  const sortChatHistories = () => {
    if (sortByValue === 'created_at') {
      setChatHistories(
        chatHistories.sort((a, b) => {
          return sortDir === 'asc'
            ? new Date(b.sort_created_date) - new Date(a.sort_created_date)
            : new Date(a.sort_created_date) - new Date(b.sort_created_date)
        }),
      )
    } else if (sortByValue === 'type') {
      setChatHistories(
        chatHistories.sort((a, b) => {
          return sortDir === 'asc'
            ? a.type.localeCompare(b.type)
            : b.type.localeCompare(a.type)
        }),
      )
    }
  }

  useEffect(() => {
    // console.log(sortByValue, sortDir)
    sortChatHistories()
    // console.log(chatHistories)
  }, [sortByValue, sortDir])

  return (
    <>
      <p>{t('with_messages')}</p>
      <div className="flex flex-row">
        <SortByDropdownAndLabel
          sortByValue={sortByValue}
          setSortByValue={setSortByValue}
          sortDir={sortDir}
          setSortDir={setSortDir}
        />
      </div>
      <div className="flex flex-col gap-4">
        {chatHistories.map((chatHistory, index) => (
          <ChatHistoryLinks chatHistory={chatHistory} key={index} />
        ))}
      </div>
    </>
  )
}
