import React, { createContext, useContext, useReducer } from 'react'
import { apiPatch } from '../../../util/api'

const AssociatedBehaviorsContext = createContext()

const useAssociatedBehaviors = () => {
  return useContext(AssociatedBehaviorsContext)
}

const reducer = (state, action) => {
  switch (action.type) {
    case 'UPDATE_BEHAVIOR': {
      return {
        ...state,
        behaviors: {
          ...state.behaviors,
          [action.payload.name]: action.payload.content,
        },
      }
    }
    case 'UPDATE_EXAMPLE': {
      return {
        ...state,
        examples: {
          ...state.examples,
          [action.payload.name]: action.payload.content,
        },
      }
    }
    default:
      return state
  }
}

export const AssociatedBehaviorsProvider = ({
  associatedBehaviors,
  examples,
  valueList,
  selectedValues,
  formOptions,
  children,
}) => {
  const getInitialStateValues = () => {
    const initialStateObject = {
      examples: {},
      behaviors: {},
    }
    selectedValues.forEach((val) => {
      initialStateObject.behaviors[val] = associatedBehaviors[val] || ''
      initialStateObject.examples[val] = examples[val] || ''
    })
    return initialStateObject
  }
  const initialState = {
    ...getInitialStateValues(),
  }
  const [state, dispatch] = useReducer(reducer, initialState)

  const getValueName = (value) => {
    return valueList.find((val) => val.value === value).name
  }

  const { url } = formOptions

  const updateDocument = async () => {
    const data = {
      document_part: { behaviors: state.behaviors, examples: state.examples },
    }
    const res = await apiPatch(url, data)
    if (!res.status === 200) {
      throw new Error('Failed to save response')
    }
  }

  const value = {
    selectedValues,
    state,
    dispatch,
    updateDocument,
    getValueName,
  }

  return (
    <AssociatedBehaviorsContext.Provider value={value}>
      {children}
    </AssociatedBehaviorsContext.Provider>
  )
}

export default useAssociatedBehaviors
