import React from 'react'
import { EditorHeading } from '../../ResumeBuilder/Editor/common'
import { EditorItemProvider, EditorItemTextField } from './EditorItemProvider'
import { useCoverLetter } from '../CoverLetterProvider'
import coverLetterMap from './index'

const ContentEditor = ({ templateIndex }) => {
  const { aylaCoverLetter } = useCoverLetter()
  const { coverLetterDocument: { coverLetter } = {} } = useCoverLetter()

  return (
    <div className="flex flex-col">
      <EditorHeading>
        {coverLetterMap[templateIndex]} Cover Letter Content
      </EditorHeading>
      <p className="pb-4">
        The purpose of a cover letter is to explain to a hiring manager how your
        accomplishments, experiences, and skills add value to the specific needs
        of the organization or position.
      </p>
      {!aylaCoverLetter && (templateIndex == 0 || templateIndex == 1) && (
        <>
          <p className="pb-4">
            Paragraph One: Why am I interested in and a good fit for your
            organization?
          </p>
          <p className="pb-4">
            Paragraph Two: How are my qualifications well-matched for a
            position?
          </p>
          <p className="pb-4">
            Paragraph Three: What should be the next step in pursuing an
            interview?
          </p>
        </>
      )}
      {!aylaCoverLetter && templateIndex == 2 && (
        <>
          <p className="pb-4">Paragraph One: Why am I writing?</p>
          <p className="pb-4">
            Paragraph Two: What am I looking for from the recipient?
          </p>
          <p className="pb-4">Paragraph Three: What are the next steps?</p>
        </>
      )}
      <EditorItemProvider sectionName="coverLetter" item={coverLetter}>
        <EditorItemTextField
          label="Write your cover letter here:"
          containerClassName="md:col-span-2"
          fieldName="content"
          areaClassName="h-[800px]"
        />
      </EditorItemProvider>
    </div>
  )
}

export default ContentEditor
