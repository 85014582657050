import React, { useEffect, useRef } from 'react'
import { ScrollArea } from '../../lib/ui/scroll-area'
import { cn } from '../../lib/utils'
import Cursor from '../stories/cover_letters/CreateAi/Cursor'
import TypingEffect from '../stories/cover_letters/CreateAi/TypingEffect'

const AylaResponseScrollArea = ({
  className,
  showBorder,
  aylaResponse,
  typingComplete,
  setTypingComplete,
  displayedText,
  setDisplayedText,
  index,
  setIndex,
  showCursor,
  children,
}) => {
  const scrollAreaRef = useRef(null)

  // Scroll to the bottom whenever displayedText changes
  useEffect(() => {
    if (scrollAreaRef.current) {
      scrollAreaRef.current.scrollTop = scrollAreaRef.current.scrollHeight
    }
  }, [displayedText])

  return (
    <ScrollArea
      viewPortRef={scrollAreaRef}
      className={cn(
        'mt-4 h-96 w-full rounded-[20px] bg-[white] p-3 font-roboto text-base',
        showBorder && 'border border-[#D3D3D3]',
        className,
      )}
    >
      <Cursor show={showCursor} />
      {aylaResponse && (
        <TypingEffect
          message={aylaResponse}
          baseSpeed={5}
          aiStreamComplete={typingComplete}
          setTypingComplete={setTypingComplete}
          displayedText={displayedText}
          setDisplayedText={setDisplayedText}
          index={index}
          setIndex={setIndex}
        />
      )}
      {children}
    </ScrollArea>
  )
}

export default AylaResponseScrollArea
