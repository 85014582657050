import React, { createContext, useContext } from 'react'
import {
  Page,
  Text,
  View,
  Font,
  Document,
  StyleSheet,
} from '@react-pdf/renderer'
import fonts from './fonts'

export { Text, View, Font, StyleSheet }
const TopPad = ({ yMargin }) => {
  return (
    <View style={{ height: `${yMargin}in` }} fixed>
      <Text>{''}</Text>
    </View>
  )
}

fonts.forEach((font) => Font.register(font))

const StyleContext = createContext()

export const useStyle = () => useContext(StyleContext)

export const DocumentPage = ({ templateStyle, children }) => {
  const {
    yMargin,
    xMargin,
    primaryFontFamily,
    size = 'Letter',
    containerColor = '#000000',
    pageMode = 'useNone',
    pageLayout = 'OneColumn',
    orientation = 'portrait',
  } = templateStyle
  const styles = StyleSheet.create({
    page: {
      flexDirection: 'column',
      width: '100%',
      paddingBottom: `${yMargin}in`,
    },
    container: {
      flexDirection: 'column',
      width: '100%',
      padding: `0 ${xMargin}in`,
      color: containerColor,
      fontFamily: primaryFontFamily,
      fontWeight: 'normal',
    },
  })

  return (
    <Document pageMode={pageMode} pageLayout={pageLayout}>
      <Page size={size} style={styles.page} orientation={orientation}>
        <TopPad yMargin={yMargin} />
        <View style={styles.container}>
          <StyleContext.Provider value={{ templateStyle }}>
            {children}
          </StyleContext.Provider>
        </View>
      </Page>
    </Document>
  )
}
