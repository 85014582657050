import React, { useState } from 'react'
import { useTemplate } from './TemplateProvider'
import { Slider } from '../ui/slider'

const SliderControl = ({
  title,
  min,
  max,
  step,
  settingName,
  onValueChange = () => {},
  onValueCommit = () => {},
  formattingFunction = (v) => v,
  ...props
}) => {
  const { useTemplateSetting, isSettingSupported } = useTemplate()
  const [settingValue, setSettingValue] = useTemplateSetting(settingName)

  const [sliderValue, setSliderValue] = useState(settingValue)

  if (!isSettingSupported(settingName)) {
    return null
  }

  return (
    <div className="mb-6">
      <div className="font-bold">{title}</div>
      <div className="flex flex-row rounded-xl border border-solid border-slider bg-intoo-haze p-4">
        <Slider
          name={title}
          accessibleLabel={title}
          className="!mt-0"
          trackClassName="bg-alto-200"
          min={min}
          max={max}
          step={step}
          defaultValue={[sliderValue]}
          onValueChange={(vals) => {
            setSliderValue(vals[0])
            onValueChange(vals)
          }}
          onValueCommit={(vals) => {
            setSettingValue(vals[0])
            onValueCommit(vals)
          }}
          dontShowSteps
          {...props}
        />
        <div className="ml-4 whitespace-nowrap">
          {formattingFunction(sliderValue)}
        </div>
      </div>
    </div>
  )
}

export default SliderControl
