import React, { useState } from 'react'
import { cn } from '../../lib/utils'
import SetGoalDialog from './SetGoalDialog'
import SetTimelineDialog from './SetTimelineDialog'
import { TargetIcon, TimeIcon } from './TrackTileComponents/icons'
import { Button } from '~/components/_atoms'

const GoalTile = ({
  className = null,
  variant = 'goal',
  goal,
  timeline,
  getUserTrackerSections,
}) => {
  const [showGoalDialog, setShowGoalDialog] = useState(false)
  const [showDateDialog, setShowDateDialog] = useState(false)
  const toggleGoalDialog = () => setShowGoalDialog((prev) => !prev)
  const toggleDateDialog = () => setShowDateDialog((prev) => !prev)
  const headerClasses = cn(
    'h-full w-[223px] items-center text-sm font-medium uppercase leading-5 tracking-wide text-intoo-blue-medium bg-[#E0F2F9] rounded-lg p-4',
    className,
  )

  return (
    <div>
      <SetGoalDialog
        showDialog={showGoalDialog}
        toggleDialog={toggleGoalDialog}
        goal={goal}
        getUserTrackerSections={getUserTrackerSections}
      />
      <SetTimelineDialog
        showDialog={showDateDialog}
        toggleDialog={toggleDateDialog}
        timeline={timeline}
        getUserTrackerSections={getUserTrackerSections}
      />
      {variant === 'goal' && (
        <div className={headerClasses}>
          <div className="mb-1 flex items-center gap-1">
            <TargetIcon variant="primary" size={16} />
            <div>YOUR GOAL:</div>
          </div>
          <Button
            variant="outline"
            size="sm"
            fullWidth
            onClick={toggleGoalDialog}
            id="tile_goal"
            className="!h-auto flex-grow !whitespace-normal break-words py-1 text-center [&&]:rounded-sm [&_.yns-stack]:min-w-0 [&_.yns-stack]:max-w-full"
          >
            {goal}
          </Button>
        </div>
      )}
      {variant === 'timeline' && (
        <div className={headerClasses}>
          <div className="mb-1 flex items-center gap-1">
            <TimeIcon variant="primary" height="16" />
            <div>YOUR TIMELINE:</div>
          </div>
          <Button
            variant="outline"
            size="sm"
            fullWidth
            onClick={toggleDateDialog}
            id="tile_timeline"
          >
            {goal}
          </Button>
        </div>
      )}
    </div>
  )
}
export default GoalTile
