import React from 'react'
import useTranslation from '../shared/TranslationProvider'
import useShortCourses from './ShortCourseProvider'
import { cn } from '../../lib/utils'
import Card from './Cards'
const SubNav = () => {
  const { currentCourse, activeSubNav, setActiveSubNav } = useShortCourses()
  const slug = currentCourse.slug
  const { t } = useTranslation(`${slug}.overview`)

  const subNavItems = t('subnav')
  return (
    <>
      <div className="mx-4 overflow-x-scroll p-2 sm:static sm:overflow-auto md:mx-10 md:mt-12 md:border-b md:border-[#e5e5e5] md:p-0">
        <div className="flex justify-start gap-10">
          {subNavItems.map((item, idx) => {
            const title = item.title
            const itemSlug = item.slug
            return (
              <SubNavButton
                key={`${itemSlug}-${idx}`}
                onClick={() => setActiveSubNav(itemSlug)}
                active={itemSlug === activeSubNav}
              >
                {title}
              </SubNavButton>
            )
          })}
        </div>
      </div>
      <Card />
    </>
  )
}

export default SubNav

const SubNavButton = ({ active, children, ...props }) => {
  return (
    <button {...props} className="flex min-w-16 flex-col items-center">
      <div
        className={cn(
          'my-2 whitespace-nowrap text-center text-black',
          active && 'font-bold',
        )}
      >
        {children}
      </div>
      <div
        className={cn(active ? 'h-1 w-full rounded-t-sm bg-primary' : 'h-1')}
      ></div>
    </button>
  )
}
