import React from 'react'
import { Form } from '../../../../lib/ui/form'
import useCreateAlertForm from './useCreateAlertForm'
import { Button } from '../../../../lib/ui/button'
import translations from '../../locales.json'
import useTranslation, {
  TranslationProvider,
} from '../../../shared/TranslationProvider'
import { InputField, SelectField } from '../../../shared/FormFields'
import parse from 'html-react-parser'

const LocationPreferencesInJobSearch = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope={
        'javascript.components.ShortCourses.work-environment.job-search-preferences'
      }
    >
      <LocationPreferencesInJobSearchInner {...props} />
    </TranslationProvider>
  )
}

const LocationPreferencesInJobSearchInner = ({
  candidateEmail,
  url,
  documentPath,
}) => {
  const { t } = useTranslation('')
  const { form, submitted, onSubmit, jobCount } = useCreateAlertForm(
    candidateEmail,
    url,
    documentPath,
    t,
  )

  const { what, where } = form.watch()

  const disabledButton = submitted || (!what && !where)

  return (
    <div className="flex flex-col gap-4">
      <Form {...form}>
        <form onSubmit={form.handleSubmit(onSubmit)}>
          <div className="flex flex-col gap-4">
            <FormRow>
              <div>{t('looking_for')}</div>
              <InputField
                control={form.control}
                name="what"
                placeholder={t('looking_for_placeholder')}
                className={'flex-grow'}
              />
              <div>{t('role')}</div>
              <InputField
                control={form.control}
                name="where"
                placeholder={t('near_placeholder')}
                className={'flex-grow'}
              />
              <div>.</div>
            </FormRow>
            <FormRow>
              <div>{t('notifications')}</div>
              <SelectField
                className={'flex-grow'}
                control={form.control}
                name="frequency"
                choices={[
                  { value: 'Daily', label: t('daily') },
                  { value: 'Weekly', label: t('weekly') },
                ]}
              />
              <div>{t('via_email')}</div>
              <InputField
                className={'flex-grow'}
                control={form.control}
                name="email"
                placeholder={t('email_placeholder')}
                disabled={true}
              />
              <div>.</div>
            </FormRow>
            <div>
              <a target="_blank" href="/users/edit?page=account">
                {t('update_email')}
              </a>
            </div>
            <Button
              disabled={disabledButton}
              className="self-start"
              type="submit"
            >
              {t('cta')}
            </Button>
          </div>
        </form>
      </Form>
      {jobCount && <JobCount t={t} jobCount={jobCount} />}
    </div>
  )
}

const JobCount = ({ t, jobCount }) => {
  const jobNoun = jobCount === 1 ? t('job') : t('jobs')
  const jobAlerts = t('job_alerts')
  return (
    <div>
      <p>{parse(t('alert_response', { jobCount, jobNoun, jobAlerts }))}</p>
    </div>
  )
}

const FormRow = ({ children }) => {
  return <div className="flex flex-wrap items-end gap-4">{children}</div>
}

export default LocationPreferencesInJobSearch
