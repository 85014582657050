import React from 'react'
import useShortCourses from './ShortCourseProvider'
import useTranslation from '../shared/TranslationProvider'

const CardDiv = ({ divType, className, children }) => {
  if (divType === 'div') return <div className={className}>{children}</div>
  if (divType === 'p') return <p className={className}>{children}</p>
  if (divType === 'ul') return <ul className={className}>{children}</ul>
  if (divType === 'li') return <li className={className}>{children}</li>
}

const Card = () => {
  const { activeSubNav, slug } = useShortCourses()
  const { t } = useTranslation(`${slug}.overview`)
  const cards = t('subnav')
  const cardContents = cards.find(
    (card) => card.slug === activeSubNav,
  ).card_content
  return (
    cardContents && (
      <div className="mx-4 max-w-lg pt-4 md:mx-10">
        {cardContents.map((cardContent, idx) => {
          const { div_type, className, content, children } = cardContent
          return (
            <CardDiv
              divType={div_type}
              className={className}
              key={`card-${idx}`}
            >
              {content}
              {children &&
                children.map((child, idx) => {
                  const { div_type, className, content, children } = child
                  return (
                    <CardDiv
                      divType={div_type}
                      className={className}
                      key={`card-${idx}`}
                    >
                      {content}
                      {children &&
                        children.map((grandChild, idx) => {
                          const { div_type, className, content, children } =
                            grandChild
                          return (
                            <CardDiv
                              divType={div_type}
                              className={className}
                              key={`card-${idx}`}
                            >
                              {content}
                              {children &&
                                children.map((greatGrandChild, idx) => {
                                  const { div_type, className, content } =
                                    greatGrandChild
                                  return (
                                    <CardDiv
                                      divType={div_type}
                                      className={className}
                                      key={`card-${idx}`}
                                    >
                                      {content}
                                    </CardDiv>
                                  )
                                })}
                            </CardDiv>
                          )
                        })}
                    </CardDiv>
                  )
                })}
            </CardDiv>
          )
        })}
      </div>
    )
  )
}

export default Card
