import React, { useRef } from 'react'
import { useTemplate } from './TemplateProvider'

const ColorPickerControl = ({ settingName, title }) => {
  const { useTemplateSetting, isSettingSupported } = useTemplate()
  const [color, setColor] = useTemplateSetting(settingName)
  const colorPickerRef = useRef()

  const colors = [
    '#ED1A1A',
    '#F8D149',
    '#77C712',
    '#00AF46',
    '#00E3C8',
    '#0070F3',
    '#005BA2',
    '#4F00F6',
  ]

  const colorIsStandard = colors.includes(color)

  const colorClick = (event) => setColor(event.target.value)

  if (!isSettingSupported(settingName)) {
    return null
  }

  return (
    <div className="mb-6">
      <div className="font-bold">{title}</div>
      <div className="align-start flex flex-row space-x-2 pt-3 lg:space-x-4">
        {colors.map((thisColor) => (
          <ColorPickerButton
            key={thisColor}
            active={thisColor === color}
            color={thisColor}
            onClick={colorClick}
          />
        ))}
        {!colorIsStandard && (
          <ColorPickerButton color={color} active={true} onClick={colorClick} />
        )}
        <button
          className="h-8 w-8 rounded-full"
          onClick={() => colorPickerRef.current.click()}
        >
          <MultiColorIcon />
        </button>
        <input
          ref={colorPickerRef}
          className="invisible !ml-0 w-[1px]"
          aria-label="color picker"
          type="color"
          onChange={colorClick}
        />
      </div>
    </div>
  )
}
export default ColorPickerControl

const MultiColorIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="32"
      height="32"
      viewBox="0 0 32 32"
      fill="none"
      aria-label="Color picker"
    >
      <g
        clipPath="url(#paint0_angular_231_1616_clip_path)"
        data-figma-skip-parse="true"
      >
        <g transform="matrix(0 0.016 -0.016 0 16 16)">
          <foreignObject x="-1062.5" y="-1062.5" width="2125" height="2125">
            <div
              xmlns="http://www.w3.org/1999/xhtml"
              style={{
                background:
                  'conic-gradient(from 90deg,rgba(246, 59, 0, 1) 0deg,rgba(255, 214, 0, 1) 57.6deg,rgba(51, 255, 0, 1) 122.4deg,rgba(32, 255, 201, 1) 205.2deg,rgba(81, 0, 255, 1) 284.4deg,rgba(255, 0, 183, 1) 360deg)',
                height: '100%',
                width: '100%',
                opacity: 1,
              }}
            ></div>
          </foreignObject>
        </g>
      </g>
      <circle
        cx="16"
        cy="16"
        r="16"
        data-figma-gradient-fill='{"type":"GRADIENT_ANGULAR","stops":[{"color":{"r":0.96666663885116577,"g":0.23199999332427979,"b":0.0,"a":1.0},"position":0.0},{"color":{"r":1.0,"g":0.83999997377395630,"b":0.0,"a":1.0},"position":0.15999999642372131},{"color":{"r":0.20000000298023224,"g":1.0,"b":0.0,"a":1.0},"position":0.34000000357627869},{"color":{"r":0.12924805283546448,"g":1.0,"b":0.79101955890655518,"a":1.0},"position":0.56999999284744263},{"color":{"r":0.31999996304512024,"g":0.0,"b":1.0,"a":1.0},"position":0.79000002145767212},{"color":{"r":1.0,"g":0.0,"b":0.72000002861022949,"a":1.0},"position":1.0}],"stopsVar":[{"color":{"r":0.96666663885116577,"g":0.23199999332427979,"b":0.0,"a":1.0},"position":0.0},{"color":{"r":1.0,"g":0.83999997377395630,"b":0.0,"a":1.0},"position":0.15999999642372131},{"color":{"r":0.20000000298023224,"g":1.0,"b":0.0,"a":1.0},"position":0.34000000357627869},{"color":{"r":0.12924805283546448,"g":1.0,"b":0.79101955890655518,"a":1.0},"position":0.56999999284744263},{"color":{"r":0.31999996304512024,"g":0.0,"b":1.0,"a":1.0},"position":0.79000002145767212},{"color":{"r":1.0,"g":0.0,"b":0.72000002861022949,"a":1.0},"position":1.0}],"transform":{"m00":1.9594349641362686e-15,"m01":-32.0,"m02":32.0,"m10":32.0,"m11":1.9594349641362686e-15,"m12":-1.9594349641362686e-15},"opacity":1.0,"blendMode":"NORMAL","visible":true}'
      />
      <defs>
        <clipPath id="paint0_angular_231_1616_clip_path">
          <circle cx="16" cy="16" r="16" />
        </clipPath>
      </defs>
    </svg>
  )
}

const ColorPickerButton = ({ color, active, onClick }) => {
  const inactiveClasses = 'h-8 w-8 rounded-full'
  const activeClasses = inactiveClasses + ' ring-2 ring-offset-1 ring-[#666666]'
  const className = active ? activeClasses : inactiveClasses

  return (
    <button
      className={className}
      style={{ backgroundColor: color }}
      value={color}
      title={`select color ${color}`}
      aria-label={`select color ${color}`}
      onClick={onClick}
    >
      &nbsp;
    </button>
  )
}
