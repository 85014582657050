import * as React from 'react'

import {
  Drawer,
  DrawerContent,
  DrawerDescription,
  DrawerHeader,
  DrawerTitle,
} from '../../../lib/ui/drawer'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../../lib/ui/dialog'
import { useIsDesktop } from '../../../hooks/useMediaQuery'

export function Modal({
  children,
  title,
  description,
  content,
  open,
  setOpen,
  variant,
}) {
  const isDesktop = useIsDesktop()

  const handleOpenChange = (isOpen) => {
    setOpen(isOpen)
    const chatButton = document.getElementById('coach_chat_button')
    if (!chatButton) return

    if (isOpen) {
      chatButton.style.display = 'none'
    } else {
      chatButton.style.display = 'block'
    }
  }

  if (isDesktop) {
    return (
      <Dialog
        open={open}
        onOpenChange={handleOpenChange}
        className="rounded-2xl"
      >
        <DialogTrigger asChild className="cursor-pointer">
          {children}
        </DialogTrigger>
        <DialogContent
          className={`fix-legacy-hack ${
            variant == 'noHeader' ? 'p-6' : ''
          } font-roboto lg:max-w-3xl xl:max-w-5xl`}
          variant={variant || 'default'}
          dialogCloseClass={'text-black'}
        >
          {variant != 'noHeader' && (
            <DialogHeader>
              <DialogTitle className="text-left" asChild>
                {title}
              </DialogTitle>
            </DialogHeader>
          )}
          {description && <DialogDescription>{description}</DialogDescription>}
          {content}
        </DialogContent>
      </Dialog>
    )
  }

  return (
    <Drawer open={open} onOpenChange={handleOpenChange}>
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>
      <DrawerContent className="fix-legacy-hack font-roboto">
        <DrawerHeader>
          <DrawerTitle asChild>{title}</DrawerTitle>
          {description && <DrawerDescription>{description}</DrawerDescription>}
        </DrawerHeader>
        {content}
      </DrawerContent>
    </Drawer>
  )
}
