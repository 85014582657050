import React from 'react'
import { useReferenceSheet } from '../ReferenceSheetProvider'
import { EditorHeading } from '../../ResumeBuilder/Editor/common'
import { EditorItemField, EditorItemProvider } from './EditorItemProvider'

const BasicsEditor = () => {
  const { referenceSheetDocument: { basics } = {} } = useReferenceSheet()
  const handleLinkedInProfileUpdate = (item, value) => {
    const updatedProfiles = item.profiles?.map((profile) =>
      profile.network?.toLowerCase() === 'linkedin'
        ? { ...profile, url: value }
        : profile,
    ) || [{ network: 'LinkedIn', url: value }]
    return {
      ...item,
      profiles: updatedProfiles,
    }
  }

  const getLinkedInUrl = (item) =>
    item?.profiles?.find(
      (profile) => profile.network?.toLowerCase() === 'linkedin',
    )?.url || ''

  return (
    <EditorItemProvider sectionName="basics" item={basics}>
      <div>
        <EditorHeading>Personal Details</EditorHeading>
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          <EditorItemField
            containerClassName="md:col-span-2"
            label="Job Title"
            fieldName="label"
          />
          <EditorItemField
            containerClassName="md:col-span-2"
            label="Full Name"
            fieldName="name"
          />
          <EditorItemField label="Phone" fieldName="phone" />
          <EditorItemField label="Email" fieldName="email" />
          <EditorItemField
            label="Address"
            fieldName="location"
            childField="address"
          />
          <EditorItemField
            label="City"
            fieldName="location"
            childField="city"
          />
          <EditorItemField
            label="Region (State, Province, etc...)"
            fieldName="location"
            childField="region"
          />
          <EditorItemField
            label="Postal Code"
            fieldName="location"
            childField="postalCode"
          />
          <EditorItemField label="Website" fieldName="url" />
          <EditorItemField
            label="LinkedIn"
            fieldName="profiles"
            getItemValue={getLinkedInUrl}
            handleItemUpdate={handleLinkedInProfileUpdate}
          />
        </div>
      </div>
    </EditorItemProvider>
  )
}
export default BasicsEditor
