import React from 'react'
import { useIntroductionScript } from './IntroductionScriptProvider'
import { Button } from '../../../lib/ui/button'
import useTranslation from '../../shared/TranslationProvider'
import {
  Dialog,
  DialogClose,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from '../../../lib/ui/dialog'
import useIntroductionScriptForm from './useIntroductionScriptForm'
import { Form } from '../../../lib/ui/form'
import { InputField } from '../../shared/FormFields'

export const NameDialog = ({ children }) => {
  const { showDialog, toggleDialog } = useIntroductionScript()

  const { t } = useTranslation('ayla_response')

  const { form, submitted, onSubmit } = useIntroductionScriptForm('update')

  return (
    <Dialog open={showDialog} onOpenChange={toggleDialog}>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent className="site-info-dialog max-w-xl font-roboto">
        <DialogHeader>
          <DialogTitle>{t('dialog_header')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 p-4">
              <InputField
                name={'script_name'}
                placeholder={t('script_name_placeholder')}
                label={t('script_name_label')}
                control={form.control}
              />
              <DialogFooter>
                <Button disabled={submitted} type="submit">
                  {t('save')}
                </Button>
                <DialogClose asChild>
                  <Button variant="outline" type="button">
                    {t('cancel')}
                  </Button>
                </DialogClose>
              </DialogFooter>
            </div>
          </form>
        </Form>
      </DialogContent>
      <DialogClose />
    </Dialog>
  )
}
