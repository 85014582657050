import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { useIntroductionScript } from './IntroductionScriptProvider'
import { apiPatch, apiPost } from '../../../util/api'

const useIntroductionScriptForm = (type = 'create') => {
  const {
    candidate,
    requestAylaStatement,
    handlePageChange,
    setIntroductionScriptId,
    completeActivity,
    customScript,
    toggleDialog,
    scriptPartId,
    setScriptPartId,
    introductionScriptId,
    aylaResponse,
  } = useIntroductionScript()
  const [submitted, setSubmitted] = useState(false)

  const { candidate_id, candidate_job_title } = candidate

  const dynamicSchema =
    type === 'update'
      ? { script_name: z.string().min(1, { message: 'Please enter a name' }) }
      : {}

  const schema = z.object({
    target_role: z.string().optional(),
    career_level: z.string().optional(),
    industry: z.string().optional(),
    organization_type: z.string().optional(),
    organization_size: z.string().optional(),
    ...dynamicSchema,
  })

  const defaultValues = {
    target_role: candidate_job_title || '',
    career_level: '',
    industry: '',
    organization_type: '',
    organization_size: '',
    script_name: '',
  }

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const updateScriptPart = async (values) => {
    const url = `/job-search/recruiters/document/introduction_scripts/${introductionScriptId}?document_part_id=${scriptPartId}`
    const script = customScript.length > 0 ? customScript : aylaResponse
    const data = {
      introduction_script: {
        candidate_id: candidate_id,
        name: 'Introduction Script',
        draft: false,
        introduction_script_part_attributes: {
          script: script,
          name: values.script_name,
        },
      },
    }

    let response
    try {
      response = await apiPatch(url, data)
    } catch (err) {
      console.log(err)
    }
    if (response.status === 200) {
      toggleDialog()
      completeActivity()
    } else {
      throw new Error('Error updating introduction script')
    }
  }

  const createIntroScript = async (values) => {
    if (introductionScriptId) {
      requestAylaStatement(values, introductionScriptId)
      handlePageChange(2)
      return
    }
    const url = '/job-search/recruiters/document/introduction_scripts'
    const data = {
      introduction_script: {
        candidate_id: candidate_id,
        name: 'Introduction Script',
        draft: true,
      },
    }
    try {
      await apiPost(url, data).then((response) => {
        const documentId = response.data.document_id
        setIntroductionScriptId(documentId)
        setScriptPartId(response.data.document_part_id)
        requestAylaStatement(values, documentId)
        handlePageChange(2)
        return response
      })
    } catch (err) {
      console.log(err)
    }
  }

  const onSubmit = (values) => {
    setSubmitted(true)
    if (type === 'update') {
      updateScriptPart(values)
    } else {
      createIntroScript(values)
    }
  }

  return { form, submitted, onSubmit }
}

export default useIntroductionScriptForm
