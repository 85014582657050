import React from 'react'
import AnimatedProgressProvider from './AnimatedProgressProvider'
import { easeQuadInOut } from 'd3-ease'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import { useActionPlan } from './ActionPlanProvider'

const ProgressContainer = ({ className, homeProgress, progressPercent }) => {
  const { progressPercent: savedProgressPercent } = useActionPlan()
  const progress = isNaN(progressPercent)
    ? savedProgressPercent
    : progressPercent

  return (
    <div
      className={`progress-container ${
        homeProgress ? 'home-progress-container' : ''
      } ${className}`}
    >
      <AnimatedProgressProvider
        valueStart={0}
        valueEnd={progress}
        duration={1.4}
        easingFunction={easeQuadInOut}
      >
        {(value) => {
          const roundedValue = Math.round(value)
          return (
            <CircularProgressbar
              value={value}
              text={`${roundedValue}%`}
              styles={buildStyles({ pathTransition: 'none' })}
            />
          )
        }}
      </AnimatedProgressProvider>
    </div>
  )
}
export default ProgressContainer
