import React, {
  useCallback,
  useEffect,
  useState,
  createContext,
  useContext,
} from 'react'
import { usePDF } from '@react-pdf/renderer'
import TemplateSwitcher from './TemplateSwitcher'
import { apiPost, apiPut } from '../../util/api'

const ReferenceSheetContext = createContext()

export const useReferenceSheet = () => {
  return useContext(ReferenceSheetContext)
}

const ReferenceSheetProvider = ({
  reference_sheet_json,
  document_id,
  template_index,
  dev_mode,
  children,
}) => {
  const [referenceSheetDocument, setReferenceSheetDocument] =
    useState(reference_sheet_json)
  const [templateIndex, setTemplateIndex] = useState(template_index || 0)
  const documentId = document_id

  // use hook for fine-grained control over PDF rendering
  const [pdf, renderPdf] = usePDF({
    document: (
      <TemplateSwitcher
        referenceSheetDocument={referenceSheetDocument}
        templateIndex={templateIndex}
      />
    ),
  })

  // Re-render the PDF
  const updateReferenceSheet = useCallback(() => {
    renderPdf(
      <TemplateSwitcher
        referenceSheetDocument={referenceSheetDocument}
        templateIndex={templateIndex}
      />,
    )
  }, [referenceSheetDocument, renderPdf, templateIndex])

  // Automatically re-render pdf every time referenceSheetDocument changes
  useEffect(() => {
    updateReferenceSheet()
  }, [referenceSheetDocument, updateReferenceSheet])

  // Persist reference sheet data to the server
  const persistReferenceSheet = async () => {
    if (documentId) {
      await saveExistingReferenceSheet()
    } else {
      await saveNewReferenceSheet()
    }
  }

  const saveNewReferenceSheet = async () => {
    let response
    try {
      response = await apiPost('/personal_brand/reference_sheet_builder/', {
        reference_sheet: referenceSheetDocument,
        template_index: templateIndex,
      })
    } catch (e) {
      console.error('Error saving new reference sheet', e)
      return
    }
    if (response.status === 200) {
      window.location.href =
        '/personal_brand/reference_sheet_builder/' + response.data.document_id
    }
  }

  const saveExistingReferenceSheet = async () => {
    let response
    try {
      response = await apiPut(
        `/personal_brand/reference_sheet_builder/${documentId}`,
        {
          reference_sheet: referenceSheetDocument,
          template_index: templateIndex,
        },
      )
    } catch (e) {
      console.error('Error saving existing reference sheet', e)
      return
    }
    if (response.status === 200) {
      console.log('Reference sheet saved successfully', response)
    } else {
      console.error('Error saving existing reference sheet', response)
    }
  }

  const value = {
    pdf,
    documentId,
    persistReferenceSheet,
    referenceSheetDocument,
    setReferenceSheetDocument,
    templateIndex,
    setTemplateIndex,
    devMode: dev_mode,
  }

  return (
    <ReferenceSheetContext.Provider value={value}>
      {children}
    </ReferenceSheetContext.Provider>
  )
}
export default ReferenceSheetProvider
