import * as React from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useDashboard } from '../DashboardProvider'
import { Button, Card, Typography } from '~/components/_atoms'
import { Stack } from '~/components/_layouts'
import { BriefcaseIcon } from '@heroicons/react/24/outline'

export function MyJobsCard({ className }) {
  const { t } = useTranslation()
  const { current_user } = useDashboard()
  return (
    <Card
      className={className}
      title={t('my_jobs_card.title')}
      icon={<BriefcaseIcon className="h-10 w-10" />}
      actions={
        <Button
          variant="ghost"
          href="job-search/saved_jobs"
          className="ga-trackable"
        >
          {t('my_jobs_card.action_text')}
        </Button>
      }
    >
      <Stack spacing={2}>
        <Typography>{t('my_jobs_card.description')}</Typography>
        <div className="max-w-90 flex items-center justify-between gap-3">
          <Typography className="flex-2 font-bold">
            {t('my_jobs_card.jobs_applied')}
          </Typography>
          <Typography
            variant="subtitle"
            className="flex-1 text-right font-bold"
          >
            {current_user.recently_applied_job_count}
          </Typography>
        </div>
      </Stack>
    </Card>
  )
}
