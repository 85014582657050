import React from 'react'
import AssessmentCard from './AssessmentCard'
import { useTranslation } from './../../hooks/useTranslation'
import { TranslationProvider } from './../shared/TranslationProvider'
import translations from './locales.json'

const AssessmentCenter = ({ assessments, s3_base_url, locale }) => {
  return (
    <TranslationProvider
      translations={translations}
      locale={locale}
      scope="javascript.components.AssessmentCenter"
    >
      <AssessmentCenterInner
        assessments={assessments}
        s3_base_url={s3_base_url}
      />
    </TranslationProvider>
  )
}

const AssessmentCenterInner = ({ assessments, s3_base_url }) => {
  const { t } = useTranslation()

  return (
    <div className="tw-enable min-h-[70vh] font-[Roboto] lg:flex lg:flex-row">
      <div className="mx-2 flex-grow sm:mx-8">
        <h1 className="mb-4 mt-10 !font-roboto-slab text-2xl text-[32px] font-bold leading-normal text-black">
          <span className="font-normal">{t(`title`)}</span> {t(`title_2`)}
        </h1>
        <div className="mb-6">{t('intro')}</div>
        <div className="grid grid-cols-1 gap-x-12 gap-y-10 md:grid-cols-3">
          <div className="min-w-[472px] overflow-hidden"></div>
        </div>
        <div className="flex flex-col">
          <div className="grid grid-cols-1 gap-x-12 gap-y-6">
            {assessments.map((assessment, idx) => (
              <AssessmentCard
                key={`assessment-${idx}`}
                assessment={assessment}
                className={'col-span-2 md:col-span-1'}
                s3_base_url={s3_base_url}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default AssessmentCenter
