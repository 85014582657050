import React from 'react'
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
} from '@mui/material'
import { Close as CloseIcon } from '@mui/icons-material'

const modalSizeMap = {
  sm: '400px',
  md: '800px',
  lg: '1200px',
  xl: '90vw',
}

function Modal({
  actions = null,
  children = [],
  onClose = () => null,
  open = false,
  size = 'sm',
  title = null,
  variant = 'primary',
  onSubmit,
}) {
  let bgColor = 'bg-intoo-blue-medium-800'
  if (variant === 'secondary') {
    bgColor = 'bg-intoo-green-medium-800'
  }

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      scroll="paper"
      sx={{
        '& .MuiDialog-paper': {
          borderRadius: '20px !important',
          maxWidth: modalSizeMap[size],
        },
      }}
    >
      <DialogTitle
        className={`text-white ${bgColor}`}
        sx={{
          display: 'flex',
          alignItems: 'center',
          fontWeight: '500 !important',
          fontSize: '18px !important',
          lineHeight: '18px !important',
          pr: 1,
          py: 1,
        }}
        component="div"
      >
        {title}
        <IconButton aria-label="close" onClick={onClose} sx={{ ml: 'auto' }}>
          <CloseIcon className="text-white" />
        </IconButton>
      </DialogTitle>
      {onSubmit ? (
        <form onSubmit={onSubmit}>
          <DialogContent
            sx={{
              maxHeight: '60vh',
              overflow: 'auto',
            }}
          >
            <Stack spacing={4} sx={{ py: 3 }}>
              {children}
            </Stack>
          </DialogContent>
          {actions && (
            <DialogActions>
              <Stack direction="row" spacing={3} sx={{ flex: 1, pt: 3 }}>
                {actions}
              </Stack>
            </DialogActions>
          )}
        </form>
      ) : (
        <>
          <DialogContent
            sx={{
              maxHeight: '80vh',
              overflow: 'auto',
            }}
          >
            <Stack spacing={4} sx={{ py: 3 }}>
              {children}
            </Stack>
          </DialogContent>
          {actions && (
            <DialogActions>
              <Stack direction="row" spacing={3}>
                {actions}
              </Stack>
            </DialogActions>
          )}
        </>
      )}
    </Dialog>
  )
}

export default Modal
