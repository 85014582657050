import React, { useEffect } from 'react'
import { BackQuizButton } from '../Buttons.js'
import { useIsDesktop, useIsMobile } from '../../../hooks/useMediaQuery.js'
import { useQuiz } from '../QuizProvider.js'
import useLikertLayout from '../LikertLayout/LikertLayoutProvider.js'
import LikertQuestion from '../LikertLayout/LikertQuestion.js'
import LayoutFiveOptions from '../LikertLayout/LayoutFiveOptions.js'
import useTranslation from '../../shared/TranslationProvider.js'

const Question = ({ questionInfo, idx }) => {
  const { saveQuiz, setQuizIndex, quizIndex, quizName } = useQuiz()
  const {
    scaleOptions,
    likert,
    numQuestions,
    setSectionIndex,
    sectionIndex,
    openOverview,
    sectionQuestions,
    numQuestionsInSection,
    translationKey,
  } = useLikertLayout()
  const { t } = useTranslation(translationKey)
  const question = questionInfo.question
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()
  const options = questionInfo.options

  const handleBackClick = () => {
    if (idx === 0) {
      openOverview()
    } else {
      setQuizIndex((prev) => prev - 1)
    }
  }

  const handleNextClick = () => {
    const showResultsPage = quizIndex === numQuestions - 1
    const showNextSection =
      quizName === 'Salary Negotiator'
        ? [9, 20, 29].includes(quizIndex)
        : (quizIndex + 1) % numQuestionsInSection === 0

    if (showResultsPage) {
      saveQuiz(true)
    } else if (showNextSection) {
      saveQuiz(false, false)
      setSectionIndex((prev) => prev + 1)
      openOverview()
    } else {
      saveQuiz(false, false)
      setQuizIndex((prev) => prev + 1)
    }
  }

  useEffect(() => {
    window.scrollTo(0, isMobile ? document.body.scrollHeight : 0)
  }, [isMobile])

  const layoutProps = { options, questionInfo, handleNextClick }
  const showQuestion = quizIndex === sectionQuestions[sectionIndex][idx] - 1
  const continueText = t('overview.back_button')

  return (
    showQuestion && (
      <div className="flex min-h-[40rem] flex-col justify-between sm:mx-20">
        <div>
          <h1 className="text-2xl sm:!my-8">{question}</h1>
          {likert ? (
            <LikertQuestion
              scaleOptions={scaleOptions}
              handleNextClick={handleNextClick}
            >
              <Image questionInfo={questionInfo} />
            </LikertQuestion>
          ) : (
            <LayoutFiveOptions {...layoutProps}>
              <Image questionInfo={questionInfo} />
            </LayoutFiveOptions>
          )}
        </div>
        <div className="flex flex-col items-center gap-2 self-end">
          <BackQuizButton onClick={handleBackClick}>
            {continueText}
          </BackQuizButton>
          {isDesktop && (
            <div>
              {quizIndex + 1} of {numQuestions}
            </div>
          )}
        </div>
      </div>
    )
  )
}

const Image = ({ questionInfo }) => {
  const { image } = questionInfo
  const isDesktop = useIsDesktop()
  const { bucketPath } = useQuiz()
  if (!image || !isDesktop) return null
  return (
    <div>
      <img src={`${bucketPath}${image.src}`} alt={image.alt} />
    </div>
  )
}

export default Question
