import React from 'react'
import { useNetworkingBrief } from '../NetworkingBriefProvider'
import { EditorHeading } from '../../ResumeBuilder/Editor/common'
import { EditorItemTextField, EditorItemProvider } from './EditorItemProvider'

const GoalsEditor = () => {
  const { networkingBriefDocument: { goals } = {} } = useNetworkingBrief()

  return (
    <EditorItemProvider sectionName="goals" item={goals}>
      <div>
        <EditorHeading>Networking Goals</EditorHeading>
        <p>
          {' '}
          Objectives for networking (e.g., seeking opportunities, mentorship).
        </p>
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          <EditorItemTextField
            containerClassName="md:col-span-2"
            fieldName="networkingGoals"
            label="Networking Goals Statement"
            areaClassName="h-[150px]"
          />
        </div>
      </div>
    </EditorItemProvider>
  )
}
export default GoalsEditor
