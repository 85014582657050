import React, { useState } from 'react'
import { SelectField, InputField } from '../../shared/FormFields'
import { RadioGroup, RadioGroupItem } from '../../../lib/ui/radio-group'
import { Form } from '../../../lib/ui/form'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button } from '../../../lib/ui/button'
import AylaResponseScrollArea from '../../ShortCourses/AylaResponseScrollArea'
import { apiPost } from '../../../util/api'
import { useAiResponseStream } from '../useAiResponseStream'
import StarFeedback from '../../shared/StarFeedback'
import { TranslationProvider } from '../../shared/TranslationProvider'
import translations from '../locales.json'
import { useTranslation } from '../../../hooks/useTranslation'

const AylaIndustryResearch = (props) => {
  const { t } = useTranslation()
  const { industries } = props
  const [industry, setIndustry] = useState('')
  const [lastIndustry, setLastIndustry] = useState('')
  const [interest, setInterest] = useState('')
  const [years, setYears] = useState('')
  const [submitted, setSubmitted] = useState(false)
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const showCursor = submitted && displayedText.length <= 0
  const {
    aiResponse,
    setAiResponse,
    researchId,
    setResearchId,
    aiStreamComplete,
    setAiStreamComplete,
  } = useAiResponseStream()
  const form = useForm({
    resolver: zodResolver(),
  })

  const handleSubmitButtonClick = async () => {
    setSubmitted(true)
    setAiResponse('')
    setDisplayedText('')
    setIndex(0)
    setAiStreamComplete(false)
    setLastIndustry(industry)

    const {
      data: { industry_research_id },
    } = await apiPost('/research_learning/ayla/create_industry_research', {
      ai_industry_research: {
        industry: industry || '',
        interest: interest || '',
        years: years || '',
      },
    })
    setResearchId(industry_research_id)
  }

  const transformAylaResponse = (response) => {
    const urlRegex = /((https?:\/\/|www\.)[^\s]+)/g
    return response.replace(urlRegex, (url) => {
      const formattedUrl = url.startsWith('http') ? url : `https://${url}`
      return `<a href="${formattedUrl}" target="_blank" rel="noopener noreferrer">${formattedUrl}</a>`
    })
  }

  return (
    <div className="m-4 flex flex-col items-baseline gap-2 md:m-8">
      <h1>{t('title')}</h1>
      <p className="w-3/4">{t('description')}</p>
      <p>{t('tell_ayla')}</p>
      <Form {...form}>
        <div className="flex items-center gap-4">
          <label htmlFor="industry" className="!m-0">
            {t('want_to_learn')}
          </label>
          <SelectField
            name="industry"
            className="w-48"
            control={form.control}
            choices={industries.map((industry) => ({
              value: industry,
              label: industry,
            }))}
            onChange={(value) => setIndustry(value)}
          />
          <label className="!m-0">{t('industry')}</label>
        </div>
        <label className="mt-4">{t('primarily_interested')}</label>
        <RadioGroup
          className="mt-2"
          onValueChange={(value) => setInterest(value)}
        >
          <label className="mb-2 !flex items-center">
            <RadioGroupItem value="pivot" className="mr-2" />
            {t('career_pivot')}
          </label>
          <label className="mb-2 !flex items-center">
            <RadioGroupItem value="trends" className="mr-2" />
            {t('industry_trends')}
          </label>
          <label className="mb-2 !flex items-center">
            <RadioGroupItem value="change" className="mr-2" />
            {t('change')}
          </label>
        </RadioGroup>
        {(interest == 'trends' || interest == 'change') && (
          <div className="flex items-center gap-4">
            <label htmlFor="industry" className="!m-0">
              {t('worked_in_industry')}
            </label>
            <InputField
              name="years"
              className="w-24"
              control={form.control}
              value={years}
              onChange={(e) => setYears(e.target.value)}
            />
            <label className="!m-0">{t('years')}</label>
          </div>
        )}
      </Form>
      <div className="my-4">
        <Button
          disabled={!(industry && interest) || (submitted && !aiStreamComplete)}
          onClick={() => handleSubmitButtonClick()}
        >
          {t('lets_go_ayla')}
        </Button>
      </div>
      {submitted && (
        <div className="subtitle">
          {t('what_found', { industry: lastIndustry })}
        </div>
      )}
      {submitted && (
        <AylaResponseScrollArea
          showBorder={submitted}
          showCursor={showCursor}
          aylaResponse={aiResponse}
          typingComplete={aiStreamComplete}
          setTypingComplete={setAiStreamComplete}
          displayedText={
            aiStreamComplete
              ? transformAylaResponse(displayedText).replace(/\n/g, '<br>')
              : displayedText.replace(/\n/g, '<br>')
          }
          setDisplayedText={setDisplayedText}
          index={index}
          setIndex={setIndex}
          className="mb-4 h-64"
        ></AylaResponseScrollArea>
      )}
      {submitted && aiStreamComplete && (
        <div className="flex flex-col gap-4">
          <a
            href={`industry-research.pdf?id=${researchId}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Button>{t('download_report')}</Button>
          </a>
          <div>{t('rate_response')}</div>
          <StarFeedback
            path="/research_learning/ayla/feedback?feedback_type=Feedback::AylaIndustryResearch"
            documentId={researchId}
          />
        </div>
      )}
    </div>
  )
}

const Index = (props) => {
  const { locale } = props
  return (
    <TranslationProvider
      translations={translations}
      locale={locale}
      scope="javascript.components.ResearchLearning.AylaIndustryResearch"
    >
      <AylaIndustryResearch {...props} />
    </TranslationProvider>
  )
}

export default Index
