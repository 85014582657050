import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import useCompanySearchForm from './useCompanySearchForm'
import { useSearchQuery } from '../../../hooks/useSearchQuery'
import { useCompanySearchParams } from './useCompanySearchParams'
import { apiDelete, apiPost } from '~/util/api'
const CompanySearchContext = createContext()

export function useCompanySearch() {
  return useContext(CompanySearchContext)
}

export function CompanySearchProvider({
  total_results,
  industries,
  revenue_options,
  company_sizes,
  location_types,
  allow_debug,
  location,
  radii,
  search_results,
  children,
  total_pages,
  next_page,
}) {
  const [selectedCompany, setSelectedCompany] = useState(null)
  const [selectedCompanyDrawerOpen, setSelectedCompanyDrawerOpen] =
    useState(false)

  const [formDisabled, setFormDisabled] = useState(false)

  const [viewMoreButtonDisabled, setViewMoreButtonDisabled] = useState(false)

  const [previousFirstCompanyId, setPreviousFirstCompanyId] = useState(null)

  const { urlSearchParams, setUrlSearchParams, paramString, lockParamString } =
    useCompanySearchParams(location)

  const [noResultQuery, setNoResultQuery] = useState(false)

  const [savedCompanies, setSavedCompanies] = useState([])
  const [savedContacts, setSavedContacts] = useState([])

  const {
    queryStatusMessage,
    pagesToShow,
    setPagesToShow,
    setPrefetchedPages,
    results,
    amountShown,
    amountTotal,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isPending,
    showViewMoreButton,
  } = useSearchQuery({
    queryKey: 'company-search',
    paramString,
    initialData: {
      pageParams: [1],
      pages: [
        {
          results: search_results,
          total_results: total_results,
          total_pages: total_pages,
          next_page: next_page,
        },
      ],
    },
  })

  const resultDetailScrollRef = useRef(null)

  const companyResults = results

  useEffect(() => {
    setSavedCompanies(
      companyResults.filter((company) => company.candidateHasCompany),
    )
  }, [companyResults])

  const { form, onSubmit } = useCompanySearchForm({
    urlSearchParams,
    lockParamString,
    noResultQuery,
    setPagesToShow,
    setPrefetchedPages,
  })

  const choicifyData = (arr) => {
    const choicifiedData = []
    Object.keys(arr).forEach((key) => {
      const choice = {}
      choice['value'] = arr[key]
      choice['id'] = arr[key]
      choice['label'] = key
      choicifiedData.push(choice)
    })
    return choicifiedData
  }

  const industryChoices = choicifyData(industries)
  const revenueChoices = choicifyData(revenue_options)
  const companySizeChoices = choicifyData(company_sizes)
  const locationTypeChoices = choicifyData(location_types)
  const radiusChoices = choicifyData(radii)

  const isDeletedContact = (contact) => {
    return savedContacts.find((c) => c.id === contact.id)?.deleted
  }

  const isSavedContact = (contact) => {
    return (
      (savedContacts.find((c) => c.id === contact.id)?.contactsTableId ||
        contact.candidateHasContact) &&
      !isDeletedContact(contact)
    )
  }

  const isSavedCompany = (company) => {
    return savedCompanies.find((c) => c.id === company.id)?.companyContactId
  }

  const addContact = async (contact) => {
    const { addContactPath } = contact
    let response
    try {
      response = await apiPost(addContactPath)
      if (response.status === 200 || response.status === 201) {
        const data = await response.data
        const newContact = {
          ...contact,
          contactsTableId: data.id,
        }
        setSavedContacts((prev) => [...prev, newContact])
        return data
      } else throw new Error('response was not ok')
    } catch (error) {
      console.error('Error adding contact:', error)
    }
  }

  const addCompany = async (company, contact) => {
    const { addCompanyPath } = company
    let response
    try {
      response = await apiPost(addCompanyPath)
      if (response.status === 200) {
        const data = await response.data
        const newCompany = { ...company, companyContactId: data.id }
        setSavedCompanies((prev) => [...prev, newCompany])
        if (contact) addContact(contact)
      } else throw new Error('response was not ok')
    } catch (error) {
      console.error('Error adding company:', error)
    }
  }

  const removeContact = async (contact) => {
    const { deleteContactPath } = contact
    const savedContact = savedContacts.find((c) => c.id === contact.id)
    const contactId = contact.contactsTableId || savedContact?.contactsTableId
    const url = `${deleteContactPath}/${contactId}`
    let response
    try {
      response = await apiDelete(url)
      if (response.status === 200) {
        const newContact = { ...contact, contactsTableId: null, deleted: true }
        const newContacts = savedContacts.filter((c) => c.id !== contact.id)
        setSavedContacts([...newContacts, newContact])
      } else throw new Error('response was not ok')
    } catch (error) {
      console.error('Error removing contact:', error)
    }
  }

  const removeCompany = async (company) => {
    const { deleteCompanyPath } = company
    const companyContactId =
      company.companyContactId ||
      savedCompanies.find((c) => c.id === company.id)?.companyContactId
    const url = `${deleteCompanyPath}/${companyContactId}`
    let response

    const { contacts } = company
    const contact = contacts && contacts[0]
    try {
      response = await apiDelete(url)
      if (response.status === 200) {
        setSavedCompanies((prev) => prev.filter((c) => c.id !== company.id))
        if (contact && isSavedContact(contact)) removeContact(contact)
      } else throw new Error('response was not ok')
    } catch (error) {
      console.error('Error removing company:', error)
    }
  }

  const handleSelectCompany = (company) => (e) => {
    e.stopPropagation()
    resultDetailScrollRef.current?.scrollTo(0, 0, {
      behavior: 'smooth',
    })
    setSelectedCompany(null)
    setSelectedCompany(company)
    setSelectedCompanyDrawerOpen(true)
  }

  useEffect(() => {
    if (isFetching && pagesToShow === 1 && !isFetchingNextPage) {
      setFormDisabled(true)
    } else {
      setFormDisabled(false)
    }
  }, [pagesToShow, isFetching, isFetchingNextPage, isPending])

  const firstCompany = results[0]

  useEffect(() => {
    if (firstCompany && firstCompany.id !== previousFirstCompanyId) {
      setPreviousFirstCompanyId(firstCompany.id)
      setSelectedCompany(firstCompany)
    }
  }, [firstCompany, previousFirstCompanyId])

  useEffect(() => {
    if (results.length === 0) {
      setNoResultQuery(true)
    } else {
      setNoResultQuery(false)
    }
  }, [results.length, paramString])

  const handleViewMore = async () => {
    setViewMoreButtonDisabled(true)
    setPagesToShow((prev) => prev + 1)
    await fetchNextPage()
    setViewMoreButtonDisabled(false)
  }

  const { watch } = form

  const company_name = watch('company_name')
  const where = watch('where')
  const radius = watch('radius')
  const industry = watch('industry')
  const revenue = watch('revenue')
  const company_size = watch('company_size')
  const location_type = watch('location_type')

  useEffect(() => {
    setUrlSearchParams(
      (prev) => {
        if (company_name) prev.set('company_name', company_name)
        else prev.delete('company_name')

        if (where) prev.set('where', where)
        else prev.delete('where')

        if (radius) prev.set('radius', radius)
        else prev.delete('radius')

        if (industry) prev.set('industry', industry)
        else prev.delete('industry')
        if (revenue) prev.set('revenue', revenue)
        else prev.delete('revenue')
        if (company_size) prev.set('company_size', company_size)
        else prev.delete('company_size')
        if (location_type) prev.set('location_type', location_type)
        else prev.delete('location_type')

        return prev
      },
      { replace: true },
    )
  }, [
    company_name,
    where,
    radius,
    industry,
    revenue,
    company_size,
    location_type,
    setUrlSearchParams,
  ])

  const goToCompetitor = (competitor) => {
    form.setValue('company_name', competitor.name)
    setUrlSearchParams(
      (prev) => {
        prev.set('company_name', competitor.name)
        prev.delete('where')
        prev.delete('industry')
        prev.delete('revenue')
        prev.delete('company_size')
        prev.delete('location_type')
        return prev
      },
      { replace: true },
    )
    form.handleSubmit(onSubmit)()
  }

  const value = {
    selectedCompanyDrawerOpen,
    setSelectedCompanyDrawerOpen,
    total_results,
    allow_debug,
    location,
    resultDetailScrollRef,
    form,
    formDisabled,
    onSubmit,
    industryChoices,
    revenueChoices,
    companySizeChoices,
    locationTypeChoices,
    radiusChoices,
    selectedCompany,
    handleSelectCompany,
    queryStatusMessage,
    amountShown,
    amountTotal,
    viewMoreButtonDisabled,
    handleViewMore,
    showViewMoreButton,
    urlSearchParams,
    companyResults,
    addCompany,
    savedCompanies,
    goToCompetitor,
    noResultQuery,
    savedContacts,
    addContact,
    removeCompany,
    isSavedContact,
    isSavedCompany,
  }

  return (
    <CompanySearchContext.Provider value={value}>
      {children}
    </CompanySearchContext.Provider>
  )
}
