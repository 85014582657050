import React from 'react'
import { useQuiz } from '../QuizProvider'
import { cn } from '../../../lib/utils'
import { Button } from '../../../lib/ui/button'
import useLikertLayout from './LikertLayoutProvider'

const LikertQuestion = ({ scaleOptions, handleNextClick, children }) => {
  const { quizIndex } = useQuiz()
  const options = scaleOptions ? Object.values(scaleOptions) : []

  return (
    <div className="z-10 justify-between sm:flex">
      <div className="question-options mx-auto flex gap-6 sm:gap-8">
        {options.map((option, index) => (
          <LikertOption
            key={`response-${index}`}
            id={`btn-${quizIndex + 1}-${index}`}
            index={index}
            handleNextClick={handleNextClick}
          >
            <div className="col-span-5 ml-2 text-left xl:col-span-9">
              {option}
            </div>
          </LikertOption>
        ))}
      </div>
      {children}
    </div>
  )
}

const LikertOption = ({ index, handleNextClick, children, ...props }) => {
  const { trackContent, quizIndex } = useQuiz()
  const { handleOptionClick } = useLikertLayout()
  const selected = trackContent[quizIndex + 1] === index + 1

  const handleClick = () => {
    handleOptionClick(index, index + 1)
    handleNextClick()
  }

  return (
    <Button
      className={cn(
        'grid h-fit grid-cols-6 gap-4 md:min-h-[60px] md:justify-start md:px-4 xl:grid-cols-10',
        'whitespace-pre-line rounded-[10px] border border-solid border-[#8E8E8E] hover:bg-primary hover:text-white',
        selected ? 'bg-primary text-white' : 'bg-white text-[#2F2F2F]',
      )}
      onClick={handleClick}
      {...props}
    >
      {children}
    </Button>
  )
}

export default LikertQuestion
