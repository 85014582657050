import * as React from 'react'
import {
  Dialog,
  DialogTrigger,
  DialogClose,
  DialogContent,
  DialogHeader,
  DialogTitle,
} from '../../../lib/ui/dialog'
import useExtendAlertExpirationForm from './useExtendExpirationForm'
import { Form } from '../../../lib/ui/form'
import { Button } from '../../../lib/ui/button'
import useTranslation from '../../shared/TranslationProvider'

export function ExpiresOnCell({ row }) {
  const alert = row.original

  const { t } = useTranslation('Page')

  const [expiresOn, setExpiresOn] = React.useState(alert.expires_at)

  const expired = expiresOn && new Date(expiresOn) < new Date()

  const formatExpiresOn = alert.expires_at
    ? new Date(expiresOn).toLocaleDateString()
    : 'N/A'

  const subTitle = (
    <div className="line-clamp-1">
      <a className="cursor-pointer">
        {expired ? t('reactivate') : formatExpiresOn}
      </a>
    </div>
  )

  return (
    <div className="flex flex-col gap-1 pr-2">
      {expired && (
        <div className="text-normal line-clamp-1 flex font-bold text-red-500">
          {t('expired_on', { date: formatExpiresOn })}
        </div>
      )}
      <div className="flex flex-row items-center gap-1 text-sm">
        <ExpiresOnDialog t={t} alert={alert} setExpiresOn={setExpiresOn}>
          <div className="flex flex-col">{subTitle}</div>
        </ExpiresOnDialog>
      </div>
    </div>
  )
}

const ExpiresOnDialog = ({ t, alert, setExpiresOn, children }) => {
  const today = new Date()
  const newExpiration = new Date(
    today.setMonth(today.getMonth() + 1),
  ).toLocaleDateString()
  const [showDialog, setShowAddDialog] = React.useState(false)
  const toggleShowDialog = () => {
    setShowAddDialog((prev) => {
      if (prev) {
        setExpiresOn(newExpiration)
      }
      return !prev
    })
  }

  const { form, onSubmit, submitted } = useExtendAlertExpirationForm(
    alert,
    toggleShowDialog,
  )

  return (
    <Dialog open={showDialog} onOpenChange={toggleShowDialog} onClose>
      <DialogTrigger asChild>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t('extend_dialog_tile')}</DialogTitle>
        </DialogHeader>
        <Form {...form}>
          <form onSubmit={form.handleSubmit(onSubmit)}>
            <div className="p-4">
              <div className="flex flex-col gap-4">
                <div>{t('extend_to_date', { date: newExpiration })}</div>
                <div className="flex gap-2 self-end">
                  <Button disabled={submitted} type="submit">
                    {t('save')}
                  </Button>
                  <DialogClose asChild>
                    <Button variant="outline">{t('cancel')}</Button>
                  </DialogClose>
                </div>
              </div>
            </div>
          </form>
        </Form>
      </DialogContent>
      <DialogClose />
    </Dialog>
  )
}
