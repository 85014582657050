import * as React from 'react'
import { useIsMobile } from '../../hooks/useMediaQuery'
import { cn } from '../../lib/utils'

export function SearchResultDetails({ children }) {
  return <div className="flex flex-col gap-4 p-4">{children}</div>
}

export const DetailsTopSave = ({ children }) => {
  const isMobile = useIsMobile()
  if (isMobile) return null
  return (
    <div className="relative flex flex-row justify-between">
      <div className="absolute right-0 top-0">{children}</div>
    </div>
  )
}

export const DetailsTopTitle = ({ children }) => {
  return <div className="text-xl font-medium">{children}</div>
}

export const DetailsTopSubtitle = ({ children }) => {
  return (
    <div className="flex items-center text-base font-normal">{children}</div>
  )
}

export const DetailsTopBody = ({ children }) => {
  return <div className="flex flex-col gap-4">{children}</div>
}

export function DetailsTop({ children, debug, queryStatusMessage }) {
  const isMobile = useIsMobile()

  return (
    <div className={`bg-primary text-white ${isMobile ? 'p-4' : 'p-0'}`}>
      {children}

      {debug && <pre className="break-all text-xs">{queryStatusMessage}</pre>}
    </div>
  )
}

export const DetailsBottomHeading = ({ className, children }) => {
  return (
    <div className={cn('text-base font-medium text-black', className)}>
      {children}
    </div>
  )
}

export const DetailsDetails = ({ className, children }) => {
  return (
    <div className={cn('text-base font-normal', className)}>{children}</div>
  )
}

export const DetailsLink = ({ className, children }) => {
  return (
    <DetailsDetails className={cn('text-blue-600 underline', className)}>
      {children}
    </DetailsDetails>
  )
}

export const DetailsRow = ({ className, children }) => {
  const isMobile = useIsMobile()
  return (
    <div className={cn('flex', isMobile && 'flex-col', className)}>
      {children}
    </div>
  )
}

export function DetailsBottom({ children }) {
  return children
}
