import React from 'react'
import Results from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { useYesNoQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'
import Task from '.././Task'
import { DownArrow, RightArrow } from '.././Icons/Arrows'

const JobSearchQuizResults = ({ tasks }) => {
  const {
    quizName,
    formattedQuizName,
    resultsPageAvatar,
    trackContent,
    numChoices,
  } = useQuiz()
  const avatar = resultsPageAvatar
  const resultsTasks = Object.values(tasks)
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)
  const [scoreSection, variable] = useYesNoQuizScore(
    trackContent,
    numChoices,
    t('questions'),
  )

  const header = t(`results.${scoreSection}.header`)
  const copy =
    scoreSection === 2
      ? t(`results.${scoreSection}.copy`, { numAs: variable })
      : t(`results.${scoreSection}.copy`, { score: variable })

  return (
    quizName === 'Job Search' && (
      <Results isScrollable={true}>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 sm:w-9/12">
            <h1 className="!text-[23px]">{header}</h1>
            <p className="mt-4">{copy}</p>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
        <div className="divide-y divide-solid divide-[#B9B9B9] rounded-[10px] border-[1.5px] border-solid border-[#08467C]">
          <div
            style={{ fontFamily: 'gotham_boldregular' }}
            className="flex flex-col items-center py-4 sm:flex-row sm:pl-16"
          >
            <div className="sm:w-2/5">Remaining areas of preparation</div>
            {!isDesktop && <DownArrow className="py-2" />}
            {isDesktop && <RightArrow className="sm:w-1/5" />}
            <div className="flex flex-col items-center sm:w-2/5">
              <div>Tasks added to Action Plan</div>
            </div>
          </div>
          {resultsTasks.map((task, idx) => {
            const { task: taskText, links } = task
            return (
              <Task
                key={`task-${idx}`}
                className={`flex flex-col items-center py-4 sm:flex-row sm:pl-16`}
                task={taskText}
                links={links}
                showArrow={isDesktop}
              />
            )
          })}
        </div>
      </Results>
    )
  )
}

export default JobSearchQuizResults
