import * as React from 'react'
import { format } from 'date-fns'
import { useTranslation } from '../../../hooks/useTranslation'
import { getDatePhase } from './getDatePhase'
import { useIsMobile } from '../../../hooks/useMediaQuery'
import { DetailsModal } from './DetailsModal'
import { useSavedJobs } from './SavedJobsProvider'

const faviconUrl =
  'https://www-yns-com.s3.us-east-2.amazonaws.com/images/favicon_intoo_32x32.png'

export function TitleCell({ table, row }) {
  const { t } = useTranslation('TitleCell')
  const savedJob = row.original
  const isMobile = useIsMobile()
  const datePhase = getDatePhase(savedJob.followup_on)
  const { appliedWithIntoo } = useSavedJobs()
  const hasAppliedWithIntoo = appliedWithIntoo(savedJob)

  const subTitle =
    isMobile && savedJob.followup_on ? (
      <>
        <div>{t('follow_up')}:</div>
        <div
          data-phase={datePhase}
          className="rounded px-1.5 py-0.5 text-black data-[phase=overdue]:bg-[#FC7C5E] data-[phase=soon]:bg-[#FFE769]"
        >
          {format(savedJob.followup_on, 'P')}
        </div>
      </>
    ) : (
      <div className="line-clamp-1">
        {savedJob.company_name} • {savedJob.location}
      </div>
    )

  return (
    <div className="flex flex-col gap-1 pr-2">
      <div className="line-clamp-1 text-base font-bold">
        {savedJob.title}
        {hasAppliedWithIntoo && (
          <img
            src={faviconUrl}
            alt="Favicon"
            title={t('applied_with_intoo')}
            className="ml-2 inline-block h-5 w-5"
          />
        )}
      </div>
      <div className="flex flex-row items-center gap-1 text-base">
        {subTitle}
      </div>
      <div className="flex flex-row items-center gap-1 text-sm">
        <DetailsModal
          savedJob={savedJob}
          rowIndex={row.index}
          updateData={table.options.meta?.updateData}
        >
          <a href="#" className="!underline">
            {t('view_job')}
          </a>
        </DetailsModal>
      </div>
    </div>
  )
}
