import React from 'react'
import useTranslation from '../../shared/TranslationProvider'
import AylaInputForm from './AylaInputForm'
import { Button } from '../../../lib/ui/button'
import { useIntroductionScript } from './IntroductionScriptProvider'
import Scripts from './Scripts'
import { NameDialog } from './NameDialog'

export const PageOne = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col">
      <h1>{t('part_1')}</h1>
      <div className="pb-6">{t('intro')}</div>
      <div className="subtitle pb-4">{t('get_started')}</div>
      <AylaInputForm />
    </div>
  )
}

export const PageTwo = () => {
  const { currentPage, setAylaResponse } = useIntroductionScript()
  const { t } = useTranslation()

  const { handlePageChange } = useIntroductionScript()

  const handleClick = () => {
    setAylaResponse(null)
    handlePageChange(1)
  }

  return (
    <div className="flex flex-col gap-4">
      <div>
        <h1>{t('part_2')}</h1>
        <Scripts />
      </div>
      <div className="flex flex-col self-start">
        <div className="flex gap-4">
          <Button
            variant="outline"
            disabled={currentPage === 1}
            onClick={handleClick}
          >
            {t('back')}
          </Button>
          <NameDialog>
            <Button>{t('ayla_response.save')}</Button>
          </NameDialog>
        </div>
      </div>
    </div>
  )
}

export const Page = () => {
  const { currentPage } = useIntroductionScript()
  if (currentPage === 1) return <PageOne />
  if (currentPage === 2) return <PageTwo />
}
