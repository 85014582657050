import React from 'react'
import Results from './Results'
import { useTranslation } from '../../../hooks/useTranslation'
import { useSelfAssessmentQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'

const IdealWorkplaceEnvironmentResults = () => {
  const { formattedQuizName, trackContent, quizName } = useQuiz()
  const { feedbackKey, selfAssessmentResponse } =
    useSelfAssessmentQuizScore(trackContent)
  const cultureTypes = [
    'collaborative',
    'competitive',
    'innovative',
    'purpose_driven',
    'structured',
  ]
  const { t } = useTranslation(formattedQuizName)
  const additionalTips = t('results.all_culture_types.additional_tips')
  const finalThoughtsContent = t(
    'results.all_culture_types.final_thoughts.content',
  )
  const numberedList = t('results.numbered_list')

  return (
    quizName === 'Ideal Workplace Environment' && (
      <Results>
        <div className="flex flex-grow flex-col overflow-y-auto py-6 sm:justify-around">
          <div className="mt-2 flex flex-col gap-4 sm:w-9/12">
            <p>{t(`results.p1`)}</p>
            <p>{t(`results.p2`)}</p>
            <h3 className="font-bold">{t(`results.numbered_list_title`)}</h3>
            <NumberedList items={numberedList} />
            {selfAssessmentResponse === 'clearResult' && (
              <ClearResult feedbackKey={feedbackKey} t={t} />
            )}
            {selfAssessmentResponse === 'splitResult' && (
              <SplitResult feedbackKey={feedbackKey} t={t} />
            )}
            {selfAssessmentResponse === 'allSameScore' && (
              <AllSameScore cultureTypes={cultureTypes} t={t} />
            )}
            <AdditionalTipsAndFinalThoughts
              additionalTips={additionalTips}
              finalThoughtsContent={finalThoughtsContent}
              t={t}
            />
          </div>
        </div>
      </Results>
    )
  )
}

export default IdealWorkplaceEnvironmentResults

const NumberedList = ({ items }) => {
  const isArray = (value) => (Array.isArray(value) ? value : [])
  return (
    <ol className="ml-6 list-decimal">
      {isArray(items).map((item, index) => (
        <li key={index} className={index !== items.length - 1 ? 'mb-4' : ''}>
          <strong>{item.title}</strong>: {item.description}
        </li>
      ))}
    </ol>
  )
}

const ClearResult = ({ feedbackKey, t }) => {
  const isArray = (value) => (Array.isArray(value) ? value : [])
  return (
    <>
      <h3 className="my-3 font-bold">
        {t(`results.clear_result`)}{' '}
        {t(`results.culture_types.${feedbackKey}`) + '.'}
      </h3>
      <h4 className="my-3 font-bold">
        {t(`results.understanding_culture_type.${feedbackKey}.title`)}
      </h4>
      <p>{t(`results.understanding_culture_type.${feedbackKey}.p1`)}</p>
      <ul className="ml-6 list-disc">
        {isArray(
          t(
            `results.understanding_culture_type.${feedbackKey}.characteristics`,
          ),
        ).map((characteristic, index) => (
          <li key={index}>{characteristic}</li>
        ))}
      </ul>
      {isArray(
        t(`results.understanding_culture_type.${feedbackKey}.conclusion`),
      ).map((conclusion, index) => (
        <p key={index}>{conclusion}</p>
      ))}
    </>
  )
}

const SplitResult = ({ feedbackKey, t }) => {
  const isArray = (value) => (Array.isArray(value) ? value : [])
  return (
    <>
      <h3 className="my-3 font-bold">
        {t(`results.split_result`)}{' '}
        {feedbackKey.length > 1
          ? feedbackKey
              .slice(0, -1)
              .map((key) => t(`results.culture_types.${key}`))
              .join(', ') +
            ` ${t('results.and')} ` +
            t(`results.culture_types.${feedbackKey[feedbackKey.length - 1]}`) +
            '.'
          : t(`results.culture_types.${feedbackKey[0]}`) + '.'}
      </h3>
      {feedbackKey.map((key) => (
        <div key={key}>
          <h4 className="my-3 font-bold">
            {t(`results.understanding_culture_type.${key}.title`)}
          </h4>
          <p>{t(`results.understanding_culture_type.${key}.p1`)}</p>
          <ul className="mb-4 ml-6 list-disc">
            {isArray(
              t(`results.understanding_culture_type.${key}.characteristics`),
            ).map((characteristic, index) => (
              <li key={index}>{characteristic}</li>
            ))}
          </ul>
          {isArray(
            t(`results.understanding_culture_type.${key}.conclusion`),
          ).map((conclusion, index, array) => (
            <p key={index} className={index !== array.length - 1 ? 'mb-4' : ''}>
              {conclusion}
            </p>
          ))}
        </div>
      ))}
    </>
  )
}

const AllSameScore = ({ cultureTypes, t }) => {
  const isArray = (value) => (Array.isArray(value) ? value : [])
  return (
    <>
      <h3 className="my-3 font-bold">{t(`results.all_same_score`)}</h3>
      {cultureTypes.map((type) => (
        <div key={type}>
          <h3 className="my-3 font-bold">
            {t(`results.understanding_culture_type.${type}.title`)}
          </h3>
          <p>{t(`results.understanding_culture_type.${type}.p1`)}</p>
          <ul className="mb-4 ml-6 list-disc">
            {isArray(
              t(`results.understanding_culture_type.${type}.characteristics`),
            ).map((characteristic, index) => (
              <li key={index}>{characteristic}</li>
            ))}
          </ul>
          {isArray(
            t(`results.understanding_culture_type.${type}.conclusion`),
          ).map((conclusion, index, array) => (
            <p key={index} className={index !== array.length - 1 ? 'mb-4' : ''}>
              {conclusion}
            </p>
          ))}
        </div>
      ))}
    </>
  )
}

const AdditionalTipsAndFinalThoughts = ({
  additionalTips,
  finalThoughtsContent,
  t,
}) => {
  const isArray = (value) => (Array.isArray(value) ? value : [])
  return (
    <div>
      <h3 className="my-3 font-bold">{t(`results.all_culture_types.p1`)}</h3>
      <ul className="ml-6 list-disc">
        {isArray(additionalTips).map((tip, index) => (
          <li
            key={index}
            className={index !== additionalTips.length - 1 ? 'mb-4' : ''}
          >
            {tip}
          </li>
        ))}
      </ul>
      <br />
      <h3 className="mb-3 font-bold">
        {t(`results.all_culture_types.final_thoughts.header`)}
      </h3>
      <div>
        {isArray(finalThoughtsContent).map((content, index) => (
          <p
            key={index}
            className={index !== finalThoughtsContent.length - 1 ? 'mb-4' : ''}
          >
            {content}
          </p>
        ))}
      </div>
    </div>
  )
}
