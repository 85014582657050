import React from 'react'
import NavHeader from './NavHeader'
import SkillsInventoryView from './SkillsInventoryComponents/SkillsInventoryView'
import SkillsAssessments from './SkillsInventoryComponents/SkillsAssessments'
import SkillsProvider, { useSkills } from './SkillsProvider'
import { useTranslation } from './../../hooks/useTranslation'
import { TranslationProvider } from './../shared/TranslationProvider'
import translations from './locales.json'

const EmptySkillStateButton = () => {
  const { moduleName, skillsPresent } = useSkills()
  const { t } = useTranslation('index')

  return (
    !skillsPresent && (
      <div>
        <a
          href={`/${moduleName}/workshops/skills-gap`}
          className={
            'flex w-full items-center justify-center rounded-3xl border bg-primary px-4 py-3 text-base font-medium text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white sm:py-2 md:w-1/5'
          }
        >
          {t(`skills_inventory_intro.start_assessment`)}
        </a>
      </div>
    )
  )
}

const SkillsInner = () => {
  const { skillsPresent } = useSkills()

  const { t } = useTranslation('index')

  const title = t('skills_inventory_intro.title')
  const title_2 = t('skills_inventory_intro.title_2')

  const intro = t(
    `skills_inventory_intro.${skillsPresent ? 'skills_present' : 'empty'}`,
  )

  return (
    <div className="tw-enable min-h-[70vh] font-[Roboto] lg:flex lg:flex-row">
      <div className="mx-2 flex-grow sm:mx-8">
        <h1 className="mb-4 mt-10 font-roboto-slab text-3.5xl leading-normal text-primary">
          <span className="font-normal">{title}</span> {title_2}
        </h1>
        <div className="mb-6 text-lg leading-7 text-[#4a5463]">{intro}</div>
        {skillsPresent && <NavHeader />}
        <EmptySkillStateButton />
        <div className="grid grid-cols-1 gap-x-12 gap-y-10 md:grid-cols-3">
          {skillsPresent && <SkillsInventoryView />}
          <div className="min-w-[472px] overflow-hidden"></div>
        </div>
        <SkillsAssessments />
      </div>
    </div>
  )
}

const SkillsInventory = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.SkillsInventory"
    >
      <SkillsProvider {...props}>
        <SkillsInner />
      </SkillsProvider>
    </TranslationProvider>
  )
}

export default SkillsInventory
