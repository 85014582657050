import React from 'react'
import { useResume } from './ResumeProvider'
import { Button } from '../../lib/ui/button'
import { cn } from '~/lib/utils'

const DownloadPdfButton = ({
  className,
  disabled,
  onClick = () => null,
  children,
}) => {
  const {
    resumeName,
    persistResumeIfChanged,
    pdf: { url },
  } = useResume()

  const buttonClick = () => {
    persistResumeIfChanged()
    onClick()
  }

  const buttonClasses = cn(
    'text-cod-gray-700 active:text-cod-gray-700 hover:text-accent-foreground',
    className,
  )

  const buttonStyle = disabled ? { pointerEvents: 'none', opacity: 0.5 } : {}

  return (
    <Button variant="outline" asChild>
      <a
        href={url}
        onClick={buttonClick}
        download={`${resumeName}.pdf`}
        style={buttonStyle}
        className={buttonClasses}
      >
        {children}
      </a>
    </Button>
  )
}
export default DownloadPdfButton
