import React from 'react'
import useTranslation from '../../shared/TranslationProvider'
import { useExercise } from '../ExerciseProvider'
import { useChartYourCourse } from './ChartYourCourseProvider'

const PartThree = () => {
  const { t } = useTranslation('chart_your_course')
  const { selectedCareers, sluggifyCareer } = useChartYourCourse()

  const careerPath = t('pages.2.career_path')

  return (
    <div className="questions">
      {selectedCareers.map((career, idx) => (
        <div
          key={`career-option-${idx}`}
          className="mb-6 w-full font-roboto text-base font-medium"
        >
          <div className="flex w-full flex-col">
            <div className="font-bold">
              {careerPath}: {career}
            </div>
            <label className="font-roboto-light !mb-1 text-sm font-medium">
              {t('pages.2.relevant_skills')}
            </label>
            <TextArea slug={sluggifyCareer(career)} />
          </div>
        </div>
      ))}
    </div>
  )
}

export default PartThree

const TextArea = ({ slug }) => {
  const { getResponse, handleChange } = useExercise()

  const handleTextChange = (e) => {
    handleChange(2, slug, e)
  }

  const textResponse = getResponse(2, slug) || ''

  return (
    <textarea
      className="min-h-[125px] w-full rounded-[20px] border border-[#D3D3D3] bg-[#F7FAFC] p-3 font-roboto text-base"
      onChange={handleTextChange}
      value={textResponse}
    />
  )
}
