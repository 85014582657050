import React, { useMemo, useState, createContext, useContext } from 'react'

const TemplateContext = createContext()

export const useTemplate = () => useContext(TemplateContext)

export const TemplateProvider = ({
  templates,
  defaultStyle,
  templateSupportedStyles,
  template_index,
  dev_mode,
  children,
}) => {
  const [templateSettings, setTemplateSettings] = useState(defaultStyle)
  const [templateIndex, setTemplateIndex] = useState(template_index || 0)
  const [showAllEditorFields, setShowAllEditorFields] = useState(false)

  const currentTemplateKey = useMemo(
    () => `template-${templateIndex}`,
    [templateIndex],
  )

  const templateStyle = useMemo(() => {
    const currentTemplateSettings = templateSettings[currentTemplateKey] || {}
    return {
      ...defaultStyle[currentTemplateKey],
      ...currentTemplateSettings,
    }
  }, [templateSettings, defaultStyle, currentTemplateKey])

  const setTemplateSetting = (setting, value) => {
    const innerTemplateSettings = templateSettings[currentTemplateKey]
    const newTemplateSettings = {
      ...templateSettings,
      [currentTemplateKey]: {
        ...innerTemplateSettings,
        [setting]: value,
      },
    }
    setTemplateSettings(newTemplateSettings)
  }

  const getTemplateSetting = (setting) => {
    if (!templateSettings || !templateSettings[currentTemplateKey]) {
      return null
    }
    return templateSettings[currentTemplateKey][setting]
  }

  const useTemplateSetting = (setting) => [
    getTemplateSetting(setting),
    (value) => setTemplateSetting(setting, value),
  ]

  const isSettingSupported = (setting) => {
    if (
      !templateSupportedStyles ||
      !templateSupportedStyles[currentTemplateKey]
    ) {
      return false
    }
    return templateSupportedStyles[currentTemplateKey].includes(setting)
  }

  const editorFieldEnabled = (fieldName) => {
    if (showAllEditorFields) return true

    const currentTemplate = templates[templateIndex]
    if (!currentTemplate.optionalEditorFields) {
      console.warn(
        `Template${templateIndex} does not define optionalEditorFields`,
      )
      return false
    }
    if (
      !Object.keys(currentTemplate.optionalEditorFields).includes(fieldName)
    ) {
      console.warn(
        `Field ${fieldName} is not defined in Template${templateIndex}.optionalEditorFields`,
      )
      return false
    }
    return currentTemplate.optionalEditorFields[fieldName]
  }

  const value = {
    templateIndex,
    setTemplateIndex,
    templateSettings,
    useTemplateSetting,
    templateStyle,
    templates,
    isSettingSupported,
    editorFieldEnabled,
    showAllEditorFields,
    setShowAllEditorFields,
    devMode: dev_mode,
  }

  return (
    <TemplateContext.Provider value={value}>
      {children}
    </TemplateContext.Provider>
  )
}
export default TemplateProvider
