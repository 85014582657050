import React from 'react'
import { useNetworkingBrief } from '../NetworkingBriefProvider'
import { EditorHeading } from '../../ResumeBuilder/Editor/common'
import { EditorItemField, EditorItemProvider } from './EditorItemProvider'

const BasicsEditor = () => {
  const { networkingBriefDocument: { basics } = {} } = useNetworkingBrief()

  return (
    <EditorItemProvider sectionName="basics" item={basics}>
      <div>
        <EditorHeading>Personal Information</EditorHeading>
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          <EditorItemField
            containerClassName="md:col-span-2"
            label="Full Name"
            fieldName="name"
          />
          <EditorItemField label="Current Position" fieldName="position" />
          <EditorItemField label="Company" fieldName="company" />
          <EditorItemField
            label="Company City/State (optional)"
            fieldName="companyCityState"
          />
          <EditorItemField label="Email (optional)" fieldName="email" />
          <EditorItemField label="Phone (optional)" fieldName="phone" />
          <EditorItemField label="LinkedIn (optional)" fieldName="linkedin" />
        </div>
      </div>
    </EditorItemProvider>
  )
}

export default BasicsEditor
