import React, { useEffect, useState } from 'react'
import { AssessmentsIcon } from './icons'
import { cn } from '../../lib/utils'
import { HTMLToJSON } from 'html-to-json-parser'
import { useTranslation } from './../../hooks/useTranslation'

const AssessmentCard = ({ assessment, s3_base_url }) => {
  const {
    title,
    description,
    image,
    status,
    alt,
    document_path,
    document_download,
  } = assessment

  const [path, setPath] = useState('')
  const [cta, setCta] = useState('')
  const [data, setData] = useState({})

  const { t } = useTranslation()

  useEffect(() => {
    const convert = async () => {
      const linkInfo = await HTMLToJSON(assessment.path, false)
      setPath(linkInfo.attributes.href)
      setCta(linkInfo.content[0])
      const data = {
        'data-notification': linkInfo.attributes['data-notification'],
        'data-points': linkInfo.attributes['data-points'],
        'data-text': linkInfo.attributes['data-text'],
      }
      setData(data)
    }
    convert()
  }, [assessment.path])

  const sampleDocImg = '/images/workshops/personal_preferences_sample.png'

  const completedButtonClasses =
    'bg-white text-[#2F2F2F] hover:!bg-accent focus:bg-accent focus:border-accent  focus:text-[#2F2F2F] active:bg-accent active:border-accent hover:text-[#2F2F2F] border-[#2F2F2F] active:text-[#2F2F2F]'

  const incompleteButtonCLasses =
    'bg-primary text-white hover:!bg-primary-lighter-30 hover:text-white focus:text-white active:text-white'

  const completedCardClasses = 'bg-white border-2 border-gray-300'

  const incompleteCardClasses = 'bg-gradient-reminders text-intoo-blue-medium'

  const completed = status === 'completed'

  const incompletedImgClasses = 'aspect-[8/5] h-full w-full'

  const completedImgClasses = 'w-5/12 pt-2'

  return (
    <div
      className={cn(
        'col-span-2 grid grid-cols-1 gap-4 rounded-3xl md:col-span-1 lg:grid-cols-2 xl:grid-cols-3',
        completed ? completedCardClasses : incompleteCardClasses,
      )}
    >
      <div className="self-center p-8 md:col-span-2">
        <AssessmentsIcon
          className={cn(
            'mb-2 inline h-12 w-12 stroke-2',
            completed ? 'stroke-black' : 'stroke-primary',
          )}
        />
        <h2
          role="heading"
          tabIndex="0"
          aria-level="2"
          className="!mb-2 !mt-0 !overflow-y-hidden truncate text-xl font-bold focus:overflow-visible"
          title={title}
        >
          {title}
        </h2>

        <p className="!my-0 line-clamp-3 flex-grow">{description}</p>

        <div className="mt-6 flex self-center">
          <a
            href={completed ? `${path}/summary` : path}
            className={cn(
              'flex items-center justify-center rounded-3xl border px-4 py-3 text-base font-medium sm:py-2 md:w-1/3',
              completed ? completedButtonClasses : incompleteButtonCLasses,
            )}
            data-notification={data['data-notification']}
            data-points={data['data-points']}
            data-text={data['data-text']}
          >
            {completed ? t('view_results') : cta}
          </a>
          {completed && (
            <a href={path} className="ml-8 flex items-center py-2 underline">
              {t('retake_assessment')}
            </a>
          )}
        </div>
      </div>
      <div className="flex h-full flex-col items-center gap-2 overflow-hidden rounded-b-[20px] md:rounded-r-[20px] md:rounded-bl-none">
        <img
          src={
            document_path && completed ? `${s3_base_url}${sampleDocImg}` : image
          }
          alt={alt}
          className={cn(
            'm-auto object-cover',
            document_path && completed
              ? completedImgClasses
              : incompletedImgClasses,
          )}
        />
        {document_path && completed && (
          <a
            href={document_path}
            download={document_download}
            data-action="View This Report"
            className="pb-4"
          >
            {t('download_assessment')}
          </a>
        )}
      </div>
    </div>
  )
}

export default AssessmentCard
