import React, { createContext, useContext } from 'react'
import { useQuiz } from '../QuizProvider'

const DiscoverYourLearningStyleContext = createContext()

const useDiscoverYourLearningStyle = () => {
  return useContext(DiscoverYourLearningStyleContext)
}

export default useDiscoverYourLearningStyle

export const DiscoverYourLearningStyleProvider = ({ children }) => {
  const { quizIndex, trackContent, setTrackContent } = useQuiz()

  const handleOptionClick = (choice, type) => {
    trackContent[quizIndex + 1] = { choice: choice, type: type }
    setTrackContent({
      ...trackContent,
    })
  }

  const value = {
    handleOptionClick,
  }

  return (
    <DiscoverYourLearningStyleContext.Provider value={value}>
      {children}
    </DiscoverYourLearningStyleContext.Provider>
  )
}
