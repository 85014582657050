import React from 'react'
import Results from '../QuizResults/Results'
import { useQuiz } from '../QuizProvider'
import useTranslation from '../../shared/TranslationProvider'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import Avatar from '../Avatar'
import parse from 'html-react-parser'

const SalaryNegotiatorResults = () => {
  const { t } = useTranslation('salary_negotiator')
  const {
    trackContent: { score },
    resultsPageAvatar,
  } = useQuiz()

  const totalScore = Object.values(score).reduce((acc, val) => acc + val, 0)

  const percentScore = Math.ceil(
    (Object.values(score).reduce((acc, val) => acc + val, 0) / 170) * 100,
  )

  const getScoreSection = () => {
    if (totalScore >= 150) return 1
    if (totalScore >= 120) return 2
    if (totalScore >= 90) return 3
    if (totalScore >= 60) return 4
    return 5
  }
  const scoreSection = getScoreSection()
  const negotiatorLevel = t(`results.${scoreSection}.negotiator_Level`)
  const copy = t(`results.${scoreSection}.copy`)
  const steps = t(`results.all.steps`)

  const isDesktop = useIsDesktop()

  return (
    <Results>
      <div className="mb-8 flex pb-6 sm:justify-around">
        <div className="mt-1 flex flex-col gap-4 sm:w-9/12">
          <h2>{t(`results.score`, { score: percentScore })}</h2>
          <div className="mt-4">
            <p>
              <span className="font-bold">{negotiatorLevel}</span> {copy}
            </p>
            <p>{parse(t(`results.all.p1`))}</p>
            <p>{parse(t(`results.all.p2`))}</p>
            <ul className="list-inside list-disc">
              {steps.map((step, index) => (
                <li className="font-bold" key={index}>
                  {step}
                </li>
              ))}
            </ul>
            <p className="mt-[10px]">{t(`results.all.p3`)}</p>
          </div>
        </div>
        {isDesktop && <Avatar src={resultsPageAvatar} />}
      </div>
    </Results>
  )
}

export default SalaryNegotiatorResults
