import React from 'react'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import ExpandyPanel from '../../../lib/ui/ExpandyPanel'
import { ArrayFields } from './common'
import {
  EditorItemField,
  useEditorItem,
  EditorYearMonthField,
} from './EditorItemProvider'

const EducationItem = () => {
  const {
    index,
    activeIndex,
    setActiveIndex,
    item,
    setItem,
    updateItem,
    deleteItem,
    updateCollection,
  } = useEditorItem()

  const headingLabel =
    item.institution && item.studyType
      ? `${item.studyType} at ${item.institution}`
      : item.institution || 'New Education History'

  const toggleExpanded = () => {
    if (activeIndex == index) {
      setActiveIndex(-1)
    } else {
      setActiveIndex(index)
    }
  }

  const addCourse = () => {
    setCourses([...(item?.courses || []), ''])
  }

  const setCourses = (courses) => {
    const newEducationItem = { ...item, courses }
    setItem(newEducationItem)
  }

  const deleteCourse = (courseIndex) => {
    const updatedCourses = item.courses.filter((_, i) => i !== courseIndex)
    updateCollection('courses', updatedCourses)
  }

  const expanded = activeIndex == index
  return (
    <div className="mb-4 flex flex-row items-center">
      <ExpandyPanel
        headingText={headingLabel}
        expanded={expanded}
        index={index}
        onClick={toggleExpanded}
      >
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          <EditorItemField
            label="School / College / University Name"
            fieldName="institution"
            containerClassName="md:col-span-2"
          />
          <EditorItemField label="Degree" fieldName="studyType" />
          <EditorItemField label="Area of Study" fieldName="area" />
          <EditorItemField label="Location" fieldName="location" />
          <EditorItemField label="GPA" fieldName="score" />
          <EditorYearMonthField label="Start Date" fieldName="startDate" />
          <EditorYearMonthField label="End Date" fieldName="endDate" />
        </div>
        <div>
          <div className="flex flex-row gap-2 pt-4">
            <span className="font-bold">Coursework and Accomplishments</span>
            <button
              className="flex flex-row items-center text-primary"
              onClick={addCourse}
            >
              Add
              <PlusIcon className="ml-1 inline w-4 stroke-2" />
            </button>
          </div>
          <div className="flex flex-col gap-2 pt-4">
            <ArrayFields
              items={item.courses || []}
              keyPrefix="course"
              onChange={setCourses}
              onBlur={updateItem}
              onDeleteClick={deleteCourse}
            />
          </div>
        </div>
      </ExpandyPanel>
      {!expanded && (
        <button
          onClick={deleteItem}
          className="max-w-min text-[#b9b9b9]"
          aria-label="remove education"
        >
          <TrashIcon className="ml-2 w-8" />
        </button>
      )}
    </div>
  )
}

export default EducationItem
