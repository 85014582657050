import React from 'react'
import useLikertLayout from '../LikertLayout/LikertLayoutProvider'
import { useQuiz } from '../QuizProvider'
import { Button } from '../../../lib/ui/button'
import useTranslation from '../../shared/TranslationProvider'

const OverviewPage = () => {
  const { t } = useTranslation('career_pivot')

  const { setStarted } = useQuiz()
  const { setShowQuizOverview } = useLikertLayout()

  const handleContinue = () => {
    setShowQuizOverview(false)
  }

  const handleBackClick = () => {
    setStarted(false)
  }

  const body = t('overview.body')

  return (
    <div className="p-4">
      <div className="quiz flex px-4 py-6">
        <div className="flex flex-col gap-4">
          <div className="flex flex-col gap-2">
            <h2>{t('overview.title')}</h2>
            {body.map((part, i) => (
              <p key={`overview-body-${i}`}>{part}</p>
            ))}
          </div>
          <div className="flex gap-4 self-start">
            <Button variant="outline" onClick={handleBackClick}>
              {t('overview.back_button')}
            </Button>
            <Button onClick={handleContinue}>
              {t('overview.continue_button')}
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverviewPage
