import React, { useEffect, useState } from 'react'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
import ActionPlanInfo from './ActionPlanInfo'
import ActiveTile from './TrackTileComponents/ActiveTile'
import StepMenu from './StepMenu'
import { ActionPlanProvider, useActionPlan } from './ActionPlanProvider'
import { Provider } from 'react-redux'
import { store } from '~/lib/store'
import { setProgressPercent, setTrackerSectionInfo } from './ActionPlanSlice'
import { useIsMobile } from '~/hooks/useMediaQuery'
import MobileMenu from './MobileMenu'

const Index = ({ ...props }) => {
  return (
    <Provider store={store}>
      <ActionPlanProvider {...props}>
        <ActionPlan {...props} />
      </ActionPlanProvider>
    </Provider>
  )
}
export default Index

export const getUserTrackerSections = async () => {
  const res = await fetch('/tracker/get_tracker_sections')
  if (res.ok) {
    const data = await res.json()
    store.dispatch(setTrackerSectionInfo(data.trackerSections))
    store.dispatch(setProgressPercent(data.trackerProgress))
    return res
  }
  throw new Error('res not ok')
}

const ActionPlan = ({ current_user }) => {
  const [activeTab, setActiveTab] = useState()
  const { trackerSectionInfo, goal, timeline } = useActionPlan()
  const [totalSteps, setTotalSteps] = useState([])
  const isMobile = useIsMobile()

  useEffect(() => {
    getUserTrackerSections()
  }, [])

  useEffect(() => {
    // Deep copy the trackerSectionInfo to avoid mutating the original state
    const deepCopy = JSON.parse(JSON.stringify([...trackerSectionInfo]))
    setTotalSteps(deepCopy)
  }, [trackerSectionInfo])

  return (
    <TranslationProvider
      locale={current_user.locale}
      translations={translations}
      scope={'javascript.components.ActionPlan'}
    >
      <div className="m-4 md:m-10">
        <ActionPlanInfo goal={goal} timeline={timeline} />
        {isMobile && activeTab ? (
          <MobileMenu
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            totalSteps={totalSteps}
          />
        ) : (
          <DesktopMenu
            activeTab={activeTab}
            setActiveTab={setActiveTab}
            totalSteps={totalSteps}
          />
        )}
      </div>
    </TranslationProvider>
  )
}

const DesktopMenu = ({ activeTab, setActiveTab, totalSteps }) => {
  return (
    <>
      <StepMenu
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        totalSteps={totalSteps}
      />
      {activeTab && <ActiveTile tileInfo={activeTab} />}
    </>
  )
}
