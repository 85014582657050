import React from 'react'
import { BrowserRouter as Router } from 'react-router-dom'
import StartQuiz from '../StartQuiz'
import { TranslationProvider } from '../../shared/TranslationProvider'
import translations from '../locales.json'
import { QuizProvider, useQuiz } from '../QuizProvider'
import Section from '../LikertLayout/Section'
import CareerPivotResults from './CareerPivotResults'
import OverviewPage from './OverviewPage'
import useLikert, {
  LikertLayoutProvider,
} from '../LikertLayout/LikertLayoutProvider'

const CareerPivot = ({ locale, ...props }) => {
  const sectionQuestions = {
    0: [1, 2, 3, 4],
    1: [5, 6, 7, 8],
    2: [9, 10, 11, 12],
    3: [13, 14, 15, 16],
    4: [17, 18, 19, 20],
  }
  return (
    <Router>
      <TranslationProvider
        locale={locale || 'en'}
        translations={translations}
        scope={'javascript.components.Quizzes'}
      >
        <QuizProvider {...props}>
          <LikertLayoutProvider
            sectionQuestions={sectionQuestions}
            numQuestions={20}
            translationKey="career_pivot"
          >
            <CareerPivotInner {...props} />
          </LikertLayoutProvider>
        </QuizProvider>
      </TranslationProvider>
    </Router>
  )
}

export default CareerPivot

const CareerPivotInner = () => {
  const { documentId, started, showResults, quizIndex, setQuizIndex } =
    useQuiz()
  const { showQuizOverview, hideOverview } = useLikert()

  const handleContinueLogic = () => {
    hideOverview()
    if (quizIndex > 0) {
      setQuizIndex((prev) => prev + 1)
    }
  }

  const handleBackClickLogic = ({
    sectionIndex,
    setQuizIndex,
    setSectionIndex,
    hideOverview,
    setShowQuizOverview,
  }) => {
    if (sectionIndex === 0) {
      setShowQuizOverview(true)
    } else {
      setQuizIndex((prev) => prev - 1)
      setSectionIndex((prev) => prev - 1)
      hideOverview()
    }
  }

  if (started && showQuizOverview) {
    return <OverviewPage />
  }
  if (started && !showResults) {
    return (
      <Section
        translationKey="career_pivot"
        handleContinueLogic={handleContinueLogic}
        handleBackClickLogic={handleBackClickLogic}
      />
    )
  }
  return (
    <div className="quiz flex justify-center px-4 py-6">
      {showResults && <CareerPivotResults />}
      {!started && !showResults && (
        <StartQuiz questionCount={20} quizExists={!!documentId} />
      )}
    </div>
  )
}
