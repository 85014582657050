import React from 'react'
import { useCoverLetter } from './CoverLetterProvider'
import { Button } from '../../lib/ui/button'
import SaveModal from './SaveModal'

const PdfViewerPanel = () => {
  const {
    coverLetterName,
    persistCoverLetter,
    pdf: { url, loading },
  } = useCoverLetter()

  if (loading) {
    return null
  }
  return (
    <>
      <div className="mb-10 flex h-16 w-full flex-row items-center justify-evenly sm:mb-0 md:absolute md:bg-[#525659]">
        <Button variant="outline" asChild>
          <a
            href={url}
            onClick={persistCoverLetter}
            download={`${coverLetterName}.pdf`}
            className="text-cod-gray-700"
          >
            Download PDF
          </a>
        </Button>
        <SaveModal />
      </div>
      <iframe
        src={`${url}#toolbar=0&navpanes=0`}
        className="tw-hidden h-full w-full pt-16 md:block"
        title="Cover Letter Preview"
        frameBorder="0"
        loading="lazy"
      />
    </>
  )
}
export default PdfViewerPanel
