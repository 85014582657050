import * as React from 'react'

export const useAiResponseStream = () => {
  const [aiResponse, setAiResponse] = React.useState('')
  const [researchId, setResearchId] = React.useState(null)
  const [aiStreamComplete, setAiStreamComplete] = React.useState(false)

  React.useEffect(() => {
    if (!researchId) return

    const eventURL = `/research_learning/ayla/create_ai_sse/${researchId}`
    const eventSource = new EventSource(eventURL)

    eventSource.addEventListener('message', (event) => {
      if (event.data != 'null') {
        setAiResponse((r) => `${r}${event.data}`)
      }
    })

    eventSource.addEventListener('error', (event) => {
      if (event.eventPhase === EventSource.CLOSED) {
        eventSource.close()
      }
    })

    return () => {
      eventSource.close()
    }
  }, [researchId])

  return {
    aiResponse,
    setAiResponse,
    researchId,
    setResearchId,
    aiStreamComplete,
    setAiStreamComplete,
  }
}
