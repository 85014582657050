import React from 'react'
import { useResume } from './ResumeProvider'
import SaveModal from './SaveModal'
import DownloadModal from './DownloadModal'

const PdfViewerPanel = () => {
  const {
    pdf: { url, loading },
  } = useResume()

  if (loading) {
    return null
  }
  return (
    <>
      <div className="mb-10 flex h-16 w-full flex-row items-center justify-between px-4 sm:mb-0 md:absolute md:justify-evenly md:bg-[#282828] md:px-0">
        <DownloadModal />
        <SaveModal />
      </div>
      <iframe
        src={`${url}#toolbar=0&navpanes=0`}
        className="tw-hidden h-full w-full pt-16 md:block"
        title="Resume Preview"
        frameBorder="0"
        loading="lazy"
      />
    </>
  )
}
export default PdfViewerPanel
