import React, { useState, useEffect } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '.././ExerciseProvider'
import { RadioGroup, RadioGroupItem } from '../../../lib/ui/radio-group'
import { Textarea } from '../../../lib/ui/textarea'

const NetworkingPreferencesSectionThree = ({ data }) => {
  const {
    updateResponse,
    handleChange,
    getResponse,
    exerciseIndex,
    formattedExerciseName,
    setContinueButtonDisabled,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)
  const sectionThreeTranslations = t('pages.2')
  const identifiedIntrovert =
    sectionThreeTranslations.identified_preference_introverting
  const identifiedExtrovert =
    sectionThreeTranslations.identified_preference_extroverting
  const identifiedMessage = data[1].preferences.introverting
    ? identifiedIntrovert
    : identifiedExtrovert
  const questions = sectionThreeTranslations.questions

  const initialRadioResponses = questions.reduce((acc, question) => {
    const response = getResponse(exerciseIndex, question.slug)
    if (response !== undefined) {
      acc[question.slug] = response
    }
    return acc
  }, {})

  const [radioResponses, setRadioResponses] = useState(initialRadioResponses)

  useEffect(() => {
    const radioQuestions = questions.filter(
      (question) => question.type === 'radio',
    )
    const allRadioAnswered = radioQuestions.every(
      (question) => radioResponses[question.slug] !== undefined,
    )
    setContinueButtonDisabled(!allRadioAnswered)
  }, [radioResponses, questions, setContinueButtonDisabled])

  const handleRadioChange = (questionSlug, value) => {
    updateResponse(exerciseIndex, questionSlug, value)
    setRadioResponses((prev) => ({
      ...prev,
      [questionSlug]: value,
    }))
  }

  return (
    <div className="flex flex-col">
      <p className="!mb-5">{identifiedMessage}</p>
      <div>
        {questions.map((question, index) => (
          <div
            key={index}
            className="mb-8 w-full font-roboto !text-base font-medium"
          >
            {question.type === 'text' && (
              <div className="flex flex-col">
                <label className="!mb-1">{question.question}</label>
                <Textarea
                  className="min-h-[125px] w-full rounded-[20px]"
                  onChange={(e) =>
                    handleChange(exerciseIndex, question.slug, e)
                  }
                  value={getResponse(exerciseIndex, question.slug) || ''}
                />
              </div>
            )}
            {question.type === 'radio' && (
              <div>
                <p>{question.question}</p>
                <RadioGroup
                  className="mt-2"
                  value={radioResponses[question.slug] || ''}
                  onValueChange={(value) =>
                    handleRadioChange(question.slug, value)
                  }
                >
                  {Object.entries(question.options).map(([key, option]) => (
                    <label key={key} className="mb-2 !flex items-center">
                      <RadioGroupItem value={key} className="mr-2" />
                      {option.option}
                    </label>
                  ))}
                </RadioGroup>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default NetworkingPreferencesSectionThree
