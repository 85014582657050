import React, { useState } from 'react'
import { apiGet } from '../../util/api'
import { useToast } from '../../lib/ui/toast'

const StarFeedback = ({ path, documentId }) => {
  const [hoverRating, setHoverRating] = useState(null)
  const { toast } = useToast()
  const handleRatingClick = (rating) => {
    if (feedbackRating === rating) {
      setFeedbackRating(null)
      sendRatingToServer(null)
    } else {
      setFeedbackRating(rating)
      sendRatingToServer(rating)
    }
  }
  const [feedbackRating, setFeedbackRating] = useState(null)

  const handleRatingMouseOver = (rating) => {
    setHoverRating(rating)
  }

  const handleRatingMouseOut = () => {
    setHoverRating(null)
  }

  const sendRatingToServer = (rating) => {
    apiGet(path, {
      params: {
        rating: rating,
        content: documentId,
      },
    })
    toast({ description: 'Thanks for your feedback!' })
  }

  const stars = []
  for (let i = 1; i <= 5; i++) {
    stars.push(
      <div
        key={i}
        id={`rating-${i}`}
        className={`rating ga-trackable ${
          i <= (hoverRating || feedbackRating) ? 'selected' : ''
        }`}
        onClick={() => handleRatingClick(i)}
        onMouseOver={() => handleRatingMouseOver(i)}
        onMouseOut={handleRatingMouseOut}
      >
        <i className="el-icon-star text-2xl"></i>
        <br />
      </div>,
    )
  }
  return <div className="flex gap-1">{stars}</div>
}

export default StarFeedback
