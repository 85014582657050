import React, { useState } from 'react'
import { Button } from '../../lib/ui/button'
import { Toaster } from '../../lib/ui/toast'
import {
  ArrowUpTrayIcon,
  DocumentTextIcon,
  PlusIcon,
} from '@heroicons/react/24/outline'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../lib/ui/dialog'
import DropzoneThankYouNoteUpload from './DropzoneThankYouNoteUpload'
import { MagicWand } from 'iconoir-react'

const NewThankYouNoteOptions = ({ uploadThankYouNoteClick }) => {
  return (
    <div className="px-4 pb-4">
      <div className="mb-4 text-lg font-medium">Choose your approach</div>
      <div className="pb-4">
        You can create a thank you note using any of the options below.
      </div>
      <div className="pb-4">
        <a
          href="/interview/thank_you_notes/ai"
          className="flex h-28 w-full flex-row rounded-2.5xl bg-willow-brook text-primary"
        >
          <div className="flex items-center justify-center p-6">
            <MagicWand width="2.5rem" height="2.5rem" />
          </div>
          <div className="flex flex-col items-start justify-evenly">
            <div className="font-roboto-slab text-xl font-medium">
              Custom Thank You Note with Ayla
            </div>
            <div>
              Create a custom thank you note using our AI-powered creator tool,
              Ayla.
            </div>
          </div>
        </a>
      </div>
      <div className="pb-4">
        <a
          href="/interview/thank_you_note_builder/new"
          className="flex h-28 w-full flex-row rounded-2.5xl bg-lily-blue text-primary"
        >
          <div className="flex items-center justify-center p-6">
            <PlusIcon className="w-10" />
          </div>
          <div className="flex flex-col items-start justify-evenly">
            <div className="text-roboto-slab text-xl font-medium">
              Create New Thank You Note
            </div>
            <div>Choose a blank template and fill in the fields yourself.</div>
          </div>
        </a>
      </div>
      <div className="pb-4">
        <button
          onClick={uploadThankYouNoteClick}
          className="flex h-28 w-full flex-row rounded-2.5xl bg-lily-blue text-primary"
        >
          <div className="flex items-center justify-center p-6">
            <ArrowUpTrayIcon className="w-10" />
          </div>
          <div className="flex flex-col items-start justify-evenly">
            <div className="text-roboto-slab text-xl font-medium">
              Upload an Existing Thank You Note
            </div>
            <div>
              Upload your existing thank you note in DOC, DOCX, or PDF formats.
            </div>
          </div>
        </button>
      </div>
    </div>
  )
}

const NewThankYouNoteModal = () => {
  const [open, setOpen] = useState(false)
  const [uploadThankYouNoteMode, setUploadThankYouNoteMode] = useState(false)

  const uploadThankYouNoteClick = () => {
    setUploadThankYouNoteMode(true)
  }

  const toggleOpen = () => {
    setOpen(!open)
    setUploadThankYouNoteMode(false)
  }

  return (
    <Dialog
      open={open}
      onOpenChange={toggleOpen}
      className="!w-auto rounded-2xl lg:max-w-[37rem] xl:max-w-[37rem]"
    >
      <DialogTrigger asChild className="cursor-pointer">
        <Button>Build a Thank You Note</Button>
      </DialogTrigger>
      <DialogContent
        className="font-roboto lg:max-w-3xl xl:max-w-5xl"
        variant="default"
        dialogCloseClass="text-black"
      >
        <DialogHeader>
          <DialogTitle className="text-left" asChild>
            <h2 className="text-2xl">
              Create Your Thank You Note
              <DocumentTextIcon className="ml-2 inline w-6" />
            </h2>
          </DialogTitle>
        </DialogHeader>
        {!uploadThankYouNoteMode && (
          <NewThankYouNoteOptions
            uploadThankYouNoteClick={uploadThankYouNoteClick}
          />
        )}
        {uploadThankYouNoteMode && <DropzoneThankYouNoteUpload />}
      </DialogContent>
      <Toaster />
    </Dialog>
  )
}
export default NewThankYouNoteModal
