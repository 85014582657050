import React from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '~/lib/utils.js'
import * as _ from 'lodash'
import { Typography } from '~/components/_atoms'

export const cardVariants = cva(
  'card block relative @container rounded-2.5xl',
  {
    variants: {
      variant: {
        default: 'border',
      },
      color: {
        neutral: 'bg-white border-cod-gray-200',
        primary: 'bg-white border-intoo-blue-dark-600',
        secondary: 'bg-intoo-green-medium-50 border-intoo-green-medium-100',
      },
      shadow: { default: 'shadow-md', none: 'shadow-none' },
    },
    defaultVariants: {
      variant: 'default',
      color: 'neutral',
      shadow: 'default',
    },
  },
)

const Card = React.forwardRef(
  (
    {
      actions,
      children,
      className,
      color,
      density,
      icon,
      image,
      imageAlt,
      imageBadge,
      header,
      shadow,
      title,
      titleColor = 'primary',
      variant,
      ...props
    },
    ref,
  ) => {
    if (_.isEmpty(titleColor) && color === 'secondary') titleColor = 'secondary'
    let densityPadding = 6
    if (density === 'compact') densityPadding = 3
    if (density === 'roomy') densityPadding = 10

    const mainGridRows = image ? 'auto_1fr' : '1fr'
    let bodyGridRows = '1fr'
    if (header || title) bodyGridRows = 'auto_' + bodyGridRows
    if (actions) bodyGridRows += '_auto'

    return (
      <div
        className={cn(cardVariants({ variant, color, shadow, className }))}
        ref={ref}
        {...props}
      >
        <div className={`grid h-full grid-rows-[${mainGridRows}]`}>
          {image && (
            <div className="relative -mx-px -mt-px">
              {imageBadge && (
                <div className="absolute bottom-0 right-0 z-10">
                  <div className="h-11 rounded-tl-[99999px] bg-primary py-2 pl-7 pr-5 text-base font-normal text-[#FFFFFF]">
                    {imageBadge}
                  </div>
                </div>
              )}
              <img
                src={image}
                alt={imageAlt || title}
                className="h-60 w-full rounded-tl-2.5xl rounded-tr-2.5xl object-cover"
              />
            </div>
          )}
          <div
            className={`grid h-full grid-rows-[${bodyGridRows}] gap-3 ${densityPadding && `p-${densityPadding}`}`}
          >
            {(!_.isEmpty(title) || !_.isEmpty(header)) && (
              <div className="flex items-center gap-2">
                {icon && <>{icon}</>}
                {header ? (
                  <>{header}</>
                ) : (
                  <Typography
                    variant="title4"
                    className="flex gap-2"
                    color={titleColor}
                  >
                    {title}
                  </Typography>
                )}
              </div>
            )}
            <div>{children}</div>
            {!_.isEmpty(actions) && (
              <div className="flex items-end justify-end">{actions}</div>
            )}
          </div>
        </div>
      </div>
    )
  },
)
Card.displayName = 'Card'

export default Card
