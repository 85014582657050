import * as React from 'react'
import { api } from '../../../util/api'

export const useAiResponseStream = () => {
  const [aiResponse, setAiResponse] = React.useState('')
  const [thankYouNoteId, setThankYouNoteId] = React.useState(null)
  const [aiStreamComplete, setAiStreamComplete] = React.useState(false)
  const [serverSentEvents, setServerSentEvents] = React.useState(false)

  React.useEffect(() => {
    if (!serverSentEvents) return
    if (!thankYouNoteId) return

    const eventURL = `/interview/thank_you_notes/create_ai_sse/${thankYouNoteId}`
    const eventSource = new EventSource(eventURL)

    eventSource.addEventListener('message', (event) => {
      setAiResponse((r) => `${r}${event.data}`)
    })

    eventSource.addEventListener('error', (event) => {
      if (event.eventPhase === EventSource.CLOSED) {
        eventSource.close()
        setAiStreamComplete(true)
      }
    })

    return () => eventSource.close()
  }, [thankYouNoteId, serverSentEvents])

  React.useEffect(() => {
    if (serverSentEvents) return
    if (!thankYouNoteId) return

    const pollingURL = `/interview/thank_you_notes/create_ai_polling/${thankYouNoteId}`

    const interval = setInterval(async () => {
      const {
        data: { content, completed },
      } = await api.get(pollingURL)
      setAiResponse(`${content}`)
      setAiStreamComplete(completed)
      const copyButton = document.getElementById('copy-button')
      const downloadButton = document.getElementById(
        'download-button-container',
      )
      const aylaThankYouNoteText = document.getElementById(
        'ayla-thank-you-note-text',
      )
      const thankYouNoteButtons = document.getElementById(
        'thank-you-note-buttons',
      )
      const continueButton = document.getElementById('continue-button')
      if (
        completed &&
        copyButton &&
        downloadButton &&
        aylaThankYouNoteText &&
        thankYouNoteButtons &&
        continueButton
      ) {
        copyButton.style.display = 'flex'
        downloadButton.style.display = 'block'
        aylaThankYouNoteText.style.display = 'block'
        thankYouNoteButtons.style.display = 'flex'
        continueButton.style.display = 'block'
        clearInterval(interval)
        setAiResponse('')
      }
    }, 1000)

    return () => clearInterval(interval)
  }, [thankYouNoteId, aiResponse, aiStreamComplete, serverSentEvents])

  return {
    aiResponse,
    thankYouNoteId,
    setThankYouNoteId,
    aiStreamComplete,
    setServerSentEvents,
  }
}
