import React from 'react'

export default function Spinner({ show, className, size = 6 }) {
  if (!show) {
    return null
  }

  return (
    <div className={`${className} h-${size} w-${size}`}>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

          #spinner {
            animation: spin 2s linear infinite; // adjust speed here
          }
        `}
      </style>
      <img
        id="spinner"
        src="https://www-yns-com.s3.us-east-2.amazonaws.com/images/spinner-green.png"
        alt="spinner"
      />
    </div>
  )
}

export const SpinnerWhite = ({ show, className }) => {
  if (!show) {
    return null
  }

  return (
    <div className={className}>
      <style>
        {`
          @keyframes spin {
            0% { transform: rotate(0deg); }
            100% { transform: rotate(360deg); }
          }

          #spinner {
            animation: spin 2s linear infinite; // adjust speed here
          }
        `}
      </style>
      <img
        id="spinner"
        src="https://www-yns-com.s3.us-east-2.amazonaws.com/images/spinner-white.png"
        alt="spinner"
      />
    </div>
  )
}
