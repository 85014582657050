import React from 'react'
import Question from './Question'
import useLikertLayout from '../LikertLayout/LikertLayoutProvider'

const Questions = () => {
  const { questions, sectionIndex } = useLikertLayout()
  const questionsArr = Object.values(questions)
  const numQuestionsInSection = questionsArr.length

  return (
    <>
      {questionsArr.map((question, idx) => (
        <Question
          key={`question-${idx}`}
          idx={idx}
          questionIdx={idx + sectionIndex * numQuestionsInSection}
          questionInfo={question}
        />
      ))}
    </>
  )
}

export default Questions
