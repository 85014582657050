import React from 'react'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
import { useTranslation } from '../../hooks/useTranslation'
import { Page } from '~/components/_layouts'
import { DashboardHeader } from './Header'
import { Solo } from './programs/Solo'
import { SoloPlus } from './programs/SoloPlus'
import { Flex } from './programs/Flex'
import { Premium } from './programs/Premium'
import { Select } from './programs/Select'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import Reminders from './Reminders'
import { DashboardProvider, useDashboard } from './DashboardProvider'
import BenefitsProvider from '../coach_services/BenefitsProvider'
import { ActionPlanProvider } from '../ActionPlan/ActionPlanProvider'
import { Provider } from 'react-redux'
import { store } from '../../lib/store'
import { setProgressPercent } from '../ActionPlan/ActionPlanSlice'

const Index = ({ ...props }) => {
  return (
    <DashboardProvider {...props}>
      <Provider store={store}>
        <ActionPlanProvider {...props}>
          <TranslationProvider
            locale={props.current_user.locale}
            translations={translations}
            scope="javascript.components.home"
          >
            <Dashboard {...props} />
          </TranslationProvider>
        </ActionPlanProvider>
      </Provider>
    </DashboardProvider>
  )
}
export default Index

const Dashboard = ({ current_user, calendly_events_path }) => {
  const queryClient = new QueryClient()
  const { onlyReminders } = useDashboard()
  const { t } = useTranslation()
  const orientationPopupProps = {
    candidate_name: current_user.candidate_name,
    candidate_email: current_user.candidate_email,
    candidate_id: current_user.candidate_id,
    coach_id: current_user.coach_id,
    coach_calendly_url: current_user.coach_calendly_url,
    calendly_events_path,
    completed_calendly_event: current_user.completed_calendly_event,
    pending_event: current_user.pending_event,
  }
  // const inChatFrame = parent.document.getElementById('chat_browse') !== null
  const { userProgress } = useDashboard()
  store.dispatch(setProgressPercent(userProgress))

  return (
    <BenefitsProvider {...orientationPopupProps}>
      {onlyReminders && <Reminders />}
      {!onlyReminders && (
        <QueryClientProvider client={queryClient}>
          <Page
            title={t('title')}
            header={<DashboardHeader current_user={current_user} />}
            rightDrawer={{
              title: t('reminders.title'),
              viewActionText: t('view_reminders'),
              children: <Reminders />,
            }}
          >
            {current_user.bronze && <Solo />}
            {current_user.solo_plus && <SoloPlus />}
            {current_user.silver && <Flex />}
            {current_user.gold && <Premium />}
            {current_user.select && <Select />}
          </Page>
        </QueryClientProvider>
      )}
    </BenefitsProvider>
  )
}
