import * as React from 'react'

import translations from './locales.json'
import { TranslationProvider } from '../shared/TranslationProvider'
import OutplacementBenefits from './OutplacementBenefits'
import CoachFeedback from './CoachFeedback'
import MeetYourCoach from './MeetYourCoach'
import BenefitsProvider from './BenefitsProvider'

export default function Page({
  action = 'outplacement_benefits',
  feedback,
  program_level,
  calendar_events,
  benefit_status,
  locale,
  ...props
}) {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.coach_services.OutplacementBenefits"
    >
      <BenefitsProvider {...props}>
        {
          {
            meet_your_coach: <MeetYourCoach />,
            outplacement_benefits: (
              <OutplacementBenefits
                program_level={program_level}
                calendar_events={calendar_events}
                benefit_status={benefit_status}
              />
            ),
            coach_feedback: <CoachFeedback feedback={feedback} />,
          }[action]
        }
      </BenefitsProvider>
    </TranslationProvider>
  )
}
