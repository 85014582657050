import React from 'react'
import { Button } from '../../lib/ui/button'
import { X } from 'lucide-react'
import { cn } from '../../lib/utils'
export function WalkthroughTooltip(props) {
  const {
    backProps,
    closeProps,
    continuous,
    index,
    primaryProps,
    skipProps,
    step,
    tooltipProps,
  } = props

  const nextTitle = 'Next'

  return (
    <div
      className="tooltip__body relative z-[1050] flex max-w-lg flex-col bg-background shadow-md sm:rounded-2xl"
      {...tooltipProps}
    >
      <button
        type="button"
        className="tooltip__close absolute right-4 top-2"
        {...closeProps}
      >
        <X className={'text-white'} />
      </button>
      {step.title && (
        <div className="flex justify-center bg-primary sm:rounded-t-2xl">
          <div className="tooltip__title subtitle ml-[-1px] px-6 py-4 text-white">
            {step.title}
          </div>
        </div>
      )}
      <div className="tooltip__content p-6">{step.content}</div>
      <div className="tooltip__footer flex flex-col p-6">
        <div
          className={cn(
            'tooltip__spacer flex',
            index > 0 ? 'justify-between' : 'justify-end',
          )}
        >
          {index > 0 && (
            <Button
              variant="outline"
              className="tooltip__button"
              {...backProps}
            >
              {backProps.title}
            </Button>
          )}
          {continuous && (
            <Button
              className="tooltip__button tooltip__button--primary"
              {...primaryProps}
            >
              {nextTitle}
            </Button>
          )}
        </div>

        <Button
          variant="ghost"
          className="tooltip__button self-end"
          {...skipProps}
        >
          {skipProps.title}
        </Button>
      </div>
    </div>
  )
}
