import React, { useState, useEffect } from 'react'
import { useTranslation } from '~/hooks/useTranslation'
import { CoachButton } from '../../coach_services/OutplacementBenefits/CoachButton.js'
import { getApi } from '~/util/api'
import { Card, Typography } from '~/components/_atoms'
import Spinner from '~/components/shared/Spinner'

export function CoachSupportCard({ className }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [coachInfo, setCoachInfo] = useState(null)

  useEffect(() => {
    setIsLoading(true)
    getApi()
      .get('/coach_info')
      .then((response) => {
        if (response.status == 200) {
          setCoachInfo(response.data)
          return
        } else {
          throw new Error('json request failed')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('json request failed', error)
      })
  }, [setCoachInfo])

  return (
    <Card
      className={className}
      color="secondary"
      title={t('coach_support_card.title')}
      titleColor="secondary"
      actions={
        <CoachButton
          className="open-chat-modal w-full"
          data-toggle="modal"
          data-target="#coach_chat_list"
          data-action={t('coach_support_card.view_action')}
        >
          {t('coach_support_card.view_action')}
        </CoachButton>
      }
    >
      {isLoading ? (
        <div className="flex h-full items-center justify-center">
          <Spinner show size={9} />
        </div>
      ) : (
        <>
          <div className="flex h-full flex-col items-center justify-center">
            <img
              className="h-20 w-20 rounded-full"
              src={coachInfo?.image}
              alt="Coach Image"
            />
            {coachInfo?.time_since_last_check_in && (
              <Typography variant="small">
                {coachInfo?.time_since_last_check_in}
              </Typography>
            )}
          </div>
        </>
      )}
    </Card>
  )
}
