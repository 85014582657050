import React, { useState, useEffect } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { Button } from '../../../lib/ui/button'
import ActiveTileHeader from './ActiveTileHeader'
import { CoachButton } from '../../coach_services/OutplacementBenefits/CoachButton'
import { useActionPlan } from '../ActionPlanProvider'
import { apiPatch } from '../../../util/api'
import { cn } from '../../../lib/utils'
import { getUserTrackerSections } from '../index'
import { TimeIcon } from './icons'

import {
  BrainResearch as AssessmentIcon,
  Compass as ArticleIcon,
  MagicWand as AylaIcon,
  Bicycle as ExerciseIcon,
  Golf as ShortCourseIcon,
  AreaSearch as SearchToolIcon,
} from 'iconoir-react'

import {
  SpeakerWaveIcon as AudioIcon,
  ClipboardDocumentCheckIcon as ChecklistIcon,
  ComputerDesktopIcon as WebinarIcon,
  PuzzlePieceIcon as QuizIcon,
  WrenchScrewdriverIcon as BuilderIcon,
  UserCircleIcon as CoachServiceIcon,
  DocumentIcon as SampleDocIcon,
  VideoCameraIcon as VideoIcon,
} from '@heroicons/react/24/outline'

export const itemTypeMap = {
  article: { text: 'Read Article', icon: <ArticleIcon /> },
  video: { text: 'Watch Video', icon: <VideoIcon /> },
  quiz: { text: 'Take Quiz', icon: <QuizIcon /> },
  exercise: { text: 'Take Exercise', icon: <ExerciseIcon /> },
  ayla: { text: 'Go to Ayla', icon: <AylaIcon /> },
  webinar: { text: 'Watch Webinar', icon: <WebinarIcon /> },
  assessment: { text: 'Take Assessment', icon: <AssessmentIcon /> },
  audio: { text: 'Listen to Audio', icon: <AudioIcon /> },
  short_course: { text: 'Take Short Course', icon: <ShortCourseIcon /> },
  coach_service: { text: 'View Coach Service', icon: <CoachServiceIcon /> },
  builder: { text: 'Go to Builder', icon: <BuilderIcon /> },
  search_tool: { text: 'Use Tool', icon: <SearchToolIcon /> },
  checklist: { text: 'View Checklist', icon: <ChecklistIcon /> },
  sample: { text: 'View Sample Doc', icon: <SampleDocIcon /> },
  workshop: { text: 'Take Workshop', icon: <ShortCourseIcon /> },
}

const ActiveTile = ({ tileInfo }) => {
  const sectionName = Object.keys(tileInfo)[0]
  const { t } = useTranslation()
  const totalTasks = tileInfo[sectionName]?.subsections
  const [completedTasks, setCompletedTasks] = useState(
    totalTasks.filter((task) => task.completed || task.skipped).length,
  )

  useEffect(() => {
    setCompletedTasks(
      totalTasks.filter((task) => task.completed || task.skipped).length,
    )
  }, [tileInfo, totalTasks])

  function calculateCompletedTasks() {
    return `${t('completed_tasks', {
      completed: completedTasks,
      total: totalTasks.length,
    })}`
  }

  const sortedSubsections = totalTasks.sort((a, b) => {
    if (a.skipped && !b.skipped) {
      return 1
    } else if (!a.skipped && b.skipped) {
      return -1
    } else {
      return 0
    }
  })

  return (
    <div className="section-tile mb-[24px] bg-[#E0F2F9] p-4 lg:p-8">
      <ActiveTileHeader
        sectionName={sectionName}
        calculateCompletedTasks={calculateCompletedTasks}
        tileInfo={tileInfo}
        t={t}
      />

      <div className="relative my-6 flex flex-row rounded-[16px]">
        <div className="flex w-full flex-col flex-wrap gap-[24px] rounded-[16px]">
          {sortedSubsections.map((tile, idx) => {
            return (
              <TrackItem
                key={`expanded-${idx}`}
                tile={tile}
                t={t}
                completedTasks={completedTasks}
                setCompletedTasks={setCompletedTasks}
                totalTasks={totalTasks}
              />
            )
          })}
        </div>
      </div>
    </div>
  )
}
export default ActiveTile

const TrackItem = ({ tile, t, completedTasks, totalTasks }) => {
  const [isSkipped, setIsSkipped] = useState(false)
  const [skipping, setSkipping] = useState(false)
  const [isCompleted, setIsCompleted] = useState(false)

  useEffect(() => {
    setIsSkipped(tile.skipped)
    setIsCompleted(tile.completed)
  }, [tile])

  const skipItemAnimation = (tile) => {
    if (!isSkipped) {
      setSkipping(true)
      setTimeout(() => {
        if (completedTasks === totalTasks.length - 1) {
          window.scrollTo({ top: 0, behavior: 'smooth' })
          setTimeout(() => {
            getUserTrackerSections()
          }, 1000)
        } else {
          getUserTrackerSections()
        }
        setSkipping(false)
      }, 2550)
    } else {
      setIsSkipped(!isSkipped)
      tile.skipped = !isSkipped
      getUserTrackerSections()
    }
  }

  const skipItem = async () => {
    const res = await apiPatch('/trackers/skip_item', { slug: tile.slug })
    if (res.status === 200) {
      skipItemAnimation(tile)

      return res
    } else {
      throw new Error('res not ok')
    }
  }
  const markItemComplete = async () => {
    const res = await apiPatch('/trackers/mark_item_complete', {
      achievement: tile.achievement,
    })
    if (res.status === 200) {
      if (completedTasks === totalTasks.length - 1) {
        // scroll to top of the page
        window.scrollTo({ top: 0, behavior: 'smooth' })
        setTimeout(() => {
          getUserTrackerSections()
        }, 1000)
      } else {
        setIsCompleted(true)
        tile.completed = true
        getUserTrackerSections()
      }

      return res
    } else {
      throw new Error('res not ok')
    }
  }

  const icon = itemTypeMap[tile.type].icon

  return (
    <TileContent
      tile={tile}
      icon={icon}
      t={t}
      isSkipped={isSkipped}
      setIsSkipped={setIsSkipped}
      setIsCompleted={setIsCompleted}
      skipItem={skipItem}
      markItemComplete={markItemComplete}
      skipping={skipping}
      isCompleted={isCompleted}
    />
  )
}

const TileContent = ({
  tile,
  icon,
  skipItem,
  markItemComplete,
  skipping,
  isSkipped,
  isCompleted,
  t,
}) => {
  const background = isSkipped ? '#373737' : '#FFFFFF'
  const isCoachService = tile['coach_service']
  const [skippedText, setSkippedText] = useState('Task Skipped')
  const { completionAnimationUrl } = useActionPlan()
  return (
    <div
      className={cn(
        'track-item relative flex flex-col gap-6 rounded-[16px] bg-white p-[16px] pl-[32px] lg:grid lg:grid-cols-12 lg:gap-0 lg:pl-[16px]',
        isSkipped || skipping ? 'skipped-track-item' : '',
      )}
    >
      <div
        className={`absolute left-0 top-0 z-50 flex h-full w-[16px] flex-col rounded-bl-[16px] rounded-tl-[16px] ${
          isCoachService ? 'bg-intoo-green-coachcta' : 'bg-primary'
        }`}
      ></div>
      <div
        className="absolute left-0 top-0 h-full w-full cursor-pointer rounded-[16px] opacity-90"
        style={{
          background: background,
          display: isSkipped ? 'block' : 'none',
        }}
        onMouseOver={() => setSkippedText('Unskip Task')}
        onMouseOut={() => setSkippedText('Task Skipped')}
        onClick={() => skipItem()}
      >
        <div className="flex h-full items-center justify-center">
          <div className="font-roboto-slab text-2xl font-bold text-white">
            {skippedText}
          </div>
        </div>
      </div>
      <div
        className={cn(
          'absolute left-0 top-0 w-full cursor-pointer rounded-[16px]',
        )}
        style={{
          background: background,
          height: skipping ? '100%' : '0px',
          opacity: skipping ? '90%' : '0%',
          transition: 'opacity 2s ease-in-out',
        }}
      ></div>
      <div className="lg:col-span-3">
        <div className="flex h-full flex-col items-center justify-center">
          <div
            className="h-[120px] w-full overflow-hidden md:w-[80%]"
            style={{
              backgroundImage:
                tile.image && tile.image.path ? `url(${tile.image.path})` : '',
              backgroundSize: 'cover',
              backgroundPosition: 'center',
            }}
            alt={tile.image?.title ? tile.image.title : tile.name}
          ></div>
        </div>
      </div>
      <div className="col-span-5 flex flex-col gap-2 lg:pr-6 xl:col-span-6">
        <div className="subtitle !text-xl">{tile.name}</div>
        {tile.description}
        <div className="flex gap-4 md:flex-row">
          <div className="flex flex-col items-center justify-center gap-2 border-r border-cod-gray-200 pr-6">
            <TimeIcon variant={isCoachService ? 'coach' : 'primary'} />
            <div
              className={`capitalize ${
                isCoachService ? 'text-intoo-green-coachcta' : 'text-primary'
              }`}
            >
              {tile.length}
            </div>
          </div>
          <div className="flex flex-col items-center justify-center gap-2 pl-6">
            {React.cloneElement(icon, {
              variant: isCoachService ? 'coach' : 'primary',
              height: '24',
            })}
            <div
              className={`capitalize ${
                isCoachService ? 'text-intoo-green-coachcta' : 'text-primary'
              }`}
            >
              {tile.type.split('_').join(' ')}
            </div>
          </div>
        </div>
      </div>
      <div className="col-span-3 flex h-full flex-col justify-center xl:col-span-2">
        <div
          className={`flex flex-col items-center justify-center ${
            isCompleted ? '' : 'gap-6'
          } text-center`}
        >
          <a href={tile.url} className="w-full">
            {isCoachService ? (
              <CoachButton
                title={t('view') + ' ' + tile.name}
                href={tile.url}
                className="w-full text-[14px]"
              >
                {itemTypeMap[tile.type].text}
                <span className="ml-[8px]">
                  {React.cloneElement(icon, { variant: 'light', height: '24' })}
                </span>
              </CoachButton>
            ) : (
              <Button
                title={t('view') + ' ' + tile.name}
                href={tile.url}
                className="w-full"
              >
                {itemTypeMap[tile.type].text}
                <span className="ml-[8px]">
                  {React.cloneElement(icon, { variant: 'light', height: '24' })}
                </span>
              </Button>
            )}
          </a>
          {isCompleted ? (
            <img
              className="w-20"
              src={completionAnimationUrl}
              alt="Article complete"
            />
          ) : (
            <Button
              variant="outline"
              onClick={markItemComplete}
              className="w-full"
            >
              Mark as Completed
            </Button>
          )}
        </div>
      </div>
      <div className="justify-top col-span-1 h-full text-right">
        {!isCompleted && !isSkipped && (
          <Button
            onClick={skipItem}
            variant="link"
            className="!h-auto cursor-pointer p-0 text-base !underline"
          >
            Skip
          </Button>
        )}
      </div>
    </div>
  )
}
