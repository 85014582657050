import React, { useEffect, useState } from 'react'
import { useTranslation } from '../../hooks/useTranslation'
import {
  BackIcon,
  ForwardIcon,
  CompleteIcon,
} from './TrackTileComponents/icons'
import { useIsDesktop, useMediaQueryScreen } from '../../hooks/useMediaQuery'

const StepMenu = ({ activeTab, setActiveTab, totalSteps }) => {
  const { t } = useTranslation()
  const [totalStepsToShow, setTotalStepsToShow] = useState([])
  const isDesktop = useIsDesktop()
  const isXlScreen = useMediaQueryScreen('xl')

  const getSectionTitle = (sectionName) => {
    const parts = sectionName?.split('_')
    if (parts) {
      if (parts.length === 3) {
        return `${t('months')} ${parts[1]} - ${parts[2]}`
      } else {
        return t(`${sectionName.split('_')[0]}_indicator`, {
          number: sectionName.split('_')[1],
        })
      }
    }
  }

  const completed = (step) => {
    const completedSubsections = Object.values(step)[0]['subsections'].filter(
      (subsection) => subsection.completed || subsection.skipped,
    )
    return (
      completedSubsections.length ===
      Object.values(step)[0]['subsections'].length
    )
  }

  const viewableSteps = isDesktop ? (isXlScreen ? 6 : 3) : 2

  useEffect(() => {
    const lastCompletedStep = totalSteps.findIndex((step) => !completed(step))
    const allCompleted = totalSteps.every((step) => completed(step))
    const start =
      lastCompletedStep > 0
        ? lastCompletedStep < totalSteps.length - viewableSteps + 1
          ? lastCompletedStep - 1
          : totalSteps.length - viewableSteps
        : allCompleted
          ? totalSteps.length - viewableSteps
          : 0
    setTotalStepsToShow(totalSteps.slice(start, start + viewableSteps))
    if (lastCompletedStep === -1) {
      setActiveTab(totalSteps[totalSteps.length - 1])
    } else {
      setActiveTab(totalSteps[lastCompletedStep])
    }
  }, [totalSteps, setActiveTab, viewableSteps])

  const shiftLeft = () => {
    const firstStep = totalStepsToShow[0]
    const firstStepIndex = totalSteps.findIndex((step) => step === firstStep)
    if (firstStepIndex > 0) {
      setTotalStepsToShow(
        totalSteps.slice(
          firstStepIndex - 1,
          firstStepIndex + viewableSteps - 1,
        ),
      )
    }
  }

  const shiftRight = () => {
    const firstStep = totalStepsToShow[0]
    const firstStepIndex = totalSteps.findIndex((step) => step === firstStep)
    if (firstStepIndex + viewableSteps - 1 < totalSteps.length - 1) {
      setTotalStepsToShow(
        totalSteps.slice(
          firstStepIndex + 1,
          firstStepIndex + viewableSteps + 1,
        ),
      )
    }
  }

  return (
    <div className="relative w-full overflow-x-scroll border-b border-solid border-[#979797] sm:static sm:overflow-auto">
      <div className="m-auto flex w-10/12 flex-row items-center justify-between gap-5">
        {totalStepsToShow.length > 0 && (
          <div
            onClick={shiftLeft}
            className="cursor-pointer pt-4"
            style={{ userSelect: 'none' }}
          >
            <BackIcon />
          </div>
        )}
        {totalStepsToShow.map((step, index) => (
          <div
            key={`${Object.keys(step)[0]}_${index}}`}
            onClick={() => setActiveTab(step)}
            className={
              activeTab === step
                ? 'boder-solid flex cursor-pointer flex-col items-center justify-center gap-2 border-b-4 border-[#08467C] text-center font-roboto-slab text-xl font-medium'
                : 'flex cursor-pointer flex-col items-center justify-center gap-2 pb-[.25rem] text-center font-roboto-slab text-xl font-medium'
            }
          >
            {completed(step) ? <CompleteIcon /> : <div className="h-6 w-6" />}
            <div
              className={`px-4 pb-4 ${
                completed(step) ? 'text-intoo-green-coachcta' : 'text-primary'
              }`}
            >
              {Object.keys(step)[0] && getSectionTitle(Object.keys(step)[0])}
            </div>
          </div>
        ))}
        {totalStepsToShow.length > 0 && (
          <div
            onClick={shiftRight}
            className="cursor-pointer pt-4"
            style={{ userSelect: 'none' }}
          >
            <ForwardIcon />
          </div>
        )}
      </div>
    </div>
  )
}

export default StepMenu
