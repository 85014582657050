import React, { useState } from 'react'
import { useResume } from '../ResumeProvider'
import { Button } from '~/lib/ui/button'
import { MagicWand } from 'iconoir-react'
import { LightBulbIcon } from '@heroicons/react/24/outline'
import Cursor from './Cursor'
import { useEditorItem } from './EditorItemProvider'
import useAiStream from './useAiStream'

const AiSummary = () => {
  const { item, updateItem } = useEditorItem()

  const { persistResumeIfChanged, documentId: resumeDocumentId } = useResume()

  const [panelOpen, setPanelOpen] = useState(false)
  const [desiredJobTitle, setDesiredJobTitle] = useState('')

  const beforeStartStream = async () => await persistResumeIfChanged()
  const {
    displayedText,
    typingComplete,
    streamNotStarted,
    streamRunning,
    streamComplete,
    resetResponse,
    startStream,
  } = useAiStream(beforeStartStream)

  const generateAiSummary = async () => {
    const eventUrl = `/resume/builder/${resumeDocumentId}/ai_summary_sse?desired_job_title=${desiredJobTitle}`
    await startStream(eventUrl)
  }

  const selectResponse = () => {
    const newItem = { ...item, summary: displayedText }
    updateItem(newItem)
    closePanel()
  }

  const closePanel = () => {
    resetResponse()
    setDesiredJobTitle('')
    setPanelOpen(false)
  }

  if (!resumeDocumentId) {
    return (
      <div className="rounded-lg bg-lily-blue p-2 md:col-span-2">
        <LightBulbIcon className="mr-2 inline h-6 w-6" />
        <span className="mr-2 inline font-bold">Tip:</span>
        <span className="inline">
          Save your resume to generate a summary with Ayla
        </span>
      </div>
    )
  }

  if (!panelOpen) {
    return (
      <div className="flex justify-between md:col-span-2">
        <Button onClick={() => setPanelOpen(true)}>
          <MagicWand /> <span className="pl-2">Generate Summary with Ayla</span>
        </Button>
      </div>
    )
  }

  return (
    <div className="rounded-lg bg-lily-blue p-4 md:col-span-2">
      <div className="flex pb-4 font-bold">
        <MagicWand /> Generate Summary with Ayla
      </div>
      {streamNotStarted && (
        <>
          <div className="pb-4">
            Ayla will analyze your anonymized experience, skills and education
            to generate a compelling professional summary. If you provide a
            desired job title, Ayla will tailor the summary to that role.
          </div>
          <div className="pb-4">
            <label htmlFor="desired_job_title" className="block font-bold">
              Desired Job Title
            </label>
            <input
              type="text"
              id="desired_job_title"
              name="desired_job_title"
              value={desiredJobTitle}
              onChange={(e) => setDesiredJobTitle(e.target.value)}
              className="w-full border border-solid border-input bg-input-background p-2"
            />
          </div>
        </>
      )}
      {streamRunning && (
        <div className="pb-4">
          <div>Please wait while Ayla writes your summary...</div>
        </div>
      )}
      {displayedText.length > 0 && (
        <div className="mb-4 block w-full border border-solid border-input bg-input-background p-4">
          {displayedText} <Cursor show={!typingComplete} />
        </div>
      )}
      <div className="flex flex-row justify-between">
        {!streamComplete && (
          <>
            <Button
              onClick={generateAiSummary}
              disabled={streamRunning || !typingComplete}
            >
              Let&apos;s go Ayla!
            </Button>
            {streamNotStarted && (
              <Button onClick={closePanel} variant="outline">
                Cancel
              </Button>
            )}
          </>
        )}
        {streamComplete && typingComplete && (
          <>
            <Button onClick={selectResponse}>Use This Summary</Button>
            <Button onClick={closePanel} variant="outline">
              Discard
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
export default AiSummary
