import { useState, useEffect, useRef } from 'react'

const useAiStream = (beforeStartStream = null) => {
  const [aiResponse, setAiResponse] = useState('')
  const [typingComplete, setTypingComplete] = useState(false)
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const eventSource = useRef(null)
  const [responseState, setResponseState] = useState('not_started')

  const streamNotStarted = responseState == 'not_started'
  const streamRunning = responseState == 'running'
  const streamComplete = responseState == 'complete'

  useEffect(() => {
    const baseSpeed = 5
    const getSpeed = (char) => {
      if (char === '.' || char === '!' || char === ',') {
        return baseSpeed
      } else if (char === ' ') {
        return baseSpeed
      } else {
        return baseSpeed
      }
    }

    if (index < aiResponse.length) {
      setTypingComplete(false)
      const currentChar = aiResponse.charAt(index)
      const timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev + currentChar)
        setIndex(index + 1)
      }, getSpeed(currentChar))

      return () => clearTimeout(timeoutId)
    } else {
      setTypingComplete(true)
    }
  }, [index, setIndex, setTypingComplete, setDisplayedText, aiResponse])

  const resetResponse = () => {
    setDisplayedText('')
    setAiResponse('')
    setIndex(0)
    setResponseState('not_started')
  }

  const startStream = async (eventUrl) => {
    if (typeof beforeStartStream == 'function') await beforeStartStream()

    setResponseState('running')
    const newSource = new EventSource(eventUrl)

    newSource.addEventListener('message', (event) => {
      if (event.data != 'null') {
        setAiResponse((r) => `${r}${event.data}`)
      }
    })

    newSource.addEventListener('error', (event) => {
      if (event.eventPhase === EventSource.CLOSED) {
        eventSource.current.close()
        eventSource.current = null
        setResponseState('complete')
      }
    })

    eventSource.current = newSource
  }
  const value = {
    displayedText,
    typingComplete,
    streamNotStarted,
    streamRunning,
    streamComplete,
    resetResponse,
    startStream,
  }

  return value
}
export default useAiStream
