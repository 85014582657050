import React from 'react'
import { cn } from '../../lib/utils'

const CheckIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="currentColor"
      className="size-6"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m4.5 12.75 6 6 9-13.5"
      />
    </svg>
  )
}

export default function SearchResultsSavedStatus({
  title,
  className,
  children,
  ...props
}) {
  return (
    <div
      {...props}
      className={cn('flex cursor-default items-center gap-0', className)}
      title={title}
    >
      <CheckIcon />
      {children}
    </div>
  )
}
