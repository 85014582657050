import React from 'react'
import { cva } from 'class-variance-authority'
import { cn } from '~/lib/utils'
import { Stack } from '../../_layouts'

export const buttonVariants = cva(
  'inline-flex items-center justify-center whitespace-nowrap rounded-full cursor-pointer font-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50',
  {
    variants: {
      variant: {
        solid_primary:
          'bg-intoo-blue-medium-800 text-white hover:bg-intoo-blue-medium-900 hover:text-white',
        solid_secondary:
          'bg-intoo-green-medium-700 text-white hover:bg-intoo-green-medium-900 hover:text-white',
        solid_neutral:
          'bg-cod-gray-200 text-cod-gray-900 hover:bg-cod-gray-300 hover:text-white',
        solid_destructive:
          'bg-red-800 text-white hover:bg-red-900 hover:text-white',
        outline_primary:
          'border border-intoo-blue-medium-800 text-intoo-blue-medium-800 hover:border-intoo-blue-medium-900 hover:text-intoo-blue-medium-900 hover:bg-intoo-blue-medium-50',
        outline_secondary:
          'border border-intoo-green-medium-700 text-intoo-green-medium-700 hover:border-intoo-green-medium-900 hover:text-intoo-green-medium-900 hover:bg-intoo-green-medium-50',
        outline_neutral:
          'border border-cod-gray-700 text-cod-gray-700 hover:border-cod-gray-700 hover:text-cod-gray-700 hover:bg-cod-gray-50',
        outline_destructive:
          'border border-red-800 text-red-800 hover:border-red-800 hover:text-red-800 hover:bg-red-50',
        ghost_primary:
          'rounded-md text-intoo-blue-medium-800 hover:border-intoo-blue-medium-900 hover:text-intoo-blue-medium-900 hover:bg-intoo-blue-medium-50',
        ghost_secondary:
          'rounded-md text-intoo-green-medium-700 hover:border-intoo-green-medium-900 hover:text-intoo-green-medium-900 hover:bg-intoo-green-medium-50',
        ghost_neutral:
          'rounded-md text-cod-gray-700 hover:border-cod-gray-700 hover:text-cod-gray-700 hover:bg-cod-gray-50',
        ghost_destructive:
          'rounded-md text-red-800 hover:border-red-800 hover:text-red-800 hover:bg-red-50',
        link_primary:
          'text-intoo-blue-medium-800 hover:text-intoo-blue-medium-600 underline-offset-4 !hover:underline !px-0 !py-0 !h-auto',
        link_secondary:
          'text-intoo-green-medium-700 hover:text-intoo-green-medium-500 underline-offset-4 [&&]:hover:underline !px-0 !py-0 !h-auto',
        link_neutral:
          'text-cod-gray-800 hover:text-cod-gray-900 underline-offset-4 !hover:underline !px-0 !py-0 !h-auto',
        link_destructive:
          'text-red-800 hover:text-red-600 underline-offset-4 !hover:underline !px-0 !py-0 !h-auto',
      },
      size: {
        sm: 'h-9 px-3 text-sm',
        md: 'h-10 px-8 py-2',
        lg: 'h-12 px-12 text-lg text-lg',
        xl: 'h-[3.75rem] px-16 text-xl',
        icon: 'h-10 w-10',
      },
      fullWidth: { true: 'w-full', false: '' },
    },
    defaultVariants: {
      variant: 'solid_primary',
      size: 'md',
      fullWidth: 'false',
    },
  },
)

const iconSizeMap = {
  sm: 'h-3 w-3',
  md: 'h-4 w-4',
  lg: 'h-5 w-5',
  xl: 'h-6 w-6',
  icon: 'h-6 w-6',
}

const Button = React.forwardRef(
  (
    {
      children,
      className,
      variant = 'solid',
      size = 'md',
      color = 'primary',
      fullWidth = false,
      startIcon,
      endIcon,
      component = 'a',
      ...props
    },
    ref,
  ) => {
    const Comp = component
    return (
      <Comp
        className={cn(
          buttonVariants({
            variant: `${variant}_${color}`,
            size,
            fullWidth: fullWidth.toString(),
            className,
          }),
        )}
        ref={ref}
        {...props}
      >
        <Stack direction="row" spacing={2}>
          {!!startIcon &&
            React.cloneElement(startIcon, { className: iconSizeMap[size] })}
          {children}
          {!!endIcon &&
            React.cloneElement(endIcon, { className: iconSizeMap[size] })}
        </Stack>
      </Comp>
    )
  },
)
Button.displayName = 'Button'

export default Button
