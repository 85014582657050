import * as React from 'react'
import ToolBar from './Toolbar'
import SearchResults from './SearchResults'
import { useTranslation } from '~/hooks/useTranslation'

const Page = () => {
  const { t } = useTranslation()
  return (
    <div className="flex flex-col border-l border-alto-200 pl-2 font-roboto">
      <div className="my-10 ml-4 mr-6 flex flex-col gap-8">
        <div className="flex max-w-[1375px] flex-col">
          <h1 className="!mb-4 font-gothamRegular text-[#333]">
            <span className="font-normal">{t('light_heading')}</span>{' '}
            {t('dark_heading')}
          </h1>
          <p className="mb-4">{t('description')}</p>
          <ToolBar />
        </div>
        <SearchResults />
      </div>
    </div>
  )
}

export default Page
