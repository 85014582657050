export const timesNewRoman = {
  family: 'Times New Roman',
  fonts: [
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Times+New+Roman.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Times+New+Roman+Bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Times+New+Roman+Italic.ttf',
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Times+New+Roman+Bold+Italic.ttf',
      fontStyle: 'italic',
      fontWeight: 'bold',
    },
  ],
}

export const roboto = {
  family: 'Roboto',
  fonts: [
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Roboto-Regular.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Roboto-Bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Roboto-Italic.ttf',
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Roboto-BoldItalic.ttf',
      fontStyle: 'italic',
      fontWeight: 'bold',
    },
  ],
}

export const lora = {
  family: 'Lora',
  fonts: [
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Lora-Regular.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Lora-Italic.ttf',
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Lora-Bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Lora-BoldItalic.ttf',
      fontStyle: 'italic',
      fontWeight: 'bold',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Lora-SemiBold.ttf',
      fontStyle: 'normal',
      fontWeight: 'semibold',
    },
  ],
}

export const inter = {
  family: 'Inter',
  fonts: [
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Inter_18pt-Regular.ttf',
      fontStyle: 'normal',
      fontWeight: 'normal',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Inter_18pt-Italic.ttf',
      fontStyle: 'italic',
      fontWeight: 'normal',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Inter_18pt-Bold.ttf',
      fontStyle: 'normal',
      fontWeight: 'bold',
    },
    {
      src: 'https://yournextstep-com.s3.us-east-2.amazonaws.com/fonts/Inter_18pt-SemiBold.ttf',
      fontStyle: 'normal',
      fontWeight: 'semibold',
    },
  ],
}

const fonts = [timesNewRoman, roboto, lora, inter]
export default fonts
