import React, {
  useContext,
  useCallback,
  createContext,
  useState,
  useEffect,
} from 'react'
import { useAlert } from './AlertModal'
import { getApi, updateCsrfToken } from '../../../util/api'
import useTranslation from '../../shared/TranslationProvider'
import useTheme from '../../shared/ThemeProvider'

const DataContext = createContext()

export const useData = () => {
  return useContext(DataContext)
}

export const DataProvider = ({
  initialFormState,
  inviteCode,
  googleAuthEnabled,
  appleAuthEnabled,
  panelAutoRotationDisabled,
  userNotificationEnabled,
  flashAlert,
  flashNotice,
  appleOauthAttachEmail,
  googleOauthAttachEmail,
  legalNotice,
  newLegalNotice,
  children,
}) => {
  const { setAlertData, setAlertState, clearAlert } = useAlert()
  const { t, locale } = useTranslation()
  const { platformName } = useTheme()

  const [formState, setFormState] = useState(initialFormState)
  const [invitation, setInvitation] = useState({})
  const [otpCreateData, setOtpCreateData] = useState({})
  const [existingUsername, setExistingUsername] = useState('')
  const [existingUserApple, setExistingUserApple] = useState(false)
  const [existingUserGoogle, setExistingUserGoogle] = useState(false)
  const [programLevel, setProgramLevel] = useState('')
  const [webinars, setWebinars] = useState([])
  const [disableRotation, setDisableRotation] = useState(
    panelAutoRotationDisabled,
  )
  const [isLoading, setIsLoading] = useState(false)
  const [documentTitle, setDocumentTitle] = useState('')

  const oauthEnabled = googleAuthEnabled || appleAuthEnabled

  const handleFormResponse = useCallback(
    (response) => {
      updateCsrfToken(response.data.csrf_token)
      if (response.data.location) {
        setIsLoading(true)
        window.location.href = response.data.location
      }
      if (response.data.valid_invitation) {
        setInvitation(response.data.valid_invitation)
        setProgramLevel(response.data.valid_invitation.program_level)
      }
      if (response.data.webinars) {
        setWebinars(response.data.webinars)
      }
      if (response.data.existing_username) {
        setExistingUsername(response.data.existing_username)
      }
      if (response.data.existing_user_apple) {
        setExistingUserApple(response.data.existing_user_apple)
      }
      if (response.data.existing_user_google) {
        setExistingUserGoogle(response.data.existing_user_google)
      }
      if (response.data.otp_create_data) {
        setOtpCreateData(response.data.otp_create_data)
      }
      if (response.data.react_form_state) {
        setIsLoading(false)
        setFormState(response.data.react_form_state)
      }
      if (response.data.previous_state === 'ForgotPassword') {
        setAlertState('success')
        setAlertData(response.data.notice)
      }
    },
    [setAlertData, setAlertState],
  )

  const getInvitation = useCallback(
    async (invitationCode, source = null) => {
      try {
        const response = await getApi().get('/users/sign_up', {
          params: {
            invitation: invitationCode.trim(),
            locale: locale,
            source: source,
          },
        })
        setIsLoading(false)
        if (response.data.alert) {
          setAlertData(response.data.alert)
          setAlertState('alert')
        } else if (response.data.notice) {
          setAlertData(response.data.notice)
          setAlertState('notice')
        } else if (response.data.valid_invitation) {
          clearAlert()
        }
        handleFormResponse(response)
      } catch {
        setIsLoading(false)
        setAlertData(t('code_validation_error'))
        setAlertState('alert')
      }
    },
    [setAlertData, setAlertState, clearAlert, locale, handleFormResponse, t],
  )

  useEffect(() => {
    if (inviteCode && inviteCode.length > 0) {
      setFormState('RegistrationForm')
      getInvitation(inviteCode)
    } else if (localStorage.getItem('unsubscribed')) {
      setAlertState('success')
      setAlertData(t('unsubscribe_success'))
      localStorage.removeItem('unsubscribed')
    }
  }, [inviteCode, getInvitation, setAlertData, setAlertState, t])

  useEffect(() => {
    setDocumentTitle(document.title)
  }, [setDocumentTitle])

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [formState])

  useEffect(() => {
    if (
      ['LoginForm', 'TwoFactorEntry', 'TwoFactorCreate'].includes(formState)
    ) {
      document.title = `${t('LoginForm.log_in_button')} | ${platformName}`
    }
    if (formState === 'InviteCodeEntry') {
      document.title = `${t('InviteCodeEntry.validate_code')} | ${platformName}`
    }
    if (
      ['RegistrationForm', 'AddLicense', 'AccountServices'].includes(formState)
    ) {
      document.title = `${t(
        'RegistrationForm.create_button',
      )} | ${platformName}`
    }
    if (formState === 'ResetPassword') {
      document.title = `${t('ResetPassword.reset_password')} | ${platformName}`
    }
    if (formState === 'ForgotPassword') {
      document.title = `${t('forgot_password')} | ${platformName}`
    }
  }, [formState, documentTitle, t, platformName])

  const data = {
    formState,
    invitation,
    otpCreateData,
    existingUsername,
    existingUserApple,
    existingUserGoogle,
    programLevel,
    webinars,
    oauthEnabled,
    googleAuthEnabled,
    appleAuthEnabled,
    disableRotation,
    userNotificationEnabled,
    flashAlert,
    flashNotice,
    isLoading,
    setFormState,
    setOtpCreateData,
    setExistingUsername,
    setDisableRotation,
    setIsLoading,
    handleFormResponse,
    getApi,
    getInvitation,
    appleOauthAttachEmail,
    googleOauthAttachEmail,
    legalNotice,
    newLegalNotice,
  }
  return <DataContext.Provider value={data}>{children}</DataContext.Provider>
}
