import React, { useState } from 'react'
import { cn } from '../../../../lib/utils'
import { Button } from '../../../../lib/ui/button'
import { apiPost } from '../../../../util/api'
import AylaResponseScrollArea from '../../AylaResponseScrollArea'

const IndustryResearch = ({ rankedIndustries, documentId, ctaText }) => {
  const [selectedIndustry, setSelectedIndustry] = useState()
  const [submitted, setSubmitted] = useState(false)
  const [typingComplete, setTypingComplete] = useState(false)
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)
  const [aylaResponse, setAylaResponse] = useState(null)
  const radioInputClasses = cn(
    '!mb-1 !mr-2 !mt-0 h-4 w-4 border-gray-300 text-blue-600',
  )
  const showCursor = submitted && displayedText.length <= 0
  const showBorder = submitted || aylaResponse

  const prompt = `Provide a high level summary of the current state of the ${selectedIndustry} industry that answers these 3 questions: (1) What are the top 3 trends impacting job growth in the ${selectedIndustry} industry? (2) What percentage job growth is projected in the ${selectedIndustry}industry in the next 5 years? and (3) What are the key challenges facing the ${selectedIndustry} industry today? This summary should be written for the average adult US job seeker to help them evaluate their job prospects in the ${selectedIndustry} industry.`

  const requestAylaStatement = async () => {
    const url = `/job-search/create_ai_response?document_id=${documentId}`
    const data = { ayla_prompt: { prompt } }
    let res
    try {
      res = await apiPost(url, data)
    } catch (err) {
      console.log(err)
    }

    if (res.status === 200) {
      setAylaResponse(res.data.ayla_response)
      setSubmitted(false)
    } else {
      console.log('error')
    }
    return res
  }

  const handleButtonClick = () => {
    if (selectedIndustry) {
      setAylaResponse(null)
      setDisplayedText('')
      setIndex(0)
      setTypingComplete(false)
      setSubmitted(true)
      requestAylaStatement()
    }
  }

  console.log('IndustryResearch')

  return (
    <div>
      <div className="flex items-end justify-between rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3">
        <fieldset>
          <div className="flex flex-col">
            {rankedIndustries.map((industry) => (
              <div key={industry.id}>
                <input
                  className={radioInputClasses}
                  type="radio"
                  id={industry.id}
                  name="selected_industry"
                  value={industry.name}
                  onChange={(e) => setSelectedIndustry(e.target.value)}
                />
                <label htmlFor={industry.id}>{industry.name}</label>
              </div>
            ))}
          </div>
        </fieldset>
        <Button disabled={submitted} onClick={handleButtonClick}>
          {ctaText}
        </Button>
      </div>
      <AylaResponseScrollArea
        showBorder={showBorder}
        showCursor={showCursor}
        aylaResponse={aylaResponse}
        typingComplete={typingComplete}
        setTypingComplete={setTypingComplete}
        displayedText={displayedText}
        setDisplayedText={setDisplayedText}
        index={index}
        setIndex={setIndex}
      />
    </div>
  )
}
export default IndustryResearch
