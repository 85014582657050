import React from 'react'
import AddAdditionalIdeas from './AddAdditionalIdeas'
import DesignThinkingSectionThree from './DesignThinkingSectionThree.js'
import { useExercise } from './ExerciseProvider'
import { Textarea } from '../../lib/ui/textarea'

const DesignThinkingExercise = ({ section, questions, conclusion }) => {
  const {
    handleChange,
    handleDivClick,
    getResponse,
    exerciseIndex,
    trackContent,
  } = useExercise()
  return (
    <>
      {section !== 3 && (
        <div className="questions">
          {questions.map((question, index) => (
            <div key={index} className="mb-6 font-roboto text-base font-medium">
              {question.type === 'checkbox' && (
                <div
                  className="flex items-center rounded-[8px] border border-[#D3D3D3] bg-[#F7FAFC] p-3"
                  onClick={() => handleDivClick(exerciseIndex, question.slug)}
                >
                  <input
                    type="checkbox"
                    className="!mb-1 !mr-2 !mt-0 h-4 w-4 rounded border-gray-300 text-blue-600"
                    onChange={(e) =>
                      handleChange(exerciseIndex, question.slug, e)
                    }
                    checked={getResponse(exerciseIndex, question.slug) || false}
                    onClick={(e) => e.stopPropagation()}
                  />
                  <label className="font-roboto-light !mb-1 text-sm font-medium">
                    {question.question}
                  </label>
                </div>
              )}
              {question.type === 'text' && (
                <div className="flex flex-col">
                  <label className="!mb-1">{question.question}</label>
                  <Textarea
                    className="min-h-[125px] w-full rounded-[20px]"
                    onChange={(e) =>
                      handleChange(exerciseIndex, question.slug, e)
                    }
                    value={getResponse(exerciseIndex, question.slug) || ''}
                  />
                </div>
              )}
            </div>
          ))}
        </div>
      )}
      {section === 2 && <AddAdditionalIdeas />}
      {section === 3 && <DesignThinkingSectionThree data={trackContent} />}
      <div className="mt-6 font-roboto text-lg">{conclusion}</div>
    </>
  )
}

export default DesignThinkingExercise
