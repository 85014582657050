import React, { useState, useEffect } from 'react'
import moment from 'moment'
import { useTranslation } from '~/hooks/useTranslation'
import { Calendar } from '../../coach_services/OutplacementBenefits/Calendar'
import { getApi } from '~/util/api'
import { Card, Typography } from '~/components/_atoms'
import { Stack } from '~/components/_layouts'
import Spinner from '~/components/shared/Spinner'

export function UpcomingWebinarsCard({ className }) {
  const { t } = useTranslation()
  const [isLoading, setIsLoading] = useState(false)
  const [calendarEvents, setCalendarEvents] = useState([])

  useEffect(() => {
    setIsLoading(true)
    getApi()
      .get('/upcoming_webinars')
      .then((response) => {
        if (response.status == 200) {
          setCalendarEvents(response.data)
          return
        } else {
          throw new Error('json request failed')
        }
      })
      .finally(() => {
        setIsLoading(false)
      })
      .catch((error) => {
        console.log('json request failed', error)
      })
  }, [setCalendarEvents])

  return (
    <Card className={className} color="secondary">
      <Stack spacing={3}>
        <Typography variant="title4" color="secondary">
          {t('upcoming_webinars_card.title')}
        </Typography>
        <Typography>{t('upcoming_webinars_card.description')}</Typography>
        {isLoading ? (
          <div className="flex items-center justify-center ">
            <Spinner show size={9} />
          </div>
        ) : (
          <>
            <div className="relative [&&]:hidden @3xl:[&&]:block">
              <Calendar
                calendar_events={calendarEvents}
                homePageDisplay={true}
              />
            </div>
            <div className="@3xl:hidden">
              <Stack spacing={6}>
                {calendarEvents.map((event) => (
                  <div
                    key={`event_${event.id}`}
                    className="w-full rounded-md border border-intoo-green-medium-700 px-4 py-2"
                  >
                    <Typography className="font-medium" color="secondary">
                      {event.webinar_type}
                    </Typography>
                    <Typography variant="small">
                      {moment(event.start_at).format(
                        'dddd, MMMM Do YYYY, h:mm a',
                      )}
                    </Typography>
                  </div>
                ))}
              </Stack>
            </div>
          </>
        )}
      </Stack>
    </Card>
  )
}
