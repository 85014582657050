import React, { useEffect } from 'react'
import { PrimaryExerciseButton, BackExerciseButton } from './Buttons'
import { useIsDesktop, useIsMobile } from '../../hooks/useMediaQuery'
import { useExercise } from './ExerciseProvider'
import { cn } from '../../lib/utils'
import Layout from './Layout'

const Page = ({ pageInfo, numPages, idx }) => {
  const {
    saveExercise,
    setExerciseIndex,
    setStarted,
    exerciseIndex,
    exerciseName,
    sectionIdx,
    setSectionIdx,
    continueButtonDisabled,
    embedExercise,
  } = useExercise()
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [exerciseIndex])

  const handleBackClick = () => {
    if (sectionIdx > 0 && exerciseIndex === 0) {
      setSectionIdx((prev) => prev - 1)
    } else if (exerciseIndex === 0) {
      setStarted(false)
    } else {
      setExerciseIndex((prev) => prev - 1)
    }
  }

  const handleNextClick = () => {
    const showConclusionPage = exerciseIndex === numPages - 1
    if (showConclusionPage) {
      saveExercise(showConclusionPage)
    } else if (
      exerciseName === 'Reason For Leaving Statement' &&
      exerciseIndex === 0
    ) {
      saveExercise()
      setExerciseIndex((prev) => prev + 1)
    } else if (
      exerciseName === 'Chart Your Course' &&
      sectionIdx === 0 &&
      exerciseIndex === 0
    ) {
      setSectionIdx((prev) => prev + 1)
    } else {
      setExerciseIndex((prev) => prev + 1)
    }
  }

  return (
    exerciseIndex === idx && (
      <div className={cn('flex flex-col', !embedExercise ? 'p-10' : '')}>
        <div>
          <Layout pageInfo={pageInfo} section={exerciseIndex} />
        </div>
        {!embedExercise && (
          <div
            className={cn(
              'flex gap-4 self-end sm:gap-6',
              isMobile ? 'self-center' : '',
            )}
          >
            <BackExerciseButton
              onClick={handleBackClick}
              className={isMobile ? 'mb-5' : ''}
            >
              Back
            </BackExerciseButton>
            <div className="flex flex-col items-center gap-[10px]">
              <PrimaryExerciseButton
                onClick={handleNextClick}
                className={isMobile ? 'mb-5' : ''}
                disabled={continueButtonDisabled}
              >
                Continue
              </PrimaryExerciseButton>
              {isDesktop && (
                <div>
                  {exerciseIndex + 1} of {numPages}
                </div>
              )}
            </div>
          </div>
        )}
      </div>
    )
  )
}

export default Page
