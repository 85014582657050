import * as React from 'react'
import * as SliderPrimitive from '@radix-ui/react-slider'
import { cn } from '../utils'
const SliderSteps = ({ name, min, max }) => {
  return (
    <div className="absolute bottom-8 w-full">
      <div className="relative w-full">
        {Array.from({ length: max - min + 1 }, (_, i) => (
          <div
            key={`${name}-step-${i}`}
            className="absolute text-[11px]"
            style={{ left: `${(i / (max - min)) * 100}%` }}
          >
            {min + i}
          </div>
        ))}
      </div>
    </div>
  )
}
const Slider = React.forwardRef(
  (
    { className, dontShowSteps, trackClassName, accessibleLabel, ...props },
    ref,
  ) => (
    <SliderPrimitive.Root
      ref={ref}
      className={cn(
        'relative !mt-8 flex w-full touch-none select-none items-center',
        className,
      )}
      {...props}
    >
      {!dontShowSteps && <SliderSteps {...props} />}
      <SliderPrimitive.Track
        className={cn(
          'relative h-2 w-full grow overflow-hidden rounded-full bg-input-background data-[disabled]:bg-gray-500',
          trackClassName,
        )}
      >
        <SliderPrimitive.Range className="absolute h-full bg-intoo-blue-medium data-[disabled]:bg-gray-500" />
      </SliderPrimitive.Track>
      <SliderPrimitive.Thumb
        className="block h-5 w-5 rounded-full border-4 border-slider bg-intoo-blue-medium ring-offset-background transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-intoo-blue-medium focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 data-[disabled]:bg-gray-500"
        aria-label={accessibleLabel}
      />
    </SliderPrimitive.Root>
  ),
)
Slider.displayName = SliderPrimitive.Root.displayName

export { Slider }
