import React, { useEffect, useState, useContext, createContext } from 'react'
import { Form } from '../../lib/ui/form'
import { useReminderForm } from './useReminderForm'
import { getApi } from '~/util/api'
import { DatePickerField } from '../shared/DatePickerField'
import { TextareaField } from '../surveys/TextareaField'
import { Button } from '../../lib/ui/button'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '../../lib/ui/dialog'

const ReminderDialogContext = createContext()

export const useReminderDialog = () => {
  return useContext(ReminderDialogContext)
}

export const ReminderProvider = ({
  webinarLink,
  candidateId,
  initialText,
  documentId,
  skillName,
  children,
}) => {
  const [showAddReminderDialog, setShowAddReminderDialog] = useState(false)
  const [editingReminder, setEditingReminder] = useState(null)
  const [reminders, setReminders] = useState([])

  const toggleShowAddReminderDialog = () => {
    setEditingReminder(null)
    setShowAddReminderDialog(!showAddReminderDialog)
  }

  const getReminders = () => {
    getApi()
      .get('/reminders')
      .then((response) => {
        if (response.status == 200) {
          setReminders(response.data.incompleteReminders)
          return
        } else {
          throw new Error('json request failed')
        }
      })
      .catch((error) => {
        console.log('json request failed', error)
      })
  }

  useEffect(() => {
    getReminders()
  }, [])

  const value = {
    showAddReminderDialog,
    setShowAddReminderDialog,
    toggleShowAddReminderDialog,
    editingReminder,
    setEditingReminder,
    reminders,
    setReminders,
    candidateId,
    initialText,
    documentId,
    skillName,
    webinarLink,
  }

  return (
    <ReminderDialogContext.Provider value={value}>
      {children}
    </ReminderDialogContext.Provider>
  )
}

export const Standalone = ({ ...props }) => {
  return (
    <ReminderProvider {...props}>
      <StandaloneInner />
    </ReminderProvider>
  )
}

const StandaloneInner = () => {
  const { toggleShowAddReminderDialog } = useReminderDialog()
  return (
    <ReminderDialog>
      <Button
        className="max-w-[15.5rem] font-roboto"
        onClick={toggleShowAddReminderDialog}
      >
        Remind me about this skill
      </Button>
    </ReminderDialog>
  )
}

const ReminderDialog = ({ children }) => {
  const {
    showAddReminderDialog,
    toggleShowAddReminderDialog,
    editingReminder,
  } = useReminderDialog()

  return (
    <Dialog
      open={showAddReminderDialog}
      onOpenChange={toggleShowAddReminderDialog}
    >
      <DialogTrigger asChild className="cursor-pointer">
        {children}
      </DialogTrigger>
      <DialogContent
        className="site-info-dialog max-w-xl font-roboto"
        aria-describedby={undefined}
      >
        <DialogHeader>
          <DialogTitle className="font-roboto-slab text-xl tracking-[0.12px]">
            Set Your Reminder
          </DialogTitle>
        </DialogHeader>
        {!editingReminder && <AddReminderForm />}
        {editingReminder && <EditReminderForm />}
      </DialogContent>
    </Dialog>
  )
}
export default ReminderDialog

const ReminderForm = ({ form, onSubmit, submitted }) => {
  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)} className="mb-4 ml-4 mr-4">
        <DatePickerField
          name="due_date"
          id="reminder_due_date"
          label="Due Date for this reminder:"
          disabled={(date) =>
            date < new Date() || date > new Date('2100-01-01')
          }
          control={form.control}
        />
        <TextareaField
          name="content"
          id="reminder_content"
          className="w-full"
          label="Share your thoughts:"
          control={form.control}
          placeholder="Remember to..."
        />
        <div className="flex w-full flex-row justify-end">
          <Button disabled={submitted} type="submit">
            Save
          </Button>
        </div>
      </form>
    </Form>
  )
}

const AddReminderForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const { candidateId } = useReminderDialog()
  const { form, onSubmit } = useReminderForm({ candidateId, setSubmitted })
  return <ReminderForm form={form} onSubmit={onSubmit} submitted={submitted} />
}

const EditReminderForm = () => {
  const [submitted, setSubmitted] = useState(false)
  const { candidateId, editingReminder } = useReminderDialog()
  const { form, onSubmit } = useReminderForm({
    candidateId: candidateId,
    reminder: editingReminder,
    setSubmitted,
  })
  return <ReminderForm form={form} onSubmit={onSubmit} submitted={submitted} />
}
