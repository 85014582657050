import React from 'react'
import useTranslation from '../../shared/TranslationProvider'
import { useExercise } from '../ExerciseProvider'

const ChartYourCourseSummaryPage = ({ conclusionP1, conclusionP2 }) => {
  const { t } = useTranslation('chart_your_course')
  const content = t('conclusion_content')

  return (
    <div className="flex flex-col gap-4">
      <p className="!mb-6 font-roboto text-base font-normal">{conclusionP1}</p>
      <div className="flex flex-col gap-2">
        {content.map((step, idx) => (
          <NextStep key={`step-${step}-${idx}`} step={step} />
        ))}
      </div>
      <p className="!my-6 font-roboto text-base font-normal">{conclusionP2}</p>
      <TopValues t={t} />
      <CareerPathOptions t={t} />
      <Reflections t={t} />
    </div>
  )
}

export default ChartYourCourseSummaryPage

const NextStep = ({ step }) => {
  const { section, description } = step
  return (
    <div>
      <div className="font-bold">
        {section}: <span className="font-normal">{description}</span>
      </div>
    </div>
  )
}

const TopValues = ({ t }) => {
  const { getResponse } = useExercise()
  const selectedValues = getResponse(0, 'top_values')
  return (
    <div>
      <div className="subtitle">{t('top_values')}</div>
      <ul>
        {selectedValues.map((value) => (
          <li className="capitalize" key={value}>
            {value}
          </li>
        ))}
      </ul>
    </div>
  )
}

const CareerPathOptions = ({ t }) => {
  const { getResponse } = useExercise()
  const selectedCareers = getResponse(1, 'top_careers')
  return (
    <div>
      <div className="subtitle">{t('career_path_options')}</div>
      <ul>
        {selectedCareers.map((career) => (
          <li key={career}>{career}</li>
        ))}
      </ul>
    </div>
  )
}

const Reflections = ({ t }) => {
  const { getResponse } = useExercise()
  const selectedCareers = getResponse(1, 'top_careers')
  const valueQuestions = t('pages.0.section_info')[0].questions
  const showValueReflectionQuestions = valueQuestions.some(
    (question) => getResponse(0, question.slug)?.length > 0,
  )
  const showRelevantExperienceReflectionQuestions = selectedCareers.some(
    (career) =>
      getResponse(2, career.toLowerCase().replace(/[^a-z0-9]+/g, '-'))?.length >
      0,
  )
  const showReflections =
    showValueReflectionQuestions || showRelevantExperienceReflectionQuestions
  return (
    showReflections && (
      <div>
        <div className="subtitle">{t('reflections')}</div>
        <div className="flex flex-col gap-2">
          {valueQuestions.map((questionInfo) => (
            <ValueReflectionQuestion
              key={questionInfo.slug}
              questionInfo={questionInfo}
            />
          ))}
          {selectedCareers.map((career, idx) => (
            <RelevantExperienceReflectionQuestion
              key={`summary-page-reflection-${idx}`}
              career={career}
              t={t}
            />
          ))}
        </div>
      </div>
    )
  )
}

const ValueReflectionQuestion = ({ questionInfo }) => {
  const { getResponse } = useExercise()
  const { question, slug } = questionInfo
  const response = getResponse(0, slug)
  return (
    response && (
      <div>
        <div className="font-bold">{question}</div>
        <div className="italic">{response}</div>
      </div>
    )
  )
}

const RelevantExperienceReflectionQuestion = ({ t, career }) => {
  const { getResponse } = useExercise()

  const slug = career
    .toLowerCase()
    .replace(/[^a-z0-9]+/g, '-')
    .replace(/(^-|-$)+/g, '')
  const response = getResponse(2, slug)

  return (
    response && (
      <div>
        <div className="font-bold">
          {career}: {t('pages.2.relevant_skills')}
        </div>
        <div className="italic">{response}</div>
      </div>
    )
  )
}
