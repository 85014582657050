import React from 'react'
import Results, { LinkifiedCopy } from './Results'
import Avatar from '.././Avatar'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { useMultipleChoiceQuizScore } from '../../JobSearchQuiz/useScoreCalculator'
import { useQuiz } from '.././QuizProvider'

const PersonalBrandQuizResults = () => {
  const { formattedQuizName, resultsPageAvatar, trackContent, quizName } =
    useQuiz()
  const avatar = resultsPageAvatar
  const isDesktop = useIsDesktop()
  const { t } = useTranslation(formattedQuizName)
  const link1 = 'Building a Personal Brand in the Digital Age'
  const link2 = 'Personal Branding in the Digital Age'

  const linkMap = {
    1: link1,
    2: link1,
    3: link2,
    4: link1,
  }

  const breakPoints = [7, 4, 2]
  const [scoreSection, score] = useMultipleChoiceQuizScore(
    trackContent,
    t('questions'),
    breakPoints,
  )

  const header = t(`results.${scoreSection}.header`)
  const copy = t(`results.${scoreSection}.copy`, { score })

  const linkText = linkMap[scoreSection]

  const link = {
    linkPath: '/personal_brand/resources/article/building-personal-brand',
    linkText: linkText,
  }

  return (
    quizName === 'Personal Brand Iq' && (
      <Results>
        <div className="flex pb-6 sm:justify-around">
          <div className="mt-1 flex h-96 flex-col gap-4 sm:w-9/12">
            {scoreSection !== 4 && <h1 className="!text-[23px]">{header}</h1>}
            <h2>{t(`results.score`, { score })}</h2>
            <div className="mt-4">
              {copy.map((copy, idx) => (
                <LinkifiedCopy key={`copy-${idx}`} copy={copy} link={link} />
              ))}
              <p>{t(`results.congrats`)}</p>
            </div>
          </div>
          {isDesktop && <Avatar src={avatar} />}
        </div>
      </Results>
    )
  )
}

export default PersonalBrandQuizResults
