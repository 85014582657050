import React from 'react'
import Box from '@mui/material/Box'
import { Input } from '../../../lib/ui/input'
import { Textarea } from '../../../lib/ui/textarea'
import { Button } from '../../../lib/ui/button'
import { cn } from '../../../lib/utils'

export default function NewThankYouNoteForm({
  targetJobTitle,
  setTargetJobTitle,
  targetCompany,
  setTargetCompany,
  interviewFormat,
  setInterviewFormat,
  interviewersRole,
  setInterviewersRole,
  keyTopics,
  setKeyTopics,
  takeaways,
  setTakeaways,
  nextSteps,
  setNextSteps,
  handleSubmitButtonClick,
  submitButtonDisabled,
  tonePresent,
  thankYouNoteTone,
  setThankYouNoteTone,
  t,
}) {
  return (
    <>
      {!tonePresent && <p className="mt-4 md:w-3/4">{t.subtitle}</p>}
      {tonePresent && (
        <p className="mt-4 px-8 md:w-3/4">{t.regenerate_subtitle}</p>
      )}

      <Box
        marginY={tonePresent && 2}
        className={cn('!my-0 flex flex-col gap-4', tonePresent && 'px-8')}
      >
        <div>
          <label className="required-text !mb-1" htmlFor="target_job_title">
            {t.target_job_title_label}
          </label>
          <Input
            placeholder={t.target_job_title_placeholder}
            name="target_job_title"
            defaultValue={targetJobTitle}
            onChange={(event) => setTargetJobTitle(event.target.value)}
          />
        </div>
        <div>
          <label className="required-text !mb-1" htmlFor="target_company">
            {t.target_company_label}
          </label>
          <Input
            label={t.target_company_label}
            placeholder={t.target_company_placeholder}
            name="target_company"
            defaultValue={targetCompany}
            onChange={(event) => setTargetCompany(event.target.value)}
          />
        </div>
        <div>
          <label className="!mb-1" htmlFor="interview_format">
            {t.interview_format_label}
          </label>
          <Textarea
            label={t.interview_format_label}
            name="interview_format"
            defaultValue={interviewFormat}
            onChange={(event) => setInterviewFormat(event.target.value)}
          />
        </div>
        <div>
          <label className="!mb-1" htmlFor="interviewers_role">
            {t.interviewers_role_label}
          </label>
          <Textarea
            label={t.interviewers_role_label}
            name="interviewers_role"
            defaultValue={interviewersRole}
            onChange={(event) => setInterviewersRole(event.target.value)}
          />
        </div>
        <div>
          <label className="!mb-1" htmlFor="key_topics">
            {t.key_topics_label}
          </label>
          <Textarea
            label={t.key_topics_label}
            name="key_topics"
            defaultValue={keyTopics}
            onChange={(event) => setKeyTopics(event.target.value)}
          />
        </div>
        <div>
          <label className="!mb-1" htmlFor="takeaways">
            {t.takeaways_label}
          </label>
          <Textarea
            label={t.takeaways_label}
            name="takeaways"
            defaultValue={takeaways}
            onChange={(event) => setTakeaways(event.target.value)}
          />
        </div>
        <div>
          <label className="!mb-1" htmlFor="next_steps">
            {t.next_steps_label}
          </label>
          <Textarea
            label={t.next_steps_label}
            name="next_steps"
            defaultValue={nextSteps}
            onChange={(event) => setNextSteps(event.target.value)}
          />
        </div>
        {tonePresent && (
          <>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                padding: '0.5rem',
                paddingTop: '2rem',
                gap: '1rem',
                fontFamily: 'roboto',
              }}
            >
              <label
                htmlFor="cover-letter-tone-slider"
                style={{ marginBottom: '2rem' }}
              >
                {t.select_tone}
              </label>
              <Input
                type="range"
                min="0"
                max="99"
                value={thankYouNoteTone}
                onChange={(event) => setThankYouNoteTone(event.target.value)}
                step={33}
                list="tickmarks"
                id="cover-letter-tone-slider"
                name="thank-you-note-tone"
                className="w-full md:w-1/2"
              />
              <datalist id="tickmarks" className="relative !font-roboto">
                <option
                  className="relative block"
                  value="0"
                  label={t.tone_informal}
                />
                <option
                  value="33"
                  className="relative hidden pl-[40px] md:!visible md:!block"
                  label={t.tone_neutral}
                />
                <option
                  value="66"
                  className="relative hidden pl-[40px] md:!visible md:!block"
                  label={t.tone_business_formal}
                />
                <option
                  className="relative block pl-[40px]"
                  value="99"
                  label={t.tone_very_formal}
                />
              </datalist>

              <div>
                {thankYouNoteTone < 33 && t.tone_informal}
                {thankYouNoteTone >= 33 &&
                  thankYouNoteTone < 66 &&
                  t.tone_neutral}
                {thankYouNoteTone >= 66 &&
                  thankYouNoteTone < 99 &&
                  t.tone_business_formal}
                {thankYouNoteTone >= 99 && t.tone_very_formal}
              </div>
            </Box>
          </>
        )}
      </Box>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: tonePresent ? 'center' : 'flex-start',
          padding: '0.5rem',
          gap: '1rem',
          marginTop: '1rem',
        }}
      >
        <Button
          onClick={handleSubmitButtonClick}
          disabled={submitButtonDisabled}
        >
          {tonePresent ? t.create_new_button_text : t.submit_button_text}
        </Button>
      </Box>
    </>
  )
}
