import React, { useState } from 'react'
import Progress from '../Progress'
import { PrimaryQuizButton, BackQuizButton } from '../Buttons'
import { useQuiz } from '../QuizProvider'
import { cn } from '../../../lib/utils'

const Results = ({ className, children, isScrollable }) => {
  const { retakeQuiz } = useQuiz()
  const [disabledButton, setDisabledButton] = useState(false)

  const handleNextClick = () => {
    setDisabledButton(true)
    const path = window.location.pathname.split('/')[1]
    const newUrl = `/${path}/resources`
    window.location.replace(newUrl)
  }

  const outerDivClassName = isScrollable
    ? cn('container mt-6 flex min-h-screen flex-col', className)
    : cn('container mt-6 flex flex-col', className)

  const innerDiv = isScrollable ? (
    <div className="flex flex-grow flex-col overflow-y-auto pb-6 sm:justify-evenly">
      {children}
    </div>
  ) : (
    <div className="flex-grow">{children}</div>
  )

  return (
    <div className={outerDivClassName}>
      <Progress completed />
      {innerDiv}
      <div className="mt-auto flex gap-4 self-end sm:gap-6">
        <BackQuizButton onClick={retakeQuiz}>Retake Quiz</BackQuizButton>
        <div className="flex flex-col items-center gap-2">
          <PrimaryQuizButton
            onClick={handleNextClick}
            disabled={disabledButton}
            className="!w-auto"
          >
            Return to Resource Center
          </PrimaryQuizButton>
        </div>
      </div>
    </div>
  )
}

export default Results

export const LinkifiedCopy = ({ copy, link }) => {
  const copySplit = copy.split('[link]')
  const { linkPath, linkText } = link
  const copy1 = copySplit[0]
  const copy2 = copySplit[1]
  return (
    <p>
      {copy1}
      {copy2 && (
        <a className="!underline" href={linkPath}>
          {linkText}
        </a>
      )}
      {copy2}
    </p>
  )
}
