import * as React from 'react'
import { useEffect } from 'react'
import { createSearchParams, useSearchParams } from 'react-router-dom'

function useSearchParamsWithUpdate(defaultInit) {
  const [searchParams, setSearchParams] = useSearchParams(defaultInit)
  const defaultSearchParams = createSearchParams(defaultInit)

  useEffect(() => {
    const update = {}
    let needUpdate = false

    defaultSearchParams.forEach((v, k) => {
      if (!searchParams.has(k)) {
        update[k] = v
        needUpdate = true
      }
    })

    if (needUpdate) setSearchParams(update, { replace: true })
  }, [searchParams, setSearchParams])

  return [searchParams, setSearchParams]
}

export function useCompanySearchParams(location) {
  const [urlSearchParams, setUrlSearchParams] = useSearchParamsWithUpdate({
    where: location,
  })

  urlSearchParams.sort()

  const [paramString, setParamString] = React.useState(
    urlSearchParams.toString(),
  )

  const lockParamString = React.useCallback(() => {
    setParamString(urlSearchParams.toString())
    setUrlSearchParams(urlSearchParams, { replace: true })
  }, [setUrlSearchParams, urlSearchParams])

  return {
    urlSearchParams,
    setUrlSearchParams,
    paramString,
    lockParamString,
  }
}
