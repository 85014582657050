import React from 'react'
import Results from '../QuizResults/Results'
import { useQuiz } from '../QuizProvider'
import useTranslation from '../../shared/TranslationProvider'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import Avatar from '../Avatar'

const DiscoverYourLearningStyleResults = () => {
  const { t } = useTranslation('discover_your_learning_style')
  const { trackContent, resultsPageAvatar } = useQuiz()

  const trackContentValues = Object.values(trackContent)

  const visualSCore = trackContentValues.filter(
    (item) => item.type === 'visual',
  ).length
  const auditoryScore = trackContentValues.filter(
    (item) => item.type === 'auditory',
  ).length
  const kinestheticScore = trackContentValues.filter(
    (item) => item.type === 'kinesthetic',
  ).length
  const socialScore = trackContentValues.filter(
    (item) => item.type === 'social',
  ).length

  const analyticScore = trackContentValues.filter(
    (item) => item.type === 'analytic',
  ).length

  const solitaryScore = trackContentValues.filter(
    (item) => item.type === 'solitary',
  ).length

  const multimodalScore = trackContentValues.filter(
    (item) => item.type === 'multimodal',
  ).length

  const verbalScore = trackContentValues.filter(
    (item) => item.type === 'verbal',
  ).length

  const majorLearningStyles = [
    { name: 'visual', score: visualSCore },
    { name: 'auditory', score: auditoryScore },
    { name: 'kinesthetic', score: kinestheticScore },
    { name: 'social', score: socialScore },
    { name: 'analytic', score: analyticScore },
  ].filter((ele) => ele['score'] > 0)

  const additionalModes = [
    { name: 'solitary', score: solitaryScore },
    { name: 'multimodal', score: multimodalScore },
    { name: 'verbal', score: verbalScore },
  ]

  const sortedLearningStyles = majorLearningStyles.sort(
    (a, b) => b['score'] - a['score'],
  )

  const additionalLearningStyles = additionalModes.filter(
    (ele) => ele['score'] > 0,
  )

  const isDesktop = useIsDesktop()

  const coachTipStyles = sortedLearningStyles
    .concat(additionalLearningStyles)
    .filter((style) => style.name !== 'multimodal')

  const pluralStyle = coachTipStyles.length > 1 ? 'styles' : 'style'
  const pluralLearningStyles =
    sortedLearningStyles.length > 1 ? 'styles' : 'style'

  return (
    <Results>
      <div className="mb-8 flex pb-6 sm:justify-around">
        <div className="mt-1 flex flex-col gap-4 sm:w-9/12">
          <div className="flex flex-col gap-2">
            <p>{t('results.copy.great_job')}</p>
            <p>{t('results.copy.learning_styles', { pluralLearningStyles })}</p>
            <ul className="pl-4">
              {sortedLearningStyles.map((style, idx) => (
                <li className="list-disc" key={`style-${idx}`}>
                  {t(`results.copy.${style.name}`)['name']}
                </li>
              ))}
            </ul>
            {additionalLearningStyles.length > 0 && (
              <>
                {additionalLearningStyles.map((style, idx) => (
                  <p key={`additional-style-${idx}`}>
                    {t(`results.copy.${style.name}.text`)}
                  </p>
                ))}
              </>
            )}
            <p>{t(`results.copy.coach_tips_all.intro`, { pluralStyle })}</p>
            {coachTipStyles.map((style, idx) => (
              <CoachTipSection
                key={`coach-tip-${idx}`}
                style={style.name}
                t={t}
              />
            ))}
          </div>
        </div>
        {isDesktop && <Avatar src={resultsPageAvatar} />}
      </div>
    </Results>
  )
}

export default DiscoverYourLearningStyleResults

const CoachTipSection = ({ style, t }) => {
  const sectionInfo = t(`results.copy.${style}`)
  const { coach_tips, name } = sectionInfo
  const sectionTitle = t('results.copy.coach_tips_all.title', {
    style: name,
  })
  return (
    <div className="flex flex-col gap-2">
      <div className="subtitle">{sectionTitle}</div>
      <div>
        {coach_tips.map((tip, idx) => (
          <p key={`coach-tip-${idx}`}>
            {tip.bold && (
              <>
                <span className="font-bold">{tip.bold}</span>
                {': '}
              </>
            )}
            {tip.text}
          </p>
        ))}
      </div>
    </div>
  )
}
