import React, { useState, useEffect } from 'react'
import ThankYouNoteProvider, { useThankYouNote } from './ThankYouNoteProvider'
import {
  TemplateProvider,
  useTemplate,
  initializeTemplates,
} from '../../lib/pdf'
import { Textarea } from '../../lib/ui/textarea'
import { Button } from '../../lib/ui/button'
import { Toaster } from '../../lib/ui/toast'
import {
  TabNav,
  TabPanel,
  PanelContainer,
  ButtonContainer,
  TabButton,
} from '~/components/shared/TabNav'
import {
  IdentificationIcon,
  ClipboardDocumentCheckIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline'
import Editor from './Editor/index'
import PdfViewerPanel from './PdfViewerPanel'
import StyleEditor from './StyleEditor'
import * as T0 from './Template0'

export const templateMap = {
  0: 'Email',
}

// These need to be declared outside of a component so they can't affect rendering
const [templates, templateStyle, templateSupportedStyles] = initializeTemplates(
  [T0],
)

const ThankYouNoteBuilder = ({
  template_settings,
  template_index,
  ...props
}) => {
  const defaultStyle = template_settings
    ? {
        ...templateStyle,
        [`template-${template_index}`]: template_settings,
      }
    : templateStyle
  const templateProps = {
    templates,
    defaultStyle,
    templateSupportedStyles,
    template_index,
    dev_mode: props.dev_mode,
  }
  return (
    <TemplateProvider {...templateProps}>
      <ThankYouNoteProvider {...props}>
        <ThankYouNoteBuilderInner />
      </ThankYouNoteProvider>
    </TemplateProvider>
  )
}
export default ThankYouNoteBuilder

const ThankYouNoteBuilderInner = () => {
  const { devMode } = useThankYouNote()
  const { templateIndex, setTemplateIndex } = useTemplate()
  const defaultActiveTab = 'details'

  return (
    <div className="flex w-full flex-col md:flex-row">
      <div className="md:basis-3/5">
        <TabNav
          className="px-4 pt-4 lg:px-8 xl:px-12"
          defaultActiveTab={defaultActiveTab}
        >
          <ButtonContainer>
            <TabButton
              name="details"
              text="Details"
              Icon={IdentificationIcon}
            />
            <TabButton
              name="formattingAndDesign"
              text="Formatting & Design"
              Icon={ClipboardDocumentCheckIcon}
            />
            {devMode && (
              <TabButton name="text" text="Raw JSON" Icon={DocumentTextIcon} />
            )}
          </ButtonContainer>
          <PanelContainer className="md:min-h-[130vh]">
            <TabPanel name="chooseTemplate" className="bg-[#E0F2F9] pt-8">
              <SelectTemplatePanel
                templateIndex={templateIndex}
                setTemplateIndex={setTemplateIndex}
              />
            </TabPanel>
            <TabPanel name="details" className="pt-8">
              <Editor templateIndex={templateIndex} />
            </TabPanel>
            <TabPanel name="formattingAndDesign" className="pt-8">
              <StyleEditor />
            </TabPanel>
            <TabPanel name="text" className="pt-8">
              <TextEditor />
            </TabPanel>
          </PanelContainer>
        </TabNav>
      </div>
      <div id="resume-pdf-preview" className="relative md:flex-grow">
        <div className="h-screen w-full md:sticky md:left-0 md:top-0">
          <PdfViewerPanel />
        </div>
      </div>
      <Toaster />
    </div>
  )
}

const SelectTemplatePanel = ({ templateIndex, setTemplateIndex }) => {
  const templateIndices = [0]
  const activeClasses = 'font-roboto-slab text-primary font-bold pb-2'
  const inactiveClasses = 'font-roboto-slab text-primary pb-2'

  return (
    <div className="grid h-full sm:grid-cols-2 lg:grid-cols-3">
      {templateIndices.map((index) => (
        <div className="flex flex-col items-center" key={`template-${index}`}>
          <span
            className={templateIndex == index ? activeClasses : inactiveClasses}
          >
            {templateMap[index]}
          </span>
          <button
            onClick={() => setTemplateIndex(index)}
            disabled={templateIndex === index}
            className={
              templateIndex === index
                ? 'rounded-xl border-4 border-solid border-primary'
                : 'm-1 rounded-xl'
            }
          >
            <TemplatePreview
              className="w-48 rounded-xl"
              templateIndex={index}
            />
          </button>
        </div>
      ))}
    </div>
  )
}

const TemplatePreview = ({ className, templateIndex }) => {
  const url = `https://www-yns-com.s3.amazonaws.com/resume_builder/ThankYouNoteTemplate${templateIndex}.png`
  return (
    <img className={className} src={url} alt={`Template ${templateIndex}`} />
  )
}

const TextEditor = () => {
  const { thankYouNoteDocument, setThankYouNoteDocument } = useThankYouNote()
  const [editorThankYouNote, setEditorThankYouNote] = useState(
    JSON.stringify(thankYouNoteDocument, null, 2),
  )

  const sync = () => {
    const updatedThankYouNote = JSON.parse(editorThankYouNote)
    setThankYouNoteDocument(updatedThankYouNote)
  }

  useEffect(() => {
    setEditorThankYouNote(JSON.stringify(thankYouNoteDocument, null, 2))
  }, [thankYouNoteDocument])

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-row justify-evenly">
        <Button onClick={sync}>Sync</Button>
      </div>
      <Textarea
        className="h-full w-full"
        value={editorThankYouNote}
        onChange={(e) => setEditorThankYouNote(e.target.value)}
      ></Textarea>
    </div>
  )
}
