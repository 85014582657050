import React, { createContext, useContext, useEffect, useState } from 'react'
import useTranslation from '../../shared/TranslationProvider'
import { useQuiz } from '../QuizProvider'

const LikertLayoutContext = createContext()

const useLikertLayout = () => useContext(LikertLayoutContext)

export default useLikertLayout

export const LikertLayoutProvider = ({
  children,
  translationKey,
  sectionQuestions,
  numQuestions,
}) => {
  const { t } = useTranslation(translationKey)
  const sections = t('sections', { returnObjects: true })
  const { quizIndex, trackContent, setTrackContent } = useQuiz()
  const [showQuizOverview, setShowQuizOverview] = useState(quizIndex === 0)
  const [score, setScore] = useState(trackContent?.score || {})
  const lastSection = Object.keys(sectionQuestions).find((section) =>
    sectionQuestions[section].includes(quizIndex + 1),
  )

  const initSectionIndex = lastSection ? parseInt(lastSection) : 0
  const [sectionIndex, setSectionIndex] = useState(initSectionIndex)
  const numQuestionsInSection = sectionQuestions[sectionIndex].length
  const [showSectionOverview, setShowSectionOverview] = useState(
    quizIndex === 0 || (quizIndex + 1) % numQuestionsInSection === 0,
  )

  useEffect(() => {
    setSectionIndex(initSectionIndex)
  }, [initSectionIndex])

  const sectionInfo = sections[sectionIndex]
  const numSections = sections.length
  const questions = sectionInfo.questions
  const scaleOptions = sectionInfo.options
  const likert = sectionInfo.type === 'likert'

  const openOverview = () => setShowSectionOverview(true)
  const hideOverview = () => setShowSectionOverview(false)

  const handleOptionClick = (choice, numPoints) => {
    trackContent[quizIndex + 1] = choice + 1
    score[quizIndex + 1] = numPoints
    setScore({ ...score })
    trackContent['score'] = score
    setTrackContent({ ...trackContent })
  }

  const value = {
    sectionInfo,
    setSectionIndex,
    sectionQuestions,
    sectionIndex,
    questions,
    scaleOptions,
    numSections,
    likert,
    numQuestions,
    numQuestionsInSection,
    score,
    setScore,
    handleOptionClick,
    showSectionOverview,
    openOverview,
    hideOverview,
    showQuizOverview,
    setShowQuizOverview,
    translationKey,
  }

  return (
    <LikertLayoutContext.Provider value={value}>
      {children}
    </LikertLayoutContext.Provider>
  )
}
