import React from 'react'
import { ArticleCards } from './ArticleCard'
import { AudioCards } from './AudioCard'
import { VideoCards } from './VideoCard'
import { ExerciseCards } from './ExerciseCard'
import { QuizCards } from './QuizCard'
import { WorkshopCards } from './WorkshopCard'
import { ScriptCards } from './ScriptCard'
import { ChecklistCards } from './ChecklistCard'
import FavoritesCards from './FavoritesCards'
import { TabPanel, PanelContainer, useTabNav } from '~/components/shared/TabNav'

const TabNavPanelContainer = () => {
  const { activeTab } = useTabNav()
  const scriptsActive = activeTab === 'scripts'

  return (
    <PanelContainer asChild>
      <div
        className={`grid grid-cols-1 ${
          scriptsActive ? 'gap-x-10' : 'gap-x-8'
        } mt-4 gap-y-10 md:mt-0 md:grid-cols-2 lg:grid-cols-3`}
      >
        <TabPanel name="articles" asChild>
          <ArticleCards />
        </TabPanel>
        <TabPanel name="audio" asChild>
          <AudioCards />
          <VideoCards />
        </TabPanel>
        <TabPanel name="exercises" asChild>
          <ExerciseCards />
        </TabPanel>
        <TabPanel name="quizzes" asChild>
          <QuizCards />
        </TabPanel>
        <TabPanel name="short_courses" asChild>
          <WorkshopCards />
        </TabPanel>
        <TabPanel name="scripts" asChild>
          <ScriptCards />
        </TabPanel>
        <TabPanel name="checklists" asChild>
          <ChecklistCards />
        </TabPanel>
        <TabPanel name="favorites" asChild>
          <FavoritesCards />
        </TabPanel>
        <div className="min-w-[472px] overflow-hidden"></div>
      </div>
    </PanelContainer>
  )
}
export default TabNavPanelContainer
