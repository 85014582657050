import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
} from 'react'
import { useSearchQuery } from '../../hooks/useSearchQuery'
const SearchContext = createContext()

export function useSearch() {
  return useContext(SearchContext)
}

export function SearchProvider({
  total_results,
  allow_debug,
  location,
  children,
  total_pages,
  next_page,
  queryKey,
  search_results,
}) {
  const [selectedItem, setSelectedItem] = useState(null)
  const [selectedItemDrawerOpen, setSelectedItemDrawerOpen] = useState(false)

  const [formDisabled, setFormDisabled] = useState(false)

  const [viewMoreButtonDisabled, setViewMoreButtonDisabled] = useState(false)

  const [previousFirstItemId, setPreviousFirstItemId] = useState(null)

  const {
    queryStatusMessage,
    pagesToShow,
    setPagesToShow,
    setPrefetchedPages,
    results,
    amountShown,
    amountTotal,
    fetchNextPage,
    isFetching,
    isFetchingNextPage,
    isPending,
    showViewMoreButton,
  } = useSearchQuery({
    queryKey,
    initialData: {
      pageParams: [1],
      pages: [
        {
          results: search_results,
          total_results: total_results,
          total_pages: total_pages,
          next_page: next_page,
        },
      ],
    },
  })

  const resultDetailScrollRef = useRef(null)

  const handleSelectItem = (item) => (e) => {
    e.stopPropagation()
    resultDetailScrollRef.current?.scrollTo(0, 0, {
      behavior: 'smooth',
    })
    setSelectedItem(null)
    setSelectedItem(item)
    setSelectedItemDrawerOpen(true)
  }

  useEffect(() => {
    if (isFetching && pagesToShow === 1 && !isFetchingNextPage) {
      setFormDisabled(true)
    } else {
      setFormDisabled(false)
    }
  }, [pagesToShow, isFetching, isFetchingNextPage, isPending])

  const firstItem = results[0]

  useEffect(() => {
    if (firstItem && firstItem.id !== previousFirstItemId) {
      setPreviousFirstItemId(firstItem.id)
      setSelectedItem(firstItem)
    }
  }, [firstItem, previousFirstItemId])

  const handleViewMore = async () => {
    setViewMoreButtonDisabled(true)
    setPagesToShow((prev) => prev + 1)
    await fetchNextPage()
    setViewMoreButtonDisabled(false)
  }

  const value = {
    selectedItemDrawerOpen,
    total_results,
    allow_debug,
    location,
    resultDetailScrollRef,
    formDisabled,
    selectedItem,
    handleSelectItem,
    queryStatusMessage,
    amountShown,
    amountTotal,
    viewMoreButtonDisabled,
    handleViewMore,
    showViewMoreButton,
    setPrefetchedPages,
    results,
    setPagesToShow,
  }

  return (
    <SearchContext.Provider value={value}>{children}</SearchContext.Provider>
  )
}
