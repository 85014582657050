import React, { useEffect, useState } from 'react'
import { BackQuizButton, PrimaryQuizButton } from '../Buttons.js'
import { useIsDesktop, useIsMobile } from '../../../hooks/useMediaQuery.js'
import { useQuiz } from '../QuizProvider.js'
import Layout from './Layout.js'
import useTranslation from '../../shared/TranslationProvider.js'
import Response from '../Response.js'
import useDiscoverYourLearningStyle from './DiscoverYourLearningStyleProvider.js'

const Question = ({ questionInfo, numQuestions, idx }) => {
  const { saveQuiz, setQuizIndex, quizIndex, setStarted, trackContent } =
    useQuiz()

  const { t } = useTranslation('discover_your_learning_style')
  const currentTrackContent = trackContent[quizIndex + 1]
  const hasResponse =
    currentTrackContent !== undefined && currentTrackContent !== null

  const [response, setResponse] = useState(
    hasResponse
      ? t(`questions.${idx + 1}.feedback.response`, {
          type: currentTrackContent?.type,
        })
      : null,
  )

  const question = questionInfo.question
  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()

  const { handleOptionClick } = useDiscoverYourLearningStyle()

  const options = questionInfo.options

  const updateResponse = (choice, type) => {
    if (choice !== null && choice !== undefined) {
      setResponse(
        t(`questions.${idx + 1}.feedback.response`, {
          type,
        }),
      )
    }
    handleOptionClick(choice, type)
  }

  const handleBackClick = () => {
    if (idx === 0) {
      setStarted(false)
    } else setQuizIndex((prev) => prev - 1)
  }

  const handleNextClick = () => {
    const showResultsPage = quizIndex === numQuestions - 1
    if (showResultsPage) {
      saveQuiz(showResultsPage)
    } else {
      saveQuiz(false, false)
      setQuizIndex((prev) => prev + 1)
    }
  }

  useEffect(() => {
    if (isMobile) {
      window.scrollTo(0, document.body.scrollHeight)
    } else {
      window.scrollTo(0, 0)
    }
  }, [isMobile])

  const layoutProps = { options, questionInfo, updateResponse }

  const showQuestion = quizIndex === idx

  return (
    showQuestion && (
      <div className="flex min-h-[40rem] flex-col justify-between sm:mr-24 sm:pl-28">
        <div>
          <h1 className="text-2xl sm:!my-8">{question}</h1>
          <Layout {...layoutProps}>
            <Image questionInfo={questionInfo} />
          </Layout>

          <div className="min-h-32">
            {hasResponse && (
              <Response
                response={response}
                showResponse={hasResponse}
                responseType={0}
                className="w-10 md:w-2/5"
                showSourceFor={questionInfo.feedback?.show_source_for}
                alreadyTransitioned={
                  trackContent[quizIndex + 1] === 'a' ||
                  trackContent[quizIndex + 1] === 'b' ||
                  trackContent[quizIndex + 1] === 'c' ||
                  trackContent[quizIndex + 1] === 'd' ||
                  trackContent[quizIndex + 1] === 'e'
                }
                showCoachTip={questionInfo.feedback?.coach_tip}
              />
            )}
          </div>
        </div>

        <div className="mt-8 flex gap-4 self-end sm:gap-6">
          <BackQuizButton onClick={handleBackClick}>
            {t('overview.back_button')}
          </BackQuizButton>
          <div className="flex flex-col items-center gap-[10px]">
            <PrimaryQuizButton
              onClick={handleNextClick}
              disabled={!hasResponse}
            >
              {t('overview.continue_button')}
            </PrimaryQuizButton>
            {isDesktop && (
              <div>
                {t('overview.progress', {
                  questionNumber: quizIndex + 1,
                  totalQuestions: numQuestions,
                })}
              </div>
            )}
          </div>
        </div>
      </div>
    )
  )
}
export default Question

const Image = ({ questionInfo }) => {
  const image = questionInfo.image
  const isDesktop = useIsDesktop()
  const { bucketPath } = useQuiz()
  if (!image || !isDesktop) return null
  return (
    <div>
      <img src={bucketPath + image.src} alt={image.alt} />
    </div>
  )
}
