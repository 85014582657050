import React, { useState } from 'react'
import { useNetworkingBrief } from '../NetworkingBriefProvider'
import { EditorHeading, newKey } from '../../ResumeBuilder/Editor/common'
import { Button } from '../../../lib/ui/button'
import { PlusIcon, TrashIcon } from '@heroicons/react/24/outline'
import { EditorItemProvider, EditorItemField } from './EditorItemProvider'

const KeySkills = () => {
  const { networkingBriefDocument, setNetworkingBriefDocument } =
    useNetworkingBrief()
  const skills = networkingBriefDocument.skills || []
  const [activeIndex, setActiveIndex] = useState(-1)

  const addSkill = () => {
    const updatedSkills = [...skills, {}]
    setActiveIndex(updatedSkills.length - 1)
    setNetworkingBriefDocument({
      ...networkingBriefDocument,
      skills: updatedSkills,
    })
  }

  const removeSkill = (index) => {
    const updatedSkills = skills.filter((_, i) => i !== index)
    setNetworkingBriefDocument({
      ...networkingBriefDocument,
      skills: updatedSkills,
    })
  }

  return (
    <div className="flex flex-col">
      <EditorHeading>Key Skills and Expertise</EditorHeading>
      <div className="pb-4 tracking-[0.024px]">
        What are your primary skills and areas of expertise? List your core
        competencies.
      </div>
      {skills.map((skill, skillIndex) => {
        skill.key = skill.key || newKey()
        return (
          <div key={skill.key}>
            <EditorItemProvider
              sectionName="skills"
              index={skillIndex}
              activeIndex={activeIndex}
              setActiveIndex={setActiveIndex}
              item={skill}
            >
              <div className="mb-4 flex flex-row items-center">
                <EditorItemField
                  fieldName="skill"
                  containerClassName="flex items-center w-1/2"
                />
                <TrashIcon
                  className="ml-2 w-8 cursor-pointer"
                  onClick={() => removeSkill(skillIndex)}
                />
              </div>
            </EditorItemProvider>
          </div>
        )
      })}
      <Button onClick={addSkill} className="mt-4 max-w-min">
        Add Skill
        <PlusIcon className="ml-2 w-5" />
      </Button>
    </div>
  )
}
export default KeySkills
