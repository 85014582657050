import React, { useEffect } from 'react'
import ReasonForLeavingSectionTwo from './ReasonForLeavingSectionTwo'
import { useExercise } from './ExerciseProvider'
import { Textarea } from '../../lib/ui/textarea'

const ReasonForLeavingExercise = ({ questions, conclusion, section }) => {
  const {
    handleChange,
    getResponse,
    exerciseIndex,
    trackContent,
    embedExercise,
    saveExercise,
  } = useExercise()

  useEffect(() => {
    const handlePaginationClick = (e) => {
      const link = e.target.closest('.workshop-pagination a')
      if (link) {
        e.preventDefault()
        saveExercise()
          .then(() => {
            if (
              !link.hasAttribute('data-method') ||
              link.getAttribute('data-method') !== 'post'
            ) {
              window.location.href = link.href
            }
            return null
          })
          .catch((error) => {
            console.error('Error saving exercise:', error)
          })
      }
    }

    document.addEventListener('click', handlePaginationClick)

    return () => {
      document.removeEventListener('click', handlePaginationClick)
    }
  }, [saveExercise])

  return (
    <div>
      {(section === 0 || embedExercise) && (
        <div>
          <div className="questions">
            {questions.map((question, index) => (
              <div
                key={index}
                className="mb-6 w-full font-roboto text-base font-medium"
              >
                <div className="flex w-full flex-col">
                  <label className="!mb-1">{question.question}</label>
                  <Textarea
                    className="min-h-[125px] w-full rounded-[20px]"
                    onChange={(e) =>
                      handleChange(exerciseIndex, question.slug, e)
                    }
                    value={getResponse(exerciseIndex, question.slug) || ''}
                  />
                </div>
              </div>
            ))}
          </div>
          <div className="mt-6 font-roboto text-lg">{conclusion}</div>
        </div>
      )}
      {(section === 1 || embedExercise) && (
        <ReasonForLeavingSectionTwo data={trackContent} />
      )}
    </div>
  )
}

export default ReasonForLeavingExercise
