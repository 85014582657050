import * as React from 'react'
import { Form } from '../../../lib/ui/form'
import { useTranslation } from '~/hooks/useTranslation'
import MagnifyingGlassIcon from '~/components/job_search/JobSearchPage/MagnifyingGlassIcon'
import { Button } from '~/lib/ui/button'
import { useCompanySearch } from './CompanySearchProvider'
import MapPinIcon from '~/components/job_search/JobSearchPage/MapPinIcon'
import SearchInput from '~/components/shared/SearchInput'
import { useIsDesktop, useIsMobile } from '~/hooks/useMediaQuery'
import { SpinnerWhite } from '~/components/shared/Spinner'
import FilterRadioGroup from './FilterRadioGroup'
import FilterDropdown from './FilterDropdown'
import { cn } from '~/lib/utils'

export default function ToolBar() {
  const { t } = useTranslation('Toolbar')
  const { form, onSubmit, urlSearchParams } = useCompanySearch()

  const isDesktop = useIsDesktop()
  const isMobile = useIsMobile()
  const inputClasses = 'col-span-1 md:col-span-5'

  return (
    <Form {...form}>
      <form onSubmit={form.handleSubmit(onSubmit)}>
        <div className="grid grid-cols-1 items-end gap-4 md:grid-cols-12">
          <SearchInput
            control={form.control}
            name="company_name"
            label={t('search')}
            icon={MagnifyingGlassIcon}
            className={inputClasses}
          />
          <SearchInput
            control={form.control}
            name="where"
            label={t('location')}
            className={inputClasses}
            data-source="locations"
            icon={MapPinIcon}
          />
          {isDesktop && (
            <SubmitButton className={'md:col-span-2'}>
              {t('search_cta')}
            </SubmitButton>
          )}
          <CompanySearchFilterDropDown
            title={t('radius')}
            filter="radius"
            hasChecked={urlSearchParams.has('radius')}
          >
            <RadiusCheckboxes />
          </CompanySearchFilterDropDown>
          <CompanySearchFilterDropDown
            title={t('industry')}
            filter="industry"
            hasChecked={urlSearchParams.has('industry')}
          >
            <IndustryCheckboxes />
          </CompanySearchFilterDropDown>
          <CompanySearchFilterDropDown
            title={t('company_size')}
            filter="company_size"
            hasChecked={urlSearchParams.has('company_size')}
          >
            <CompanySizeCheckboxes />
          </CompanySearchFilterDropDown>
          <CompanySearchFilterDropDown
            title={t('revenue')}
            filter="revenue"
            hasChecked={urlSearchParams.has('revenue')}
          >
            <RevenueCheckboxes />
          </CompanySearchFilterDropDown>
          <CompanySearchFilterDropDown
            title={t('location_types')}
            filter="location_type"
            hasChecked={urlSearchParams.has('location_type')}
          >
            <LocationTypeCheckboxes />
          </CompanySearchFilterDropDown>
          {isMobile && <SubmitButton>{t('search_cta')}</SubmitButton>}
        </div>

        <div className="flex flex-row flex-wrap gap-2 md:gap-4"></div>
      </form>
    </Form>
  )
}

function SubmitButton({ className, children, ...props }) {
  const { formDisabled } = useCompanySearch()
  const [fakeDisabled, setFakeDisabled] = React.useState(false)
  const fakeQuickSearch = () => {
    setFakeDisabled(true)
    setTimeout(() => {
      setFakeDisabled(false)
    }, 500)
  }

  const showSpinner = formDisabled || fakeDisabled

  return (
    <Button
      size="lg"
      className={className}
      disabled={formDisabled}
      type="submit"
      onClick={fakeQuickSearch}
      {...props}
    >
      {children}
      <SpinnerWhite show={showSpinner} className="pl-4" />
    </Button>
  )
}
const CompanySearchFilterDropDown = ({ className, children, ...props }) => {
  return (
    <FilterDropdown
      {...props}
      className={cn('col-span-1 md:col-span-2', className)}
    >
      {children}
    </FilterDropdown>
  )
}

function RadiusCheckboxes({ showLabel }) {
  const { t } = useTranslation('Toolbar')
  const { radiusChoices } = useCompanySearch()

  return (
    <FilterRadioGroup
      name="radius"
      label={t('radius')}
      showLabel={showLabel}
      items={radiusChoices}
    />
  )
}
function IndustryCheckboxes({ showLabel }) {
  const { t } = useTranslation('Toolbar')
  const { industryChoices } = useCompanySearch()

  return (
    <FilterRadioGroup
      name="industry"
      label={t('industry')}
      showLabel={showLabel}
      items={industryChoices}
    />
  )
}

function CompanySizeCheckboxes({ showLabel }) {
  const { t } = useTranslation('Toolbar')
  const { companySizeChoices } = useCompanySearch()

  return (
    <FilterRadioGroup
      name="company_size"
      label={t('company_size')}
      showLabel={showLabel}
      items={companySizeChoices}
    />
  )
}

function RevenueCheckboxes({ showLabel }) {
  const { t } = useTranslation('Toolbar')
  const { revenueChoices } = useCompanySearch()

  return (
    <FilterRadioGroup
      name="revenue"
      label={t('revenue')}
      showLabel={showLabel}
      items={revenueChoices}
    />
  )
}

function LocationTypeCheckboxes({ showLabel }) {
  const { t } = useTranslation('Toolbar')
  const { locationTypeChoices } = useCompanySearch()

  return (
    <FilterRadioGroup
      name="location_type"
      label={t('location_types')}
      showLabel={showLabel}
      items={locationTypeChoices}
    />
  )
}
