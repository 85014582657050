import React, { useEffect, useRef } from 'react'
import { Chart } from 'chart.js/auto'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import { useTranslation } from '~/hooks/useTranslation'
import { useExercise } from '../ExerciseProvider'
import parse from 'html-react-parser'

const PieOfLife = ({ data }) => {
  const chartRef = useRef(null)
  const chartInstanceRef = useRef(null)
  const { formattedExerciseName } = useExercise()
  const { t } = useTranslation(formattedExerciseName)

  useEffect(() => {
    if (chartRef.current) {
      const ctx = chartRef.current.getContext('2d')

      if (chartInstanceRef.current) {
        chartInstanceRef.current.destroy()
      }

      const labels = Object.keys(data).map((key) => t(`data_labels.${key}`))

      chartInstanceRef.current = new Chart(ctx, {
        type: 'pie',
        data: {
          labels: labels,
          datasets: [
            {
              data: Object.values(data),
              backgroundColor: [
                '#f79647',
                '#4aadc6',
                '#4ad1a3',
                '#46dc56',
                '#8de646',
                '#eeef46',
              ],
            },
          ],
        },
        plugins: [ChartDataLabels],
        options: {
          plugins: {
            legend: { display: false },
            tooltip: { enabled: false },
            datalabels: {
              color: 'black',
              align: 'start',
              anchor: 'end',
              font: {
                family: 'Roboto',
              },
              formatter: (value, ctx) => {
                if (!value || Number(value) === 0) {
                  return null
                }
                let dataArr = ctx.chart.data.datasets[0].data
                let sum = dataArr.reduce((a, b) => a + Number(b), 0)
                let percentage = ((value * 100) / sum).toFixed(2) + '%'
                let label = ctx.chart.data.labels[ctx.dataIndex]

                return parse(label) + ':\n' + percentage
              },
            },
          },
        },
      })
    }
  }, [data, t])

  return (
    <>
      <div>
        <canvas ref={chartRef} />
      </div>
    </>
  )
}

export default PieOfLife
