import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { z } from 'zod'
import { apiGet, apiPatch, apiPost } from '../../../../util/api'

const useCreateAlertForm = (candidateEmail, url, documentPath, t) => {
  const [submitted, setSubmitted] = useState(false)
  const [jobCount, setJobCount] = useState()

  const schema = z.object({
    what: z.string().optional(),
    where: z.string().optional(),
    email: z.string().email(),
    frequency: z.enum(['Daily', 'Weekly']),
  })

  const defaultValues = {
    email: candidateEmail || '',
    what: '',
    where: '',
    frequency: 'Daily',
  }

  const form = useForm({
    resolver: zodResolver(schema),
    defaultValues,
  })

  const updateDocument = async (alertId, jobCount) => {
    const url = documentPath
    const jobNoun = jobCount === 1 ? t('job') : t('jobs')
    const jobAlerts = t('job_alerts')
    const jobCountInfo = t('alert_response', { jobCount, jobNoun, jobAlerts })
    const data = {
      document_part: {
        content: { alert: { id: alertId, job_count: jobCountInfo } },
      },
    }
    let response
    try {
      response = await apiPatch(url, data)
    } catch (error) {
      console.log(error)
    }
    if (!response.status === 200) {
      throw new Error('Failed to save response')
    }
  }

  const fetchAlertJobCount = async (alertId) => {
    const url = `/job-search/alerts/${alertId}/job_count_from_id`
    let response
    try {
      response = await apiGet(url)
    } catch (error) {
      console.log(error)
    }
    if (response.status === 200) {
      setJobCount(response.data.job_count)
      updateDocument(alertId, response.data.job_count)
    } else throw new Error('Error fetching job count')
  }

  const onSubmit = async (values) => {
    setSubmitted(true)
    let response
    const data = {
      alert: {
        via_email: true,
        what: values.what,
        where: values.where,
        frequency: values.frequency,
      },
      format: 'json',
    }
    try {
      response = await apiPost(url, data)
    } catch (error) {
      console.log(error)
    }
    if (response.status === 200) {
      const alertId = response.data.alert_id
      if (alertId) {
        fetchAlertJobCount(alertId)
        setSubmitted(false)
        return response
      }
    } else throw new Error('Error creating alert')
  }

  return { form, submitted, jobCount, onSubmit }
}

export default useCreateAlertForm
