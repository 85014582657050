import React, { useState, useEffect } from 'react'
import ReferenceSheetProvider, {
  useReferenceSheet,
} from './ReferenceSheetProvider'
import { Textarea } from '../../lib/ui/textarea'
import { Button } from '../../lib/ui/button'
import {
  TabNav,
  TabPanel,
  PanelContainer,
  ButtonContainer,
  TabButton,
} from '~/components/shared/TabNav'
import {
  DocumentMagnifyingGlassIcon,
  IdentificationIcon,
  ClipboardDocumentCheckIcon,
  DocumentTextIcon,
} from '@heroicons/react/24/outline'
import Editor from './Editor/index'
import PdfViewerPanel from './PdfViewerPanel'

const ReferenceSheetBuilder = ({ ...props }) => {
  return (
    <ReferenceSheetProvider {...props}>
      <ReferenceSheetBuilderInner />
    </ReferenceSheetProvider>
  )
}
export default ReferenceSheetBuilder

const ReferenceSheetBuilderInner = () => {
  const { devMode, documentId } = useReferenceSheet()

  const defaultActiveTab = documentId ? 'details' : 'chooseTemplate'

  return (
    <div className="flex w-full flex-col md:flex-row">
      <div className="md:basis-3/5">
        <TabNav
          className="px-4 pt-4 lg:px-8 xl:px-12"
          defaultActiveTab={defaultActiveTab}
          mobileMode="swipe"
        >
          <ButtonContainer>
            <TabButton
              name="chooseTemplate"
              text="Choose Template"
              Icon={DocumentMagnifyingGlassIcon}
            />
            <TabButton
              name="details"
              text="Details"
              Icon={IdentificationIcon}
            />
            <TabButton
              name="review"
              text="Review"
              Icon={ClipboardDocumentCheckIcon}
            />
            {devMode && (
              <TabButton name="text" text="Raw JSON" Icon={DocumentTextIcon} />
            )}
          </ButtonContainer>
          <PanelContainer className="md:min-h-[130vh]">
            <TabPanel name="chooseTemplate" className="bg-[#E0F2F9] pt-8">
              <SelectTemplatePanel />
            </TabPanel>
            <TabPanel name="details" className="pt-8">
              <Editor />
            </TabPanel>
            <TabPanel name="review" className="pt-8">
              <ReviewPanel />
            </TabPanel>
            <TabPanel name="text" className="pt-8">
              <TextEditor />
            </TabPanel>
          </PanelContainer>
        </TabNav>
      </div>
      <div id="resume-pdf-preview" className="relative md:flex-grow">
        <div className="h-screen w-full md:sticky md:left-0 md:top-0">
          <PdfViewerPanel />
        </div>
      </div>
    </div>
  )
}

const SelectTemplatePanel = () => {
  const { templateIndex, setTemplateIndex } = useReferenceSheet()
  const templateIndices = [0, 1]
  const activeClasses = 'font-roboto-slab text-primary font-bold pb-2'
  const inactiveClasses = 'font-roboto-slab text-primary pb-2'

  return (
    <div className="grid h-full sm:grid-cols-2 lg:grid-cols-3">
      {templateIndices.map((index) => (
        <div className="flex flex-col items-center" key={`template-${index}`}>
          <span
            className={templateIndex == index ? activeClasses : inactiveClasses}
          >
            Style {index + 1}
          </span>
          <button
            onClick={() => setTemplateIndex(index)}
            disabled={templateIndex === index}
            className={
              templateIndex === index
                ? 'rounded-xl border-4 border-solid border-primary'
                : 'm-1 rounded-xl'
            }
          >
            <TemplatePreview
              className="w-48 rounded-xl"
              templateIndex={index}
            />
          </button>
        </div>
      ))}
    </div>
  )
}

const TemplatePreview = ({ className, templateIndex }) => {
  const url = `https://www-yns-com.s3.amazonaws.com/resume_builder/ReferenceSheetTemplate${templateIndex}.png`
  return (
    <img className={className} src={url} alt={`Template ${templateIndex}`} />
  )
}

const ReviewPanel = () => {
  const { persistReferenceSheet, documentId } = useReferenceSheet()
  const referenceSheetIsNew = !documentId

  return (
    <div className="flex flex-col">
      <div className="flex flex-row justify-evenly">
        <Button onClick={persistReferenceSheet}>
          {referenceSheetIsNew ? 'Create' : 'Save'}
        </Button>
      </div>
    </div>
  )
}

const TextEditor = () => {
  const { referenceSheetDocument, setReferenceSheetDocument } =
    useReferenceSheet()
  const [editorReferenceSheet, setEditorReferenceSheet] = useState(
    JSON.stringify(referenceSheetDocument, null, 2),
  )

  const sync = () => {
    const updatedReferenceSheet = JSON.parse(editorReferenceSheet)
    setReferenceSheetDocument(updatedReferenceSheet)
  }

  useEffect(() => {
    setEditorReferenceSheet(JSON.stringify(referenceSheetDocument, null, 2))
  }, [referenceSheetDocument])

  return (
    <div className="flex h-full flex-col">
      <div className="flex flex-row justify-evenly">
        <Button onClick={sync}>Sync</Button>
      </div>
      <Textarea
        className="h-full w-full"
        value={editorReferenceSheet}
        onChange={(e) => setEditorReferenceSheet(e.target.value)}
      ></Textarea>
    </div>
  )
}
