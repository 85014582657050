import React from 'react'
import Section from './Section'
import { AudioPlayer, LeftPanel, NavHeader } from './controls'
import { Button } from '../../lib/ui/button'
import { BackArrow } from './icons'
import { ListItems } from './lists'
import ArticleProvider, { useArticle } from './ArticleProvider'
import HighlightCommentProvider from './HighlightCommentProvider'
import { TranslationProvider } from '../shared/TranslationProvider'
import translations from './locales.json'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

const queryClient = new QueryClient()

const ArticleInner = () => {
  const {
    jsonContent: { highlights, heroImage },
  } = useArticle()

  return (
    <div className="article-redesign flex w-full flex-col">
      <NavHeader />
      <div className="flex w-full flex-row">
        <div className="mb-16 mt-4 flex flex-grow flex-row justify-around font-[Roboto] text-base antialiased md:ml-10 md:pl-4">
          <div className="mx-4 max-w-xs md:max-w-5xl 2xl:m-0">
            <AudioPlayer />
            {highlights && <Highlights />}
            {heroImage && <Hero />}
            <Sections />
          </div>
        </div>
        <LeftPanel />
      </div>
    </div>
  )
}

const Article = ({ ...props }) => {
  const { locale } = props
  return (
    <QueryClientProvider client={queryClient}>
      <TranslationProvider
        locale={locale}
        translations={translations}
        scope={'javascript.components.Article'}
      >
        <ArticleProvider {...props}>
          <HighlightCommentProvider>
            <ArticleInner />
          </HighlightCommentProvider>
        </ArticleProvider>
      </TranslationProvider>
    </QueryClientProvider>
  )
}

const Highlights = () => {
  const {
    jsonContent: { highlights },
  } = useArticle()
  return (
    <div className="mb-10 mt-4 p-8 shadow-[0_0_20px_rgba(0,70,124,0.8)]">
      <div>
        <h3 className="!mt-0 mb-4 !text-2xl font-bold text-black">
          Highlights
        </h3>
        <ol className="list-inside list-decimal space-y-4 text-black">
          <HighlightCommentProvider locatorSuffix=".highlights">
            <ListItems items={highlights} locatorSuffix=".highlights" />
          </HighlightCommentProvider>
        </ol>
      </div>
    </div>
  )
}

const Hero = () => {
  const {
    heroAwsUrl,
    jsonContent: {
      heroImage: { title },
    },
  } = useArticle()
  return (
    <div className="mb-10">
      <img
        className="aspect-[11/5] w-full object-cover object-center shadow-md shadow-slate-500/40"
        alt={title}
        src={heroAwsUrl}
      />
    </div>
  )
}

const Sections = () => {
  const {
    jsonContent: { sections },
    sectionRef,
  } = useArticle()

  const lastIndex = sections.length - 1

  return (
    <div ref={sectionRef}>
      {sections.map((section, index) => (
        <div
          key={index}
          className={`scroll-mt-[35px] sm:scroll-mt-[200px] ${
            section.exclude_divider ? 'mb-2' : ''
          }`}
        >
          <HighlightCommentProvider locatorSuffix={`.sections[${index}]`}>
            <Section section={section} />
          </HighlightCommentProvider>
          {index !== lastIndex && !section.exclude_divider && (
            <SectionDivider />
          )}
        </div>
      ))}
      <CompletionNotice />
    </div>
  )
}

const SectionDivider = () => {
  return (
    <div className="my-8 w-full">
      <hr className="w-full border-[1.5px] border-solid border-primary" />
    </div>
  )
}

const CompletionNotice = () => {
  const {
    hasNotifiedViewed,
    completionAnimationUrl,
    navigateToResourceCenter,
    jsonContent: { title, guideComplete },
    navigateToGuides,
    cliftonStrengths,
  } = useArticle()

  const contentTitle = guideComplete?.title || 'Congratulations!'
  const message =
    guideComplete?.content || `${title} has been marked as complete.`
  const className = hasNotifiedViewed ? 'mt-6' : 'hidden'
  return (
    <div className={className}>
      <div className="mb-8 flex w-full flex-row items-center">
        <img
          className="w-20"
          src={completionAnimationUrl}
          alt="Article complete"
        />
        <div className="flex flex-col">
          <div className="text-2xl font-bold text-[#27772B]">
            {contentTitle}
          </div>
          <div>{message}</div>
        </div>
      </div>
      <div className="mb-4">
        {cliftonStrengths ? (
          <Button
            variant="default"
            className="h-12 rounded-[1.25rem] py-3 font-medium sm:h-10 sm:py-2"
            onClick={navigateToGuides}
          >
            Back to Guides
            <BackArrow className="ml-2 stroke-white" />
          </Button>
        ) : (
          <Button
            variant="default"
            className="h-12 rounded-[1.25rem] bg-primary py-3 font-medium hover:hover:bg-primary sm:h-10 sm:py-2"
            onClick={navigateToResourceCenter}
          >
            Resource Center
            <BackArrow className="ml-2 stroke-white" />
          </Button>
        )}
      </div>
    </div>
  )
}

export default Article
