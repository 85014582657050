import React, { createContext, useContext, useEffect, useState } from 'react'
import { apiPatch, apiPost } from '../../../util/api'

const IntroductionScriptContext = createContext()

export function useIntroductionScript() {
  return useContext(IntroductionScriptContext)
}

export function IntroductionScriptProvider({
  industries,
  candidate,
  children,
}) {
  const formatIndustries = () => {
    return industries.map((industry) => ({
      value: industry,
      label: industry,
    }))
  }

  const [currentPage, setCurrentPage] = useState(1)
  const [aylaResponse, setAylaResponse] = useState(null)
  const [customScript, setCustomScript] = useState('')
  const industryChoices = formatIndustries()
  const [introductionScriptId, setIntroductionScriptId] = useState()
  const [scriptPartId, setScriptPartId] = useState()
  const [showDialog, setShowDialog] = useState(false)
  const toggleDialog = () => setShowDialog(!showDialog)

  const handlePageChange = (page) => setCurrentPage(page)

  const completeActivity = () => {
    window.location.href = '/job-search/recruiters'
  }

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [currentPage])

  const buildPrompt = (values) => {
    const { target_role, career_level, organization_type, industry } = values
    return `Write a framework for an introductory script that can be used by a ${career_level} job seeker to introduce themself to an executive recruiter who places individuals in roles like ${target_role} at ${
      organization_type !== 'flexible' && `${organization_type}`
    } companies in ${
      industry ? `the ${industry}` : `any`
    } industry. This script should be appropriate to be used as a professional introduction on LinkedIn with the intent of making a connection and requesting an opportunity to discuss how the individual matches opportunities for which the recruiter is currently filling. Tone should be professional`
  }

  const requestAylaStatement = async (values, documentId) => {
    const prompt = buildPrompt(values)
    const url = `/job-search/recruiters/create_ai_introduction_script?document_id=${documentId}`
    const data = { introduction_script: { prompt } }
    let res
    try {
      res = await apiPost(url, data)
    } catch (err) {
      console.log(err)
    }
    if (res.status === 200) {
      setAylaResponse(res.data.response)
    } else {
      console.log('error')
    }
    return res
  }

  const updateScriptPart = async () => {
    const url = `/job-search/recruiters/document/introduction_scripts/${introductionScriptId}`
    const data = {
      introduction_script_part: {
        content: {
          script: customScript,
        },
      },
    }

    let response
    try {
      response = await apiPatch(url, data)
    } catch (err) {
      console.log(err)
    }
    if (response.status === 200) {
      toggleDialog()
      console.log('updated')
    } else {
      throw new Error('Error updating introduction script')
    }
  }

  const value = {
    candidate,
    industryChoices,
    requestAylaStatement,
    aylaResponse,
    setAylaResponse,
    currentPage,
    handlePageChange,
    customScript,
    setCustomScript,
    updateScriptPart,
    setIntroductionScriptId,
    introductionScriptId,
    setScriptPartId,
    scriptPartId,
    showDialog,
    toggleDialog,
    completeActivity,
  }

  return (
    <IntroductionScriptContext.Provider value={value}>
      {children}
    </IntroductionScriptContext.Provider>
  )
}
