import React from 'react'
import { cn } from '../../../lib/utils'
import RightNavList from './RightNavList'

const RightNav = ({ className }) => {
  return (
    <>
      <div
        className={cn(
          'reminders-div relative min-h-0 overflow-y-auto text-primary md:h-full md:pl-0 md:pr-8',
          className,
        )}
      >
        <RightNavList />
      </div>
    </>
  )
}

export default RightNav
