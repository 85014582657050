import React from 'react'
import { useNetworkingBrief } from '../NetworkingBriefProvider'
import { EditorHeading } from '../../ResumeBuilder/Editor/common'
import { EditorItemTextField, EditorItemProvider } from './EditorItemProvider'

const CallToActionEditor = () => {
  const { networkingBriefDocument: { callToAction } = {} } =
    useNetworkingBrief()

  return (
    <EditorItemProvider sectionName="callToAction" item={callToAction}>
      <div>
        <EditorHeading>Call to Action</EditorHeading>
        <p>
          Desired outcome from networking interactions - what you hope to
          accomplish with your networking activities. Optional if included in
          your professional summary.
        </p>
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          <EditorItemTextField
            containerClassName="md:col-span-2"
            fieldName="callToActionStatement"
            label="Call to Action Statement"
            areaClassName="h-[150px]"
          />
        </div>
      </div>
    </EditorItemProvider>
  )
}
export default CallToActionEditor
