import React, { useState } from 'react'
import { api } from '../../../../util/api'
import TypingEffect from './TypingEffect'
import { useAiResponseStream } from './useAiResponseStream'
import Box from '@mui/material/Box'
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from '../../../../lib/ui/dialog'
import NewCoverLetterForm from './NewCoverLetterForm'
import { Button } from '../../../../lib/ui/button'
import { Download } from 'iconoir-react'
import { DocumentDuplicateIcon } from '@heroicons/react/24/outline'
import StarFeedback from '../../../../components/shared/StarFeedback'

export default function CreateAi({ id, t, useSkillsMatch, jobPosting }) {
  const [formSubmitted, setFormSubmitted] = React.useState(false)
  const [targetJobTitle, setTargetJobTitle] = React.useState(jobPosting?.title)
  const [yearsExperience, setYearsExperience] = React.useState('')
  const [exampleDescription, setExampleDescription] = React.useState(
    jobPosting?.description,
  )
  const [exampleLink, setExampleLink] = React.useState('')
  const [skillsExperiences, setSkillsExperiences] = React.useState('')
  const [showAiTyping, setShowAiTyping] = React.useState(false)
  const [typingComplete, setTypingComplete] = React.useState(false)
  const [parentCoverLetterId, setParentCoverLetterId] = React.useState()
  const [displayedText, setDisplayedText] = React.useState('')
  const [index, setIndex] = React.useState(0)
  const {
    aiResponse,
    coverLetterId,
    setCoverLetterId,
    aiStreamComplete,
    setServerSentEvents,
  } = useAiResponseStream()
  const [showDialog, setShowDialog] = useState(false)
  const toggleDialog = () => setShowDialog((prev) => !prev)

  const handleOpen = () => {
    setShowDialog(true)
    setFormSubmitted(false)
  }
  const handleClose = () => {
    setShowDialog(false)
    setFormSubmitted(true)
  }
  const [coverLetterTone, setCoverLetterTone] = React.useState(66)
  const [coverLetters, setCoverLetters] = React.useState()
  const [fetchCoverLetters, setFetchCoverLetters] = React.useState(true)
  const [selectedCoverLetter, setSelectedCoverLetter] = React.useState()
  const [typingEffect, setTypingEffect] = React.useState(false)
  const [copyButtonText, setCopyButtonText] = React.useState(t.copy_button_text)
  const [goBack, setGoBack] = React.useState(true)
  const [regenerateCoverLetter, setRegenerateCoverLetter] =
    React.useState(false)

  const showContinueButton = typingComplete && aiStreamComplete && coverLetters
  const formComplete = targetJobTitle && yearsExperience
  const submitButtonDisabled = formSubmitted || !formComplete

  const coverLetterTones = React.useMemo(
    () => ({
      0: 'in an informal tone',
      33: 'in a neutral tone',
      66: 'in a business formal tone',
      99: 'in a very formal tone',
    }),
    [],
  )

  const fetchCoverLettersFromApi = React.useCallback(
    async (id) => {
      const {
        data: { cover_letters },
      } = await api.get(`/personal_brand/cover_letters/ai_versions/${id}`, {
        id: id,
      })
      setCoverLetters(cover_letters)
      cover_letters.forEach((element, idx) => {
        const documentElement = document.getElementById(
          `cover-letter-${idx + 1}`,
        )
        if (documentElement) {
          documentElement.hidden = false
          documentElement.classList.add('not-selected')
        }

        if (element && element.id && element.id === parseInt(id)) {
          setTargetJobTitle(element.target_job_title)
          setYearsExperience(element.years_experience)
          setExampleDescription(element.example_description)
          setExampleLink(element.example_link)
          setSkillsExperiences(element.skills_experiences)
          setParentCoverLetterId(element.parent_cover_letter_id)
          setCoverLetterTone(
            Object.keys(coverLetterTones).find(
              (key) => coverLetterTones[key] === element.tone,
            ),
          )
          if (documentElement) {
            documentElement.classList.remove('not-selected')
          }
        }
      })
    },
    [coverLetterTones],
  )

  React.useEffect(() => {
    if (aiStreamComplete) {
      fetchCoverLettersFromApi(coverLetterId)
      setSelectedCoverLetter(coverLetterId)
      setRegenerateCoverLetter(false)
    }
  }, [
    aiStreamComplete,
    coverLetterId,
    setSelectedCoverLetter,
    fetchCoverLettersFromApi,
  ])

  React.useEffect(() => {
    if (regenerateCoverLetter) {
      const copyButton = document.getElementById('copy-button')
      const downloadButton = document.getElementById(
        'download-button-container',
      )
      const aylaCoverLetterText = document.getElementById(
        'ayla-cover-letter-text',
      )
      const coverLetterButtons = document.getElementById('cover-letter-buttons')
      const continueButton = document.getElementById('continue-button')

      if (
        copyButton &&
        downloadButton &&
        aylaCoverLetterText &&
        coverLetterButtons &&
        continueButton
      ) {
        copyButton.style.display = 'none'
        downloadButton.style.display = 'none'
        aylaCoverLetterText.style.display = 'none'
        coverLetterButtons.style.display = 'none'
        continueButton.style.display = 'none'
      }
    }
  })

  React.useEffect(() => {
    if (id && fetchCoverLetters && goBack) {
      fetchCoverLettersFromApi(parseInt(id))
      setFetchCoverLetters(false)
      setCoverLetterId(parseInt(id))
      setSelectedCoverLetter(parseInt(id))
      setShowAiTyping(true)
      setGoBack(false)
    }
  }, [
    id,
    coverLetters,
    fetchCoverLetters,
    goBack,
    setCoverLetterId,
    setShowAiTyping,
    setSelectedCoverLetter,
    setFetchCoverLetters,
    setGoBack,
    fetchCoverLettersFromApi,
  ])

  React.useEffect(() => {
    setIndex(0)
    setDisplayedText('')
  }, [coverLetterId])

  const handleSubmitButtonClick = async (event) => {
    event.preventDefault()
    setFormSubmitted(true)
    setShowAiTyping(true)
    setShowDialog(false)
    setTypingEffect(true)
    setTypingComplete(false)
    setDisplayedText('')
    setIndex(0)
    setRegenerateCoverLetter(true)

    const {
      data: { cover_letter_id, use_sse },
    } = await api.post('/personal_brand/cover_letters/create_ai', {
      ai_cover_letter: {
        target_job_title: targetJobTitle,
        years_experience: yearsExperience,
        example_description: exampleDescription,
        example_link: exampleLink,
        skills_experiences: skillsExperiences,
        parent_cover_letter_id: parentCoverLetterId,
        tone: coverLetterTones[coverLetterTone],
        use_skills_match: useSkillsMatch,
      },
    })
    setServerSentEvents(use_sse)
    setCoverLetterId(cover_letter_id)

    if (!parentCoverLetterId) {
      setParentCoverLetterId(cover_letter_id)
    }
    setFetchCoverLetters(true)
  }

  const handleCopyButtonClick = (e) => {
    e.preventDefault()

    document.addEventListener(
      'copy',
      function (e) {
        const coverLetter = coverLetters.find(
          (ele) => ele.id === parseInt(selectedCoverLetter),
        )
        const coverLetterText = coverLetter.content
        e.clipboardData.setData('text/plain', coverLetterText)
        e.preventDefault()
      },
      true,
    )
    setCopyButtonText(t.copied_text)

    document.execCommand('copy')
  }

  const handleContinueButtonClick = () => {
    fetchCoverLettersFromApi(selectedCoverLetter)
      .then(() => {
        window.location.href = `/personal_brand/cover_letter_builder/${selectedCoverLetter}`
        return true
      })
      .catch(() => {
        return false
      })
  }

  const handleSelectButtonClick = (index) => {
    const selected = coverLetters[index]
    setTypingEffect(false)
    setCopyButtonText(t.copy_button_text)

    for (let i = 0; i < coverLetters.length; i++) {
      document
        .getElementById(`cover-letter-${i + 1}`)
        .classList.add('not-selected')
      if (i === index) {
        document
          .getElementById(`cover-letter-${i + 1}`)
          .classList.remove('not-selected')
      }
    }
    const newSelectedId = selected.id
    setSelectedCoverLetter(newSelectedId)
  }

  if (showAiTyping) {
    return (
      <>
        {showContinueButton && (
          <div
            className="actions"
            id="download-button-container"
            style={{
              margin: '0 auto',
              padding: '1rem',
              paddingRight: '0',
              marginBottom: '1rem',
            }}
          >
            <div
              id="download-button"
              style={{ position: 'absolute', right: '4rem' }}
            >
              <a
                className="pull-right"
                href="#"
                id="download-dropdown"
                data-toggle="dropdown"
                aria-expanded="true"
              >
                <div className="flex items-center">
                  <Download />
                  <div>{t.download_button_text}</div>
                </div>
              </a>
              <ul
                className="dropdown-menu dropdown-menu-right"
                role="menu"
                aria-labelledby="download-dropdown"
              >
                <li role="presentation">
                  <a
                    href={`/personal_brand/document/cover_letters/${selectedCoverLetter}.pdf`}
                  >
                    Download - PDF
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href={`/personal_brand/document/cover_letters/${selectedCoverLetter}.docx`}
                  >
                    Download - Word
                  </a>
                </li>
                <li role="presentation">
                  <a
                    href={`/personal_brand/document/cover_letters/${selectedCoverLetter}.txt`}
                  >
                    Download - Text
                  </a>
                </li>
              </ul>
            </div>
          </div>
        )}
        <Paper>
          <Box
            sx={{
              flexDirection: 'row',
              justifyContent: 'space-between',
              padding: '0.5rem',
              display: 'flex',
            }}
          >
            <div className="subtitle px-2">
              Here is your cover letter with the information you provided:
            </div>
            {showContinueButton && (
              <button
                id="copy-button"
                onClick={handleCopyButtonClick}
                style={{
                  fontWeight: 'bold',
                }}
              >
                <div className="flex items-center font-roboto text-primary">
                  <DocumentDuplicateIcon className="w-6 stroke-primary" />
                  <div className="font-roboto">&nbsp;{copyButtonText}</div>
                </div>
              </button>
            )}
          </Box>

          {aiStreamComplete &&
            coverLetters &&
            !typingEffect &&
            coverLetters.map((coverLetter) => {
              if (
                coverLetter &&
                coverLetter.id === parseInt(selectedCoverLetter)
              ) {
                return coverLetter.content
                  .split('\n\n')
                  .map((paragraph, index) => (
                    <p key={index} className="m-4">
                      {paragraph}
                    </p>
                  ))
              }
            })}

          {((!aiStreamComplete && !coverLetters) || typingEffect) && (
            <TypingEffect
              message={aiResponse}
              baseSpeed={5}
              aiStreamComplete={aiStreamComplete}
              setTypingComplete={setTypingComplete}
              displayedText={displayedText}
              setDisplayedText={setDisplayedText}
              index={index}
              setIndex={setIndex}
            />
          )}
          <>
            <br />
            <div className="items-top flex flex-row justify-between">
              <div>
                <Box
                  id="ayla-cover-letter-text"
                  sx={{ display: 'none', padding: '0.5rem' }}
                  className="subtitle"
                >
                  {t.ayla_cover_letters_text}
                </Box>
                <Box
                  id="cover-letter-buttons"
                  sx={{
                    display: 'none',
                    flexDirection: 'row',
                    justifyContent: 'left',
                    padding: '0.5rem',
                    gap: '1rem',
                    flexWrap: 'wrap',
                  }}
                >
                  <button
                    id="cover-letter-1"
                    className="btn btn-primary !h-8 !w-8 !rounded-lg !p-0 !leading-none"
                    hidden={true}
                    onClick={() => handleSelectButtonClick(0)}
                    size="square"
                  >
                    1
                  </button>
                  <button
                    id="cover-letter-2"
                    className="btn btn-primary !h-8 !w-8 !rounded-lg !p-0 !leading-none"
                    hidden={true}
                    onClick={() => handleSelectButtonClick(1)}
                    size="square"
                  >
                    2
                  </button>
                  <button
                    id="cover-letter-3"
                    className="btn btn-primary !h-8 !w-8 !rounded-lg !p-0 !leading-none"
                    hidden={true}
                    onClick={() => handleSelectButtonClick(2)}
                    size="square"
                  >
                    3
                  </button>
                  <button
                    id="cover-letter-4"
                    className="btn btn-primary !h-8 !w-8 !rounded-lg !p-0 !leading-none"
                    hidden={true}
                    onClick={() => handleSelectButtonClick(3)}
                    size="square"
                  >
                    4
                  </button>
                  <button
                    id="cover-letter-5"
                    className="btn btn-primary !h-8 !w-8 !rounded-lg !p-0 !leading-none"
                    hidden={true}
                    onClick={() => handleSelectButtonClick(4)}
                    size="square"
                  >
                    5
                  </button>
                </Box>
              </div>
              <div>
                {showContinueButton && (
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                      alignItems: 'center',
                      padding: '0.5rem',
                      gap: '0.5rem',
                    }}
                  >
                    <div className="text-base">Please rate this response:</div>
                    <StarFeedback
                      path="/personal_brand/cover_letters_feedback"
                      documentId={selectedCoverLetter}
                    />
                  </div>
                )}
              </div>
            </div>
          </>
        </Paper>
        <Dialog open={showDialog} onOpenChange={toggleDialog}>
          <DialogTrigger asChild className="cursor-pointer"></DialogTrigger>
          <DialogContent className="max-w-7xl">
            <DialogHeader>
              <DialogTitle>{t.regenerate_title}</DialogTitle>
            </DialogHeader>
            <NewCoverLetterForm
              t={t}
              targetJobTitle={targetJobTitle}
              setTargetJobTitle={setTargetJobTitle}
              yearsExperience={yearsExperience}
              setYearsExperience={setYearsExperience}
              exampleDescription={exampleDescription}
              setExampleDescription={setExampleDescription}
              exampleLink={exampleLink}
              setExampleLink={setExampleLink}
              skillsExperiences={skillsExperiences}
              setSkillsExperiences={setSkillsExperiences}
              handleSubmitButtonClick={handleSubmitButtonClick}
              submitButtonDisabled={submitButtonDisabled}
              tonePresent={true}
              coverLetterTone={coverLetterTone}
              setCoverLetterTone={setCoverLetterTone}
              handleClose={handleClose}
              useSkillsMatch={useSkillsMatch}
              jobPosting={jobPosting}
            />
            <DialogClose />
          </DialogContent>
        </Dialog>
        {showContinueButton && (
          <div id="continue-button">
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ padding: '0.5rem' }}
              className="subtitle"
            >
              {t.want_to_change_text}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ padding: '0.5rem' }}
            >
              <Button
                id="regenerate_button"
                onClick={handleOpen}
                disabled={coverLetters && coverLetters.length > 4}
                variant="outline"
              >
                {t.regenerate_button_text}
              </Button>
            </Box>

            <div className="mx-auto my-4 h-[13px] w-[33%] border-b border-[#A8A8A8] text-center">
              <span className="bg-white px-[20px] text-[14px]">OR</span>
            </div>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              textAlign="center"
              sx={{ padding: '0.5rem' }}
              className="subtitle"
            >
              {t.looks_good_text}
            </Box>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="center"
              sx={{ padding: '0.5rem' }}
            >
              <Button onClick={handleContinueButtonClick}>
                {t.continue_button_text}
              </Button>
            </Box>
          </div>
        )}
      </>
    )
  }

  return (
    <NewCoverLetterForm
      t={t}
      targetJobTitle={targetJobTitle}
      setTargetJobTitle={setTargetJobTitle}
      yearsExperience={yearsExperience}
      setYearsExperience={setYearsExperience}
      exampleDescription={exampleDescription}
      setExampleDescription={setExampleDescription}
      exampleLink={exampleLink}
      setExampleLink={setExampleLink}
      skillsExperiences={skillsExperiences}
      setSkillsExperiences={setSkillsExperiences}
      handleSubmitButtonClick={handleSubmitButtonClick}
      submitButtonDisabled={submitButtonDisabled}
      coverLetterTone={coverLetterTone}
      setCoverLetterTone={setCoverLetterTone}
      tonePresent={false}
      useSkillsMatch={useSkillsMatch}
      jobPosting={jobPosting}
    />
  )
}

function Paper({ children, ...props }) {
  return (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        color: '#000',
        fontSize: '0.85rem',
        lineHeight: '1.5',
        fontFamily: 'Roboto',
        borderRadius: '0.3125rem',
        boxShadow: '0 0 0.625rem rgba(0, 0, 0, 0.5)',
      }}
      {...props}
      className="my-8 md:p-12"
    >
      {children}
    </Box>
  )
}
