import React, { useEffect, useState } from 'react'
import { useChartYourCourse } from './ChartYourCourseProvider'
import { Button } from '../../../lib/ui/button'
import useTranslation from '../../shared/TranslationProvider'
import {
  FancyCheckBox,
  FancyCheckBoxComponent,
} from '../../shared/FancyCheckBox'

const PartTwo = ({ sectionInfo }) => {
  const { selectedValues, clearSelectedCareers } = useChartYourCourse()
  const [tempSelectedVals, setTempSelectedVals] = useState([])
  useEffect(() => {
    setTempSelectedVals(selectedValues)
  }, [selectedValues])

  const { t } = useTranslation('chart_your_course')

  const content = sectionInfo.values.filter((item) =>
    tempSelectedVals.includes(item.value),
  )
  return (
    <div className="questions flex flex-col gap-4">
      <div className="flex flex-col gap-6">
        {content.map((options, idx) => (
          <CareerOptions key={`career-options-${idx}`} options={options} />
        ))}
      </div>
      <Button
        className="self-end"
        variant="outline"
        onClick={clearSelectedCareers}
      >
        {t('clear_selections')}
      </Button>
    </div>
  )
}

const CareerOptions = ({ options }) => {
  const { description, careers } = options
  return (
    <div className="rounded-[10px] border border-[#D3D3D3] p-4">
      <div className="subtitle">{description}</div>
      <div className="grid grid-cols-1 gap-4 md:grid-cols-2">
        {careers.map((career, idx) => (
          <CareerListItem key={`career-option-${idx}`} career={career} />
        ))}
      </div>
    </div>
  )
}

const CareerListItem = ({ career }) => {
  const { selectedCareers, handleSelectCareer } = useChartYourCourse()
  const { title, description } = career
  const { t } = useTranslation('chart_your_course')
  const selected = selectedCareers.includes(title)

  const handleInterestingClick = () => handleSelectCareer(title)

  const buttonText = selected ? t('interested') : t('interesting')

  return (
    <div className="mt-2 grid grid-cols-1 items-center md:grid-cols-2">
      <div className="flex flex-col">
        <div className="font-bold">{title}</div>
        <div>{description}</div>
      </div>
      <div>
        <FancyCheckBoxComponent
          label={buttonText}
          variant={selected ? 'default' : 'unselected'}
          className={'w-full'}
        >
          <FancyCheckBox
            checked={selected}
            onCheckedChange={handleInterestingClick}
          />
        </FancyCheckBoxComponent>
      </div>
    </div>
  )
}

export default PartTwo
