import React, { useEffect } from 'react'
import { useTranslation } from '../../../hooks/useTranslation'
import { useExercise } from '../ExerciseProvider'
import { ReturnToResourceCenter, DownloadExerciseButton } from '../Buttons'
import {
  CalendarIcon,
  ConclusionPageImage,
  ExerciseIcon,
  PersonIcon,
} from '../icons'
import { useIsMobile } from '../../../hooks/useMediaQuery'

const NetworkingPreferencesConclusion = () => {
  const {
    formattedExerciseName,
    documentId,
    dateAndTimeUpdated,
    candidateFirstName,
    ssoName,
  } = useExercise()
  const { t } = useTranslation(formattedExerciseName)

  useEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  const link = {
    linkPath: `/documents/${documentId}.pdf`,
    linkText: t('conclusion_link'),
  }

  return (
    <div className="exercise mb-0">
      <Header
        t={t}
        ssoName={ssoName}
        candidateFirstName={candidateFirstName}
        dateAndTimeUpdated={dateAndTimeUpdated}
      />
      <SummaryContent link={link} t={t} />
    </div>
  )
}

const Header = ({ t, ssoName, candidateFirstName, dateAndTimeUpdated }) => {
  const isMobile = useIsMobile()
  const { t: tExercise } = useTranslation('exercise')
  return (
    <div className="bg-intoo-blue-dark text-white">
      <div
        className={`${
          isMobile ? 'mx-4' : 'mx-8'
        } flex items-center justify-between ${isMobile ? 'pb-10' : 'pb-14'}`}
      >
        <div>
          <h1 className="mb-0 mt-10 !font-roboto-slab text-2xl text-[32px] font-medium leading-normal !text-white">
            {t(`start_exercise.title`)}
          </h1>
          <hr className="!b-0 my-4 h-[1px] w-32 bg-white text-white" />
          <div className="flex flex-col !font-roboto-slab text-[20px] sm:flex-row">
            {!ssoName && (
              <div
                className={`${
                  isMobile ? 'mb-4' : ''
                } mr-0 flex items-center sm:mr-12`}
              >
                <div className="mr-4">
                  <PersonIcon />
                </div>
                {`${candidateFirstName}'s`} {tExercise('results')}
              </div>
            )}
            <div className="flex items-center">
              <div className="mr-4">
                <CalendarIcon />
              </div>
              {dateAndTimeUpdated}
            </div>
          </div>
        </div>
        <div className="mt-10 flex">
          <ExerciseIcon />
        </div>
      </div>
    </div>
  )
}

const SummaryContent = ({ link, t }) => {
  const isMobile = useIsMobile()
  const { retakeExercise, trackContent } = useExercise()
  const evaluationData = trackContent[2]
  const { t: tExercise } = useTranslation('exercise')
  const conclusionGreatJob = t('conclusion_great_job')
  const introvertingPreferenceMatch = t('introverting_preference_match')
  const extrovertingPreferenceMatch = t('extroverting_preference_match')
  const introvertingPreferenceMismatch = t('introverting_preference_mismatch')
  const extrovertingPreferenceMismatch = t('extroverting_preference_mismatch')

  const calculatePreferenceMessage = () => {
    const preferenceMap = {
      evaluate_in_most_cases: {
        a: 'extroverting',
        b: 'introverting',
      },
      evaluate_going_to_events: {
        a: 'introverting',
        b: 'extroverting',
      },
      evaluate_prefer_events: {
        a: 'introverting',
        b: 'extroverting',
      },
      evaluate_prefer_to: {
        a: 'extroverting',
        b: 'introverting',
      },
      evaluate_prefer_event: {
        a: 'extroverting',
        b: 'introverting',
      },
    }

    let introvertingCount = 0
    let extrovertingCount = 0

    Object.keys(evaluationData).forEach((key) => {
      if (preferenceMap[key]) {
        const value = evaluationData[key]
        if (preferenceMap[key][value] === 'introverting') {
          introvertingCount++
        } else if (preferenceMap[key][value] === 'extroverting') {
          extrovertingCount++
        }
      }
    })

    const userPreference = trackContent[1].preferences

    if (introvertingCount > extrovertingCount) {
      if (userPreference === 'introverting') {
        return introvertingPreferenceMatch
      } else {
        return extrovertingPreferenceMismatch
      }
    } else {
      if (userPreference === 'extroverting') {
        return extrovertingPreferenceMatch
      } else {
        return introvertingPreferenceMismatch
      }
    }
  }

  const preferenceMessage = calculatePreferenceMessage()

  return (
    <div
      className={`summary-content ${
        isMobile ? 'p-6' : 'p-10'
      } flex flex-col bg-[#E0F2F9]`}
    >
      <div
        className={`rounded-[20px] bg-white ${
          isMobile ? 'px-4 py-12' : 'px-12 py-12'
        }`}
      >
        <div className="text-center">
          <h2 className="mb-4 text-2xl font-semibold">{conclusionGreatJob}</h2>
          <p className="!mb-6 font-roboto text-base font-normal">
            {preferenceMessage}
          </p>
          <div className="mx-auto my-6 flex justify-center">
            <ConclusionPageImage />
          </div>
        </div>
        <div className="flex flex-col">
          <div className="flex justify-center">
            <DownloadExerciseButton
              href={link.linkPath}
              text={t('conclusion_link')}
            />
          </div>
          <div className="flex justify-center">
            <a
              onClick={retakeExercise}
              className="mt-4 cursor-pointer !underline"
            >
              {tExercise('retake_exercise')}
            </a>
          </div>
        </div>
      </div>
      <ReturnToResourceCenter />
    </div>
  )
}
export default NetworkingPreferencesConclusion
