import React, { useState } from 'react'
import { useIntroductionScript } from './IntroductionScriptProvider'
import TypingEffect from '../../stories/cover_letters/CreateAi/TypingEffect'
import { Button } from '../../../lib/ui/button'
import useTranslation from '../../shared/TranslationProvider'
import Cursor from '../../stories/cover_letters/CreateAi/Cursor'

const AylaResponse = () => {
  const { aylaResponse, setCustomScript } = useIntroductionScript()
  const [typingComplete, setTypingComplete] = useState(false)
  const [displayedText, setDisplayedText] = useState('')
  const [index, setIndex] = useState(0)

  const { t } = useTranslation('ayla_response')

  const typingNotStarted = displayedText.length <= 0

  const handleUseScript = () => {
    setCustomScript(aylaResponse)
  }

  return (
    <div className="flex flex-col items-start gap-4">
      <h2 className="subtitle">{t('title')}</h2>
      <div className="mt-4 min-h-[150px] w-full rounded-[20px] border border-[#D3D3D3] bg-[white] p-3 font-roboto text-base">
        {typingNotStarted && <Cursor show={true} />}
        {aylaResponse && (
          <TypingEffect
            message={aylaResponse}
            baseSpeed={5}
            aiStreamComplete={typingComplete}
            setTypingComplete={setTypingComplete}
            displayedText={displayedText}
            setDisplayedText={setDisplayedText}
            index={index}
            setIndex={setIndex}
          />
        )}
      </div>
      {typingComplete && (
        <Button onClick={handleUseScript} title={t('use_script')}>
          {t('use_script')}
        </Button>
      )}
    </div>
  )
}

export default AylaResponse
