import React from 'react'
import { Input } from '../ui/input'
import { Label } from '../ui/label'
import { useJsonItem } from './JsonItemProvider'

const JsonItemField = ({
  containerClassName,
  label,
  fieldName,
  childField,
  handleItemUpdate = null,
  getItemValue = null,
}) => {
  const { index, sectionName, item, setItem, updateItem } = useJsonItem()

  const id = [sectionName, fieldName, childField, index].join('-')

  const onBlur = () => {
    updateItem()
  }

  const onChange = (event) => {
    const value = event.target.value || ''

    const updatedItem = handleItemUpdate
      ? handleItemUpdate(item, value)
      : childField
        ? { ...item, [fieldName]: { ...item[fieldName], [childField]: value } }
        : { ...item, [fieldName]: value }
    setItem(updatedItem)
  }

  const itemValue = getItemValue
    ? getItemValue(item)
    : childField
      ? item[fieldName]
        ? item[fieldName][childField] || ''
        : ''
      : item[fieldName] || ''

  return (
    <div className={containerClassName}>
      <Label htmlFor={id}>{label}</Label>
      <Input
        onBlur={onBlur}
        value={itemValue}
        id={id}
        name={id}
        onChange={onChange}
      />
    </div>
  )
}
export default JsonItemField
