import React from 'react'
import {
  SliderControl,
  useTemplate,
  fonts,
  SelectControl,
  ColorPickerControl,
} from '../../../lib/pdf'

const formatDecimal = (value) => {
  if (value >= 1.0) {
    return value.toFixed(2)
  } else {
    return value.toFixed(2).slice(1)
  }
}

const formatInches = (value) => {
  return `${formatDecimal(value)} in`
}

const formatPercent = (value) => {
  return `${Math.round(value * 100)}%`
}

const StyleEditor = () => {
  const { templateSettings, devMode } = useTemplate()

  return (
    <>
      <div className="mb-6 text-lg font-medium text-primary">Styling</div>
      <SelectControl title="Primary Font" settingName="primaryFontFamily">
        {fonts.map((font) => (
          <option key={font.family} value={font.family}>
            {font.family}
          </option>
        ))}
      </SelectControl>
      <SelectControl title="Accent Font" settingName="secondaryFontFamily">
        {fonts.map((font) => (
          <option key={font.family} value={font.family}>
            {font.family}
          </option>
        ))}
      </SelectControl>
      <ColorPickerControl settingName="accentColor" title="Accent Color" />
      <SliderControl
        title="Line Height"
        min={0.5}
        max={1.5}
        step={0.01}
        settingName="lineHeightMultiplier"
        formattingFunction={formatPercent}
      />

      <div className="mb-6 text-lg font-medium text-primary">Page Setup</div>
      <SelectControl title="Paper Size" settingName="size">
        <option value="Letter">Letter (8.5 x 11 in)</option>
        <option value="A4">A4 (210 x 297 mm)</option>
      </SelectControl>
      <SliderControl
        title="Top and Bottom Margin"
        min={0.4}
        max={1.5}
        step={0.01}
        settingName="yMargin"
        formattingFunction={formatInches}
      />
      <SliderControl
        title="Left and Right Margin"
        min={0.4}
        max={1.5}
        step={0.01}
        settingName="xMargin"
        formattingFunction={formatInches}
      />
      {devMode && <pre>{JSON.stringify(templateSettings, null, 2)}</pre>}
    </>
  )
}

export default StyleEditor
