import React from 'react'
import {
  TabNav,
  TabPanel,
  PanelContainer,
  ButtonContainer,
  TabButton,
} from '~/components/shared/TabNav'
import { SampleIcon } from '../shared/LandingPage/icons'

const SampleCard = ({ index, sample }) => {
  return (
    <a
      href={sample.url}
      target="_blank"
      rel="noreferrer"
      className="col-span-12 lg:col-span-6 xl:col-span-4"
    >
      <div
        key={`${sample.title}-${index}`}
        className="flex w-full items-center gap-8 rounded-3xl border border-[#B9B9B9] px-4 py-2 hover:border-black focus:border-black active:border-black"
      >
        <img
          src={sample.imageUrl}
          alt={sample.imageAlt}
          className="aspect-[8/5] h-24 rounded-xl object-cover"
        />
        <div className="flex flex-col gap-2 text-base !text-black">
          <SampleIcon />
          <div>{sample.title}</div>
        </div>
      </div>
    </a>
  )
}

const Samples = ({ tab, ...props }) => {
  const {
    coverLetterCollection,
    pitchCollection,
    personalBrandStatementCollection,
    reasonForLeavingStatementCollection,
    executiveBioCollection,
    referenceSheetCollection,
  } = props
  const defaultActiveTab = tab || 'coverLetters'
  return (
    <div className="home-container flex flex-col p-4 md:m-10 md:p-0">
      <h1 className="mb-4 !font-roboto-slab text-2xl text-[32px] font-medium text-cloud-burst">
        Personal Brand Sample Documents
      </h1>
      <div className="mb-6 md:w-3/4">
        <p>
          Review sample documents to help you prepare your personal marketing
          materials. Sample documents can be downloaded as reference as you
          complete the exercises to create your own materials.
        </p>
      </div>
      <TabNav defaultActiveTab={defaultActiveTab} mobileMode="dropdown">
        <ButtonContainer
          className="mb-8 justify-start"
          buttonClass="md:ml-4 md:first:ml-0 px-6 md:px-0"
        >
          <TabButton name="coverLetters" text="Cover Letters" />
          <TabButton name="pitchStatements" text="Pitch Statements" />
          <TabButton
            name="personalBrandStatements"
            text="Personal Brand Statements"
          />
          <TabButton
            name="reasonForLeavingStatements"
            text="Reason for Leaving Statements"
          />
          <TabButton name="executiveBios" text="Executive Bios" />
          <TabButton name="referenceSheets" text="Reference Sheets" />
        </ButtonContainer>
        <PanelContainer className="pt-6 md:min-h-[91vh] md:pt-0">
          <TabPanel name="coverLetters">
            <div className="grid w-full grid-cols-12 gap-6">
              {coverLetterCollection.map((coverLetter, index) => (
                <SampleCard key={index} sample={coverLetter} />
              ))}
            </div>
          </TabPanel>
          <TabPanel name="pitchStatements">
            <div className="grid w-full grid-cols-12 gap-6">
              {pitchCollection.map((pitch, index) => (
                <SampleCard key={index} sample={pitch} />
              ))}
            </div>
          </TabPanel>
          <TabPanel name="personalBrandStatements">
            <div className="grid w-full grid-cols-12 gap-6">
              {personalBrandStatementCollection.map(
                (personalBrandStatement, index) => (
                  <SampleCard key={index} sample={personalBrandStatement} />
                ),
              )}
            </div>
          </TabPanel>
          <TabPanel name="reasonForLeavingStatements">
            <div className="grid w-full grid-cols-12 gap-6">
              {reasonForLeavingStatementCollection.map(
                (reasonForLeavingStatement, index) => (
                  <SampleCard key={index} sample={reasonForLeavingStatement} />
                ),
              )}
            </div>
          </TabPanel>
          <TabPanel name="executiveBios">
            <div className="grid w-full grid-cols-12 gap-6">
              {executiveBioCollection.map((executiveBio, index) => (
                <SampleCard key={index} sample={executiveBio} />
              ))}
            </div>
          </TabPanel>
          <TabPanel name="referenceSheets">
            <div className="grid w-full grid-cols-12 gap-6">
              {referenceSheetCollection.map((referenceSheet, index) => (
                <SampleCard key={index} sample={referenceSheet} />
              ))}
            </div>
          </TabPanel>
        </PanelContainer>
      </TabNav>
    </div>
  )
}

export default Samples
