import React from 'react'
import { useThankYouNote } from '../ThankYouNoteProvider'
import { EditorHeading } from '../../ResumeBuilder/Editor/common'
import { EditorItemField, EditorItemProvider } from './EditorItemProvider'

const BasicsEditor = ({ templateIndex }) => {
  const { thankYouNoteDocument: { basics } = {} } = useThankYouNote()
  const { aylaThankYouNote } = useThankYouNote()

  return (
    <EditorItemProvider sectionName="basics" item={basics}>
      <div>
        {aylaThankYouNote && (
          <div className="mb-8">
            <EditorHeading>Disclaimer</EditorHeading>
            <p>
              Ayla INTOO thank you notes are crafted to help you express
              gratitude and leave a lasting impression after interviews or
              professional interactions. Ayla encourages you to personalize the
              content to ensure it reflects your unique experiences and aligns
              with the specific context of your communication.
            </p>
          </div>
        )}
        <EditorHeading>Addressee Details</EditorHeading>
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-6">
          {templateIndex == 0 && (
            <>
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Salutation"
                fieldName="salutation"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="First Name"
                fieldName="firstName"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Last Name"
                fieldName="lastName"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Position Title"
                fieldName="positionTitle"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Company Name"
                fieldName="companyName"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Company Division"
                fieldName="companyDivision"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Company Street"
                fieldName="companyStreet"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Company City"
                fieldName="companyCity"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Company State"
                fieldName="companyState"
              />
              <EditorItemField
                containerClassName="md:col-span-3"
                label="Company ZIP Code"
                fieldName="companyZip"
              />
            </>
          )}
          {(templateIndex == 1 || templateIndex == 2) && (
            <>
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Salutation"
                fieldName="salutation"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="First Name"
                fieldName="firstName"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Last Name"
                fieldName="lastName"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Position Title"
                fieldName="positionTitle"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Company Name"
                fieldName="companyName"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Company Division"
                fieldName="companyDivision"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Address Line 1"
                fieldName="address1"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="Address Line 2"
                fieldName="address2"
              />
              <EditorItemField
                containerClassName="md:col-span-2"
                label="City, State, Zip Code"
                fieldName="cityStateZip"
              />
            </>
          )}
        </div>
      </div>
    </EditorItemProvider>
  )
}
export default BasicsEditor
