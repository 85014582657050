import React, { useState } from 'react'
import ExpandedCell from './ExpandedCell'
import { useSavedJobs } from './SavedJobsProvider'
import { columns } from './columns'
import {
  flexRender,
  getCoreRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table'
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../../lib/ui/table'
import { Button } from '../../../lib/ui/button'
import { useIsDesktop } from '../../../hooks/useMediaQuery'
import { useTranslation } from '../../../hooks/useTranslation'
import { useQueryClient } from '@tanstack/react-query'
import NoSavedJobsImage from './NoSavedJobsImage'
import { TrashIcon } from './Icons'
import { ChevronDownIcon } from '@heroicons/react/24/outline'
import { cn } from '../../../lib/utils'

export function DataTable() {
  const { t } = useTranslation('DataTable')
  const {
    jobs,
    sortBy: {
      setSorting,
      sorting,
      setColumnFilters,
      columnFilters,
      statusSortingOrder,
    },
    deleteSavedJob,
  } = useSavedJobs()

  const [columnVisibility, setColumnVisibility] = React.useState({
    updated_at: false,
  })
  const [expanded, setExpanded] = useState({})

  const isDesktop = useIsDesktop()

  React.useEffect(() => {
    setExpanded({})
    if (isDesktop) {
      setColumnVisibility({
        title: true,
        created_at: true,
        updated_at: false,
        followup_on: true,
        status: true,
      })
    } else {
      setColumnVisibility({
        title: true,
        created_at: false,
        updated_at: false,
        followup_on: false,
        status: true,
      })
    }
  }, [isDesktop])

  const queryClient = useQueryClient()

  const table = useReactTable({
    debugTable: true,
    data: jobs,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getRowCanExpand: () => true,
    state: {
      columnVisibility,
      sorting,
      columnFilters,
      expanded,
    },
    onExpandedChange: setExpanded,
    getExpandedRowModel: getExpandedRowModel(),
    enableMultiSorting: true,
    onSortingChange: setSorting,
    onColumnFiltersChange: setColumnFilters,
    sortingFns: {
      statusSortingFn: (rowA, rowB, _columnId) => {
        const valueA = rowA.original.status
        const valueB = rowB.original.status
        return (
          statusSortingOrder.indexOf(valueA) -
          statusSortingOrder.indexOf(valueB)
        )
      },
    },
    meta: {
      updateData: (rowIndex, columnId, value) => {
        queryClient.setQueryData(['get-saved-jobs'], (old) =>
          old.map((row, index) => {
            if (index === rowIndex) {
              return {
                ...old[rowIndex],
                [columnId]: value,
              }
            }
            return row
          }),
        )
      },
    },
  })

  const [lastUpdatedId, setLastUpdatedId] = React.useState()

  const handleRowClick = (row) => () => {
    setLastUpdatedId(row.id)
  }

  const handleDeleteItem = async (e, row) => {
    e.preventDefault()
    queryClient.setQueryData(['get-saved-jobs'], (old) =>
      old.filter((oldRow) => oldRow.id !== row.original.id),
    )
    deleteSavedJob(row.original)
  }

  return (
    <div className="rounded-2xl border border-alto-200">
      <Table tableClass="rounded-2xl">
        <TableHeader className="rounded-2xl bg-intoo-blue-medium">
          {table.getHeaderGroups().map((headerGroup) => (
            <TableRow
              key={`saved_job_header_${headerGroup.id}`}
              className="border-alto-200 bg-intoo-blue-medium p-4 text-white hover:bg-intoo-blue-medium sm:px-8 sm:py-6"
            >
              {headerGroup.headers.map((header) => {
                return (
                  <TableHead
                    key={header.id}
                    className="job-search-table-header font-weight-700 h-16 pl-0 font-roboto text-lg font-bold text-foreground data-[col-id=status]:w-[96px] sm:h-16 sm:px-4 data-[col-id=status]:sm:w-[120px]"
                    data-col-id={header.column.id}
                  >
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                )
              })}
              <TableHead className="job-search-table-header h-6 pl-0 text-sm font-bold text-foreground data-[col-id=status]:w-[96px] sm:h-12 sm:px-4 data-[col-id=status]:sm:w-[120px]">
                {null}
              </TableHead>
              <TableHead className="job-search-table-header h-6 pl-0 text-sm font-bold text-foreground data-[col-id=status]:w-[96px] sm:h-12 sm:px-4 data-[col-id=status]:sm:w-[120px]">
                {null}
              </TableHead>
            </TableRow>
          ))}
        </TableHeader>
        <TableBody>
          {table.getRowModel().rows?.length ? (
            table.getRowModel().rows.map((row, idx) => (
              <>
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && 'selected'}
                  fred={5}
                  className={`${
                    idx % 2
                      ? 'bg-[#e2e2e2] bg-opacity-30 hover:bg-[#e2e2e2] hover:bg-opacity-30 data-[state=selected]:bg-[#e2e2e2] data-[state=selected]:bg-opacity-30'
                      : 'bg-white hover:bg-white data-[state=selected]:bg-white'
                  } border-alto-200`}
                  data-last-updated={row.id === lastUpdatedId}
                  onClick={handleRowClick(row)}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell
                      key={cell.id}
                      className="pb-2 pl-0 pr-0 pt-3 sm:p-4 sm:pr-1"
                    >
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                  {row.getCanExpand() && (
                    <TableCell className="text-right">
                      <Button
                        className="w-48 text-base"
                        {...{ onClick: row.getToggleExpandedHandler() }}
                      >
                        {row.getIsExpanded()
                          ? t('hide_details')
                          : t('expand_details')}
                        <ChevronDownIcon
                          className={cn(
                            'ml-auto inline-block h-4 w-4 stroke-2 transition-all duration-500',
                            row.getIsExpanded() && 'rotate-180',
                          )}
                        />
                      </Button>
                    </TableCell>
                  )}
                  <TableCell className="text-right">
                    <Button
                      onClick={(e) => handleDeleteItem(e, row)}
                      className="bg-transparent pr-0 hover:bg-transparent"
                      title={t('delete')}
                    >
                      <TrashIcon
                        className={
                          'bg-transparent text-red-500 hover:bg-transparent hover:text-red-400'
                        }
                      />
                    </Button>
                  </TableCell>
                </TableRow>
                <TableRow
                  className={cn(
                    `${
                      idx % 2
                        ? 'bg-[#e2e2e2] bg-opacity-30 hover:bg-[#e2e2e2] hover:bg-opacity-30 data-[state=selected]:bg-[#e2e2e2] data-[state=selected]:bg-opacity-30'
                        : 'bg-white hover:bg-white data-[state=selected]:bg-white'
                    } `,
                    row.getIsExpanded() ? '' : 'border-b-0 p-0',
                  )}
                >
                  <TableCell
                    colSpan={columns.length + 2}
                    className={cn(
                      'p-0',
                      row.getIsExpanded() ? '' : 'border-b-0',
                    )}
                  >
                    <div
                      className={cn(
                        'transition-all duration-500',
                        row.getIsExpanded()
                          ? 'visible max-h-screen overflow-scroll md:overflow-hidden'
                          : 'invisible max-h-0 overflow-hidden',
                      )}
                    >
                      <ExpandedCell row={row} />
                    </div>
                  </TableCell>
                </TableRow>
              </>
            ))
          ) : (
            <TableRow>
              <TableCell colSpan={columns.length} className="h-24">
                <div className="flex flex-col items-center justify-center">
                  <NoSavedJobsImage />
                  <div className="max-w-96 text-center">
                    <h2 className="p-8">{t('no_saved_jobs_title')}</h2>
                    <p className="text-lg">{t('no_saved_jobs_message')}</p>
                    <p className="text-lg">
                      <a href="/job-search/jobs">{t('explore_jobs')}</a>
                    </p>
                  </div>
                </div>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  )
}
