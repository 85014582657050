import * as React from 'react'
import Cursor from './Cursor'

export default function TypingEffect({
  message,
  baseSpeed,
  aiStreamComplete,
  setTypingComplete,
  displayedText,
  setDisplayedText,
  index,
  setIndex,
}) {
  React.useEffect(() => {
    const getSpeed = (char) => {
      if (char === '.' || char === '!' || char === ',') {
        return baseSpeed
      } else if (char === ' ') {
        return baseSpeed
      } else {
        return baseSpeed
      }
    }

    if (index < message.length) {
      setTypingComplete(false)
      const currentChar = message.charAt(index)
      const timeoutId = setTimeout(() => {
        setDisplayedText((prev) => prev + currentChar)
        setIndex(index + 1)
      }, getSpeed(currentChar))

      return () => clearTimeout(timeoutId)
    } else {
      // document.getElementById('copy-button').style.display = 'flex'
      // document.getElementById('download-button').style.display = 'block'
      // document.getElementById('ayla-cover-letter-text').style.display = 'block'
      // document.getElementById('cover-letter-buttons').style.display = 'flex'
      setTypingComplete(true)
    }
  }, [
    displayedText,
    message,
    index,
    baseSpeed,
    setDisplayedText,
    setTypingComplete,
    setIndex,
  ])

  const paragraphs = displayedText.split('\n\n')

  const showCursor = (paragraphIndex) => {
    if (aiStreamComplete) return false

    return paragraphIndex === paragraphs.length - 1
  }

  return (
    <>
      {paragraphs.map((paragraph, index) => (
        <p key={index} className="m-4">
          {paragraph}
          <Cursor show={showCursor(index)} />
        </p>
      ))}
      <Cursor show={showCursor(-1)} />
    </>
  )
}
