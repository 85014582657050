import React from 'react'
import { cn } from '~/lib/utils'
import TabNav, { useTabNav } from './TabNav'
import ButtonContainer from './ButtonContainer'
import TabButton from './TabButton'

const TabPanel = ({ name, className, children, asChild }) => {
  const { activeTab } = useTabNav()
  if (activeTab != name) {
    return null
  }
  if (asChild) {
    return children
  }
  return <div className={cn('h-full', className)}>{children}</div>
}

const PanelContainer = ({ className, asChild, children }) => {
  if (asChild) {
    return children
  }
  return (
    <div className={cn('h-full w-full flex-grow', className)}>{children}</div>
  )
}

export {
  TabNav,
  TabPanel,
  PanelContainer,
  ButtonContainer,
  TabButton,
  useTabNav,
}
