import React from 'react'
import parse from 'html-react-parser'
import { useDashboard } from './DashboardProvider'
import { useTranslation } from '~/hooks/useTranslation'
import { Card, Typography } from '~/components/_atoms'
import { Modal, Stack } from '~/components/_layouts'
import { UserIcon } from '@heroicons/react/24/outline'
import Spinner from '~/components/shared/Spinner'

const CoachInfoDialog = ({ coach }) => {
  const { showCoachInfoDialog, toggleShowCoachInfoDialog } = useDashboard()
  const { t } = useTranslation()

  const {
    yearsExperience,
    numClientsSupported,
    satisfactionRating,
    numRatings,
  } = coach?.accolades || {}

  const bio = coach?.bio?.bio ? coach.bio.bio : coach.bio
  const accoladesDisplay = []
  if (yearsExperience > 0) {
    accoladesDisplay.push(
      t('coach_info_modal.experience', { years: yearsExperience }),
    )
  }
  if (numClientsSupported > 0) {
    accoladesDisplay.push(
      t('coach_info_modal.clients_supported', { count: numClientsSupported }),
    )
  }
  if (numRatings > 0 && satisfactionRating > 0) {
    accoladesDisplay.push(
      t('coach_info_modal.satisfaction', {
        rating: satisfactionRating,
      }),
    )
  }
  const skills = coach.skills

  return (
    <Modal
      title={
        <Stack direction="row" spacing={2}>
          <UserIcon className="h-6 w-6" />
          {t('coach_info_modal.title')}
        </Stack>
      }
      variant="secondary"
      size="md"
      open={showCoachInfoDialog}
      onClose={() => toggleShowCoachInfoDialog()}
    >
      <div className="pt-6">
        <Card color="secondary" shadow="none">
          {coach ? (
            <Stack spacing={6}>
              <div className="flex items-center gap-4">
                {coach?.headshot ? (
                  <img
                    className="mt-[-50px] h-28 w-28 object-contain"
                    src={coach?.headshot}
                    alt="Coach headshot"
                  />
                ) : (
                  <img
                    className="mt-[-50px] h-28 w-28 rounded-full"
                    src={coach?.image}
                    alt="Coach Image"
                  />
                )}
                <Stack spacing={3}>
                  <Typography variant="title4" color="secondary">
                    {coach.name}
                  </Typography>
                </Stack>
              </div>
              <div className="grid grid-cols-3 gap-y-1">
                {skills.map((skill, index) => {
                  const slot = index + 1
                  const firstInRow = index % 3 === 0
                  let lastInRow = slot % 3 === 0
                  if (slot === skills.length) lastInRow = true

                  return (
                    <div
                      key={skill}
                      className={`relative z-10 ${skills.length % 3 > 0 && slot === skills.length ? ` col-span-${3 - (slot % 3) + 1}` : ''} border border-intoo-green-medium-800 bg-intoo-green-medium-800 p-3 text-center text-white ${firstInRow ? 'rounded-l-md' : ''} ${lastInRow ? 'rounded-r-md' : ''}`}
                    >
                      <Typography className="font-medium text-white">
                        {skill}
                      </Typography>
                      {!lastInRow && (
                        <div className="absolute -top-px right-1 z-50 flex h-[calc(100%+2px)] w-1 -skew-x-12 justify-center bg-intoo-green-medium-50"></div>
                      )}
                    </div>
                  )
                })}
              </div>
              <Stack spacing={1}>
                <Typography variant="title4" color="secondary">
                  {t('coach_info_modal.bio', {
                    name: coach?.first_name || coach?.name,
                  })}
                </Typography>
                <Typography className="max-h-48 overflow-auto">
                  {parse(bio)}
                </Typography>
              </Stack>
              <div className="grid grid-cols-2 gap-1">
                {accoladesDisplay.map((a) => (
                  <div
                    key={a}
                    className="rounded-md border border-cod-gray-100 bg-white/75 p-3"
                  >
                    <Typography color="secondary" className="font-medium">
                      {a}
                    </Typography>
                  </div>
                ))}
              </div>
            </Stack>
          ) : (
            <Spinner show size={9} />
          )}
        </Card>
      </div>
    </Modal>
  )
}
export default CoachInfoDialog
