import React from 'react'
import { TrashIcon } from '@heroicons/react/24/outline'
import ExpandyPanel from '../../../lib/ui/ExpandyPanel'
import { EditorItemField, useEditorItem } from './EditorItemProvider'
import { Textarea } from '../../../lib/ui/textarea'

const CoverLetterItem = () => {
  const {
    index,
    activeIndex,
    setActiveIndex,
    deleteItem,
    item,
    setItem,
    updateItem,
  } = useEditorItem()

  const headingLabel = item.name ? item.name : 'New Paragraph'

  const toggleExpanded = () => {
    if (activeIndex == index) {
      setActiveIndex(-1)
    } else {
      setActiveIndex(index)
    }
  }

  const setConnection = (connection) => {
    const newCoverLetterItem = { ...item, connection }
    setItem(newCoverLetterItem)
  }

  const expanded = activeIndex == index
  return (
    <div className="mb-4 flex flex-row items-center">
      <ExpandyPanel
        headingText={headingLabel}
        expanded={expanded}
        index={index}
        onClick={toggleExpanded}
      >
        <div className="grid grid-cols-1 gap-x-6 gap-y-4 md:grid-cols-2">
          <EditorItemField
            containerClassName="md:col-span-2"
            label="Full Name"
            fieldName="name"
          />
          <EditorItemField label="Job Title" fieldName="positionTitle" />
          <EditorItemField label="Company Name" fieldName="companyName" />
          <EditorItemField label="Phone" fieldName="phoneNumber" />
          <EditorItemField label="Email" fieldName="email" />
          <EditorItemField label="Address" fieldName="streetAddress" />
          <EditorItemField label="City, State, Zip" fieldName="cityStateZip" />
        </div>
        <div className="mt-4">
          <span className="font-bold">Write a paragraph.</span>
          <Textarea
            onChange={(e) => setConnection(e.target.value)}
            onBlur={updateItem}
            value={item.connection}
          />
        </div>
      </ExpandyPanel>
      {!expanded && (
        <button
          onClick={deleteItem}
          className="max-w-min text-[#b9b9b9]"
          aria-label="remove education"
        >
          <TrashIcon className="ml-2 w-8" />
        </button>
      )}
    </div>
  )
}

export default CoverLetterItem
