import React from 'react'
import {
  LongTermGoals,
  OrganizationalCulture,
  StressAndStrategies,
  CoachingGoals,
} from '../QuestionBank'
import { useProgramEvaluation } from '../ProgramEvaluationProvider'
import { Tabs, TabsList, TabsTrigger, TabsContent } from '~/lib/ui/tabs'
import { FormLabel } from '~/lib/ui/form'
import Audio from '../Audio'

const Executive = ({ form }) => {
  const { longTermGoalsBlob, coachingGoalsBlob } = useProgramEvaluation()

  return (
    <>
      <Tabs defaultValue="text" className="col-span-6">
        <TabsList>
          <TabsTrigger disabled={!!longTermGoalsBlob} value="text">
            Text
          </TabsTrigger>
          <TabsTrigger value="audio">Voice Recording</TabsTrigger>
        </TabsList>
        <TabsContent value="text">
          <LongTermGoals form={form} />
        </TabsContent>
        <TabsContent value="audio">
          <FormLabel className="mb-[5px]">
            It is helpful to share some of your goals and aspirations with your
            coach. Share with us how you feel your executive coaching program
            can help you grow as a leader. Where do you hope to be in your
            leadership journey in the next year and how do you envision your
            leadership style developing?
          </FormLabel>
          <LongTermGoalsAudio />
        </TabsContent>
      </Tabs>
      <OrganizationalCulture form={form} />
      <StressAndStrategies form={form} />
      <Tabs defaultValue="text" className="col-span-6">
        <TabsList>
          <TabsTrigger disabled={!!coachingGoalsBlob} value="text">
            Text
          </TabsTrigger>
          <TabsTrigger value="audio">Voice Recording</TabsTrigger>
        </TabsList>
        <TabsContent value="text">
          <CoachingGoals
            form={form}
            label={`In order to identify a great coach match for you, please share any thoughts you have about what coaching style you prefer and what you’re looking for in your coach.`}
          />
        </TabsContent>
        <TabsContent value="audio">
          <FormLabel>
            In order to identify a great coach match for you, please share any
            thoughts you have about what coaching style you prefer and what
            you’re looking for in your coach.
          </FormLabel>
          <CoachingGoalsAudio />
        </TabsContent>
      </Tabs>
    </>
  )
}
export default Executive

const LongTermGoalsAudio = () => {
  const {
    setLongTermGoalsBlob,
    longTermGoalsBlob,
    longTermGoalsSrc,
    setLongTermGoalsSrc,
  } = useProgramEvaluation()

  const handleAudioStop = (blob) => {
    const url = URL.createObjectURL(blob)
    setLongTermGoalsSrc(url)
    setLongTermGoalsBlob(blob)
  }

  const deleteRecording = () => {
    setLongTermGoalsSrc('')
    setLongTermGoalsBlob(null)
  }

  return (
    <Audio
      audioSrc={longTermGoalsSrc}
      setAudioSrc={setLongTermGoalsSrc}
      name="long_term_goals"
      onRecordingComplete={(blob) => handleAudioStop(blob)}
      deleteRecording={deleteRecording}
      showAudio={!!longTermGoalsBlob}
    />
  )
}

const CoachingGoalsAudio = () => {
  const {
    setCoachingGoalsBlob,
    coachingGoalsBlob,
    coachingGoalsSrc,
    setCoachingGoalsSrc,
  } = useProgramEvaluation()

  const handleAudioStop = (blob) => {
    const url = URL.createObjectURL(blob)
    setCoachingGoalsSrc(url)
    setCoachingGoalsBlob(blob)
  }

  const deleteRecording = () => {
    setCoachingGoalsSrc('')
    setCoachingGoalsBlob(null)
  }

  return (
    <Audio
      audioSrc={coachingGoalsSrc}
      setAudioSrc={setCoachingGoalsSrc}
      name="coaching_goals"
      onRecordingComplete={(blob) => handleAudioStop(blob)}
      deleteRecording={deleteRecording}
      showAudio={!!coachingGoalsBlob}
    />
  )
}
