import React from 'react'
import { cn } from '../../../lib/utils'

export const QuizIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 24
  const width = 24
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className={displayClass}
      width={width}
      height={height}
    >
      <title>Quizzes Icon</title>
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M14.25 6.087c0-.355.186-.676.401-.959.221-.29.349-.634.349-1.003 0-1.036-1.007-1.875-2.25-1.875s-2.25.84-2.25 1.875c0 .369.128.713.349 1.003.215.283.401.604.401.959v0a.64.64 0 0 1-.657.643 48.39 48.39 0 0 1-4.163-.3c.186 1.613.293 3.25.315 4.907a.656.656 0 0 1-.658.663v0c-.355 0-.676-.186-.959-.401a1.647 1.647 0 0 0-1.003-.349c-1.036 0-1.875 1.007-1.875 2.25s.84 2.25 1.875 2.25c.369 0 .713-.128 1.003-.349.283-.215.604-.401.959-.401v0c.31 0 .555.26.532.57a48.039 48.039 0 0 1-.642 5.056c1.518.19 3.058.309 4.616.354a.64.64 0 0 0 .657-.643v0c0-.355-.186-.676-.401-.959a1.647 1.647 0 0 1-.349-1.003c0-1.035 1.008-1.875 2.25-1.875 1.243 0 2.25.84 2.25 1.875 0 .369-.128.713-.349 1.003-.215.283-.4.604-.4.959v0c0 .333.277.599.61.58a48.1 48.1 0 0 0 5.427-.63 48.05 48.05 0 0 0 .582-4.717.532.532 0 0 0-.533-.57v0c-.355 0-.676.186-.959.401-.29.221-.634.349-1.003.349-1.035 0-1.875-1.007-1.875-2.25s.84-2.25 1.875-2.25c.37 0 .713.128 1.003.349.283.215.604.401.96.401v0a.656.656 0 0 0 .658-.663 48.422 48.422 0 0 0-.37-5.36c-1.886.342-3.81.574-5.766.689a.578.578 0 0 1-.61-.58v0Z"
      />
    </svg>
  )
}

export const ExerciseIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 24
  const width = 24
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
    >
      <title>Exercises Icon</title>
      <path
        d="M5 19C7.20914 19 9 17.2091 9 15C9 12.7909 7.20914 11 5 11C2.79086 11 1 12.7909 1 15C1 17.2091 2.79086 19 5 19Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 7.5H14.5M14.5 7.5H15L19 15M14.5 7.5L16.5 4.5M16.5 4.5H14M16.5 4.5H18.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M5 15L8.5 7.5L12 14H15"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M8.5 7.5C8.16667 6.5 7 4.5 5 4.5"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19 19C21.2091 19 23 17.2091 23 15C23 12.7909 21.2091 11 19 11C16.7909 11 15 12.7909 15 15C15 17.2091 16.7909 19 19 19Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const BuilderIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 24
  const width = 24
  return (
    <svg
      viewBox="0 0 75 75"
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width={width}
      height={height}
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M35.6874 47.4063L53.9061 65.625C55.4686 67.1376 57.563 67.9754 59.7376 67.9577C61.9122 67.9401 63.9928 67.0684 65.5305 65.5306C67.0682 63.9929 67.9399 61.9124 67.9576 59.7378C67.9752 57.5632 67.1374 55.4687 65.6249 53.9062L47.2592 35.5406M35.6874 47.4063L43.4874 37.9375C44.478 36.7375 45.7999 35.9812 47.2624 35.5437C48.9811 35.0312 50.8967 34.9563 52.7092 35.1063C55.1525 35.316 57.6081 34.8833 59.8324 33.851C62.0567 32.8187 63.9724 31.2226 65.3894 29.2213C66.8064 27.2199 67.6754 24.8828 67.9102 22.4419C68.145 20.0009 67.7375 17.541 66.728 15.3062L56.4905 25.5469C54.7777 25.1508 53.2105 24.2818 51.9674 23.0387C50.7244 21.7956 49.8553 20.2284 49.4592 18.5156L59.6967 8.27812C57.462 7.26863 55.0021 6.86107 52.5611 7.09589C50.1202 7.3307 47.7831 8.19972 45.7817 9.61671C43.7804 11.0337 42.1843 12.9494 41.152 15.1737C40.1197 17.3981 39.6869 19.8537 39.8967 22.2969C40.1811 25.6594 39.6749 29.3719 37.0717 31.5156L36.753 31.7813M35.6874 47.4063L21.1405 65.0719C20.4355 65.9313 19.5583 66.6335 18.5655 67.1335C17.5727 67.6334 16.4861 67.9199 15.3759 67.9745C14.2657 68.0292 13.1563 67.8507 12.1191 67.4507C11.082 67.0507 10.1401 66.438 9.35414 65.652C8.56812 64.866 7.95539 63.9241 7.55539 62.887C7.15538 61.8498 6.97696 60.7405 7.0316 59.6302C7.08624 58.52 7.37275 57.4335 7.87267 56.4406C8.37259 55.4478 9.07484 54.5707 9.93424 53.8656L31.2999 36.2719L18.4655 23.4375H14.0624L7.03112 11.7188L11.7186 7.03125L23.4374 14.0625V18.4656L36.7499 31.7781L31.2967 36.2687M57.4217 57.4219L49.2186 49.2188M15.2092 59.7656H15.2342V59.7906H15.2092V59.7656Z"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const WorkshopIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 24
  const width = 24
  return (
    <svg
      viewBox="0 0 75 75"
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width={width}
      height={height}
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M33.2303 34.5488L6.71375 61.0651L13.3429 67.6944L39.8594 41.1779"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M33.2303 34.5486L37.6497 30.129C37.6497 30.129 38.7546 20.1854 27.7061 9.13683L31.0206 5.82227L57.5371 23.4999L54.2225 26.8145L58.6418 31.2339L61.9565 27.9193L69.6906 35.6533L54.2225 51.1214L46.4887 43.3874L49.8031 40.0727L45.3837 35.6533L39.8593 41.1777L33.2303 34.5486Z"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ChecklistIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 24
  const width = 24
  return (
    <svg
      viewBox="0 0 75 75"
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width={width}
      height={height}
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M35.4688 11.9875C35.2656 12.6437 35.1562 13.3406 35.1562 14.0625C35.1562 15.3563 36.2062 16.4062 37.5 16.4062H51.5625C52.1841 16.4062 52.7802 16.1593 53.2198 15.7198C53.6593 15.2802 53.9062 14.6841 53.9062 14.0625C53.9065 13.3591 53.8011 12.6596 53.5937 11.9875M35.4688 11.9875C35.9126 10.5522 36.8043 9.29667 38.0133 8.40481C39.2223 7.51295 40.6851 7.0316 42.1875 7.03125H46.875C50.0375 7.03125 52.7094 9.11875 53.5937 11.9875M35.4688 11.9875C34.2938 12.0594 33.125 12.1437 31.9562 12.2375C28.4219 12.5312 25.7812 15.5406 25.7812 19.0875V25.7812M53.5937 11.9875C54.7687 12.0594 55.9375 12.1437 57.1063 12.2375C60.6406 12.5312 63.2812 15.5406 63.2812 19.0875V51.5625C63.2812 53.4273 62.5405 55.2157 61.2218 56.5343C59.9032 57.853 58.1148 58.5938 56.25 58.5938H49.2188M25.7812 25.7812H15.2344C13.2938 25.7812 11.7188 27.3563 11.7188 29.2969V64.4531C11.7188 66.3937 13.2938 67.9688 15.2344 67.9688H45.7031C47.6437 67.9688 49.2188 66.3937 49.2188 64.4531V58.5938M25.7812 25.7812H45.7031C47.6437 25.7812 49.2188 27.3563 49.2188 29.2969V58.5938M23.4375 49.2188L28.125 53.9062L37.5 42.1875"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SampleIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 40
  const width = 40
  return (
    <svg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width={width}
      height={height}
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M32.5 23.75V19.375C32.5 17.8832 31.9074 16.4524 30.8525 15.3975C29.7976 14.3426 28.3668 13.75 26.875 13.75H24.375C23.8777 13.75 23.4008 13.5525 23.0492 13.2008C22.6975 12.8492 22.5 12.3723 22.5 11.875V9.375C22.5 7.88316 21.9074 6.45242 20.8525 5.39752C19.7976 4.34263 18.3668 3.75 16.875 3.75H13.75M13.75 25H26.25M13.75 30H20M17.5 3.75H9.375C8.34 3.75 7.5 4.59 7.5 5.625V34.375C7.5 35.41 8.34 36.25 9.375 36.25H30.625C31.66 36.25 32.5 35.41 32.5 34.375V18.75C32.5 14.7718 30.9196 10.9564 28.1066 8.1434C25.2936 5.33035 21.4782 3.75 17.5 3.75Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ShortCourseIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 40
  const width = 40
  return (
    <svg
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width={width}
      height={height}
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <path
        d="M20 30V20M20 20V5.6832C20 4.96528 20.734 4.48126 21.394 4.76406L35.4672 10.7955C36.2582 11.1344 36.2802 12.2476 35.5033 12.6175L20 20Z M20 36.6667C26.4433 36.6667 31.6667 34.055 31.6667 30.8333C31.6667 27.6117 26.4433 25 20 25C13.5567 25 8.33334 27.6117 8.33334 30.8333C8.33334 34.055 13.5567 36.6667 20 36.6667Z"
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const ArticleIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 40
  const width = 40
  return (
    <svg
      viewBox="0 0 75 75"
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width={width}
      height={height}
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>Articles</title>
      <path
        d="M33.0803 33.0797L52.9675 22.0312L41.9191 41.9187L22.0316 52.9672L33.0803 33.0797Z"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M37.5 68.75C54.7587 68.75 68.75 54.7587 68.75 37.5C68.75 20.2411 54.7587 6.25 37.5 6.25C20.2411 6.25 6.25 20.2411 6.25 37.5C6.25 54.7587 20.2411 68.75 37.5 68.75Z"
        strokeWidth="3.5"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}

export const SearchIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 40
  const width = 40
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>Search</title>
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z"
      />
    </svg>
  )
}

export const CollectionIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 40
  const width = 40
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>Search</title>
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M12 4.5v15m7.5-7.5h-15"
      />
    </svg>
  )
}

export const ScriptsIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 40
  const width = 40
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className={displayClass}
      role="img"
      width={width}
      height={height}
      viewBox="0 0 24 24"
      fill="none"
      preserveAspectRatio="xMidYMid meet"
    >
      <title>Quote</title>
      <path
        d="M3 20.2895V5C3 3.89543 3.89543 3 5 3H19C20.1046 3 21 3.89543 21 5V15C21 16.1046 20.1046 17 19 17H7.96125C7.35368 17 6.77906 17.2762 6.39951 17.7506L4.06852 20.6643C3.71421 21.1072 3 20.8567 3 20.2895Z"
        strokeWidth="1.5"
      ></path>
      <path
        d="M10.5 10H8.5C7.94772 10 7.5 9.55228 7.5 9V8C7.5 7.44772 7.94772 7 8.5 7H9.5C10.0523 7 10.5 7.44772 10.5 8V10ZM10.5 10C10.5 11 9.5 12 8.5 13"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>
      <path
        d="M16.5 10H14.5C13.9477 10 13.5 9.55228 13.5 9V8C13.5 7.44772 13.9477 7 14.5 7H15.5C16.0523 7 16.5 7.44772 16.5 8V10ZM16.5 10C16.5 11 15.5 12 14.5 13"
        strokeWidth="1.5"
        strokeLinecap="round"
      ></path>
    </svg>
  )
}

export const WebinarIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 24
  const width = 24
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      className={displayClass}
      width={width}
      height={height}
    >
      <title>Webinar Icon</title>
      <path
        strokeWidth="1.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M9 17.25v1.007a3 3 0 0 1-.879 2.122L7.5 21h9l-.621-.621A3 3 0 0 1 15 18.257V17.25m6-12V15a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 15V5.25m18 0A2.25 2.25 0 0 0 18.75 3H5.25A2.25 2.25 0 0 0 3 5.25m18 0V12a2.25 2.25 0 0 1-2.25 2.25H5.25A2.25 2.25 0 0 1 3 12V5.25"
      />
    </svg>
  )
}

export const AudioIcon = ({ className }) => {
  const displayClass = cn('inline stroke-black', className)
  const height = 24
  const width = 24
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      fill="none"
      viewBox="0 0 75 75"
      stroke="currentColor"
      className={displayClass}
      width={width}
      height={height}
    >
      <title>Videos and Audio Icon</title>
      <path
        strokeWidth="2.5"
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M49.2188 32.8125L63.9687 18.0625C64.2965 17.7351 64.714 17.5122 65.1683 17.422C65.6227 17.3317 66.0936 17.3781 66.5216 17.5554C66.9496 17.7326 67.3155 18.0327 67.5731 18.4177C67.8307 18.8028 67.9683 19.2555 67.9688 19.7187V55.2813C67.9683 55.7445 67.8307 56.1972 67.5731 56.5823C67.3155 56.9673 66.9496 57.2674 66.5216 57.4446C66.0936 57.6219 65.6227 57.6683 65.1683 57.578C64.714 57.4878 64.2965 57.2649 63.9687 56.9375L49.2188 42.1875M14.0625 58.5938H42.1875C44.0523 58.5937 45.8407 57.853 47.1593 56.5343C48.478 55.2157 49.2188 53.4273 49.2188 51.5625V23.4375C49.2188 21.5727 48.478 19.7843 47.1593 18.4657C45.8407 17.147 44.0523 16.4063 42.1875 16.4062H14.0625C12.1977 16.4062 10.4093 17.147 9.09066 18.4657C7.77204 19.7843 7.03125 21.5727 7.03125 23.4375V51.5625C7.03125 53.4273 7.77204 55.2157 9.09066 56.5343C10.4093 57.853 12.1977 58.5938 14.0625 58.5938Z"
      />
    </svg>
  )
}
