import * as React from 'react'

const useTypingAnimator = (start, finalVal, initialVal = '') => {
  const [val, setVal] = React.useState(initialVal)

  React.useEffect(() => {
    const handleTyping = () => {
      const valArr = finalVal.split('')
      let tempVal = ''
      const animation = () => {
        const interval = setInterval(() => {
          if (valArr.length > 0) {
            tempVal += valArr.shift()
            setVal(tempVal)
          } else {
            clearInterval(interval)
            setVal(tempVal)
          }
        }, 100)
      }

      animation()
    }
    if (start) {
      setTimeout(() => {
        handleTyping()
      }, 1000)
    }
  }, [start, finalVal])

  return val
}

export default useTypingAnimator
