import React from 'react'

const TemplateSwitcher = ({
  jsonDocument,
  templateIndex,
  templateStyle,
  candidateName,
  Template,
}) => {
  if (!Template) {
    console.error('Invalid template index', templateIndex)
    return null
  } else if (!Template.Template) {
    console.error('Selected file does not export a Template', Template)
    return null
  }

  return (
    <Template.Template
      jsonDocument={jsonDocument}
      templateStyle={templateStyle}
      candidateName={candidateName}
    />
  )
}
export default TemplateSwitcher
