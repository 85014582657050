import React from 'react'
import { useExercise } from '../ExerciseProvider'
import useTranslation from '../../shared/TranslationProvider'
import PartOne from './PartOne'
import { ChartYourCourseProvider } from './ChartYourCourseProvider'
import PartTwo from './PartTwo'
import PartThree from './PartThree'

const ChartYourCourseExerciseInner = () => {
  const { exerciseIndex } = useExercise()
  const { t } = useTranslation('chart_your_course')
  const sectionInfo = t(`pages.${exerciseIndex}`)

  return (
    <div>
      <div>
        {exerciseIndex === 0 && <PartOne />}
        {exerciseIndex === 1 && <PartTwo sectionInfo={sectionInfo} />}
        {exerciseIndex === 2 && <PartThree />}
      </div>
    </div>
  )
}

const ChartYourCourseExercise = ({ ...props }) => {
  return (
    <ChartYourCourseProvider {...props}>
      <ChartYourCourseExerciseInner />
    </ChartYourCourseProvider>
  )
}
export default ChartYourCourseExercise
