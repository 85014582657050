import React from 'react'
import ResourcesProvider, { useResources } from './ResourcesProvider'

import useTranslation, {
  TranslationProvider,
} from '~/components/shared/TranslationProvider'
import translations from './locales.json'

import { TabNav } from '~/components/shared/TabNav'
import TabNavButtons from './TabNavButtons'
import TabNavPanelContainer from './TabNavPanelContainer'

const ResourcesInner = () => {
  const { moduleName } = useResources()
  const { t } = useTranslation('index')

  return (
    <div className="tw-enable min-h-[70vh] font-[Roboto] lg:flex lg:flex-row">
      <div className="mx-2 flex-grow sm:mx-8">
        <h1 className="mb-4 mt-10 font-roboto-slab text-2xl text-3.5xl font-medium leading-normal text-primary">
          Resource Center
        </h1>
        <div className="mb-6 text-lg leading-7 text-[#4a5463]">
          {t(`${moduleName}_intro`)}
        </div>
        <TabNav defaultActiveTab="articles" mobileMode="dropdown">
          <TabNavButtons />
          <TabNavPanelContainer />
        </TabNav>
      </div>
    </div>
  )
}

const Resources = ({ locale, ...props }) => {
  return (
    <TranslationProvider
      locale={locale}
      translations={translations}
      scope="javascript.components.Resources"
    >
      <ResourcesProvider {...props}>
        <ResourcesInner />
      </ResourcesProvider>
    </TranslationProvider>
  )
}

export default Resources
